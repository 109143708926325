import React from 'react';
import ListWithRadioButton from '../common/ListWithRadioButton';
import {
  PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
  PETOLO_DOCUMENT_TYPE_INVOICE,
  PETOLO_DOCUMENT_TYPE,
  PETOLO_TREATMENT_DATE,
  useNewPetoloFormDataValue,
  PET_HEALTH_TREATMENT_CATEGORIES,
  PETOLO_TREATMENT_CATEGORY_OTHERS,
} from '../../claimFormData/newClaimFormData';
import {
  useSelectDocumentTypePetoloBackButtonLink,
  useSelectDocumentTypePetoloNextButtonLink,
} from './selectDocumentTypePetoloHooks';

const documentTypeConfig = [
  {
    label: 'petolo.new_claim.select_document_type.invoice',
    value: PETOLO_DOCUMENT_TYPE_INVOICE,
  },
  {
    label: 'petolo.new_claim.select_document_type.treatment_plan',
    value: PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
  },
];

const SelectDocumentTypePetolo = () => {
  const [documentType, setDocumentType] = useNewPetoloFormDataValue(PETOLO_DOCUMENT_TYPE);
  const [treatmentCategories] = useNewPetoloFormDataValue(PET_HEALTH_TREATMENT_CATEGORIES);

  const [, /*treatmentDate*/ setTreatmentDate] = useNewPetoloFormDataValue(PETOLO_TREATMENT_DATE);

  const setDocumentTypeWithExtraSteps = (newDocumentType) => {
    setDocumentType(newDocumentType);

    if (newDocumentType === PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN) {
      setTreatmentDate('');
    }
  };

  const backButtonLink = useSelectDocumentTypePetoloBackButtonLink();
  const nextButtonLink = useSelectDocumentTypePetoloNextButtonLink();

  let excludedDocumentTypes = [];
  if (Array.isArray(treatmentCategories)) {
    const selectedJustOneTreatmentCategory =
      treatmentCategories.length === 1 &&
      treatmentCategories[0] !== PETOLO_TREATMENT_CATEGORY_OTHERS;

    if (selectedJustOneTreatmentCategory)
      excludedDocumentTypes = [...excludedDocumentTypes, PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN];
  }

  const filteredDocumentTypeConfig = documentTypeConfig.filter(
    (config) => !excludedDocumentTypes.includes(config.value)
  );

  return (
    <ListWithRadioButton
      name="document_type_petolo"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId={'petolo.new_claim.select_document_type.page_title'}
      listConfig={filteredDocumentTypeConfig}
      selected={documentType}
      setSelected={setDocumentTypeWithExtraSteps}
    />
  );
};

export default SelectDocumentTypePetolo;
