import styled from 'styled-components';
import { Tab, TabList } from 'react-tabs';

import { Paper } from '../../common';
import { color } from '../../../theme/functions';
import { min_width_md } from '../../../theme/breakpoints';
import { Button } from '../Button';

export const StyledDentistsMapTitle = styled(Paper)`
  width: 100%;
  /* max-width: 450px; */
  padding: 0;
`;

export const StyledTabList = styled(TabList)`
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid ${color('gray_blue')};
  background-color: ${color('ultra_clear_light_blue')};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTab = styled(Tab)`
  flex: 1;
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 450;
  /* word-break: break-all; */
  border-bottom: 2px solid transparent;
  box-shadow: none;
  cursor: pointer;
  transition: border 0.25s ease-in-out;

  &.is-selected {
    font-weight: 700;
    border-bottom-color: ${color('primary')};
  }

  @media (min-width: ${min_width_md}) {
    font-size: 1rem;
  }
`;

export const StyledDentistsMapToggleHelpButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: 700;
  box-shadow: none;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  > img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
