import styled from 'styled-components';

export const StyledTreatmentCategoryContainer = styled.div`
  margin-top: 1em;
`;

export const StyledTreatmentCategory = styled.div`
  display: flex;
  word-break: break-word;
`;
export const RadioOptionContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const CheckboxOptionContainer = styled(RadioOptionContainer)`
  align-items: center;

  & > input {
    margin-right: 1em;
  }
`;

export const StyledTreatmentCategoryImage = styled.img`
  margin-left: 1em;
  margin-right: 1em;
  width: 3.5em;
  height: 3.5em;
`;
