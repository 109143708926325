import styled from 'styled-components';

import { min_width_lg } from '../../theme/breakpoints';
import { boxShadow, color } from '../../theme/functions';

const TextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 11.25em;
  margin: 0;
  padding: 0.5em;
  font-size: 1em;
  font-weight: 300;
  color: ${color('main_blue')};
  background-color: ${color('white')};
  border: ${color('main_blue')} 1px solid;
  border-radius: 4px;
  appearance: none;

  @media only screen and (min-width: ${min_width_lg}) {
    padding: 0.5em 1em;
  }

  &::placeholder {
    color: ${color('main_blue')};
    opacity: 0.6;
  }

  &:focus {
    outline: none;
    box-shadow: ${boxShadow('box_shadow_3')};
    border-color: ${color('main_blue')};
    color: ${color('main_blue')};
    ::placeholder {
      color: ${color('main_blue')};
    }
  }
`;

export default TextArea;
