import React from 'react';
import PropTypes from 'prop-types';
import OnboardCard from './OnboardCard';
import { imgLinks } from '../../../shared';
import {
  StyledClaimOnBoardCardParent,
  StyledClaimOnBoardCardWrapper,
  StyledClaimOnBoardImageEuro,
  StyledClaimOnBoardImageHand,
} from './UserClaimsOnboardDentolo.styled';
const UserClaimsOnboardCard1 = ({ titleHtml, bodyText }) => {
  return (
    <OnboardCard titleHtml={titleHtml} bodyText={bodyText}>
      <StyledClaimOnBoardCardParent>
        <StyledClaimOnBoardCardWrapper>
          <StyledClaimOnBoardImageEuro src={imgLinks['icon.user.claim.onboard.euro']} alt="" />
          <StyledClaimOnBoardImageHand src={imgLinks['icon.user.claim.onboard.hand']} alt="" />
        </StyledClaimOnBoardCardWrapper>
      </StyledClaimOnBoardCardParent>
    </OnboardCard>
  );
};

UserClaimsOnboardCard1.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
};

export default UserClaimsOnboardCard1;
