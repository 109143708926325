import { dateFormatRegex } from '../../../../shared/regex';

export const combineFullDateFromInputs = (day, month, year) => {
  let monthNum = month.length === 1 ? `0${month}` : month;
  let dayNum = day.length === 1 ? `0${day}` : day;
  let dateISOString = `${year}-${monthNum}-${dayNum}`;
  return dateISOString;
}

export const getInputFieldValuesFromDate = (date) => {
  let regex = new RegExp(dateFormatRegex)
  let isValidDate = regex.test(date)
  const dateObject = isValidDate ? new Date(date) : '';
  if (dateObject && !isNaN(dateObject)) {
    let dayValue = dateObject.getDate();
    let monthValue = dateObject.getMonth() + 1;
    let yearValue = dateObject.getFullYear();
    return ({
      day: dayValue < 10 ? `0${dayValue}` : `${dayValue}`,
      month: monthValue < 10 ? `0${monthValue}` : `${monthValue}`,
      year: `${yearValue}`,
    });
  } else {
    return ({ day: '', month: '', year: '' });
  }
}

export const validateMonthRange = date => {
  let dateObject = new Date(date);
  const [year, month, day] = date.split('-')
  if (dateObject.getFullYear() === +year && dateObject.getMonth() + 1 === +month && dateObject.getDate() === +day) {
    return true;
  }
  return false;
}
