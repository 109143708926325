import React from 'react';
import { useParams } from 'react-router-dom';
import CustomerNote from './CustomerNote';
import {
  PETOLO_CUSTOMER_NOTE,
  useNewPetoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useCustomerNotePetoloBackButtonLink,
  useCustomerNotePetoloNextButtonLink,
} from './customerNotePetoloHooks';

const CustomerNotePetolo = () => {
  const { claimId } = useParams();

  const backButtonLink = useCustomerNotePetoloBackButtonLink(claimId);
  const nextButtonLink = useCustomerNotePetoloNextButtonLink(claimId);

  const [customerNote, setCustomerNote] = useNewPetoloFormDataValue(PETOLO_CUSTOMER_NOTE);

  return (
    <CustomerNote
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      customerNote={customerNote}
      setCustomerNote={setCustomerNote}
      maxWords={80}
      pageTitleId={'petolo.new_claim.treatment_info_text.page_title'}
      pageDescriptionId={'petolo.new_claim.treatment_info_text.page_description'}
      placeholder={'petolo.new_claim.treatment_info_text.placeholder'}
      errorMessageId={'petolo.new_claim.treatment_info_text.error'}
    />
  );
};

export default CustomerNotePetolo;
