import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import cssVars from 'css-vars-ponyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import ErrorBoundary from '@honeybadger-io/react'
import { IntlProvider } from 'react-intl'
import { apolloClient, honeybadger } from './ApolloConfig'
import ErrorPlaceholder from './components/ui/ErrorPlaceholder'
import { polyfillReactIntl } from './locale/polyfillReactIntl'
import { messages } from './locale/messages'
import { DEFAULT_LOCALE } from './constants/DefaultValues'

// TODO: configure properly if IE support required
cssVars({
  include: 'style',
  onlyLegacy: true,
  watch: true,
})

const locale = DEFAULT_LOCALE

const render = async () => {
  const App = require('./App').default

  //Polyfill window.Intl for older browsers if required asynchronously
  try {
    await polyfillReactIntl(locale)
  } catch (error) {
    honeybadger.notify(error, {
      action: 'PolyfillError',
      message: error?.message,
    })
  }

  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <ErrorBoundary honeybadger={honeybadger} ErrorComponent={ErrorPlaceholder}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <App />
        </IntlProvider>
      </ErrorBoundary>
    </ApolloProvider>,
    document.getElementById('root'),
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}