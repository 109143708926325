// Breakpoints were chosen according to this guideline by Webflow which we use to host Tyrion application
// https://university.webflow.com/lesson/intro-to-breakpoints

const colors = {
  main_color_1: '#32ff96', //Light green
  main_color_2: '#003264', //Dark blue
  main_color_3: '#0a4c2a', //Dark green
  main_color_4: '#ffa445', //Orange
  main_color_5: '#ed7800', //Dark Orange
  main_color_6: '#7c94ad', //Greyish blue
  main_color_7: 'rgba(255, 94, 46, 1)', //Bright orange
  main_color_7_opacity: 'rgba(255, 94, 46, 0.8)', //Bright orange with opacity
  main_color_7_low_opacity: 'rgba(255, 94, 46, 0.3)', //Bright orange low opacity
  main_color_8: '#1498eb', //Bright light blue
  main_color_9: '#335b83', //Matte dark blue
  main_color_10: '#1B3562', //dark blue
  background_color_1: '#ffffff', //White
  background_color_2: '#d8edfc', //Light blue
  background_color_3: 'rgba(223,241,253,0.3)', //Light blue transparent
  background_color_4: '#e8e8e8', //Very light gray
  background_color_5: '#f7f7f8', //Very very light gray
  background_color_6: '#fff4d9', //Very light orange
  background_color_7: '#d8d8d8', //Light gray
  text_color_1: '#2a3c33', //Black
  text_color_2: '#a5add8', //Dark gray
  text_color_3: '#abbccc', //Light gray
  text_color_4: '#8aa0b6', //Medium gray
  text_color_5: '#7c95ae', //light gray blue
  text_color_6: '#8098b1', //Pale blue
  text_color_7: '#E7E7E8', //light gray border
  error_color_1: '#ff0000', //Red
};

export const palette = Object.assign({}, colors, {
  font_family_1: '"Circular TT", Arial',
  font_family_2: 'CentraNo2-Medium,fallback-font,sans-serif',

  box_shadow_1: `0 0 0.25em 0.0625em ${colors.background_color_7}`,
  box_shadow_2: `0 0 0.25em 0.0625em ${colors.main_color_1}`,
  box_shadow_3: `0 0 1em 0 rgba(0, 0, 0, 0.1)`,
  box_shadow_4: `0 0 0.625em 0 rgba(0, 0, 0, 0.1)`,
  box_shadow_5: `0px 0px 0px 3px ${colors.text_color_5}`,
});
