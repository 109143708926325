import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  PET_LIABILITY_AFFECTED_PARTY,
  PET_LIABILITY_REASON,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useUploadDocumentPetLiabilityBackButtonLink,
  useUploadDocumentPetLiabilityNextButtonLink,
} from './useUploadDocumentPetLiability';

import { FileUploader } from './FileUploader';
import { FileList } from './FileList';
import { BackButton, Paper, Paragraph, Title } from '../../../../components/common';
import { Button } from '../../../../components/ui';
import { StyledButtonContainer, StyledFileList } from './FileUploader.styled';

function UploadDocumentPetLiability(props) {
  const [liabilityReason] = useNewPetLiabilityFormDataValue(PET_LIABILITY_REASON);

  const [affectedParty] = useNewPetLiabilityFormDataValue(PET_LIABILITY_AFFECTED_PARTY);

  const { selectedDocuments, setSelectedDocuments } = props;

  const history = useHistory();

  const validSelectedDocuments = Array.isArray(selectedDocuments)
    ? selectedDocuments.filter((d) => d?.valid)
    : [];

  const backButtonLink = useUploadDocumentPetLiabilityBackButtonLink(
    liabilityReason,
    affectedParty
  );
  const nextButtonLink = useUploadDocumentPetLiabilityNextButtonLink();

  const addFileHandler = (currFiles) => {
    setSelectedDocuments((state) => [...state, ...currFiles]);
  };

  const removeFileHandler = (ev, id) => {
    ev && ev.preventDefault();
    ev && ev.stopPropagation();

    const updatedFileList = selectedDocuments?.filter((item) => item?.fileId !== id);
    setSelectedDocuments(updatedFileList);
  };

  const handleNextStep = () => {
    history.push(nextButtonLink);
  };

  const hasFiles = selectedDocuments?.length > 0;

  return (
    <>
      <BackButton label={<FormattedMessage id="common.button.back" />} to={backButtonLink} />

      <Title>
        <FormattedMessage
          id={
            hasFiles
              ? 'new_claim.upload.page.title.with.files'
              : 'new_claim.upload.page.title.without.files'
          }
        />
      </Title>

      {!hasFiles && (
        <Paragraph>
          <FormattedMessage id="pet_liability.new_claim.upload_document.page.description" />
        </Paragraph>
      )}

      {hasFiles && (
        <StyledFileList>
          <FileList files={selectedDocuments} onRemove={removeFileHandler} />
        </StyledFileList>
      )}

      <Paper>
        <FileUploader
          descriptionIdWithFiles="pet_liability.new_claim.upload_document.file_uploader.description_with_files"
          selectedFiles={selectedDocuments}
          setSelectedFiles={addFileHandler}
        />

        <StyledButtonContainer hasFiles={hasFiles}>
          <Button onClick={handleNextStep} disabled={!validSelectedDocuments.length}>
            <FormattedMessage id="common.button.next" />
          </Button>
        </StyledButtonContainer>
      </Paper>
    </>
  );
}

export default UploadDocumentPetLiability;
