import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { invalidAmountRegex } from '../../shared/regex';

import { ErrorMessage } from './';
import {
  StyledInputCurrency,
  StyledInputCurrencyContainer,
  StyledSymbolSuffix,
} from './styled/InputCurrency.styled';

function InputCurrency(props) {
  const { errorMessageId, amount, maxLength, suffix, onChange, onBlur, onKeyDown, ...rest } = props;

  const [isValidAmount, setIsValidAmount] = useState(true);

  const handleAmountKeyDown = (ev) => {
    if (!ev) return;

    const regex = new RegExp(invalidAmountRegex);

    if (regex.test(ev?.key)) {
      ev.preventDefault();
    }
  };

  const handleAmountChange = (ev) => {
    if (!ev) return;

    const value = ev?.target?.value;
    let splitValueWithDecimal = value?.split('.');

    if (splitValueWithDecimal[0]?.length > maxLength) return;

    setIsValidAmount(true);
    onChange(ev?.target?.value);
  };

  const handleAmountBlur = (ev) => {
    if (!ev) return;

    let value = ev?.target?.value;
    let amt = '';

    if (parseInt(value) < 1 || isNaN(value)) {
      setIsValidAmount(false);
    } else if (value) {
      value = parseFloat(value).toFixed(2);
      value += /^\d+\.\d{1}$/gi.test(value) ? '0' : '';

      setIsValidAmount(true);
      amt = value;
    }

    onBlur ? onBlur(amt) : onChange(amt);
  };

  return (
    <>
      <StyledInputCurrencyContainer>
        <StyledInputCurrency
          type="number"
          value={amount}
          onBlur={handleAmountBlur}
          onChange={handleAmountChange}
          onKeyDown={handleAmountKeyDown}
          data-testid="input-currency"
          {...rest}
        />

        {suffix && <StyledSymbolSuffix>{suffix}</StyledSymbolSuffix>}
      </StyledInputCurrencyContainer>

      {!isValidAmount && (
        <ErrorMessage>
          <FormattedMessage id={errorMessageId} />
        </ErrorMessage>
      )}
    </>
  );
}

export default InputCurrency;

InputCurrency.defaultProps = {
  maxLength: 7,
  errorMessageId: 'common.form.error.invalid_amount',
  name: 'amount',
  placeholder: '70,00',
  suffix: '€',
};

InputCurrency.propTypes = {
  maxLength: PropTypes.number,
  errorMessageId: PropTypes.string,
  suffix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
};
