import styled from 'styled-components';

import { color } from '../../theme/functions';
import { min_width_md } from '../../theme/breakpoints';

import { Link } from '../ui';
import { Container } from '../common';
import { variantStyles } from '../ui/styled/Button.styled';

export const StyledPolicyDetailsContainer = styled(Container)`
  max-width: 840px;
`;

export const StyledPolicyDetailsTitle = styled.h4`
  font-size: 1.1rem;
  font-weight: 700;
  color: ${color('main_blue')};
  opacity: 0.5;

  @media (min-width: ${min_width_md}) {
    font-size: 1.25rem;
  }
`;

export const StyledPolicyDetailsPolicyName = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${color('main_blue')};

  @media (min-width: ${min_width_md}) {
    font-size: 1.875rem;
  }
`;

export const StyledPolicyDetailsLink = styled(Link)`
  margin: 1.5rem auto 0;
`;

export const StyledPolicyDetailsText = styled.div`
  font-size: 1.1rem;
  font-weight: 450;
  color: ${color('main_blue')};
  opacity: 0.5;
  a {
    ${variantStyles('nostyle')}
  }
  @media (min-width: ${min_width_md}) {
    font-size: 1.185rem;
  }
`;

export const StyledPolicyDetailsTextLg = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${color('main_blue')};
`;

export const StyledPolicyDetailsSectionTitle = styled(StyledPolicyDetailsTextLg)`
  margin-bottom: 1rem;
`;

export const StyledPolicyDetailsList = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};

  & + & {
    margin-top: 1rem;
  }
`;
