export const en = {
  // Common
  'common.button.back.home': 'Zur Startseite',
  'common.button.back': 'Zurück',
  'common.button.next': 'Weiter',
  'common.button.yes': 'Ja',
  'common.button.no': 'Nein',
  'common.button.update': 'Bearbeiten',
  'common.button.upload.invoice': 'Rechnungen hochladen',
  'common.button.close': 'Schließen',
  'common.button.upload.document': 'Dokumente hochladen',
  'common.title.history': 'Historie',
  'common.title.status': 'Status',
  'common.first.name': 'Vorname',
  'common.last.name': 'Nachname',
  'common.title.imprint': 'Impressum',
  'common.title.data.protection': 'Datenschutz',
  'common.title.help': 'Hilfe',
  'common.title.cookie.settings': 'Cookie Einstellungen',

  'common.form.error.invalid_amount': 'Bitte geben Sie einen gültiges Betrag ein',
  'common.form.error.invalid_time': 'Bitte geben Sie eine gültige Zeit ein',
  'common.form.error.invalid_email': 'Bitte geben Sie eine gültige Email-Adresse ein',

  // Menu
  'menu.header.item.my_profile': 'Mein Profil',
  'menu.header.item.reimbursement': 'Kostenerstattungen',
  'menu.header.item.claim_report': 'Schadenmeldungen',
  'menu.header.item.find_dentist': 'Zahnärzt:innen finden',
  'menu.header.item.find_vet': 'Online Tierärzt:innen',
  'menu.header.item.referrals': 'Freund:innen werben',
  'menu.header.item.miscellaneous': 'Sonstiges',
  'menu.header.item.visit_dentolo': 'Besuchen Sie dentolo.de',
  'menu.header.item.help': 'Hilfe',

  'menu.header.item.tarif': 'Tarifdetails',
  'menu.header.item.contact_details': 'Kontaktdaten',
  'menu.header.item.docs': 'Vertragsunterlagen',
  'menu.header.item.premiums': 'Versicherungsbeiträge',
  'menu.header.item.contribution_statement': 'Beitragsbescheinigung',
  'menu.header.item.help_center': 'Hilfe-Center',
  'menu.header.item.contacts': 'Kontakt',

  // Contact
  'contact.page.main.title': 'Kontakt',
  'contact.page.main.description':
    'Bei Fragen oder Problemen, können Sie unserem Expertenteam jederzeit eine Nachricht senden.',
  'contact.page.main.phone':
    'In dringenden Fällen oder bei komplexen Sachverhalten können Sie uns telefonisch erreichen.',
  'contact.page.main.working.days': 'Montag - Freitag',
  'contact.page.main.working.hours': '9:00 - 18:00 Uhr',
  'contact.page.main.form.compose': 'Nachricht verfassen',
  'contact.page.main.form.sender': 'Absender: ',
  'contact.page.main.form.reference': 'Betreff:',
  'contact.page.main.form.message': 'Ihre Nachricht',
  'contact.page.main.form.send': 'Absenden',
  'contact.page.main.sent': 'Ihre Nachricht wurde erfolgreich versendet.',
  'contact.page.main.register.email':
    'Wir werden uns so bald wie möglich per E-Mail bei Ihnen melden.',
  'contact.page.main.back': 'Zurück zur Startseite',
  'contact.page.main.fail': 'Fehler! <br /> Es gab ein Problem beim Versenden der Nachricht.',
  'contact.page.main.cancel': 'Abbrechen',
  'contact.page.main.try.again': 'Erneut versuchen',
  'contact.page.main.cancellation.text1':
    'Wenn Sie Ihren Vertrag kündigen oder widerrufen wollen, können Sie dies',
  'contact.page.main.cancellation.here_link': 'hier',
  'contact.page.main.cancellation.text2': 'selbst durchführen.',

  // Policy Info
  'policy.info.title': 'Mein Schutz',
  'policy.info.your.policy': 'Ihr {policy} auf einen Blick',
  'policy.info.policy.details': 'Details zum Tarif',
  'policy.info.policy.status.title': 'Vertragsstatus',
  'policy.info.policy.status.accepted': 'Aktiv',
  'policy.info.policy.status.active': 'Aktiv',
  'policy.info.policy.status.pending': 'Pending',
  'policy.info.policy.status.ended': 'Beendet',
  'policy.info.policy.active.now-or-past': 'Schutz aktiv seit',
  'policy.info.policy.active.future': 'Schutz aktiv ab',
  'policy.info.policy.active.canceled': 'Schutz aktiv bis',
  'policy.info.policy.montly.price': 'Monatliche Prämie',
  'policy.info.my.claims': 'Meine Leistungsfälle',
  'policy.info.new.claim.description':
    'Sie möchten einen neuen Leistungsfall einreichen oder Unterlagen zu einem bestehenden hinzufügen?',
  'policy.info.document.notification.submitted': 'Unterlagen erhalten',
  'policy.info.document.notification.description':
    'Aktuell werden Ihre Unterlagen bearbeitet. Sie werden per E-Mail benachrichtigt, sobald Sie weitere Details einsehen können.',
  'policy.info.see.contracts': 'Vertragsunterlagen ansehen',
  'policy.info.your_contact_details': 'Ihre Kontaktdaten',
  'policy.info.your_contract_documents': 'Ihre Vertragsunterlagen',
  'policy.info.your_claims': 'Ihre Leistungsfälle',
  'policy.info.your_payments': 'Ihre Versicherungsbeiträge',
  'policy.cancellation.text1': 'Klicken Sie',
  'policy.cancellation.here_link': 'hier',
  'policy.cancellation.text2': ', wenn Sie Ihren Vertrag kündigen möchten.',

  'policy.info.missing.iban.title': 'Für besonders schnelle Rückerstattungen',
  'policy.info.missing.iban.message1': 'Geben Sie für eine noch schnellere Abwicklung ',
  'policy.info.missing.iban.link': 'hier Ihre IBAN ',
  'policy.info.missing.iban.message2': ' an',
  'bank.details.add.missed.iban.modal.title':
    'Bitte geben Sie eine gültige IBAN für Ihre Rückerstattungen an',
  'bank.details.add.missed.iban.modal.desc': `Beachten Sie, dass somit alle Rückerstattungen an die neuen Kontodaten überwiesen werden. Bitte prüfen Sie, ob Ihre Kontodaten korrekt sind, um einen reibungslosen Zahlungsvorgang gewährleisten zu können.`,
  // Policy Card
  'policy.card.label.name': 'Name',
  'policy.card.label.policy': 'Tarif',
  'policy.card.label.pet.name': 'Ihr Haustier',
  'policy.card.label.addon': 'AKUT-Soforthilfe',
  'policy.card.label.contract_number': 'Versichertennummer',
  'policy.card.label.monthly_premium': 'Monatlicher Beitrag',
  'policy.card.label.account_number': 'Kundennummer',
  'policy.card.contact.and.billing.info': 'Kontakt- und Rechnungsinformationen',
  'policy.card.label.addon.trial_month': 'Probemonat',
  'policy.card.addon.trial_month.from': 'Ab',

  // Policy Covergae Info
  'policy.covergae_info.title': 'Meine Leistungen',
  'policy.covergae_info.additional_advantages.title': 'Weitere Vorteile',
  'policy.covergae_info.all_details.button': 'Alle Leistungen im Detail',

  // Policy All Details
  'policy.all_details.title': 'Ihr Schutz im Detail',
  'policy.all_details.conditions.title': 'Rahmenbedingungen',
  'policy.all_details.premium.title': 'Prämienstaffelung',
  'policy.all_details.budget.prefix': 'Im',
  'policy.all_details.budget.last_prefix': 'Ab dem',
  'policy.all_details.budget.only_prefix': 'Ab',
  'policy.all_details.budget.suffix': 'Jahr',
  'policy.all_details.budget.unlimited': 'unbegrenzt',
  'policy.all_details.premium.years': 'Jahre',

  // Policy Personal Details
  'policy.details.my.policy': 'Details zu meinem Schutz',
  'policy.details.my.contact.info': 'Meine Kontaktdaten',
  'policy.details.contact.info.name': 'Name',
  'policy.details.contact.info.email': 'Email',
  'policy.details.contact.info.phone': 'Telefon',
  'policy.details.contact.info.address': 'Adresse',
  'policy.details.contact.info.dob': 'Geburtstag',
  'policy.details.contact.info.address_details': 'Adresszusatz',
  'policy.details.my.billing.info': 'Meine Kontoverbindung',
  'policy.details.account.billing.desc':
    'Auf folgendem Konto werden die monatlichen Prämien abgebucht sowie Erstattungen ausgezahlt.',
  'policy.details.billing.info.account_holder': 'Kontoinhaber',
  'policy.details.billing.info.iban': 'IBAN',
  'policy.details.billing.info.billing_day': 'Abbuchung des Beitrags',
  'policy.details.found.errors':
    'Sie können auf den Stift klicken, um eine Änderung vorzunehmen. Wollen Sie die versicherte Person ändern oder einen weiteren Vertrag abschließen? Haben Sie noch Fragen oder wollen Sie weitere Daten ändern?',
  'policy.details.contact.us': 'Kontaktieren Sie uns einfach.',
  'policy.details.to.contact': 'Zum Kundendienst',
  'policy.details.billing_day_1': 'Zum 15. des Monats',
  'policy.details.billing_day_15': 'Zum 15. des Monats',
  'policy.details.insured.person.info.title': 'Versicherte Person',
  'policy.details.insured.person.info.name': 'Name',
  'policy.details.insured.person.info.address': 'Adresse',
  'policy.details.pet.info.title': 'Versichertes Tier',
  'policy.details.pet.info.name': 'Name',
  'policy.details.pet.info.transponder_code': 'Transpondercode',
  'policy.details.pet.info.transponder_code.missing': 'Kein Transpondercode vorhanden',

  // Digital payment
  'policy.details.my.refund_account.info': 'Ihr Konto für Erstattungen',
  'policy.details.my.refund_account.desc':
    'Die Kostenerstattungen werden auf das folgende Konto ausgezahlt',
  'bank.details.edit_refund_account.modal.title': 'Änderung des Erstattungskontos',
  'bank.details.edit_refund_account.modal.desc':
    'Bitte beachten Sie, dass durch die Aktualisierung Ihrer angegebenen Kontoinformationen alle künftigen Rückerstattungen auf das neue Konto überwiesen werden. Bitte überprüfen Sie, ob Ihre neuen Kontodaten korrekt sind, um einen reibungslosen Zahlungsvorgang zu gewährleisten.',

  'policy.details.my.digital.payment.info': 'Monatliche Beitragszahlung',
  'policy.details.account.digital.payment.desc':
    'Ihre monatlichen Beiträge werden über die folgende Zahlungsmethode abgebucht',
  'policy.details.account.digital.payment.expire': 'Ablauf: ',
  'policy.details.account.digital.payment.paypal': 'PayPal',
  'policy.details.account.digital.payment.googlePay': 'Google Pay',
  'policy.details.account.digital.payment.applePay': 'Apple Pay',

  //Personal Details Edit Form
  'personal.details.form.title': 'Meine Kontaktdaten ändern',
  'personal.details.form.gender.label': 'Anrede',
  'personal.details.form.gender.male.label': 'Herr',
  'personal.details.form.gender.female.label': 'Frau',
  'personal.details.form.email.label': 'E-Mail',
  'personal.details.form.email.error.message': 'E-Mail muss vollständig ausgefüllt werden',
  'personal.details.form.phone.label': 'Telefonnummer',
  'personal.details.form.phone.error.message': 'Telefonnummer muss vollständig ausgefüllt werden',
  'personal.details.form.street.house.number.label': 'Straße, Nummer',
  'personal.details.form.postcode.city.label': 'Postleitzahl, Ort',
  'personal.details.form.address.error.message': 'Adresse muss vollständig ausgefüllt werden',
  'personal.details.form.address.details.label': 'Adresszusatz',
  'personal.details.form.additional.details':
    'Für Änderungen zum Namen schreiben Sie uns bitte eine Email an {serviceEmail} oder nutzen Sie dazu das Kontaktformular.',
  'personal.details.form.submit.button': 'Angaben ändern',

  //Insured Person Details Edit Form
  'insured.person.details.form.title': 'Versicherte Person ändern',
  'insured.person.details.form.gender.label': 'Anrede',
  'insured.person.details.form.gender.male.label': 'Herr',
  'insured.person.details.form.gender.female.label': 'Frau',
  'insured.person.details.form.street.house.number.label': 'Straße, Nummer',
  'insured.person.details.form.postcode.city.label': 'Postleitzahl, Ort',
  'insured.person.details.form.address.error.message': 'Adresse muss vollständig ausgefüllt werden',
  'insured.person.details.form.additional.details':
    'Für Änderungen zum Namen schreiben Sie uns bitte eine Email an service@dentolo.de oder nutzen Sie dazu das Kontaktformular.',
  'insured.person.details.form.submit.button': 'Angaben ändern',

  //Pet Details Edit Form
  'pet.details.form.title': 'Versichertes Tier',
  'pet.details.form.name.label': 'Name',
  'pet.details.form.transponder_code.label': 'Transpondercode',
  'pet.details.form.transponder_code.placeholder': 'Diese ist eine 15-stellige Zahl',
  'pet.details.form.transponder_code.validation.message': 'Transpondercode muss 15 Ziffern haben.',
  'pet.details.form.additional.details.line.1':
    'Zur Erstattung eines Leistungsfalls, benötigen wir Ihre 15-stellige Chipnummer, die auf dem Transponder (Chip) Ihres Tieres gespeichert ist.',
  'pet.details.form.additional.details.line.2':
    'Für Änderungen zum Namen schreiben Sie uns bitte eine Email an service@petolo.de oder nutzen Sie dazu das Kontaktformular.',
  'pet.details.form.submit.button': 'Angaben ändern',

  //digital payment edit form
  'customer.details.digital.payment.edit.title': 'Monatliche Beitragszahlung anpassen',
  'customer.details.digital.payment.billing.date.title': 'Abbuchungsdatum ändern',
  'customer.details.digital.payment.billing.date.start.label': ' Zum 1. des Monats',
  'customer.details.digital.payment.billing.date.middle.label': 'Zum 15. des Monats',
  'customer.details.digital.payment.details.title': 'Aktuelle Zahlunsgmethode',
  'customer.details.digital.payment.novalnet.form.title': 'Andere Zahlungsart wählen',
  'customer.details.digital.payment.success.title': 'Wir haben Ihre Angaben erfolgreich geändert.',
  'customer.details.digital.payment.failure.title':
    'Änderung der Zahlungsmethode aktuell nicht möglich',
  'customer.details.digital.payment.failure.desc':
    'Leider steht dieser Service aktuell nicht zur Verfügung. Wir entschuldigen uns für die Unannehmlichkeiten und bitten Sie, es später erneut zu versuchen.',
  'customer.details.digital.payment.success.btn': 'Zurück zu Ihren Kontaktdaten',

  //Bank Details Edit Form
  'bank.details.form.title': 'Meine Kontoverbindung ändern',
  'bank.details.form.error.firstname': 'Vorname muss ausgefüllt werden',
  'bank.details.form.error.lastname': 'Name muss ausgefüllt werden',
  'bank.details.form.error.iban_invalid': 'Ungültiges IBAN-Format. Bitte überprüfen',
  'bank.details.form.error.iban_empty': 'IBAN muss ausgefüllt werden',
  'bank.details.form.error.iban_not_sepa':
    'Diese IBAN ist nicht gültig. Bitte geben Sie eine IBAN der offiziellen SEPA Länder an, um fortzufahren.',
  'bank.details.form.error.billingDay': 'Abbuchung des beitrags muss ausgefüllt werden',
  'bank.details.form.note_1':
    'Mit dieser Änderung wird ein neues SEPA Mandat für Sie generiert, damit wir zukünftige Beiträge direkt von Ihrem Konto abbuchen können.',
  'bank.details.form.note_2':
    'Bitte beachten Sie, dass die Änderungen erst ab dem nächsten Abbuchungszeitraum gelten und für bereits angeforderte Zahlungen noch die alten Kontodaten verwendet werden.',
  'bank.details.form.submit_button': 'Angaben ändern',
  'bank.details.update.success.title': 'Wir haben Ihre Angaben erfolgreich geändert.',
  'bank.details.update.success.message':
    'Falls Sie noch weitere Fragen haben, kontaktieren Sie bitte unseren Kundendienst.',
  'bank.details.update.failure.title': 'Fehler! Es gab ein Problem mit der Änderung Ihrer Daten.',
  'bank.details.update.failure.message':
    'Bei bestehenden Problemen kontaktieren Sie bitte unseren Kundendienst.',

  // Policies
  'policies.vorsorgeschutz': 'Vorsorgeschutz',
  'policies.akutschutz': 'Akutschutz',
  'policies.vorsorgeschutz-kompakt': 'Vorsorgeschutz Kompakt',
  'policies.akutschutz-kompakt': 'Akutschutz Kompakt',
  'policies.rundumschutz': 'Rundumschutz',
  'policies.zahnschutz-basis': 'Zahnschutz Basis',
  'policies.zahnschutz-komfort': 'Zahnschutz Komfort',
  'policies.zahnschutz-premium': 'Zahnschutz Premium',
  'policies.zahnschutz-premium-plus': 'Zahnschutz Premium Plus',
  'policies.premium_plus': 'Zahnschutz Premium Plus',
  'policies.premium': 'Premium',
  'policies.addons.im_coverage': 'AKUT-Soforthilfe',
  'policies.hundekrankenversicherung-komfort': 'Hundekrankenversicherung Komfort',
  'policies.hundekrankenversicherung-premium': 'Hundekrankenversicherung Premium',
  'policies.hundekrankenversicherung-premium-plus': 'Hundekrankenversicherung Premium Plus',
  'policies.tierkrankenversicherung-komfort': 'Tierkrankenversicherung Komfort',
  'policies.tierkrankenversicherung-premium': 'Tierkrankenversicherung Premium',
  'policies.tierkrankenversicherung-premium-plus': 'Tierkrankenversicherung Premium Plus',
  'policies.zahnschutz-prophylaxe': 'Zahnschutz Prophylaxe',

  // File Upload Status
  'file.upload.title': 'Datei Upload',
  'file.upload.success.title.html': 'Hochladen erfolgreich!',
  'file.upload.success.text.html.plural':
    'Es wurden {filesNum} Dateien übertragen.  \n Wir werden diese prüfen und Sie per E-Mail über weitere Schritte kontaktieren.',
  'file.upload.success.text.html.singular':
    'Es wurde {filesNum} Datei übertragen.  \n Wir werden diese prüfen und Sie per E-Mail über weitere Schritte kontaktieren.',
  'file.upload.success.text.html.plural.delay':
    'Es wurden {filesNum} Dateien übertragen.  \n Aufgrund der erhöhten Anzahl an Anfragen, kommt es zu einer Verzögerung bei der Bearbeitung Ihres Leistungsfalles. Die Bearbeitung kann aktuell bis zu 14 Tage dauern. Wir bedauern die Verzögerung, werden Ihre Unterlagen aber schnellstmöglich prüfen und Sie per E-Mail über weitere Schritte informieren.',
  'file.upload.success.text.html.singular.delay':
    'Es wurde {filesNum} Datei übertragen.  \n Aufgrund der erhöhten Anzahl an Anfragen, kommt es zu einer Verzögerung bei der Bearbeitung Ihres Leistungsfalles. Die Bearbeitung kann aktuell bis zu 14 Tage dauern. Wir bedauern die Verzögerung, werden Ihre Unterlagen aber schnellstmöglich prüfen und Sie per E-Mail über weitere Schritte informieren.',
  'file.upload.success.back.home.btn': 'Zurück zur Startseite',
  'file.upload.failure.title.html': 'Fehler! <br />Es gab ein Problem mit dem Datei-Upload.',
  'file.upload.failure.text.html':
    'Bei bestehenden Problemen kontaktieren Sie bitte unseren Kundendienst.',
  'file.upload.failure.cancel.btn': 'Abbrechen',
  'file.upload.failure.try.again.btn': 'Erneut versuchen',
  'file.upload.pet_liability.success.title.html':
    'Vielen Dank, wir haben Ihre Schadenmeldung erhalten.',
  'file.upload.pet_liability.success.text.html':
    'Für die Schadenbearbeitung erhalten wir Unterstützung von einem Experten-Team unseres Versicherungspartners DA Direkt.  \n &nbsp;  \n &nbsp; Dieser wird Sie zeitnah über die nächsten Schritte informieren.',

  //# User claims
  'user_claims.page.title': 'Meine Kostenerstattungen',
  'user_claims.page.description':
    'Hier können Sie einen neuen Leistungsfall erstellen und Details zu aktuellen oder vergangenen Erstattungen einsehen. Wenn Sie bereits einen Leistungsfall erstellt haben, können Sie mit einem Klick auf "Bestehender Leistungsfall" fehlende Dokumente hochladen.',
  'user_claims.button.new_claim_document': 'Neuer Leistungsfall',
  'user_claims.button.existing_claim_document': 'Bestehender Leistungsfall',
  'user_claims.pet_liability.page.title': 'Meine Schadenmeldungen',
  'user_claims.pet_liability.page.description':
    'Hier können Sie Details zu allen aktuellen oder bisherigen Schadenmeldungen sehen oder eine neue Meldung einreichen.',
  'user_claims.pet_liability.button.new_claim_report': 'Schaden melden',

  //# Treatment categories - DENTAL
  'treatment.category.null': 'Zahnbehandlung',
  'treatment.category.dummy_dental_category_for_account_area': 'Zahnbehandlung',
  'treatment.category.implant_basic': 'Implantatbehandlung',
  'treatment.category.implant_complex': 'Implantatbehandlung',
  'treatment.category.total': 'Prothesenbehandlung',
  'treatment.category.combined': 'Prothetikbehandlung',
  'treatment.category.crown': 'Kronenbehandlung',
  'treatment.category.bridge': 'Brückenbehandlung',
  'treatment.category.fixed': 'Prothetikbehandlung',
  'treatment.category.telescope': 'Prothesenbehandlung',
  'treatment.category.conservative': 'Zahnerhaltungsbehandlung',
  'treatment.category.endodontic': 'Wurzelkanalbehandlung',
  'treatment.category.veneer': 'Veneerbehandlung',
  'treatment.category.misc': 'Zahnbehandlung',
  'treatment.category.pzr': 'Zahnreinigung',
  'treatment.category.periodontic': 'Parodontitisbehandlung',
  'treatment.category.interim_care': 'Interimsversorgung',
  'treatment.category.broken_jaw': 'Kieferbruch',
  'treatment.category.request_for_info': 'Auskunftsersuchen',
  'treatment.category.x_ray': 'Röntgen',
  'treatment.category.orthodontics': 'Kieferorthopädie',
  'treatment.category.general_anesthetic': 'Vollnarkose',

  //# Treatment categories - PET
  'treatment.category.pet_null': 'Tiermedizinische Behandlung',
  'treatment.category.dummy_pet_category_for_account_area': 'Tiermedizinische Behandlung',
  'treatment.category.pet_diagnosis': 'Diagnostik',
  'treatment.category.pet_worming': 'Wurmkur',
  'treatment.category.pet_health_check': 'Gesundheitscheck',
  'treatment.category.pet_vaccination': 'Schutzimpfung',
  'treatment.category.pet_fleas_and_ticks': 'Floh- und Zeckenvorsorge',
  'treatment.category.pet_gastrointestinal_disease': 'Magen-Darm-Erkrankung',
  'treatment.category.pet_dental_prophylaxis': 'Zahnprophylaxe',
  'treatment.category.pet_castration_or_sterilization': 'Kastration oder Sterilisation',
  'treatment.category.pet_euthanasia': 'Einschläferung',
  'treatment.category.pet_rescue': 'Such-, Rettungs- und Bergungskosten',
  'treatment.category.pet_desensitization': 'Hyposensibilisierung',
  'treatment.category.pet_entering_medication': 'Eingeben von Medikamenten',
  'treatment.category.pet_implant': 'Implantation eines Arzneimittels',
  'treatment.category.pet_injection': 'Injektion, Instillation, Infusion',
  'treatment.category.pet_eyes_treatment': 'Behandlungen an den Augen',
  'treatment.category.pet_respiratory_treatment': 'Behandlungen am Atmungsapparat',
  'treatment.category.pet_muscles_treatment': 'Behandlungen am Bewegungsapparat',
  'treatment.category.pet_fracture': 'Frakturbehandlung',
  'treatment.category.pet_digestive_system': 'Behandlungen am Verdauungsapparat',
  'treatment.category.pet_dental': 'Zahnbehandlung',
  'treatment.category.pet_spine_and_nervous_system_treatment':
    'Behandlungen an der Wirbelsäule und Nervensystem',
  'treatment.category.pet_eyes_operation': 'Operationen an den Augen',
  'treatment.category.pet_respiratory_operation': 'Operationen am Atmungsapparat',
  'treatment.category.pet_muscles_operation': 'Operationen am Bewegungsapparat',
  'treatment.category.pet_amputation': 'Amputation',
  'treatment.category.pet_heart_operation': 'Operation am Herzen',
  'treatment.category.pet_digestive_system_operation': 'Operationen am Verdauungsapparat',
  'treatment.category.pet_spine_and_nervous_system_operation':
    'Operation an der Wirbelsäule und Nervensystem',
  'treatment.category.pet_prosthesis': 'Prothese',
  'treatment.category.pet_telemedicine': 'Telemedizin',
  'treatment.category.pet_misdevelopment': 'Fehlentwicklung',

  //# Treatment categories - PET LIABILITY
  'treatment.category.pet_liability_damage_to_small_object': 'Kleinerer Sachschaden',
  'treatment.category.pet_liability_damage_to_large_object': 'Größerer Sachschaden',
  'treatment.category.pet_liability_damage_to_motor_vehicle': 'Beschädigtes KFZ',
  'treatment.category.pet_liability_damage_to_bicycle_or_similar': 'Beschädigtes Verkehrsmittel',
  'treatment.category.pet_liability_damage_to_an_apartment': 'Wohnungsschaden',
  'treatment.category.pet_liability_damage_to_a_building': 'Gebäudeschaden',
  'treatment.category.pet_liability_damage_to_a_property': 'Grundstückschaden',
  'treatment.category.pet_liability_injured_animal': 'Verletztes Tier',
  'treatment.category.pet_liability_injured_person': 'Verletzte Person',
  'treatment.category.pet_liability_other': 'Sonstiger Schaden',

  // Claim Statuses
  'claim.status.last.update': 'Letzte Aktualisierung:',
  'claim.status.label.new': 'In Bearbeitung',
  'claim.status.label.waiting_for_information': 'Wartend',
  'claim.status.label.reimbursement_acknowledged': 'Deckungszusage',
  'claim.status.label.waiting_for_payout': 'Auszahlung',
  'claim.status.label.closed': 'Ausgezahlt',
  'claim.status.label.declined': 'Abgelehnt',
  'claim.status.label.blocked': 'Blockiert',
  'claim.status.label.cancelled': 'Storniert',

  // History item claim statuses
  'claim.history.status.new': 'In Bearbeitung',
  'claim.history.status.processing': 'In Bearbeitung',
  'claim.history.status.waiting_for_information': 'Wartend',
  'claim.history.status.processing_completed': 'Bearbeitung abgeschlossen',
  'claim.history.status.declined': 'Abgelehnt',
  'claim.history.status.reimbursement_acknowledged': 'Deckungszusage',
  'claim.history.status.checking_invoice': 'Rechnung wird geprüft',
  'claim.history.status.waiting_for_payout': 'Auszahlung',
  'claim.history.status.closed': 'Ausgezahlt',
  'claim.history.status.blocked': 'Blockiert',
  'claim.history.status.cancelled': 'Storniert',

  // Claim Status details
  // Neu
  'claim.status.details.claim_opened': 'Leistungsfall eröffnet',
  // Wartend
  'claim.status.details.treatment_or_costplan_missing': 'Genehmigter Heil- und Kostenplan fehlt',
  'claim.status.details.information_request_missing': 'Auskunftsersuchen fehlt',
  'claim.status.details.patient_file_missing': 'Patientenakte fehlt',
  'claim.status.details.invoice_missing': 'Rechnung fehlt',
  'claim.status.details.documents_missing': 'Unterlagen fehlen',
  'claim.status.details.pet_medical_card_is_missing': 'Krankenakte fehlt',
  // Deckungszusage
  'claim.status.details.reimbursement_acknowledged': 'Deckung zugesagt',
  'claim.status.details.partial_reimbursement_acknowledged': 'Teildeckung zugesagt',
  // Auszahlung
  'claim.status.details.reimbursement_will_be_paid': 'Erstattung wird ausgezahlt',
  'claim.status.details.partial_reimbursement_will_be_paid': 'Teil–Erstattung wird ausgezahlt',
  'claim.status.details.reimbursement_paid': 'Erstattung ausgezahlt',
  'claim.status.details.partial_reimbursement_paid': 'Teil–Erstattung ausgezahlt',

  'claim.status.details.help.center.text1':
    'Weitere Informationen über den Erstattungsbetrag auf der Rechnung finden Sie',
  'claim.status.details.help.center.link': 'hier.',
  // Blockiert
  'claim.status.details.contract_signature_missing': 'Vertragsbestätigung fehlt',
  'claim.status.details.withdrawal_period_still_running': 'Widerrufsfrist läuft noch',
  'claim.status.details.outstanding_payments': 'Ausstehende Beitragszahlungen',
  'claim.status.details.trial_month': 'Versicherung/Tarif noch nicht aktiv (Probemonat)',
  // Abgelehnt - TODO: update details
  'claim.status.details.treatment_before_contract_start':
    'Behandlung erfolgte vor Versicherungsbeginn',
  'claim.status.details.known_of_treatment_before_contract_start_by_plan':
    'Behandlungsbedürftigkeit laut Heil- und Kostenplan vor Versicherungsbeginn bekannt',
  'claim.status.details.known_of_treatment_before_contract_start_by_dentist_request':
    'Behandlungsbedürftigkeit laut Auskunftsersuchen des Zahnarztes vor Versicherungsbeginn bekannt',
  'claim.status.details.known_of_treatment_before_contract_start_by_pretreatment_provide':
    'Behandlungsbedürftigkeit laut Auskunftsersuchen des Vorbehandlers vor Versicherungsbeginn bekannt',
  'claim.status.details.treatment_before_contract_start_by_invoice':
    'Behandlung erfolgte laut Rechnung vor Versicherungsbeginn',
  'claim.status.details.no_budget': 'Leistungsbegrenzung ausgeschöpft',
  'claim.status.details.no_coverage': 'Keine Deckung durch den Versicherungstarif',
  'claim.status.details.no_coverage_no_medical_need':
    'Keine Deckung durch den Versicherungstarif, da keine medizinische Notwendigkeit',
  'claim.status.details.no_coverage_orthodontics':
    'Keine Deckung durch den Versicherungstarif / Kieferorthopädie durch Tarif nicht abgedeckt',
  'claim.status.details.coverage_for_children_only_for_accident':
    'Abdeckung von Kindern nur bei Unfall',
  'claim.status.details.max_amount_of_tooth_cleanings_reached':
    'Anzahl maximal gedeckter Professioneller Zahnreinigungen pro Jahr erreicht',
  'claim.status.details.no_coverage_cosmetic_measures': 'Keine Deckung von kosmetischen Maßnahmen',
  'claim.status.details.not_the_insured_animal': 'Behandeltes Tier ist nicht das versicherte Tier',
  'claim.status.details.pet_health_budget_exhausted': 'Gesundheitsbudget ausgeschöpft',
  'claim.status.details.pet_sos_budget_exhausted': 'SOS-Budget ausgeschöpft',
  'claim.status.details.known_of_treatment_before_contract_start_by_veterinarian_request':
    'Behandlungsbedürftigkeit laut Auskunftsersuchen Ihres Tierarztes vor Versicherungsbeginn bekannt',
  'claim.status.details.known_of_treatment_before_contract_start_by_vaterinarian_pretreatment_provide':
    'Behandlungsbedürftigkeit laut Auskunftsersuchen des vorbehandelten Tierarztes vor Versicherungsbeginn bekannt',
  'claim.status.details.firstvet_treatment_needed':
    'Behandlungsbedürftigkeit laut Videokonsultation vor Versicherungsbeginn bekannt',
  'claim.status.details.treatment_person_is_not_insured':
    'Behandelte Person entspricht nicht versicherter Person',
  'claim.status.details.reimbursement_is_already_covered':
    'Festzuschuss der Krankenkasse deckt Erstattungsbetrag',
  'claim.status.details.copayment_is_already_covered':
    'Krankenkasse deckt den verbleibenden Eigenanteil',
  'claim.status.details.treatment_was_known_before_insurance':
    'Behandlungsbedürftigkeit laut Patientenakte vor Versicherungsbeginn bekannt',
  'claim.status.details.treatment_before_contract_sign':
    'Behandlung erfolgte vor Vertragsabschluss',
  'claim.status.details.treatment_needs_before_contract_sign':
    'Behandlungsbedürftigkeit vor Vertragsabschluss bekannt',
  'claim.status.details.treatment_was_known_before_insurance_by_dentist':
    'Behandlungsbedürftigkeit laut Auskunftsersuchen Ihres Kieferorthopäden vor Versicherungsbeginn bekannt',
  // Storniert
  'claim.status.details.canceled_by_dentolo': 'Auf Veranlassung von dentolo',
  'claim.status.details.canceled_by_customer': 'Auf Veranlassung des Kunden',
  'claim.status.details.other.payouts': 'Weitere Auszahlungen',
  'claim.status.details.invoice.from': 'Rechnung vom',

  'claim.details.general.amount': 'Betrag',
  'claim.details.general.hour': 'Uhr',
  'claim.details.general.load.invoice':
    'Für die Auszahlung laden Sie bitte die Rechnungen zu der Behandlung hoch.',

  'claim.user.claims.make.appointment.title': 'Termin vereinbaren',
  'claim.user.claims.no.reimbursement': 'Bisher noch keine Kostenerstattungen',
  'claim.user.claims.make.appointment.description':
    'Jetzt Termin bei einem dentolo Zahnarzt vereinbaren und eine höhere Erstattung bekommen.',

  // Claim contact person details
  'claim.contact.person.title': 'Persönlicher Ansprechpartner',
  'claim.contact.person.text':
    'Bei Rückfragen geben Sie bitte immer die Referenznummer {external_reference_number} an.',

  // Dentists Map
  'dentist.details.open.hours.day.monday.short': 'Mo',
  'dentist.details.open.hours.day.tuesday.short': 'Di',
  'dentist.details.open.hours.day.wednesday.short': 'Mi',
  'dentist.details.open.hours.day.thursday.short': 'Do',
  'dentist.details.open.hours.day.friday.short': 'Fr',
  'dentist.details.open.hours.day.saturday.short': 'Sa',
  'dentist.details.open.hours.day.sunday.short': 'So',
  'dentist.details.open.hours.now.open': 'Jetzt geöffnet',
  'dentist.details.open.hours.closing.soon': 'Schließt bald',
  'dentist.details.open.hours.closed': 'Jetzt geschlossen',
  'dentist.details.open.hours.day.closed': 'geschlossen',
  'dentist.details.dentolo.dentist': 'dentolo Zahnarzt',
  'dentist.details.common.save.favourites': 'Für später speichern',
  'dentist.details.book.appointment': 'Jetzt Termin vereinbaren',
  'dentist.details.back.to.map': 'Zurück zur Zahnarztkarte',
  'dentist.map.loading': 'Zahnarztkarte wird geladen',
  // Map tabs
  'map.dentists.tabs.dentist.favourite': 'Meine Lieblingszahnärzte',
  'map.dentists.tabs.dentist.favourite.message': 'Wir kümmern uns um Ihr strahlendes Lächeln',
  'map.dentists.tabs.dentist.favourite.message.no_dentist':
    'Sie haben noch keinen dentolo Zahnarzt zu Ihrer Favoritenliste hinzugefügt.',
  'map.dentists.tabs.dentist.find': 'dentolo Zahnärzte finden',
  'map.dentists.network.about': 'Über das dentolo Netzwerk',

  // Sign out
  'sign.out': 'Abmelden',
  'sign.out.change.user': 'Versicherten wechseln',

  // Select customer
  'select.customer.insured.title': 'Bitte wählen Sie einen Versicherten aus:',
  'select.customer.insured.note':
    'Sie können jederzeit einen anderen Versicherten über das Menü bzw. Benutzersymbol auswählen.',

  // Second Auth
  'second.auth.title': 'Zwei Faktor Authentifizierung für Ihr Kundenkonto',
  'second.auth.description':
    'Für die zusätzliche Sicherheit Ihres Kundenkontos benötigen wir Ihr Geburtsdatum zum Einloggen.',
  'second.auth.input.label.day': 'Tag',
  'second.auth.input.label.month': 'Monat',
  'second.auth.input.label.year': 'Jahr',
  'second.auth.wrong.details': 'Bitte überprüfen Sie Ihre Angaben.',
  'second.auth.login.btn': 'Login',
  'second.auth.fields.required': 'Erforderliche Angaben',
  'second.auth.status.title': 'Zwei Faktor Authentifizierung',
  'second.auth.status.failure.title':
    'Leider waren die letzten drei Anmeldeversuche nicht erfolgreich.',
  'second.auth.status.failure.text':
    'Aus Sicherheitsgründen muss der Anmeldeprozess neu gestartet werden.',
  'second.auth.status.failure.button.text': 'Zurück zur Startseite',

  // Login
  'login.common.title': 'Mein Kundenkonto',
  'login.common.send.email':
    'Wir senden Ihnen umgehend eine E-Mail mit einem Link, welcher Sie direkt und sicher in Ihrem Kundenkonto anmeldet.',
  'login.common.send.link': 'Anmeldelink senden',

  // Login Status
  'login.status.failed': 'Fehler!  \n Es gab ein Problem.',
  'login.status.not.activated':
    'Vielleicht wurde Ihr Kundenkonto noch nicht aktiviert oder Sie haben sich möglicherweise verschrieben?',
  'login.status.click.register':
    'Klicken Sie den darin enthaltenen Anmeldelink, um sich in Ihrem Kundenkonto anzumelden. Falls Sie keine E-Mail erhalten haben, prüfen Sie bitte Ihren Spam Ordner.',
  'login.status.try.again': 'Erneut versuchen',
  'login.status.email.sent': 'Wir haben Ihnen eine E-Mail an  \n {confirmEmail} gesendet.',
  'login.status.email.failed':
    'Leider ist uns die E-Mail-Adresse  \n {confirmEmail} nicht bekannt.',
  'login.info.message.logout_success': 'Sie haben sich erfolgreich abgemeldet!',
  'login.info.title.invalid_link': 'Ungültiger oder abgelaufener Anmeldelink.',
  'login.info.message.invalid_link':
    'Bitte geben Sie unten erneut Ihre E-Mail an, damit wir Ihnen einen gültigen Anmeldelink per E-Mail zusenden können.',
  'login.': '',
  'login.link.to_dentolo_homepage': 'Zurück zur dentolo Startseite',
  'login.link.to_petolo_homepage': 'Zurück zur petolo Startseite',

  // Contract status title
  'contract.status.title.start': 'Versicherungsbeginn',
  'contract.status.title.end': 'Versicherungsende',
  // Contract status label
  'contract.status.start': 'Aktiv',
  'contract.status.will.start': 'Wird Aktiv',
  'contract.status.revoked': 'Widerrufen',
  'contract.status.will.end': 'Wird Beendet',
  'contract.status.end': 'Beendet',
  'contract.status.active.trial_month': 'Probemonat aktiv',

  // Contract docs
  'contract.docs.title': 'Meine Vertragsunterlagen',
  'contract.docs.description':
    'Hier können Sie alle relevanten Vertragsunterlagen als PDF Dateien einsehen und herunterladen.',
  'contract.docs.download.all': 'Alle Dokumente herunterladen',
  'contract.docs.download.all.subtext':
    'Hier können Sie alle Dokumente in einer ZIP-Datei herunterladen.',
  // Contract status/placeholder
  'contract.placeholder.inactive': 'Dieser Vertrag ist nicht aktiv',

  // Sign docs
  'sign.docs.title': 'Ihre Vertragsunterlagen',
  'sign.docs.description':
    'Hier finden Sie alle Unterlagen zu Ihrem Versicherungsvertrag. Unter "Mein Schutz" können Sie diese jederzeit abrufen.',
  'sign.docs.accordion.toggle.text': 'Weitere Dokumente',
  'sign.docs.accordion.show.text': 'anzeigen',
  'sign.docs.accordion.hide.text': 'schließen',
  'sign.docs.go.to.account': 'Zum Kundenkonto',
  'sign.docs.input.label.text':
    'Ich bestätige den Versicherungsschein und die obigen Dokumente erhalten und gespeichert zu haben, sowie mit deren Inhalt einverstanden zu sein.',

  // Documents
  'document.Allgemeine_Versicherungsbedingungen': 'Versicherungsbedingungen',
  'document.Produktinformationsblatt': 'Produktinformationsblatt',
  'document.Kundeninformationsblatt': 'Kundeninformationsblatt',
  'document.Kundeninformation': 'Kundeninformation',
  'document.Einwilligungserklärung': 'Einwilligungserklärung',
  'document.Datenschutzerklärung': 'Datenschutzerklärung',
  'document.protocol': 'Beratungsprotokoll',
  'document.contract': 'Versicherungsschein',
  'document.sepa': 'SEPA Mandat',
  'document.opt-in_document': 'Einverständniserklärung',
  'document.Versicherungsbedingungen': 'Versicherungsbedingungen',
  'document.Nutzungsbedingungen Kundenkonto': 'Nutzungsbedingungen Kundenkonto',
  'document.Extra Vorteile': 'Extra Vorteile',
  'document.dentolo Zahnarztnetzwerk': 'dentolo Zahnarztnetzwerk',
  'document.Weitere Verbraucherinformationen': 'Weitere Verbraucherinformationen',
  'document.Vertragsunterlagen': 'Vertragsunterlagen',
  'document.Sondervereinbarung Probemonat': 'Sondervereinbarung Probemonat',
  'document.Tarifblatt Akutschutz Modul.pdf': 'Tarifblatt Akutschutz Modul',

  // Payment status block
  'payment.status.total_premium': 'Beitrag',
  'payment.status.policy_premium': 'Tarif',

  // Payment general
  'payment.general.to.document': 'Zu den Dokumenten',
  'payment.general.access.documents': 'Beitragsbescheingungen herunterladen',

  // Payment overview
  'payment.overview.download.certificate':
    'Hier finden Sie Ihre Bescheinigungen über alle gezahlten Versicherungsbeiträge. Diese können immer ab dem 1. Februar für das Vorjahr heruntergeladen werden.',
  'payment.overview.heading': 'Meine Beitragsbescheinigungen',
  'payment.overview.contribution.document': 'Beitragsbescheinigung',
  'payment.overview.about.taxes': 'Mehr zum Thema Steuern und Versicherungen?',
  'payment.overview.helpcenter': 'Zum Hilfe-Center',
  'payment.overview.notification.title': 'Ihre Beitragsbescheinigung',
  'payment.overview.notification.text': 'Hier können Sie das Dokument herunterladen',
  'payment.overview.message.no_contribution': 'Noch keine Beitragsbescheinigung vorhanden',

  // Payment error messages
  'payment.failed.title': 'Meine Versicherungsbeiträge',
  'payment.failed.desc': 'Versicherungsbeiträge verwalten',
  'payment.failed.subText':
    'Hier können Sie den aktuellen Status ihrer monatliche Beiträge überschauen.',
  'payment.failed.payment.link': 'Versicherungsbeiträge anzeigen',
  'payment.failed.notification.text.one':
    'Sie haben einen offenen Versicherungsbeitrag. Klicken Sie hier, um Ihren Schutz zu reaktivieren. ',
  'payment.failed.notification.text.two':
    'Sie haben offene Versicherungsbeiträge. Klicken Sie hier, um Ihren Schutz zu reaktivieren.',
  'payment.failed.re.payment.heading': 'Der Beitrag konnte nicht abgebucht werden ',
  'payment.failed.re.payment.heading.continue': ' konnte nicht abgebucht werden.',
  'payment.failed.re.payment.link': 'Jetzt bezahlen',
  'payment.paid.message': 'Bezahlt',
  'payment.page.main.heading': 'Meine Versicherungsbeiträge',
  'payment.page.main.sub.heading':
    'Hier können Sie Details zu Ihren Versicherungsbeiträgen sehen und eventuell offene Beiträge bezahlen.',
  'payment.page.no.payments': 'Bisher noch keine Versicherungsbeiträge',
  'payment.page.network.error': 'Etwas ist schief gelaufen',
  'payment.exported.message.one': 'Ihr Konto wird am ',
  'payment.exported.message.two': ' belastet',
  'payment.exportable.message': 'Ihr Konto wird belastet',
  'payment.exportable.cancelled': 'Der Beitrag konnte nicht abgebucht werden',
  'payment.exportable.refunded': 'Wurde erstattet',
  'payment.exportable.refundable': 'Wird erstattet',
  'payment.cancelled.message': 'Storniert',
  'payment.blocked.message': 'Der Beitrag konnte nicht abgebucht werden',

  // ErrorMessage common
  'error.message.common.title.desktop': 'Ohja! Ein Fehler ist aufgetreten!',
  'error.message.common.title.mobile': 'Hoppla! Ein Fehler ist aufgetreten!',
  'error.message.common.subtitle.desktop': 'Bitte laden Sie die Seite in ein paar Minuten erneut.',
  'error.message.common.subtitle.mobile': 'Wir arbeiten daran, es schnellstmöglich zu lösen.',
  'error.message.common.support': 'Bei dringenden Anfragen schreiben Sie uns bitte an',
  'error.message.common.reward': 'Vielen Dank für Ihr Verständnis.',
  'error.message.common.team': 'Ihr dentolo Team',

  //# Pomotion carousel
  'promotion.carousel.dentolo.referral_link.title':
    'Jetzt dentolo empfehlen und 50€ Prämie erhalten',
  'promotion.carousel.dentolo.referral_car.text1': 'dentolo empfiehlt: ',
  'promotion.carousel.dentolo.referral_car.text2': 'Autoversicherung von  ',
  'promotion.carousel.dentolo.referral_car.text3': 'DA Direkt. Bis 30.11. ',
  'promotion.carousel.dentolo.referral_car.text4': 'wechseln und bis ',
  'promotion.carousel.dentolo.referral_car.text5': 'zu 60% sparen!',
  'promotion.carousel.dentolo.referral_link.button.label': 'Zu meiner Prämie',
  'promotion.carousel.dentolo.car_link.button.label': 'Mehr erfahren',
  'promotion.carousel.petolo.referral_link.title': 'Jetzt petolo empfehlen und 50€ Prämie erhalten',
  'promotion.carousel.petolo.referral_link.button.label': 'Zu meiner Prämie',
  'promotion.carousel.petolo.confirmation.title': 'Freunde werben und doppelt profitieren',
  'promotion.carousel.petolo.confirmation.accept_joining_text_1': 'Ich akzeptiere die  ',
  'promotion.carousel.petolo.confirmation.accept_joining_text_link': 'Teilnahmebedingungen',
  'promotion.carousel.petolo.confirmation.accept_joining_text_2':
    ' und möchte am petolo Empfehlungsprogramm teilnehmen.',
  'promotion.carousel.petolo.confirmation.accept': 'petolo empfehlen',
  'promotion.carousel.petolo.confirmation.reject': 'Vielleicht später',

  //Referral banner
  'referral.banner.link': 'Mehr Informationen',
  'referral.banner.content': 'Jetzt dentolo empfehlen. 50€ Prämie erhalten!',

  //# Join referral page
  'referral_permission.dental.page.title': 'Freunde werben und doppelt profitieren',
  'referral_permission.dental.form.checkbox.label_1': 'Ich akzeptiere die',
  'referral_permission.dental.form.checkbox.label.terms_link': 'Teilnahmebedingungen',
  'referral_permission.dental.form.checkbox.label_2':
    'und möchte am dentolo Empfehlungsprogramm teilnehmen.',
  'referral_permission.dental.form.button.agree': 'dentolo empfehlen',
  'referral_permission.dental.form.button.later': 'Vielleicht später',

  // Referrals - Common
  'referral.page.title': 'Freund:innen werben',
  'referral.page.content': 'Jetzt {contract} empfehlen und 50€ Prämie erhalten',
  'referral.page.step.one.title': 'Link mit Freund:innen teilen',
  'referral.page.step.one.description':
    'Klicken Sie jetzt auf den untenstehenden Button “Empfehlungslink kopieren” und leiten Sie diesen an Ihre Freund:innen weiter.',
  'referral.page.step.two.title': 'Gutschein erhalten',
  'referral.page.step.two.description':
    'Nachdem Ihr:e Freund:in eine {product} {insurance} über den Empfehlungslink abgeschlossen hat, spart sich Ihr:e Freund:in den ersten Monatsbeitrag und Sie erhalten einen {amount}€ {giftbrand}-Gutschein per E-Mail.',
  'referral.page.additional_info.one':
    'Ihren {brand}-Gutschein erhalten Sie, nachdem Ihr:e Freund:in den kostenfreien Probemonat erfolgreich getestet, die Vertragsunterlagen bestätigt und den ersten Monatsbeitrag gezahlt hat. Zudem muss die 14-tägige Widerrufsfrist erloschen sein.',
  'referral.page.additional_info.two': 'Dieser Prozess kann bis zu {days} Tage dauern.',
  'referral.page.copy.button': 'Empfehlungslink kopieren',
  'referral.page.link.copied': 'Link kopiert',
  'referral.page.share_in_socials': 'oder Link direkt via Facebook, WhatsApp oder E-Mail teilen',
  'referral.page.referral_id.description':
    'Möchten Ihre Freund:innen die {product} {insurance} lieber im <b>Rahmen eines Beratungsgesprächs abschließen?</b> Damit unsere Expert:innen Ihre Empfehlung auch in diesem Fall korrekt zuordnen können, benötigen Ihre Freund:innen den folgenden Empfehlungscode.',
  'referral.page.referral_id.title': 'Ihr Empfehlungscode',
  'referral.page.privacy.text':
    'Aus datenschutzrechtlichen Gründen raten wir davon ab, zusätzlich Ihre Telefonnummer oder E-Mail-Adresse weiterzugeben.',
  'referral.page.terms_and_conditions.text':
    'Mehr Informationen zu Ihrer Teilnahme am Freunde-werben-Programm und unseren AGBs finden Sie <a>hier</a>.',
  'referral.page.tab.label.one': 'Freund:innen werben',
  'referral.page.tab.label.two': 'Ihre Empfehlungen',
  'referral.page.information.header': "So geht's",
  'referral.page.status.title': 'Prüfen Sie den Status Ihrer Empfehlungen',
  'referral.page.status.empty.title': 'Noch keine Empfehlung erfasst',
  'referral.page.status.empty.description':
    'Teilen Sie jetzt Ihr Glück und empfehlen {product} Ihren Liebsten.',
  'referral.page.list.title.email': 'E-Mail Ihrer Freund:innen',
  'referral.page.list.title.status': 'Status',
  'referral.reward.title': 'Ihr {rewardbrand} Gutschein-Code:',
  'referral.status.label.pending': 'Ausstehend',
  'referral.status.label.cancelled': 'Abgebrochen',
  'referral.status.label.sent': 'Abgeschlossen',
  'referral.status.description.title': 'Bedeutung des Status',
  'referral.status.description.pending':
    '<b>Ausstehend:</b> Ihre Empfehlung wird zurzeit bearbeitet. Sobald diese erfolgreich abgeschlossen ist, werden wir Sie benachrichtigen.',
  'referral.status.description.cancelled':
    '<b>Abgebrochen:</b> Ihre Empfehlung konnte leider nicht erfolgreich abgeschlossen werden. Probieren Sie es gerne erneut & empfehlen petolo weiteren Freund:innen.',
  'referral.status.description.completed':
    '<b>Abgeschlossen:</b> Ihre Empfehlung wurde erfolgreich abgeschlossen und Ihr 50€ Fressnapf-Gutschein wartet in Ihrem E-Mail Postfach auf Sie. Sie können den Gutschein-Code aus der E-Mail nutzen oder direkt von oben kopieren.',

  // Referrals - Dentolo
  'referral.page.dentolo.title': 'dentolo empfehlen & {amount}€ {couponbrand} sichern',
  'referral.page.dentolo.whatsapp.message':
    'Suchst du noch eine Zahnzusatzversicherung? Dann empfehle ich dir dentolo. Hier kannst du dir einen passenden Tarif aussuchen %26 einen kostenfreien Probemonat sichern: {referralUrl}',
  'referral.page.dentolo.email.subject': 'Meine Empfehlung: dentolo Zahnzusatzversicherung',
  'referral.page.dentolo.email.message':
    'Hallo,%0D%0Dsuchst du noch eine Zahnzusatzversicherung? Dann empfehle ich dir dentolo. Über diesen Link kannst du dir einen passenden Tarif aussuchen %26 einen kostenfreien Probemonat sichern: {referralUrl}%0D%0DViele Grüße',

  // Referrals - Petolo
  'referral.page.petolo.title': 'petolo empfehlen & {amount}€ {couponbrand} sichern',
  'referral.page.petolo.illustration.text': 'Teilen Sie Ihr Glück!',
  'referral.page.petolo.whatsapp.message':
    'Suchst du noch eine Tierkrankenversicherung? Dann empfehle ich dir petolo. Hier kannst du dir einen passenden Tarif aussuchen %26 einen kostenfreien Probemonat sichern: {referralUrl}',
  'referral.page.petolo.email.subject': 'Meine Empfehlung: petolo Tierkrankenversicherung',
  'referral.page.petolo.email.message':
    'Hallo,%0D%0Dsuchst du noch einen starken Krankenschutz für deinen Vierbeiner? Dann empfehle ich dir petolo. Über diesen Link kannst du dir einen passenden Tarif aussuchen %26 einen kostenfreien Probemonat sichern: {referralUrl}%0D%0DViele Grüße',

  // First Vet
  'first.vet.go.to.link': 'Zu FirstVet',
  'first.vet.online.vets': 'Online-Tierärzte',
  'first.vet.app.find': 'Die FirstVet App finden Sie hier:',
  'first.vet.slider.create.account': 'Kostenfreies Konto erstellen',
  'first.vet.slider.appointment': 'Termin buchen',
  'first.vet.slider.secure': 'Sorgenfrei abgesichert',
  'first.vet.slider.create.account.description':
    'Erstellen Sie ein Konto auf der FirstVet Homepage oder laden Sie die FirstVet App herunter. Registieren Sie ihr Haustier und erhalten eine höhere Erstattung nach einer digitalen Erstberatung.',
  'first.vet.slider.appointment.description':
    'Sie können die Videosprechstunde mit einem Tierarzt rund um die Uhr in Anspruch nehmen. Die Kosten für die digitale Erstberatung tragen wir für Sie.',
  'first.vet.slider.secure.description':
    'Ihr Tier ist in guten Händen: dank der professionellen Beratung oder Überweisung an eine örtliche Tierarztpraxis oder -klinik, falls nötig.',

  //Fressnapf
  'fressnapf.header': 'Online Tierärzte',
  'fressnapf.button.text': 'Zu Dr. Fressnapf',
  'fressnapf.slide1.title': 'Anmelden',
  'fressnapf.slide2.title': 'Termin buchen',
  'fressnapf.slide3.title': 'Gesund werden',
  'fressnapf.slide1.description':
    'Erstellen Sie sich mit Ihrer E-Mail Adresse ein Kundenkonto bei Dr.{nbsp}Fressnapf oder melden sich in Ihrem Fressnapf-Konto an.',
  'fressnapf.slide2.description':
    'Buchen Sie einen Termin für eine Videosprechstunde mit einem Tierarzt und erhalten Ihre digitale Erstberatung.',
  'fressnapf.slide3.description':
    'Ihr Tier ist in guten Händen: dank der professionellen Beratung oder Überweisung an eine örtliche Tierarztpraxis oder -klinik.',

  //Petolo cross selling banner
  'petolo.cross-selling.banner.title': 'Wir versichern jetzt auch Vierbeiner!',
  'petolo.cross-selling.banner.content':
    'Die Hundekranken- & OP-Versicherung von petolo schützt Sie jetzt auch vor hohen Tierarztkosten.',
  'petolo.cross-selling.banner.button': 'Mehr Information',

  //datepicker placeholder
  'dentolo.day_placeholder': 'Tag',
  'dentolo.month_placeholder': 'Monat',
  'dentolo.year_placeholder': 'Jahr',

  //New Claim Creation Flow common
  'new_claim.prompt.message':
    'Sind Sie sicher, dass Sie die Seite verlassen wollen? Bereits ausgewählte Dateien müssen dann erneut ausgewählt werden.',
  'new_claim.file.too.big': 'Datei zu groß (max. 10 MB)',
  'new_claim.file.wrong.type': 'Ungültiger Dateityp',
  'new_claim.file.ready.to.upload': 'Bereit zum Absenden',
  'new_claim.invalid.date': 'Bitte geben Sie ein gültiges Datum ein',
  'new_claim.invalid.date_past': 'Das Datum darf maximal fünf Jahre zurückliegen',
  'new_claim.invalid.postcode': 'Ungültige Postleitzahl',
  'new_claim.upload.page.title.with.files': 'Ihre ausgewählten Dokumente',
  'new_claim.upload.page.title.without.files': 'Dokumente hochladen',
  'new_claim.upload.page.description':
    'Bitte laden Sie die Dokumente nur für eine Kostenerstattung hoch. Sollten Sie Dokumente für weitere Kostenerstattungen besitzen, starten Sie nach erfolgreichem hochladen bitte von vorn.',
  'new_claim.upload.page.hint.description':
    'Um eine schnellere Bearbeitung Ihrer Erstattung zu gewährleisten, stellen Sie bitte sicher, dass Sie eine korrekte Rechnung hochladen mit:',
  'new_claim.upload.page.hint.good_stuff_pet_1': 'Behandeltes Tier',
  'new_claim.upload.page.hint.good_stuff_dental_1': 'Behandelte Person',
  'new_claim.upload.page.hint.good_stuff_2': 'Datum',
  'new_claim.upload.page.hint.good_stuff_3': 'Behandlungen',
  'new_claim.upload.page.hint.good_stuff_4': 'Rechnungsbetrag',
  'new_claim.upload.page.hint.bad_stuff_title': 'Nicht gültig sind',
  'new_claim.upload.page.hint.bad_stuff_1': 'Zahlungsanweisung',
  'new_claim.upload.page.hint.bad_stuff_2': 'Kostenübersicht',
  'new_claim.upload.page.hint.bad_stuff_3': 'Überweisungsträger',
  'new_claim.file.uploader.title.with_files': 'Weitere Dateien auswählen',
  'new_claim.file.uploader.title.without_files': 'Dateien auswählen',
  'new_claim.file.uploader.description.with_files':
    'Bitte überprüfen Sie vor dem Absenden, dass Sie alle gewünschten Dateien ausgewählt haben. Alle Dateien mit dem Status "Bereit zum Absenden" werden übertragen.',
  'new_claim.file.uploader.description.without_files':
    '**Klicken Sie hier**, um Dateien hochzuladen. Alternativ können Sie Dateien auch direkt per Drag & Drop in dieses Feld ziehen.',
  'new_claim.date.info_text_max_error_message': 'Maximal 50 Wörter',

  //New Claim Creation Flow Dentolo
  'dentolo.new_claim.select_treatment_category.page_title': 'Behandlungsart auswählen',
  'dentolo.new_claim.select_treatment_category.page_description':
    'Hier können Sie die Art Ihrer Behandlung auswählen, um einen Leistungsfall einzureichen.',
  'dentolo.new_claim.select_treatment_category.teeth_cleaning': 'Zahnreinigung',
  'dentolo.new_claim.select_treatment_category.tooth_filling': 'Füllung',
  'dentolo.new_claim.select_treatment_category.other_treatments': 'Andere Behandlung',
  'dentolo.new_claim.select_document_type.page_title':
    'Was für ein Dokument möchten Sie hochladen?',
  'dentolo.new_claim.select_document_type.invoice': 'Rechnung',
  'dentolo.new_claim.select_document_type.treatment_plan': 'Kostenvoranschlag',
  'dentolo.new_claim.select_document_creation_date.page_title': 'Wann wurde das Dokument erstellt?',
  'dentolo.new_claim.select_document_creation_date.form_description': 'Datum des Dokumentes',
  'dentolo.new_claim.select_treatment_creation_date.page_title':
    'Wann war der erste Tag der Behandlung?',
  'dentolo.new_claim.select_treatment_creation_date.form_description': 'Behandlungsdatum',
  'dentolo.new_claim.select_treatment_creation_date.check_label_text':
    'Vorheriges Datum übernehmen',
  'dentolo.new_claim.date.error_message': 'Bitte geben Sie ein gültiges Datum ein.',
  'dentolo.new_claim.treatment_amount.page.title': 'Wie hoch ist der ausgewiesene Betrag?',
  'dentolo.new_claim.treatment_amount.form.label.amount': 'Betrag',
  'dentolo.new_claim.treatment_amount.form.error.invalid_treatment_amount':
    'Bitte geben Sie einen gültiges Betrag ein',
  'dentolo.new_claim.dentist_details.page_title': 'Wie heißt Ihr Zahnarzt?',
  'dentolo.new_claim.dentist_details.input_label': 'Name des Zahnarztes',
  'dentolo.new_claim.filling_count.page.title': 'Wie viele Füllungen haben Sie bekommen?',
  'dentolo.new_claim.treatment_amount.form.label.filling_count': 'Anzahl der Füllungen',
  'dentolo.new_claim.treatment_amount.form.error.invalid_filling_count':
    'Bitte geben Sie eine gültige Anzahl ein',
  'dentolo.new_claim.submit_claim_form.page_title': 'Letzter Schritt: Bestätigung Ihrer Angaben',
  'dentolo.new_claim.submit_claim_form.treatment_category.pzr': 'Zahnreinigung',
  'dentolo.new_claim.submit_claim_form.treatment_category.filling': 'Füllung',
  'dentolo.new_claim.submit_claim_form.treatment_category.other': 'Zahnbehandlung',
  'dentolo.new_claim.submit_claim_form.card_label.document_type': 'Art des Dokumentes:',
  'dentolo.new_claim.submit_claim_form.card_label.document_date':
    'Erstellungsdatum des Dokumentes:',
  'dentolo.new_claim.submit_claim_form.card_label.treatment_date': 'Datum der ersten Behandlung:',
  'dentolo.new_claim.submit_claim_form.card_label.amount': 'Betrag:',
  'dentolo.new_claim.submit_claim_form.card_label.fillings': 'Anzahl der Füllungen:',
  'dentolo.new_claim.submit_claim_form.card_label.dentist': 'Zahnarzt:',
  'dentolo.new_claim.submit_claim_form.uploaded_files.title': 'Dokumente',
  'dentolo.new_claim.submit_claim_form.send_button': 'Absenden',
  'dentolo.new_claim.existing_claim_question.page.title':
    'Handelt sich diese Rechnung um eine Folgebehandlung oder einen bestehenden Leistungsfall?',
  'dentolo.new_claim.existing_claim_question.option.yes': 'Yes',
  'dentolo.new_claim.existing_claim_question.option.no': 'No',
  'dentolo.new_claim.existing_claims.page.title':
    'Um welchen bestehenden Leistungsfall handelt es sich?',
  'dentolo.new_claim.existing_claims.form.label.not_selected': 'Keiner dieser Leistungsfälle',

  //New Claim Creation Flow Petolo
  'pet_health.new_claim.select_treatment_category.page_title':
    'Welche Behandlung wurde durchgeführt?',
  'pet_health.new_claim.select_treatment_category.other': 'Andere Behandlungen',
  'petolo.new_claim.select_document_type.page_title': 'Was für ein Dokument möchten Sie hochladen?',
  'petolo.new_claim.select_document_type.invoice': 'Rechnung',
  'petolo.new_claim.select_document_type.treatment_plan': 'Kostenvoranschlag',
  'petolo.new_claim.select_document_creation_date.page_title': 'Wann wurde das Dokument erstellt?',
  'petolo.new_claim.select_document_creation_date.form_description': 'Datum des Dokumentes',
  'petolo.new_claim.select_treatment_creation_date.page_title':
    'Wann war der erste Tag der Behandlung?',
  'petolo.new_claim.select_treatment_creation_date.form_description': 'Behandlungsdatum',
  'petolo.new_claim.select_treatment_creation_date.check_label_text': 'Vorheriges Datum übernehmen',
  'petolo.new_claim.treatment_amount.page.title': 'Wie hoch ist der ausgewiesene Betrag?',
  'petolo.new_claim.treatment_amount.form.label.amount': 'Betrag',
  'petolo.new_claim.treatment_amount.form.error.invalid_treatment_amount':
    'Bitte geben Sie einen gültiges Betrag ein',
  'petolo.new_claim.consultation_question.page.title':
    'Haben Sie vor der ärztlichen Sprechstunde eine digitale Erstberatung in Anspruch genommen?',
  'petolo.new_claim.consultation_question.option.yes': 'Ja',
  'petolo.new_claim.consultation_question.option.no': 'Nein',
  'petolo.new_claim.treatment_info_text.page_title':
    'Wann sind die Symptome aufgetreten und was war der Grund für die Behandlung?',
  'petolo.new_claim.treatment_info_text.page_description':
    'Bitte geben Sie das Datum an wann die Symptome erstmals aufgetreten sind und warum die Behandlung notwendig war',
  'petolo.new_claim.submit_claim_form.treatment_category.other': 'Übersicht',
  'petolo.new_claim.submit_claim_form.page_title': 'Letzter Schritt: Bestätigung Ihrer Angaben',
  'petolo.new_claim.submit_claim_form.uploaded_files.title': 'Dokumente',
  'petolo.new_claim.submit_claim_form.send_button': 'Absenden',
  'petolo.new_claim.submit_claim_form.card_label.document_type': 'Art des Dokumentes:',
  'petolo.new_claim.submit_claim_form.card_label.document_date': 'Erstellungsdatum des Dokumentes:',
  'petolo.new_claim.submit_claim_form.card_label.treatment_date': 'Datum der ersten Behandlung:',
  'petolo.new_claim.submit_claim_form.card_label.amount': 'Betrag:',
  'petolo.new_claim.submit_claim_form.card_label.treatment_necessary_answer':
    'Grund der Behandlung:',
  'petolo.new_claim.submit_claim_form.card_label.videoConsultation': 'Digitale Erstberatung:',
  'petolo.new_claim.select_video_consultation.page.title':
    'Wann hat die digitale Erstberatung stattgefunden?',
  'petolo.new_claim.select_video_consultation.option.default.label': 'Keine passende Erstberatung',
  'petolo.new_claim.treatment_info_text.placeholder': 'Maximal 80 Wörter',
  'petolo.new_claim.treatment_info_text.error': 'Maximal 80 Wörter',
  'petolo.new_claim.existing_claim_question.page.title':
    'Handelt es sich bei dieser Rechnung um eine Folgebehandlung zu einem bestehenden Leistungsfall?',
  'petolo.new_claim.existing_claim_question.option.yes': 'Ja',
  'petolo.new_claim.existing_claim_question.option.no': 'Nein',
  'petolo.new_claim.existing_claims.page.title':
    'Um welchen bestehenden Leistungsfall handelt es sich?',
  'petolo.new_claim.existing_claims.form.label.not_selected': 'Keiner dieser Leistungsfälle',

  // New Claim Creation Flow `Pet Liability`
  'pet_liability.new_claim.liability_reason.page_title': 'Was genau ist passiert?',
  'pet_liability.new_claim.liability_reason.item': 'Gegenstand beschädigt',
  'pet_liability.new_claim.liability_reason.another_animal': 'Anderes Tier wurde verletzt',
  'pet_liability.new_claim.liability_reason.vehicle': 'KFZ/Verkehrsmittel beschädigt',
  'pet_liability.new_claim.liability_reason.property': 'Gebäude/Grundstück beschädigt',
  'pet_liability.new_claim.liability_reason.person': 'Person wurde verletzt',
  'pet_liability.new_claim.liability_reason.other': 'Sonstiges',
  'pet_liability.new_claim.liability_damage_caused_by.page_title.damage':
    'Wer hat den Schaden verursacht?',
  'pet_liability.new_claim.liability_damage_caused_by.page_title.injury':
    'Wer hat die Verletzung verursacht?',
  'pet_liability.new_claim.liability_damage_caused_by.other': 'Jemand anderes',
  'pet_liability.new_claim.pet_accompanied_by.page_title':
    'Wer war zum relevanten Zeitpunkt mit {petName} unterwegs?',
  'pet_liability.new_claim.pet_accompanied_by.myself': 'Ich selbst',
  'pet_liability.new_claim.pet_accompanied_by.partner': 'Partner:in',
  'pet_liability.new_claim.pet_accompanied_by.family': 'Familenmitglied/Verwandte',
  'pet_liability.new_claim.pet_accompanied_by.flatmate': 'Mitbewohner:in',
  'pet_liability.new_claim.pet_accompanied_by.work_colleague': 'Arbeitskolleg:in',
  'pet_liability.new_claim.pet_accompanied_by.no_relation': 'Keine Beziehung',
  'pet_liability.new_claim.damage_estimate.page_title': 'Wie hoch schätzen Sie den Gesamtschaden?',
  'pet_liability.new_claim.damage_estimate.upto_500': '0 bis 500 €',
  'pet_liability.new_claim.damage_estimate.501_1000': '501 bis 1,000 €',
  'pet_liability.new_claim.damage_estimate.1001_5000': '1,001 bis 5,000 €',
  'pet_liability.new_claim.damage_estimate.5001_10000': '5,001 bis 10,000 €',
  'pet_liability.new_claim.damage_estimate.unknown': 'Unbekannt',
  'pet_liability.new_claim.affected_party.page_title.injured_animal':
    'Wer ist Halter:in des verletzten Tieres?',
  'pet_liability.new_claim.affected_party.page_title.injured_person': 'Wer wurde verletzt?',
  'pet_liability.new_claim.affected_party.page_title.who_is_affected':
    'Wer ist von dem Schaden betroffen?',
  'pet_liability.new_claim.affected_party.option.myself': 'Ich selbst',
  'pet_liability.new_claim.affected_party.option.other': 'Jemand anderes',
  'pet_liability.new_claim.relation_with_affected_party.page_title.injured_animal':
    'In welcher Beziehung stehen Sie zur Halter:in?',
  'pet_liability.new_claim.relation_with_affected_party.page_title.damage':
    'In welcher Beziehung stehen Sie zu der geschädigten Person?',
  'pet_liability.new_claim.relation_with_affected_party.page_title.injured_person':
    'In welcher Beziehung stehen Sie zur verletzten Person?',
  'pet_liability.new_claim.relation_with_affected_party.partner': 'Partner:in',
  'pet_liability.new_claim.relation_with_affected_party.family': 'Familenmitglied/Verwandte',
  'pet_liability.new_claim.relation_with_affected_party.flatmate': 'Mitbewohner:in',
  'pet_liability.new_claim.relation_with_affected_party.work_colleague': 'Arbeitskolleg:in',
  'pet_liability.new_claim.relation_with_affected_party.no_relation': 'Keine Beziehung',
  'pet_liability.new_claim.medical_exam.page_title_person':
    'Kam es zu einer ärztlichen Untersuchung?',
  'pet_liability.new_claim.medical_exam.page_title_animal':
    'Kam es zu einer tierärztlichen Untersuchung?',
  'pet_liability.new_claim.medical_exam.yes': 'Ja',
  'pet_liability.new_claim.medical_exam.no': 'Nein',
  'pet_liability.new_claim.incident_location.page_title': 'Wo genau ist es passiert?',
  'pet_liability.new_claim.details_of_affected_party.page_title.injured_animal':
    'Details zur Halter:in',
  'pet_liability.new_claim.details_of_affected_party.page_title.injured_person':
    'Wer wurde verletzt?',
  'pet_liability.new_claim.details_of_affected_party.page_title.damage':
    'Details zur geschädigten Person.',
  'pet_liability.new_claim.incident_note.page_title': 'Wie ist es dazu gekommen?',
  'pet_liability.new_claim.incident_note.page_description':
    'Beschreiben Sie bitte kurz, was passiert ist.',
  'pet_liability.new_claim.incident_note.placeholder': 'Maximal 150 Wörter',
  'pet_liability.new_claim.upload_document.page.description':
    'Bitte laden Sie relevante Dokumente wie Rechnungen, Bilder vom Schaden oder Ähnliches hoch.',
  'pet_liability.new_claim.upload_document.file_uploader.description_with_files':
    'Bitte überprüfen Sie, ob Sie alle Ihre gewünschten Dateien korrekt ausgewählt haben. Alle Dateien mit dem Status "Bereit zum Absenden" werden übertragen.',
  'pet_liability.new_claim.incident_date_time.page_title.injury': 'Wann kam es zu der Verletzung?',
  'pet_liability.new_claim.incident_date_time.page_title.damage': 'Wann ist der Schaden passiert?',
  'pet_liability.new_claim.incident_date_time.checkbox.label.dont_know_time':
    'Ich weiß die genaue Uhrzeit nicht',
  'pet_liability.new_claim.damaged_item.page_title': 'Was wurde beschädigt?',
  'pet_liability.new_claim.damaged_item.form.label.damaged_item': 'Beschädigtes Objekt',
  'pet_liability.new_claim.damaged_item.form.label.damaged_item_age':
    'Alter des beschädigten Objekts',
  'pet_liability.new_claim.damaged_item.form.label.damaged_item_price': 'Anschaffungspreis',
  'pet_liability.new_claim.damaged_item.form.error.invalid_price': 'Der Betrag ist ungültig',
  'pet_liability.new_claim.submit_claim_form.page_title':
    'Letzter Schritt: Bestätigung Ihrer Angaben',
  'pet_liability.new_claim.submit_claim_form.data_card.title': 'Übersicht',
  'pet_liability.new_claim.submit_claim_form.card_label.tariff': 'Tarif:',
  'pet_liability.new_claim.submit_claim_form.card_label.incident_date': 'Datum:',
  'pet_liability.new_claim.submit_claim_form.card_label.city': 'Ort:',
  'pet_liability.new_claim.submit_claim_form.uploaded_files_card.title': 'Dokumente',
  'pet_liability.new_claim.submit_claim_form.button.send': 'Absenden',

  'new_claim.form.label.date': 'Datum',
  'new_claim.form.label.time': 'Uhrzeit',
  'new_claim.form.label.name': 'Name',
  'new_claim.form.label.street_number': 'Straße, Nummer',
  'new_claim.form.label.postcode_city': 'Postleitzahl, Ort',
  'new_claim.form.label.email.optional': 'Email (optional)',
  'new_claim.form.label.phone.optional': 'Telefonnummer (optional)',

  //404 page
  'petolo.404.page.message': 'Oje, diese Seite existiert nicht',

  // cancellation contract
  'cancellation.contract.cancel.exit': 'Abbrechen',
  'cancellation.contract.confirm.continue': 'Widerruf bestätigen',
  'cancellation.contract.confirm.cancel': 'Kündigung bestätigen',
  'cancellation.contract.title': 'Schade, dass Sie gehen möchten!',
  'cancellation.contract.withdrawl.success.title':
    'Ihr Vertrag wurde erfolgreich widerrufen. Sie erhalten eine Bestätigung per E-Mail.',
  'cancellation.contract.withdrawl.success.description':
    'Bitte geben Sie einen Grund für Ihren Widerruf an damit wir unsere Services verbessern können.',
  'cancellation.contract.regular.success.title':
    'Ihr Vertrag wurde erfolgreich gekündigt. Sie erhalten eine Bestätigung per E-Mail.',
  'cancellation.contract.regular.success.description':
    'Bitte geben Sie einen Grund für Ihre Kündigung an damit wir unsere Services verbessern können.',
  'cancellation.contract.button.send': 'Absenden',
  'cancellation.contract.button.backToHome': 'Zurück zur Startseite',
  'contract.cancellation.success.title': 'Vielen Dank für Ihr Feedback!!',
  'contract.cancellation.success.button': 'Zurück zur Startseite',
  'contract.cancellation.failure.title':
    'Fehler! Es gab ein Problem mit der Kündigung Ihres Vertrags.',
  'contract.cancellation.failure.text':
    'Bei bestehenden Problemen kontaktieren Sie bitte unseren Kundendienst.',
  'contract.cancellation.failure.button': 'Zum Kundendienst',
  'cancellation.contract.landing.page.question': 'Möchten Sie Ihren Vertrag wirklich widerrufen?',
  'cancellation.contract.landing.page.warning1':
    'Sie befinden sich noch innerhalb der Widerrufsfrist. Wenn Sie fortfahren, verlieren Sie Ihren Versicherungsschutz.',
  'cancellation.contract.landing.page.warning2':
    'Zuviel gezahlte Beiträge erstatten wir Ihnen automatisch auf das uns bekannte Konto in den nächsten Tagen.',
  'cancellation.contract.landing.page.warning3':
    'Ihre offenen Leistungsfälle werden automatisch geschlossen. Eine Erstattung ist nicht mehr möglich.',
  'cancellation.contract.landing.page.warning4':
    'Ihre offenen Leistungsfälle werden automatisch geschlossen und eine Erstattung ist nicht mehr möglich.',
  'cancellation.contract.landing.page.regular.next_possible_date':
    'Das nächstmögliche Kündigungsdatum ist:',
  'cancellation.contract.landing.page.regular.text1':
    'Da Sie bereits eine Auszahlung von uns erhalten haben, greift die Mindestlaufzeit von Ihrem Vertrag gemäß Ziffer 5 der Versicherungsbedingungen.',
  'cancellation.contract.landing.page.regular.text2':
    'Wir werden Ihren Vertrag zum nächstmöglichen Zeitpunkt zum Monatsende kündigen.',
  'cancellation.contract.landing.page.regular.text3':
    'Zuviel gezahlte Beiträge erstatten wir Ihnen automatisch auf das uns bekannte Konto in den nächsten Tagen.',
  'cancellation.contract.landing.page.regular.text4':
    'Ihre offenen Leistungsfälle werden  automatisch geschlossen. Eine Erstattung ist nicht mehr möglich.',
  'cancellation.contract.landing.page.regular.text5':
    'Aufgrund des Akutschutz Moduls ist eine Kündigung erst nach Ablauf der Mindestvertragsdauer möglich.',

  //Block screen when contract is not approved
  'contract_not_approved.title.dentolo': 'Danke, dass Sie dentolo gewählt haben!',
  'contract_not_approved.title.petolo': 'Danke, dass Sie petolo gewählt haben!',
  'contract_not_approved.text_1':
    'Ihr Vertrag wird zur Zeit geprüft, aber das sollte nicht lange dauern.',
  'contract_not_approved.text_2':
    'Bitte warten Sie ein paar Minuten und aktualisieren Sie die Seite.',
  'contract_not_approved.button': 'Seite aktualisieren',

  // Insure Another Pet Banner
  'insure_another_pet.banner.title': 'Jetzt weitere Fellnasen absichern!',
  'insure_another_pet.banner.description':
    'Sie haben mehr als ein Haustier, das Sie versichern möchten? Klicken Sie jetzt auf “Weitere Haustiere absichern”, um weitere Fellnasen zu versichern.',
  'insure_another_pet.banner.button.label': 'Weitere Haustiere absichern',
};
