import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  ButtonContainer,
  IconButton,
  PageTitle,
  Paper,
  Text,
} from '../../../../components/common';
import { Button } from '../../../../components/ui';
import { FileList } from '../UploadDocument/FileList';
import { StyledCardHeader } from './SubmitClaimForm.styled';

const SubmitClaimForm = ({
  selectedDocuments,
  backButtonLink,
  editDetailsButtonLink,
  editUploadedFilesLink,
  pageTitleId,
  editIcon,
  formDataCardHeaderId,
  cardContent,
  uploadedFilesCardHeaderId,
  uploadClaimForm,
  submitButtonId,
}) => {
  let history = useHistory();

  const [disabled, setDisabled] = useState(false);

  const validSelectedDocuments = Array.isArray(selectedDocuments)
    ? selectedDocuments.filter((d) => d?.valid)
    : [];

  const cardContentToDisplay = Array.isArray(cardContent)
    ? cardContent.filter((contentItem) => !!contentItem?.value)
    : [];

  const handleButtonClick = () => {
    if (typeof uploadClaimForm === 'function') {
      setDisabled(true);
      uploadClaimForm();
    }
  };

  return (
    <>
      <BackButton to={backButtonLink}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      <PageTitle>
        <FormattedMessage id={pageTitleId} />
      </PageTitle>

      <Paper style={{ marginTop: '1.5em' }}>
        <StyledCardHeader>
          <PageTitle>
            <FormattedMessage id={formDataCardHeaderId} />
          </PageTitle>

          {editDetailsButtonLink && (
            <IconButton
              icon={editIcon}
              alt="edit"
              size="sm"
              onClick={() => history.push(editDetailsButtonLink)}
            />
          )}
        </StyledCardHeader>

        {cardContentToDisplay.map((contentItem) => {
          const { label, value } = contentItem;
          return (
            <div key={label}>
              <Text style={{ fontSize: '1em' }}>
                <FormattedMessage id={label} />
                &nbsp;<strong>{value}</strong>
              </Text>
            </div>
          );
        })}
      </Paper>

      <Paper style={{ marginTop: '1.5em' }}>
        <StyledCardHeader>
          <PageTitle>
            <FormattedMessage id={uploadedFilesCardHeaderId} />
          </PageTitle>

          <IconButton
            icon={editIcon}
            alt="edit"
            size="sm"
            onClick={() => history.push(editUploadedFilesLink)}
          />
        </StyledCardHeader>

        <div>
          <FileList files={validSelectedDocuments} />
        </div>
      </Paper>

      <ButtonContainer>
        <Button
          variant="primary"
          onClick={handleButtonClick}
          data-testid="btn_submit_claim"
          disabled={disabled || !validSelectedDocuments.length}
        >
          <FormattedMessage id={submitButtonId} />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default SubmitClaimForm;
