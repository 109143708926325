import React from "react";
import { useParams } from "react-router-dom";
import SelectTreatmentDate from "./SelectTreatmentDate";
import {
  DENTOLO_DOCUMENT_DATE,
  DENTOLO_TREATMENT_DATE,
  useNewDentoloFormDataValue
} from '../../claimFormData/newClaimFormData';
import {
  useSelectTreatmentDateDentoloBackButtonLink,
  useSelectTreatmentDateDentoloNextButtonLink
} from "./selectTreatmentDateDentoloHooks";

const SelectTreatmentDateDentolo = () => {
  const { claimId } = useParams();

  const backButtonLink = useSelectTreatmentDateDentoloBackButtonLink(claimId);
  const nextButtonLink = useSelectTreatmentDateDentoloNextButtonLink(claimId);

  const [documentCreationDate] = useNewDentoloFormDataValue(DENTOLO_DOCUMENT_DATE);
  const [treatmentDate, setTreatmentDate] = useNewDentoloFormDataValue(DENTOLO_TREATMENT_DATE);

  return (
    <SelectTreatmentDate
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      documentCreationDate={documentCreationDate}
      treatmentDate={treatmentDate}
      setTreatmentDate={setTreatmentDate}
      pageTitleId={'dentolo.new_claim.select_treatment_creation_date.page_title'}
      formDescription={'dentolo.new_claim.select_treatment_creation_date.form_description'}
      checkLabelText={'dentolo.new_claim.select_treatment_creation_date.check_label_text'}
    />
  );
}

export default SelectTreatmentDateDentolo;
