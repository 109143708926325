import { useEffect, useRef } from 'react';

const useBeforeunload = (handler) => {
  if (!handler || typeof handler !== 'function') {
    console.error('Expected `handler` to be a function in `useBeforeunload`');
  }

  const eventListenerRef = useRef();

  useEffect(() => {
    eventListenerRef.current = (event) => {
      const returnValue = handler?.(event);

      // Handle legacy `event.returnValue` property
      if (typeof returnValue === 'string') {
        return (event.returnValue = returnValue);
      }

      // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
      // instead it requires `event.returnValue` to be set
      if (event.defaultPrevented) {
        return (event.returnValue = '');
      }
    };
  }, [handler]);

  useEffect(() => {
    const eventListener = (event) => eventListenerRef.current(event);

    window.addEventListener('beforeunload', eventListener);

    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  }, []);
};

export { useBeforeunload };
