import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import { palette } from '../../constants/Theme';
import { THEME_DENTOLO, THEME_PETOLO } from '../../shared';

const optionBackgroundColor = (theme) => {
  switch (theme) {
    case THEME_DENTOLO:
      return palette.background_color_2;
    case THEME_PETOLO:
      return palette.background_color_6;
    default:
      return palette.background_color_2;
  }
};

export const customSelectStyles = (theme) => ({
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? optionBackgroundColor(theme) : palette.background_color_1,
    color: isFocused ? palette.main_color_2 : palette.text_color_6,
    padding: '.75em',
  }),
  container: (styles) => ({ ...styles }),
  control: (styles, { isSelected, isFocused }) => ({
    ...styles,
    'paddingRight': '0.5em',
    'height': '3.5em',
    'borderColor': isSelected || isFocused ? palette.main_color_2 : palette.text_color_6,
    'boxShadow': isSelected || isFocused ? `${palette.box_shadow_5}` : 'none',
    ':hover': {
      borderColor: isSelected || isFocused ? palette.main_color_2 : palette.text_color_6,
      boxShadow: isSelected || isFocused ? `${palette.box_shadow_5}` : 'none',
    },
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: '.25em',
    zIndex: 3,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: palette.main_color_2,
    fontFamily: palette.font_family_1,
    fontSize: '1em',
  }),
  input: (styles) => ({
    ...styles,
    'color': palette.main_color_2,
    'fontSize': '1em',
    'input:focus': {
      boxShadow: 'none !important',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: palette.text_color_6,
    fontSize: '1em',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    'cursor': 'pointer',
    'color': palette.text_color_3,
    ':hover': {
      color: palette.text_color_5,
    },
  }),
});

const Select = (props) => {
  const themeContext = useContext(ThemeContext);
  const { theme } = themeContext || {};

  const customStylesForSelect = customSelectStyles(theme);

  return <CreatableSelect styles={customStylesForSelect} {...props} />;
};

export { Select };
