import { useClaimUploadFlowSource } from "../../claimUploadFlowSource";
import {
  PARAM_CLAIM_ID,
  PETOLO_EDIT_STEP_DOCUMENT_DATE,
  PETOLO_EDIT_STEP_TREATMENT_PRICE
} from "../../edit/editClaimFormSteps";
import {
  PETOLO_STEP_DOCUMENT_DATE,
  PETOLO_STEP_TREATMENT_PRICE,
} from "../../new/newClaimsFormSteps";

export const useSelectTreatmentDatePetoloBackButtonLink = (claimId) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const backButtonLink = PETOLO_EDIT_STEP_DOCUMENT_DATE.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLink);
  } else {
    return withSource(PETOLO_STEP_DOCUMENT_DATE);
  }
}

export const useSelectTreatmentDatePetoloNextButtonLink = (claimId) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const nextButtonLink = PETOLO_EDIT_STEP_TREATMENT_PRICE.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLink);
  } else {
    return withSource(PETOLO_STEP_TREATMENT_PRICE);
  }
}