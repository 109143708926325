import { useState, useEffect } from 'react';
import { useWindow } from './useWindow';

function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);
  const [getFromWindow] = useWindow();

  useEffect(() => {
    const matchMedia = getFromWindow('matchMedia');
    let media = null;
    let listener = null;

    if (typeof matchMedia === 'function') {
      media = matchMedia(query);
      if (media?.matches !== matches) {
        setMatches(media?.matches);
      }

      if (!!media?.addEventListener && typeof media?.addEventListener === 'function') {
        listener = () => setMatches(media?.matches);
        media.addEventListener('change', listener);
      }
    }

    return () => {
      if (!!media?.removeEventListener && typeof media?.removeEventListener === 'function') {
        media.removeEventListener('change', listener);
      }
    };
  }, [getFromWindow, matches, query]);

  return matches;
}

export { useMediaQuery };
