import styled from 'styled-components';

export const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

// export const StyledButtonContainer = styled.div`
//   margin-top: 1.5em;
//   margin-bottom: 1.5em;
//   text-align: center;
// `
