import { claimStatus } from "../../../../shared";

export const isReimbursementPromised = (claim) => {
  return claim?.status === claimStatus.REIMBURSEMENT_ACKNOWLEDGED;
}

export const isMissingInvoice = (claim) => {
  const isClaimWaiting = claim?.status === claimStatus.WAITING_FOR_INFORMATION;
  const isInvoiceMissing = claim?.statusDetails === claimStatus.INVOICE_MISSING;
  return isClaimWaiting && isInvoiceMissing;
}

export const isMissingTreatmentPlan = (claim) => {
  const isClaimWaiting = claim?.status === claimStatus.WAITING_FOR_INFORMATION;
  const isTreatmentPlanMissing = claim?.statusDetails === claimStatus.TREATMENT_PLAN_MISSING;
  return isClaimWaiting && isTreatmentPlanMissing;
}