import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { IconButton, Tag } from '../../../../components/common';
import { useDeleteIcon } from '../../../../hooks/useIcons';
import { StyledFileItem, StyledFileName } from './FileUploader.styled';

function FileList(props) {
  const { files, onRemove } = props;

  const validFiles = files?.filter((file) => file?.valid) || [];
  const invalidFiles = files?.filter((file) => !file?.valid) || [];

  return (
    <>
      {validFiles.map((file) => (
        <FileItem
          key={file?.fileId}
          fileId={file?.fileId}
          file={file?.file}
          isValidFile={file?.valid}
          error={file?.error}
          onRemove={onRemove}
        />
      ))}

      {invalidFiles.map((file) => (
        <FileItem
          key={file?.fileId}
          fileId={file?.fileId}
          file={file?.file}
          isValidFile={file?.valid}
          error={file?.error}
          onRemove={onRemove}
        />
      ))}
    </>
  );
}

export { FileList };

FileList.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
};

function FileItem(props) {
  const { fileId, file, isValidFile, error, onRemove } = props;

  const removeButtonIcon = useDeleteIcon();

  const fileNameText = file?.name || file?.path || fileId;

  return (
    <StyledFileItem>
      <div>
        <StyledFileName disabled={isValidFile}>{fileNameText}</StyledFileName>

        {isValidFile && (
          <Tag variant="success" data-testid="valid_file_tag">
            <FormattedMessage id="new_claim.file.ready.to.upload" />
          </Tag>
        )}

        {!isValidFile && error && <Tag variant="error" data-testid="invalid_file_tag">{error}</Tag>}
      </div>

      {!!onRemove &&
        <IconButton
          icon={removeButtonIcon}
          size="sm"
          alt="Remove file"
          onClick={(ev) => onRemove(ev, fileId)}
        />
      }
    </StyledFileItem>
  );
}

FileItem.propTypes = {
  fileId: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  isValidFile: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onRemove: PropTypes.func,
};
