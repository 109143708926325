import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import Slider from 'react-slick';

import { useInsuranceType } from '../../../hooks/useInsuranceType';
import { INSURANCE_TYPE_PETOLO, GET_CUSTOMER, INSURANCE_TYPE_DENTOLO } from '../../../shared';
import { carouselSettings as settings, filterValidSlides } from './carouselHelper';
import { promotionSlidesDentolo, promotionSlidesPetolo } from './carouselSlides';

import { PromotionCarouselControls } from './PromotionCarouselActions';
import { StyledPromotionCarousel } from './PromotionCarousel.styled';

const PromotionCarousel = () => {
  const insuranceType = useInsuranceType();
  const { data: customerData } = useQuery(GET_CUSTOMER);

  const [currSlide, setCurrSlide] = useState(0);
  const sliderRef = useRef(null);

  const currentPolicyCategory = customerData?.customer?.contract?.policyCategory;

  let slidesToUse = [];
  if (insuranceType === INSURANCE_TYPE_DENTOLO) {
    slidesToUse = promotionSlidesDentolo;
  } else if (insuranceType === INSURANCE_TYPE_PETOLO) {
    slidesToUse = promotionSlidesPetolo;
  } else {
    slidesToUse = promotionSlidesDentolo;
  }

  const promotionSlides = filterValidSlides(slidesToUse, currentPolicyCategory);
  const promotionSlideCount = promotionSlides?.length || 0;

  const handleNavigate = (to) => {
    if (!sliderRef.current) return;

    if (to === 'prev') {
      sliderRef.current.slickPrev();
    } else if (to === 'next') {
      sliderRef.current.slickNext();
    } else {
      sliderRef.current.slickGoTo(to);
    }
  };

  const handleBeforeChange = (_, idx) => {
    setCurrSlide(idx);
  };

  if (!Array.isArray(promotionSlides) || promotionSlideCount < 1) return null;

  return (
    <StyledPromotionCarousel>
      <Slider ref={sliderRef} {...settings} beforeChange={handleBeforeChange}>
        {promotionSlides.map((slide) => (
          <React.Fragment key={slide.id}>{slide?.card}</React.Fragment>
        ))}
      </Slider>

      {promotionSlideCount > 1 && (
        <PromotionCarouselControls
          slides={promotionSlides}
          current={currSlide}
          onPrevious={() => handleNavigate('prev')}
          onNext={() => handleNavigate('next')}
          onGoto={handleNavigate}
        />
      )}
    </StyledPromotionCarousel>
  );
};

export { PromotionCarousel };
