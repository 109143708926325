import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { imgLinks } from '../../../shared';

import { Button } from '../../../components/ui';
import { Container } from '../../../components/common';
import { StyledButtonContainer, StyledImg, StyledBackButton } from './Dentolo404Page.styled';

const Dentolo404Page = () => {
  let history = useHistory();

  return (
    <Container>
      <StyledBackButton
        to={'/dashboard'}
        label={<FormattedMessage id="common.button.back.home" />}
      ></StyledBackButton>

      <StyledImg src={imgLinks['dentolo.404.page']} alt="404"></StyledImg>
      <StyledButtonContainer>
        <Button variant="primary" onClick={() => history.push('/dashboard')}>
          <FormattedMessage id="common.button.back.home" />
        </Button>
      </StyledButtonContainer>
    </Container>
  );
};

export default Dentolo404Page;
