import { imgLinks } from '../../../shared';

export const getReferralShareToSocialList = (params) => {
  const { refUrl, fbIcon, waIcon, waMessage, emailIcon, emailContent } = params || {};

  return [
    {
      id: 'fb',
      image: imgLinks[fbIcon],
      click: () => {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(refUrl)}`,
          'pop',
          'width=600, height=400, scrollbars=no'
        );
      },
    },
    {
      id: 'whatsapp',
      image: imgLinks[waIcon],
      click: () => {
        const URL = `whatsapp://send?text=${waMessage || ''}`;

        window.open(URL, '_blank');
      },
    },
    {
      id: 'email',
      image: imgLinks[emailIcon],
      click: () => {
        const emailAddressTo = emailContent?.to || '';
        const emailSubject = emailContent?.subject || '';
        const emailBody = emailContent?.body || '';

        const emailLink = `mailto:${emailAddressTo}?subject=${emailSubject}&body=${emailBody}`;

        window.open(emailLink);
      },
    },
  ];
};
