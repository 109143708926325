import {
  PET_LIABILITY_AFFECTED_PARTY_OPTION_OTHER,
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_PERSON,
} from '../../claimFormData/newClaimFormDataPetLiability';
import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PET_LIABILITY_STEP_AFFECTED_PARTY_DETAILS,
  PET_LIABILITY_STEP_DAMAGE_ESTIMATE,
  PET_LIABILITY_STEP_INCIDENT_NOTE,
  PET_LIABILITY_STEP_MEDICAL_EXAM,
  PET_LIABILITY_STEP_UPLOAD_DOCUMENTS,
} from '../../new/newClaimsFormSteps';

export const useAffectedPartyPetLiabilityBackButtonLink = (liabilityReason) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  const backButtonLink =
    liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL ||
    liabilityReason === PET_LIABILITY_REASON_PERSON
      ? PET_LIABILITY_STEP_INCIDENT_NOTE
      : PET_LIABILITY_STEP_DAMAGE_ESTIMATE;

  return withSource(backButtonLink);
};

const getNextButtonLinkForNewClaim = (liabilityReason, affectedParty) => {
  if (affectedParty === PET_LIABILITY_AFFECTED_PARTY_OPTION_OTHER) {
    return PET_LIABILITY_STEP_AFFECTED_PARTY_DETAILS;
  } else {
    if (
      liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL ||
      liabilityReason === PET_LIABILITY_REASON_PERSON
    ) {
      return PET_LIABILITY_STEP_MEDICAL_EXAM;
    } else {
      return PET_LIABILITY_STEP_UPLOAD_DOCUMENTS;
    }
  }
};

export const useAffectedPartyPetLiabilityNextButtonLink = (liabilityReason, affectedParty) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  const nextButtonLink = getNextButtonLinkForNewClaim(liabilityReason, affectedParty);
  return withSource(nextButtonLink);
};
