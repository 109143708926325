import { useClaimUploadFlowSource } from "../../claimUploadFlowSource";
import {
  DENTOLO_EDIT_STEP_DENTIST_DETAILS,
  DENTOLO_EDIT_STEP_DOCUMENT_DATE,
  DENTOLO_EDIT_STEP_FILLING_COUNT,
  DENTOLO_EDIT_STEP_UPLOAD_DOCUMENTS,
  PARAM_CLAIM_ID
} from "../../edit/editClaimFormSteps";
import { DENTOLO_TREATMENT_CATEGORY_FILLING } from "../../claimFormData/newClaimFormData";
import {
  DENTOLO_STEP_DENTIST_DETAILS,
  DENTOLO_STEP_FILLING_COUNT,
  DENTOLO_STEP_TREATMENT_CATEGORY,
  DENTOLO_STEP_UPLOAD_DOCUMENTS,
} from "../../new/newClaimsFormSteps";

const getBackButtonLinkForExistingClaim = (treatmentCategory) => {
  switch (treatmentCategory) {
    case DENTOLO_TREATMENT_CATEGORY_FILLING:
      return DENTOLO_EDIT_STEP_FILLING_COUNT;
    default:
      return DENTOLO_EDIT_STEP_DENTIST_DETAILS;
  }
}

const getBackButtonLinkForNewClaim = (treatmentCategory) => {
  switch (treatmentCategory) {
    case DENTOLO_TREATMENT_CATEGORY_FILLING:
      return DENTOLO_STEP_FILLING_COUNT;
    default:
      return DENTOLO_STEP_DENTIST_DETAILS;
  }
}

export const useSubmitClaimFormDentoloBackButtonLink = (claimId, treatmentCategory) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const backButtonLink = getBackButtonLinkForExistingClaim(treatmentCategory);
    const backButtonLinkWithClaimId = backButtonLink.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLinkWithClaimId);
  } else {
    const backButtonLink = getBackButtonLinkForNewClaim(treatmentCategory)
    return withSource(backButtonLink);
  }
}

export const useEditDetailsLinkDentolo = (claimId) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const editButtonLink = DENTOLO_EDIT_STEP_DOCUMENT_DATE.replace(PARAM_CLAIM_ID, claimId);
    return withSource(editButtonLink);
  } else {
    return withSource(DENTOLO_STEP_TREATMENT_CATEGORY);
  }
};

export const useEditDocumentsLinkDentolo = (claimId) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const editDocumentsLink = DENTOLO_EDIT_STEP_UPLOAD_DOCUMENTS.replace(PARAM_CLAIM_ID, claimId);
    return withSource(editDocumentsLink);
  } else {
    return withSource(DENTOLO_STEP_UPLOAD_DOCUMENTS);
  }
};
