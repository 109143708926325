import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabPanel } from 'react-tabs';
import { FormattedMessage } from 'react-intl';

import { useMediaQuery } from '../../hooks/useMediaQuery';
import { min_width_md } from '../../theme/breakpoints';

import DentistDetailsUI from './DentistDetailsUI';
import FavoriteDentist from './FavoriteDentist';
import { Icon } from '../common';
import {
  StyledDentistsMapTitle,
  StyledDentistsMapToggleHelpButton,
  StyledTab,
  StyledTabList,
} from './styled/DentistsMapTitle.styled';

const DentistsMapTitle = ({ setOnboard, menuHeight, dentist, setDentist }) => {
  const [maxHeight, setMaxHeight] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const isDesktop = useMediaQuery(`(min-width: ${min_width_md})`);

  const handleResize = useCallback(() => {
    setMaxHeight(window.innerHeight - menuHeight - 200);
  }, [setMaxHeight, menuHeight]);

  const handleSelect = (index) => {
    setSelectedIndex(index);

    if (index === 0) setDentist(null);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [maxHeight, menuHeight, dentist, handleResize]);

  useEffect(() => {
    if (dentist) {
      setSelectedIndex(1);
    }
  }, [dentist, setSelectedIndex]);

  return (
    <StyledDentistsMapTitle>
      <Tabs
        selectedIndex={selectedIndex}
        selectedTabClassName="is-selected"
        onSelect={handleSelect}
      >
        <StyledTabList>
          <StyledTab>
            <FormattedMessage id="map.dentists.tabs.dentist.favourite" />
          </StyledTab>
          <StyledTab>
            <FormattedMessage id="map.dentists.tabs.dentist.find" />
          </StyledTab>
        </StyledTabList>

        <TabPanel>
          <FavoriteDentist maxHeight={maxHeight} />
        </TabPanel>

        <TabPanel>
          <StyledDentistsMapToggleHelpButton variant="nostyle" onClick={() => setOnboard(true)}>
            <Icon icon="icon.info" alt="help" />
            <FormattedMessage id="map.dentists.network.about" />
          </StyledDentistsMapToggleHelpButton>

          {isDesktop && (
            <DentistDetailsUI
              maxHeight={maxHeight}
              dentist={dentist}
              setDentist={setDentist}
              hidden={!isDesktop}
            />
          )}
        </TabPanel>
      </Tabs>
    </StyledDentistsMapTitle>
  );
};

DentistsMapTitle.propTypes = {
  setOnboard: PropTypes.func,
  menuHeight: PropTypes.number,
  dentist: PropTypes.object,
  setDentist: PropTypes.func,
};

export default DentistsMapTitle;
