import { useQuery } from '@apollo/client';
import { NODE_ENV, GET_CUSTOMER } from '../shared';
import { DENTOLO_DOMAIN, PETOLO_DOMAIN } from '../shared';
import { DEFAULT_INSURANCE_TYPE, INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO } from '../shared';
import {
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
} from '../shared';

const getInsuranceTypeFromURL = () => {
  const hostname = window.location.host;

  if (hostname.includes(DENTOLO_DOMAIN)) {
    return INSURANCE_TYPE_DENTOLO;
  } else if (hostname.includes(PETOLO_DOMAIN)) {
    return INSURANCE_TYPE_PETOLO;
  } else {
    return INSURANCE_TYPE_DENTOLO;
  }
};

const getInsuranceTypeFromInsuranceCategory = (customerInsuranceCategory) => {
  if (customerInsuranceCategory === INSURANCE_CATEGORY_DENTAL) {
    return INSURANCE_TYPE_DENTOLO;
  } else if (
    customerInsuranceCategory === INSURANCE_CATEGORY_PET_HEALTH ||
    customerInsuranceCategory === INSURANCE_CATEGORY_PET_LIABILITY
  ) {
    return INSURANCE_TYPE_PETOLO;
  } else {
    return INSURANCE_TYPE_DENTOLO;
  }
};

export const useInsuranceType = () => {
  const insuranceTypeFromUrl =
    NODE_ENV === 'development' ? DEFAULT_INSURANCE_TYPE : getInsuranceTypeFromURL();

  const { data: customerData } = useQuery(GET_CUSTOMER);
  const customer = customerData?.customer;

  const customerInsuranceCategory = customer?.insuranceCategory;
  const insuranceTypeFromCustomer =
    getInsuranceTypeFromInsuranceCategory(customerInsuranceCategory);

  const insuranceType = !!customer ? insuranceTypeFromCustomer : insuranceTypeFromUrl;

  return insuranceType;
};
