import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { GlobalStyles } from './GlobalStyles';
import { useApplicationTheme } from './hooks/useApplicationTheme';
import PreloadAssets from './components/ui/PreloadAssets';
import { THEME_DENTOLO } from './shared';
import { palette } from './theme';
import Routes from './Routes';

function App() {
  const theme = useApplicationTheme();
  const themePalette = palette[theme || THEME_DENTOLO];
  const themeData = { name: theme, ...themePalette };

  return (
    <ThemeProvider theme={themeData}>
      <Router>
        <Routes />
      </Router>

      <GlobalStyles />
      <PreloadAssets />
    </ThemeProvider>
  );
}

export default App;
