import styled from 'styled-components';

import { Paper, RadioLabel } from '../../../../components/common';

export const StyledRadioContainer = styled(Paper)`
  margin-top: 1rem;
`;

export const StyledRadioOptionContainer = styled.div`
  display: flex;
  cursor: pointer;
  word-break: break-word;
`;

export const StyledRadioLabel = styled(RadioLabel)`
  margin-left: 1rem;

  > div {
    font-size: 1.25rem;
    font-weight: 700;
  }

  > label {
    font-size: 1rem;
  }
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  margin-top: 1rem;
`;
