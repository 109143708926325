import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO } from "../shared"
import { useInsuranceType } from "./useInsuranceType"

export const useMenuIcon = () => {
  const insuranceType = useInsuranceType()

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.menu.dentolo.logo'
    case INSURANCE_TYPE_PETOLO:
      return 'icon.menu.petolo.logo'
    default:
      return 'icon.menu.dentolo.logo'
  }
}

export const useDownloadIcon = () => {
  const insuranceType = useInsuranceType()

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.download.dental'
    case INSURANCE_TYPE_PETOLO:
      return 'icon.download.pet'
    default:
      return 'icon.download.dental'
  }
}

export const useDeleteIcon = () => {
  const insuranceType = useInsuranceType()

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.delete.circle.dental'
    case INSURANCE_TYPE_PETOLO:
      return 'icon.delete.circle.pet'
    default:
      return 'icon.delete.circle.dental'
  }
}

export const useFileUploadIcon = () => {
  const insuranceType = useInsuranceType()

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.plus.teal'
    case INSURANCE_TYPE_PETOLO:
      return 'icon.plus.orange'
    default:
      return 'icon.plus.teal'
  }
}

export const useCheckIcon = () => {
  const insuranceType = useInsuranceType()

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.success'
    case INSURANCE_TYPE_PETOLO:
      return 'icon-checked-stroke-orange'
    default:
      return 'icon.success'
  }
}

export const useEditIcon = () => {
  const insuranceType = useInsuranceType()

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.pencil.blue.filled'
    case INSURANCE_TYPE_PETOLO:
      return 'icon.pencil.orange.filled'
    default:
      return 'icon.pencil.blue.filled'
  }
}