import styled from 'styled-components';
import { min_width_md } from '../../../theme/breakpoints';

import Paragraph from '../Paragraph';

export const StyledInfoWindow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${min_width_md}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const StyledInfoWindowTitle = styled.h4`
  margin-top: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
`;

export const StyledInfoWindowParagraph = styled(Paragraph)`
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 450;
  text-align: center;
`;

export const InfoWindowButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5rem;
  width: 100%;

  > *:not(:first-child) {
    margin-left: 1rem;
  }
`;
