import styled from 'styled-components';

import { min_width_md } from '../../theme/breakpoints';
import { width } from '../../theme/functions';


const StyledContainer = styled.div`
  margin: 1.5rem auto 0;
  padding: 0 1rem;
  width: 100%;
  max-width: ${width('container')};

  @media (min-width: ${min_width_md}) {
    margin-top: 4rem;
    padding: 0;
  }
`;

export default StyledContainer;
