import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { GET_DOCUMENTS, useReady, documentsOrder } from '../shared';
import { min_width_md } from '../theme/breakpoints';
import { color } from '../theme/functions';

import { BackButton, Container, Paper, Paragraph, PageTitle } from './common';
import { Button, DownloadLink } from './ui';

const StyledButton = styled(Button)`
  margin-top: 2rem;
  margin: 2rem auto 0;

  @media (min-width: ${min_width_md}) {
    margin-left: 0;
  }
`;

const StyledInfo = styled.div`
  margin-top: 1rem;
  font-size: 0.688rem;
  color: ${color('main_blue')};
  text-align: center;

  @media (min-width: ${min_width_md}) {
    font-size: 0.875rem;
  }
`;

const ContractDocs = () => {
  const showZip = false;
  const ready = useReady();
  const getDocuments = useQuery(GET_DOCUMENTS);
  let documents = [];

  if (getDocuments?.data?.documents?.length) {
    let maxSortNum = documentsOrder.length;
    let documentsWithNums = getDocuments?.data?.documents?.map((item, index) => {
      for (const docOrder of documentsOrder) {
        if (item.name === docOrder.name) {
          return {
            ...item,
            num: docOrder.numContractDoc,
          };
        }
      }
      return {
        ...item,
        num: maxSortNum + index,
      };
    });
    documents = documentsWithNums.sort((a, b) => a.num - b.num);
  }

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <BackButton to="/policy-info" />

        <PageTitle>
          <FormattedMessage id="contract.docs.title" />
        </PageTitle>

        <Paragraph>
          <FormattedMessage id="contract.docs.description" />
        </Paragraph>

        <Paper style={{ paddingTop: 0, paddingBottom: 0 }}>
          {documents &&
            documents.map(
              (doc) =>
                doc &&
                doc.url &&
                doc.name && (
                  <DownloadLink link={doc.url} name={doc.name} key={doc.name} target="_blank">
                    <FormattedMessage id={`document.${doc.name}`} />
                  </DownloadLink>
                )
            )}

          {showZip && (
            <>
              <StyledButton variant="outline">
                <FormattedMessage id="contract.docs.download.all" />
              </StyledButton>

              <StyledInfo>
                <FormattedMessage id="contract.docs.download.all.subtext" />
              </StyledInfo>
            </>
          )}
        </Paper>
      </Container>
    </CSSTransition>
  );
};

export default ContractDocs;
