import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { useTrackDtEvent } from '../../DtlTracker';
import { GET_FAVORITE_DENTISTS, ADD_FAVORITE_DENTIST, REMOVE_FAVORITE_DENTIST } from '../../shared';

import { BarLoader, Icon, Text } from '../common';
import {
  StyledDentistFloatCard,
  StyledDentistFloatCardItem,
  StyledDentistFloatCardWrapper,
  StyledFavDentistIconButton,
  StyledDentistFloatCardCloseButton,
  StyledBarLoaderWrapper,
} from './styled/DentiststFloatCard.styled';

const DentistsFloatCardPartner = ({ customer, dentist, setDentist }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [favourites, setFavourites] = useState([]);
  const [sendUserData] = useTrackDtEvent();

  const {
    loading: favouriteDentistsLoading,
    error: favouriteDentistsError,
    data: favouriteDentists,
  } = useQuery(GET_FAVORITE_DENTISTS);

  const [removeFavouriteDentist] = useMutation(REMOVE_FAVORITE_DENTIST, {
    variables: {
      dentistKey: dentist?.referenceKey,
    },
    refetchQueries: [{ query: GET_FAVORITE_DENTISTS }],
  });

  const [setFavouriteDentist] = useMutation(ADD_FAVORITE_DENTIST, {
    variables: {
      dentistKey: dentist?.referenceKey,
    },
    refetchQueries: [{ query: GET_FAVORITE_DENTISTS }],
  });

  const isFavourite = useCallback(
    (fav) => {
      return fav?.some((favourite) => favourite?.dentistKey === dentist?.referenceKey);
    },
    [dentist]
  );

  const toggleFavourite = () => {
    setShowLoader(true);

    if (isFavourite(favourites)) {
      trackDtEvent('click remove dentist from favourites');
      removeFavouriteDentist();
    } else {
      trackDtEvent('click add dentist to favourites');
      setFavouriteDentist();
    }
  };

  const trackDtEvent = (eventType = '') => {
    const trackData = {
      userData: {
        eventType: eventType || 'click',
        time: new Date().toISOString(),
        url: window?.location?.href,
        uuid: customer?.uuid,
        dentist: dentist?.referenceKey,
        website: dentist?.websiteUrl,
      },
    };
    sendUserData(trackData);
  };

  const handleLinkCLick = (event) => {
    event.stopPropagation();
    trackDtEvent('click dentist website');
  };

  useEffect(() => {
    if (!favouriteDentistsError) {
      setFavourites(favouriteDentists?.customer?.favoriteDentists);
    }

    setShowLoader(favouriteDentistsLoading);
  }, [dentist, favouriteDentists, favouriteDentistsError, favouriteDentistsLoading]);

  const address = `${dentist?.streetName} ${dentist?.houseNumber}, ${dentist?.postcode} ${dentist?.city}`;
  const heartIcon = isFavourite(favouriteDentists?.customer?.favoriteDentists)
    ? 'icon.fill.heart'
    : 'icon.blank.heart';

  if (!dentist || favouriteDentistsError) return <></>;

  return (
    <StyledDentistFloatCard>
      <StyledDentistFloatCardWrapper blurred={showLoader}>
        <StyledDentistFloatCardCloseButton icon="icon.x" onClick={() => setDentist(null)} />

        <Text fontSize="1.15rem">{dentist?.practiceName}</Text>
        <Text fontSize="1.5rem" fontWeight="700" style={{ margin: '0.5rem 0 1rem' }}>
          {dentist?.title} {dentist?.name}
        </Text>

        <StyledDentistFloatCardItem>
          <Icon icon="icon.pin" alt="address" />
          <label>{address}</label>
        </StyledDentistFloatCardItem>

        <StyledDentistFloatCardItem>
          <Icon icon="icon.phone" alt="phone" />
          <label>
            Termin buchen: <strong>{dentist?.phoneNumber}</strong>
          </label>
        </StyledDentistFloatCardItem>

        {dentist?.websiteUrl && (
          <StyledDentistFloatCardItem>
            <Icon icon="icon.globe" alt="website" />
            <label>
              Online unter:{' '}
              <a
                href={`${dentist?.websiteUrl}`}
                rel="noopener noreferrer"
                target="_blank"
                onClick={handleLinkCLick}
              >
                <strong>{dentist?.websiteUrl}</strong>
              </a>
            </label>
          </StyledDentistFloatCardItem>
        )}

        <StyledFavDentistIconButton
          onClick={toggleFavourite}
          isFavourite={heartIcon === 'icon.fill.heart'}
        >
          <span>
            <Icon icon={heartIcon} alt="favourite" />
          </span>

          <FormattedMessage tagName="label" id="dentist.details.common.save.favourites" />
        </StyledFavDentistIconButton>
      </StyledDentistFloatCardWrapper>

      {showLoader && (
        <StyledBarLoaderWrapper>
          <BarLoader />
        </StyledBarLoaderWrapper>
      )}
    </StyledDentistFloatCard>
  );
};

DentistsFloatCardPartner.propTypes = {
  customer: PropTypes.object,
  /** This contains all the information needed to render the component */
  dentist: PropTypes.object,
  /** Set dentist to null to render <></> (and hide this float window) */
  setDentist: PropTypes.func,
};

export default DentistsFloatCardPartner;
