//prod
export const v2Designs = '//s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/v2-designs/';
export const userAreaIconsDentolo =
  '//s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/user-area-icons/';
export const userAreaIconsPetolo =
  '//s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/user-area-icons/petolo/';
export const userAreaIconsOnboard =
  '//s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/user-area-icons/onboard/';
export const icons = '//s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/icons/';
export const logos = '//s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/logos/';

/*// to run the assets locale:
// validate the below constants and make sure to =>
// copy img folder from dentolo-ui-elements/dist to the puplic folder in this proj
// thats how u can work locally and replace in puplic/image , then replace img folder to it's original position dentolo-ui-elements/dist
// don't forget to remove img folder from public after u finish.


export const v2Designs = '/img/v2-designs/'
export const userAreaIconsDentolo = '/img/user-area-icons/'
export const userAreaIconsPetolo = '/img/user-area-icons/petolo/'
export const userAreaIconsOnboard = '/img/user-area-icons/onboard/'
export const icons = '/img/icons/'
export const logos = '/img/logos/'
*/

export const imgLinks = {
  // new icons
  'icon.failed': `${v2Designs}icon.failed.svg`,
  'icon.success': `${v2Designs}icon.success.svg`,
  'confirm.time': `${v2Designs}confirm.time.svg`,
  'flying.money': `${v2Designs}flying.money.svg`,
  'icon.delete.circle.dental': `${v2Designs}icon.delete.circle.svg`,
  'icon.delete.circle.pet': `${v2Designs}icon.delete.circle.pet.svg`,
  'icon.menu.green.tooth.lg': `${v2Designs}icon.green.circle.tooth-lg.svg`,
  'icon.menu.orange.paw.lg': `${v2Designs}icon.orange.circle.paw-lg.svg`,
  'icon.menu.info': `${v2Designs}icon.info.svg`,
  'icon.menu.person': `${v2Designs}icon.person.svg`,
  'icon.menu.doc': `${v2Designs}icon.doc.svg`,
  'icon.menu.money': `${v2Designs}icon.money.svg`,
  'icon.menu.headphone': `${v2Designs}icon.headphone.svg`,
  'icon.menu.phone': `${v2Designs}icon.contact.phone.svg`,
  'icon.menu.email': `${v2Designs}icon.contact.email.svg`,
  'info.success': `${v2Designs}info.success.svg`,
  'info.warning': `${v2Designs}info.failure.svg`,
  'notification.success': `${v2Designs}notification.success.svg`,
  'notification.warning': `${v2Designs}notification.warning.svg`,
  'notification.processing': `${v2Designs}notification.processing.svg`,
  'notification.information': `${v2Designs}notification.information.svg`,
  'iban.missing': `${userAreaIconsDentolo}icon.orange.circle.doc.svg`,

  'icon.plus.teal': `${v2Designs}icon.plus.teal.svg`,
  'icon.download.dental': `${v2Designs}icon.download.dental.svg`,
  'icon.download.pet': `${v2Designs}icon.download.pet.svg`,
  'icon.menu.green.tooth': `${v2Designs}icon.green.circle.tooth.svg`,
  'icon.menu.orange.paw': `${v2Designs}icon.orange.circle.paw.svg`,
  'icon.plus.orange': `${v2Designs}icon.plus.petolo.svg`,
  'icon.pencil.blue.filled': `${v2Designs}icon.pencil.blue.filled.svg`,
  'icon.pencil.orange.filled': `${v2Designs}icon.pencil.orange.filled.svg`,
  'icon.telephone.blue.filled': `${v2Designs}icon.telephone.blue.filled.svg`,
  'icon.telephone.orange.filled': `${v2Designs}icon.telephone.orange.filled.svg`,
  'icon.contribution.blue.filled': `${v2Designs}icon.contribution.blue.filled.svg`,

  //
  'icon.failure': `${userAreaIconsDentolo}icon.failure.svg`,
  'icon.claim-success': `${userAreaIconsDentolo}icon.claim-success.svg`,
  'icon.claim-warning': `${userAreaIconsDentolo}icon.claim-warning.svg`,
  'icon.user': `${userAreaIconsDentolo}icon.user.svg`,
  'icon.policy.shield': `${userAreaIconsDentolo}icon.policy.shield.svg`,
  'icon.female.dentist': `${userAreaIconsDentolo}icon.female.dentist.svg`,
  'icon.claim.hand': `${userAreaIconsDentolo}icon.claim.hand.svg`,
  'logo.dentolo.faircare.white.medium': `${logos}logo.dentolo.logo.white.new.svg`,

  'icons.help': `${userAreaIconsDentolo}icon.on.board.help.svg`,
  'icons.play': `${userAreaIconsDentolo}icon.on.board.svg`,
  'icon.info': `${icons}icon.info-24x24.svg`,
  'icon.true': `${icons}icon.true-24x24.svg`,
  'icon.back.arrow': `${icons}icon.back.arrow.svg`,
  'icon.contact.info': `${userAreaIconsDentolo}icon.contact.info.svg`,
  'icon.pin': `${userAreaIconsDentolo}icon.pin.svg`,
  'icon.phone': `${userAreaIconsDentolo}icon.phone.svg`,
  'icon.globe': `${userAreaIconsDentolo}icon.globe.svg`,
  'icon.hours': `${userAreaIconsDentolo}icon.hours.svg`,
  'icon.accordion.arrow.down': `${userAreaIconsDentolo}icon.accordion.arrow.down.svg`,
  'icon.x': `${userAreaIconsDentolo}icon.x.svg`,
  'icon.upload.loader.outer': `${userAreaIconsDentolo}icon.upload.loader.outer.svg`,
  'icon.upload.loader.inner': `${userAreaIconsDentolo}icon.upload.loader.inner.svg`,
  'icon.user.claim.onboard.hand': `${userAreaIconsOnboard}icon.user.claim.onboard.hand.svg`,
  'icon.user.claim.onboard.euro': `${userAreaIconsOnboard}icon.user.claim.onboard.euro.svg`,
  'icon.user.claim.onboard.phone': `${userAreaIconsOnboard}icon.user.claim.onboard.phone.svg`,
  'icon.user.claim.onboard.tablet': `${userAreaIconsOnboard}icon.user.claim.onboard.tablet.svg`,
  'icon.user.claim.onboard.laptop': `${userAreaIconsOnboard}icon.user.claim.onboard.laptop.svg`,
  'icon.user.claim.onboard.rechnung': `${userAreaIconsOnboard}icon.user.claim.onboard.rechnung.svg`,
  'icon.user.claim.onboard.success': `${userAreaIconsOnboard}icon.user.claim.onboard.success.svg`,
  'icon.user.claim.onboard.big.success': `${userAreaIconsOnboard}icon.user.claim.onboard.big.success.svg`,
  'icon.user.claim.onboard.coin': `${userAreaIconsOnboard}icon.user.claim.onboard.coin.svg`,
  'icon.user.claim.onboard.coins': `${userAreaIconsOnboard}icon.user.claim.onboard.coins.svg`,
  'icon.user.claim.onboard.stars': `${userAreaIconsOnboard}icon.user.claim.onboard.stars.svg`,
  'icon.home.onboard.laptop.logo': `${userAreaIconsOnboard}icon.home.onboard.laptop.logo.svg`,
  'icon.home.onboard.tablet.logo': `${userAreaIconsOnboard}icon.home.onboard.tablet.logo.svg`,
  'icon.home.onboard.phone.logo': `${userAreaIconsOnboard}icon.home.onboard.phone.logo.svg`,
  'icon.home.onboard.map.pin': `${userAreaIconsOnboard}icon.home.onboard.map.pin.svg`,
  'icon.home.onboard.map': `${userAreaIconsOnboard}icon.home.onboard.map.svg`,
  'icon.home.onboard.dentolo.card': `${userAreaIconsOnboard}icon.home.onboard.dentolo.card.new.svg`,
  'icon.home.onboard.star': `${userAreaIconsOnboard}icon.home.onboard.star.svg`,
  'icon.home.onboard.hand': `${userAreaIconsOnboard}icon.home.onboard.hand.svg`,

  'icon.fill.heart': `${userAreaIconsDentolo}icon.fill.heart.svg`,
  'icon.blank.heart': `${userAreaIconsDentolo}icon.empty.heart.svg`,
  'icon.close': `${icons}icon.close.svg`,

  'icon.caret.right': `${icons}icon.caret-right-24x24.svg`,
  'icon.contact.menu': `${userAreaIconsDentolo}icon.contact.menu.svg`,
  'icon.helpcenter.menu': `${userAreaIconsDentolo}icon.helpcenter.menu.svg`,
  'icon.helpcenter.menu.burger': `${userAreaIconsDentolo}icon.helpcenter.menu.burger.svg`,
  'icon.user.amazon.voucher': `${userAreaIconsDentolo}amazon-voucher.transparent.svg`,
  'icon.user.gift.voucher': `${userAreaIconsDentolo}gift-voucher.referral.svg`,
  'icon.user.referral.voucher.sm': `${v2Designs}icon.user.referral.voucher.sm.svg`,
  'icon.user.referral.voucher.lg': `${v2Designs}icon.user.referral.voucher.lg.svg`,
  'icon.user.referral.present': `${v2Designs}icon.user.referral.present.svg`,
  'icon.user.referral.car': `${v2Designs}icon.user.referral.car.svg`,
  'icon.user.referral.fb': `${v2Designs}fb.referral.logo.svg`,
  'icon.user.referral.email': `${v2Designs}email.referral.logo.svg`,
  'icon.user.referral.whatsapp': `${v2Designs}whatsup.referral.logo.svg`,
  'image.cover.referral.dental.lg': `${v2Designs}referral-cover-image-dental-lg.jpg`,
  'image.cover.referral.dental.sm': `${v2Designs}referral-cover-image-dental-sm.jpg`,

  'icon.user.referral.pet.present': `${v2Designs}icon.user.referral.pet.present.svg`,
  'icon.user.referral.pet.voucher.sm': `${v2Designs}icon.user.referral.pet.voucher.sm.png`,
  'icon.user.referral.pet.voucher.lg': `${v2Designs}icon.user.referral.pet.voucher.lg.png`,
  'illustration.petolo.referral.fressnapf.voucher.sm': `${v2Designs}petolo/illustration.petolo.referral.fressnapf.voucher.sm.jpg`,
  'illustration.petolo.referral.fressnapf.voucher.lg': `${v2Designs}petolo/illustration.petolo.referral.fressnapf.voucher.lg.jpg`,
  'image.petolo.referral.fressnapf.voucher': `${v2Designs}petolo/image.petolo.referral.fressnapf.voucher.png`,
  'icon.user.join.referral.pet.sm': `${v2Designs}icon.user.join.referral.pet.sm.png`,
  'icon.user.join.referral.pet.lg': `${v2Designs}icon.user.join.referral.pet.lg.png`,
  'icon.user.referral.pet.fb': `${v2Designs}fb.referral.pet.logo.svg`,
  'icon.user.referral.pet.email': `${v2Designs}email.referral.pet.logo.svg`,
  'icon.user.referral.pet.whatsapp': `${v2Designs}whatsup.referral.pet.logo.svg`,
  'illustration.join.referral.dental': `${v2Designs}illustration.join.referral.dental.svg`,

  'icon.payment.overview.background': `${logos}payment-overview-720x222.png`,
  'icon.payment.details': `${userAreaIconsDentolo}flying-money-hand.svg`,
  'icon.error.message.placeholder': `${userAreaIconsDentolo}error-placeholder-background-760-507.png`,

  // 404 pages

  'dentolo.404.page': `${v2Designs}dentolo-404.svg`,
  'petolo.404.page': `${v2Designs}petolo-404.svg`,

  // cancellation contract
  'icon.user.dental.cancellation.contract.mobile': `${v2Designs}cancellation-dental-mobile.png`,
  'icon.user.dental.cancellation.contract.desktop': `${v2Designs}cancellation-dental-desktop.png`,
  'icon.user.pet.cancellation.contract.mobile': `${v2Designs}cancellation-pet-mobile.png`,
  'icon.user.pet.cancellation.contract.desktop': `${v2Designs}cancellation-pet-desktop.png`,

  'icon.user.dental.cancellation.success': `${v2Designs}cancellation-dental-success.png`,
  'icon.user.pet.cancellation.success': `${v2Designs}cancellation-pet-success.png`,

  // Header Menu
  'icon.menu.green.doc': `${userAreaIconsDentolo}icon.green.circle.doc.svg`,
  'icon.menu.green.headphone': `${userAreaIconsDentolo}icon.green.circle.headphone.svg`,
  'icon.menu.green.info': `${userAreaIconsDentolo}icon.green.circle.info.svg`,
  'icon.menu.green.money': `${userAreaIconsDentolo}icon.green.circle.money.svg`,
  'icon.menu.green.person': `${userAreaIconsDentolo}icon.green.circle.person.svg`,
  'icon.menu.green.phone': `${userAreaIconsDentolo}icon.green.circle.phone.svg`,

  'icon.menu.dentolo.logo': `${logos}logo.dentolo.small-113x32.svg`,
  'icon.menu.accordion.arrow.down': `${icons}icon.caret-down-24x24.svg`,
  'icon.menu.blue.mark': `${icons}icon_checkbox_32x32_selected_simple.svg`,
  'icon.menu.dadirekt.dark': `${logos}da_direkt_logo_new_dark.svg`,

  // Petolo
  'icon.menu.petolo.logo': `${userAreaIconsPetolo}icon-petolo-logo-blue.svg`,
  'icon.menu.orange.doc': `${userAreaIconsDentolo}icon.orange.circle.doc.svg`,
  'icon.menu.orange.headphone': `${userAreaIconsDentolo}icon.orange.circle.headphone.svg`,
  'icon.menu.orange.info': `${userAreaIconsDentolo}icon.orange.circle.info.svg`,
  'icon.menu.orange.money': `${userAreaIconsDentolo}icon.orange.circle.money.svg`,
  'icon.menu.orange.person': `${userAreaIconsDentolo}icon.orange.circle.person.svg`,
  'icon.menu.orange.phone': `${userAreaIconsDentolo}icon.orange.circle.phone.svg`,
  'icon.petolo.check.orange': `${userAreaIconsPetolo}icon-success-orange.svg`,
  'icon.checked.orange': `${userAreaIconsPetolo}icon-checked-orange.svg`,

  'icon.orange.claim-warning': `${userAreaIconsPetolo}icon-warning-orange.svg`,

  // First Vet
  'icon.petolo.firstvet': `${userAreaIconsDentolo}petolo.firstvet.logo.png`,
  'icon.apple.store': `${userAreaIconsDentolo}icon.apple.store.badge.png`,
  'icon.google.play': `${userAreaIconsDentolo}icon.google.play.badge.png`,
  'icon.online.vet.slider.one': `${v2Designs}petolo/icon-number-one.svg`,
  'icon.online.vet.slider.two': `${v2Designs}petolo/icon-number-two.svg`,
  'icon.online.vet.slider.three': `${v2Designs}petolo/icon-number-three.svg`,
  'icon.petolo.desktop.logo': `${userAreaIconsPetolo}icon-desktop-petolo.svg`,
  'icon.petolo.hand.money': `${userAreaIconsPetolo}icon-hand-money-orange.svg`,
  'icon.petolo.mobile.logo': `${userAreaIconsPetolo}icon-mobile-petolo.svg`,
  'icon.petolo.blue': `${userAreaIconsPetolo}icon-petolo-blue.svg`,
  'icon.petolo.card.no.stars': `${userAreaIconsPetolo}icon-petolo-card-no-starts.svg`,
  'icon.petolo.shield.orange': `${userAreaIconsPetolo}icon-shield-orange.svg`,
  'icon.petolo.star.one': `${userAreaIconsPetolo}icon-star-one.svg`,
  'icon.petolo.star.two': `${userAreaIconsPetolo}icon-star-two.svg`,
  'icon.petolo.star.three': `${userAreaIconsPetolo}icon-star-three.svg`,
  'icon.petolo.tablet.logo': `${userAreaIconsPetolo}icon-tablet-petolo.svg`,
  'icon-checked-stroke-orange': `${userAreaIconsPetolo}icon-checked-stroke-orange.svg`,

  //New Claim Creation
  'icon.other.treatments': `${v2Designs}dentolo/icon.other.treatments.svg`,
  'icon.teeth.cleaning': `${v2Designs}dentolo/icon.teeth.cleaning.svg`,
  'icon.teeth.filling': `${v2Designs}dentolo/icon.teeth.filling.svg`,

  //Fressnapf
  'fressnapf.banner': `${v2Designs}petolo/dr-fressnapf-banner.png`,

  // InsureAnotherPetBanner
  'image.insure.another.pet.banner': `${v2Designs}image-insure-another-pet-banner.svg`,
};
