import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';
import { CSSTransition } from 'react-transition-group';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode';
import { useHistory, useLocation } from 'react-router-dom';
import { InfoCard, InputField, Paragraph } from '../ui';
import { Paper } from '../common';
import {
  GET_CUSTOMER,
  CONFIRM_EMAIL,
  SET_CONFIRM_EMAIL_MUTATION,
  directlyLoadFromLocalStorage,
  useReady,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
} from '../../shared';
import { useInsuranceType } from '../../hooks/useInsuranceType';

import { AuthContainer, PageTitle, StyledRememberCheckbox, Button } from './Login.styled';

const getButtonConfig = (insuranceType, intl) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return {
        text: intl.formatMessage({ id: 'login.link.to_dentolo_homepage' }),
        href: '//dentolo.de/',
      };
    case INSURANCE_TYPE_PETOLO:
      return {
        text: intl.formatMessage({ id: 'login.link.to_petolo_homepage' }),
        href: '//petolo.de/',
      };
    default:
      return {
        text: intl.formatMessage({ id: 'login.link.to_dentolo_homepage' }),
        href: '//dentolo.de/',
      };
  }
};

const getInsuranceCategory = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return INSURANCE_CATEGORY_DENTAL;
    case INSURANCE_TYPE_PETOLO:
      return INSURANCE_CATEGORY_PET_HEALTH;
    default:
      return INSURANCE_CATEGORY_DENTAL;
  }
};

const Login = () => {
  const [email, setEmail] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const insuranceType = useInsuranceType();
  const location = useLocation();
  const history = useHistory();
  const ready = useReady();
  const intl = useIntl();

  const gduid = directlyLoadFromLocalStorage('gduid');
  const search = location?.search || '';
  const buttonConfig = getButtonConfig(insuranceType, intl);

  useEffect(() => {
    const params = queryString.parse(search);

    if (params?.logout === 'true' && !isLogout) {
      setIsLogout(true);
    }

    if (params?.invalid === 'true' && !isInvalid) {
      setIsInvalid(true);
      const jwt = params?.auth;
      const decodedJwt = !!jwt ? jwt_decode(jwt) : {};
      const email = decodedJwt?.email;
      setEmail(email || '');

      const rememberMeString = params?.remember_me;
      const rememberMe = rememberMeString === 'true';
      setRememberMe(rememberMe);
    }
  }, [isInvalid, isLogout, search]);

  const getCustomer = useQuery(GET_CUSTOMER);
  const customerUuid = getCustomer?.data?.customer?.uuid;
  useEffect(() => {
    if (!!customerUuid) {
      history.push('/');
    }
  }, [customerUuid, history]);

  const [setConfirmEmail] = useMutation(SET_CONFIRM_EMAIL_MUTATION, {
    variables: {
      email,
    },
  });

  const [confirmEmail] = useMutation(CONFIRM_EMAIL, {
    variables: {
      email,
      gduid,
      rememberMe,
      insuranceCategory: getInsuranceCategory(insuranceType),
    },
  });

  const onFormSubmit = (e) => {
    e.preventDefault();
    setDisableForm(true);
    confirmEmail()
      .then((res) => {
        const { email, errors } = res.data.confirmEmail;
        if (errors.length) {
          let noCustomerEmail = false;
          for (const error of errors) {
            if (error.includes('No customers with email')) {
              noCustomerEmail = true;
            }
          }
          if (noCustomerEmail) {
            setConfirmEmail().then((_) => history.push(`/login/failure`));
          }
        }
        if (email && !errors.length) {
          let url = `/login/success`;
          const params = queryString.parse(location.search);
          if (params.customer_source === 'zurich') {
            url = `/login/success?customer_source=zurich`;
          }
          setConfirmEmail().then((_) => history.push(url));
        }
      })
      .then(() => {
        setDisableForm(false);
      })
      .catch();
  };

  return (
    <AuthContainer>
      <PageTitle>
        <FormattedMessage id="login.common.title" />
      </PageTitle>

      <CSSTransition in={isLogout} timeout={400} classNames="fade" unmountOnExit>
        <InfoCard
          style={{ marginBottom: '1.5rem' }}
          mode="success"
          titleHtml={intl.formatMessage({ id: 'login.info.message.logout_success' })}
          button={buttonConfig}
        />
      </CSSTransition>

      <CSSTransition in={isInvalid} timeout={400} classNames="fade" unmountOnExit>
        <InfoCard
          style={{ marginBottom: '1.5rem' }}
          mode="failure"
          titleHtml={intl.formatMessage({ id: 'login.info.title.invalid_link' })}
          textHtml={intl.formatMessage({ id: 'login.info.message.invalid_link' })}
        />
      </CSSTransition>

      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Paper style={{ padding: '2rem' }}>
          <form onSubmit={(e) => onFormSubmit(e)}>
            <fieldset disabled={disableForm} aria-busy={disableForm}>
              <InputField
                id="login-email"
                name="login-email"
                placeholder="maria.muster@web.de"
                labelText="E-Mail Adresse"
                labelStyle={{ fontWeight: 700 }}
                type="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />

              <StyledRememberCheckbox
                id="login-checkbox"
                name="login-checkbox"
                label="Login-Daten merken"
                value={rememberMe}
                onChange={() => {
                  setRememberMe(!rememberMe);
                }}
              />

              <Paragraph type="subtext" style={{ margin: '1.5rem 0' }}>
                {intl.formatMessage({ id: 'login.common.send.email' })}
              </Paragraph>

              <Button type="submit" data-testid="btn_send_login_link">
                <FormattedMessage data-testid="btn_send_login_link"id="login.common.send.link" />
              </Button>
            </fieldset>
          </form>
        </Paper>
      </CSSTransition>
    </AuthContainer>
  );
};

export default Login;
