import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';

const useFuse = (data, options) => {
  const [fuse, setFuse] = useState(null);

  useEffect(() => {
    if (!data || !options) return;

    setFuse(new Fuse(data, options));

    return () => setFuse(null);
  }, [data, options]);

  return fuse;
};

export { useFuse };

useFuse.propTypes = {
  data: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
};
