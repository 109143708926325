import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import LandingPageContents from './LandingPageContents';
import { BackButton, PageTitle, Paper } from '../../../components/common';
import { CANCEL_CONTRACT, GET_CANCELLATION_PAGE_DATA, GET_CUSTOMER } from '../../../shared';
import { StyledDesktopImage, StyledMobileImage, StyledImageContainer } from './LandingPage.styled';

export default function LandingPage({ pageTitleId, pageDesktopImage, pageMobileImage }) {
  let history = useHistory();

  const [disableButton, setDisableButton] = useState(false);

  const { data } = useQuery(GET_CANCELLATION_PAGE_DATA, {
    fetchPolicy: 'network-only',
  });
  const cancellationPageKey = data?.cancellationPageData?.key;

  const [cancelContract] = useMutation(CANCEL_CONTRACT, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  const handleExit = () => history.goBack();

  const handleCancelContract = async () => {
    setDisableButton(true);
    const response = await cancelContract();

    if (response?.data?.cancelContract?.status === 'success') {
      history.replace('/cancellation-reason');
    } else {
      history.replace('/contract-cancellation/fail');
    }
    setDisableButton(false);
  };

  return (
    <>
      <BackButton onClick={() => history.goBack()}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      <PageTitle>
        <FormattedMessage id={pageTitleId} />
      </PageTitle>

      <StyledImageContainer>
        <StyledMobileImage src={pageMobileImage} alt="" />
        <StyledDesktopImage src={pageDesktopImage} alt="" />
      </StyledImageContainer>

      <Paper>
        <LandingPageContents
          cancellationPageKey={cancellationPageKey}
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      </Paper>
    </>
  );
}
