import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { color, boxShadow } from '../../theme/functions';

const StyledPaper = styled.div`
  padding: 1rem;
  border-radius: 5px;
  color: ${({ filled }) => (filled ? color('white') : 'inherit')};
  background-color: ${({ filled }) => (filled ? color('main_blue') : 'white')};

  ${({ hasShadow }) =>
    hasShadow &&
    css`
      box-shadow: ${boxShadow('box_shadow_1')};
    `};
`;

export { StyledPaper as Paper };

StyledPaper.defaultProps = {
  hasShadow: true,
  filled: false,
};

StyledPaper.propTypes = {
  hasShadow: PropTypes.bool,
  filleds: PropTypes.bool,
};
