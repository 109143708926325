import React from 'react';

import { useInsuranceType } from '../../../hooks/useInsuranceType';
import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO } from '../../../shared';

import DentoloReferral from './DentoloReferral/';
import PetoloReferral from './PetoloReferral';

const ReferralPage = () => {
  const insuranceType = useInsuranceType();
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return <DentoloReferral />;

    case INSURANCE_TYPE_PETOLO:
      return <PetoloReferral />;

    default:
      return <DentoloReferral />;
  }
};

export default ReferralPage;
