import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../components/ui';
import { Separator, Icon } from '../../../components/common';
import {
  StyledPromotionCarouselCard,
  StyledPromotionCarouselCardContent,
  StyledPromotionCarouselCardContentImageWrapper,
  StyledPromotionCarouselCardContentTitle,
  StyledPromotionCarouselButtonContainer,
} from '../PromotionCarouselCard.styled';

const ReferralLinkCardDentolo = () => {
  const history = useHistory();

  const handleGotoReferralPage = () => {
    history.push('/referral');
  };

  return (
    <StyledPromotionCarouselCard>
      <StyledPromotionCarouselCardContent>
        <StyledPromotionCarouselCardContentImageWrapper>
          <Icon icon="icon.user.referral.present" alt="referral link image" />
        </StyledPromotionCarouselCardContentImageWrapper>

        <StyledPromotionCarouselCardContentTitle>
          <FormattedMessage id="promotion.carousel.dentolo.referral_link.title" />
        </StyledPromotionCarouselCardContentTitle>
      </StyledPromotionCarouselCardContent>

      <Separator />

      <StyledPromotionCarouselButtonContainer>
        <Button onClick={handleGotoReferralPage}>
          <FormattedMessage id="promotion.carousel.dentolo.referral_link.button.label" />
        </Button>
      </StyledPromotionCarouselButtonContainer>
    </StyledPromotionCarouselCard>
  );
};

export { ReferralLinkCardDentolo };
