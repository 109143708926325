import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { useInsuranceType } from '../../hooks/useInsuranceType';
import {
  formatDate,
  formatPolicyName,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  contractInTrialMonth,
  showMoney,
} from '../../shared';
import { getInsuredPersonName } from '../../utils/customerUtils';

import PolicyAndAddonName from './PolicyAndAddonName';
import { Paper } from '../common';
import {
  StyledPolicyCardItem,
  StyledPolicyCardText,
  StyledPolicyCardTextThemed,
  StyledPolicyAmount,
} from '../styled/PolicyInfo.styled';

const MonthlyPremium = ({ customer, shouldFormatPolicyName, monthlyPremium, addons }) => {
  const intl = useIntl();

  if (!!addons && !!addons?.title) {
    const addOnTitle = addons?.title || '';
    const addOnPrice = addons?.price || 0;
    const premiumPrice = monthlyPremium - addOnPrice;
    const { policyName } = customer?.contract || {};
    const policyNameToUse = shouldFormatPolicyName
      ? intl.formatMessage({ id: `policies.${formatPolicyName(policyName)}` })
      : policyName;

    return (
      <>
        <StyledPolicyCardTextThemed>
          <StyledPolicyAmount>{showMoney(premiumPrice)}</StyledPolicyAmount>
          <span>{policyNameToUse}</span>
        </StyledPolicyCardTextThemed>

        <StyledPolicyCardTextThemed>
          <StyledPolicyAmount>{showMoney(addOnPrice)}</StyledPolicyAmount>
          <span>{addOnTitle}</span>
        </StyledPolicyCardTextThemed>
      </>
    );
  } else {
    return <StyledPolicyCardTextThemed>{showMoney(monthlyPremium)}</StyledPolicyCardTextThemed>;
  }
};

const PolicyCard = ({ customer, addons, contractStatusInfo }) => {
  const insuranceType = useInsuranceType();
  const isDentolo = insuranceType === INSURANCE_TYPE_DENTOLO;
  const isPetolo = insuranceType === INSURANCE_TYPE_PETOLO;

  const { date: contractDate, title: contractStatusTitle } = contractStatusInfo || {};
  const { name: petName } = customer?.insuredPet || {};

  const addonTrialMonth = customer?.contract?.addOnTrialMonth;
  const showTrialMonth =
    contractInTrialMonth(
      addonTrialMonth?.status,
      addonTrialMonth?.startingAt,
      customer?.contract?.startingAt
    ) ||
    (addonTrialMonth?.status === 'active' && dayjs(addonTrialMonth?.startingAt).isAfter(dayjs()));

  if (!customer?.contract) return <></>;

  return (
    <Paper hasShadow={false} filled style={{ marginBottom: '1rem' }}>
      <StyledPolicyCardItem petolo={isPetolo} type="title">
        <StyledPolicyCardText data-hj-suppress="true">
          {getInsuredPersonName(customer)}
        </StyledPolicyCardText>

        <StyledPolicyCardTextThemed data-hj-suppress>
          {customer?.contract?.key}
        </StyledPolicyCardTextThemed>
      </StyledPolicyCardItem>

      {isPetolo && (
        <StyledPolicyCardItem petolo={isPetolo}>
          <StyledPolicyCardText>
            <FormattedMessage id="policy.card.label.pet.name" />
          </StyledPolicyCardText>

          <StyledPolicyCardTextThemed>{petName || ''}</StyledPolicyCardTextThemed>
        </StyledPolicyCardItem>
      )}

      <StyledPolicyCardItem petolo={isPetolo}>
        <StyledPolicyCardText>
          <FormattedMessage id="policy.card.label.policy" />
        </StyledPolicyCardText>

        <StyledPolicyCardTextThemed>
          <PolicyAndAddonName
            customer={customer}
            addons={addons}
            shouldFormatPolicyName={isDentolo}
          />
        </StyledPolicyCardTextThemed>
      </StyledPolicyCardItem>

      {!showTrialMonth && contractDate && contractStatusTitle && (
        <StyledPolicyCardItem petolo={isPetolo}>
          <StyledPolicyCardText>{contractStatusTitle}</StyledPolicyCardText>
          <StyledPolicyCardTextThemed>{contractDate}</StyledPolicyCardTextThemed>
        </StyledPolicyCardItem>
      )}

      {customer?.contract?.status !== 'ended' && (
        <StyledPolicyCardItem petolo={isPetolo}>
          <StyledPolicyCardText>
            <FormattedMessage id="policy.card.label.monthly_premium" />
          </StyledPolicyCardText>

          <MonthlyPremium
            customer={customer}
            shouldFormatPolicyName={isDentolo}
            monthlyPremium={customer?.contract?.monthlyPremium}
            addons={addons}
          />
        </StyledPolicyCardItem>
      )}

      {showTrialMonth && (
        <StyledPolicyCardItem petolo={isPetolo}>
          <StyledPolicyCardText>
            <FormattedMessage id="policy.card.label.addon.trial_month" />
          </StyledPolicyCardText>
          <StyledPolicyCardTextThemed>
            {formatDate(addonTrialMonth?.startingAt)}
          </StyledPolicyCardTextThemed>
        </StyledPolicyCardItem>
      )}
    </Paper>
  );
};

PolicyCard.propTypes = {
  customer: PropTypes.object.isRequired,
  addons: PropTypes.object,
  contractStatusInfo: PropTypes.object,
};

export default PolicyCard;
