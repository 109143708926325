import {
  PET_LIABILITY_AFFECTED_PARTY_OPTION_MYSELF,
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_PERSON,
} from '../../claimFormData/newClaimFormDataPetLiability';
import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PET_LIABILITY_STEP_AFFECTED_PARTY,
  PET_LIABILITY_STEP_MEDICAL_EXAM,
  PET_LIABILITY_STEP_RELATION_WITH_AFFECTED_PARTY,
  PET_LIABILITY_STEP_REVIEW,
} from '../../new/newClaimsFormSteps';

const getBackButtonLinkForNewClaim = (liabilityReason, affectedParty) => {
  if (
    liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL ||
    liabilityReason === PET_LIABILITY_REASON_PERSON
  ) {
    return PET_LIABILITY_STEP_MEDICAL_EXAM;
  } else {
    if (affectedParty === PET_LIABILITY_AFFECTED_PARTY_OPTION_MYSELF) {
      return PET_LIABILITY_STEP_AFFECTED_PARTY;
    }

    return PET_LIABILITY_STEP_RELATION_WITH_AFFECTED_PARTY;
  }
};

export const useUploadDocumentPetLiabilityBackButtonLink = (liabilityReason, affectedParty) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  const backButtonLink = getBackButtonLinkForNewClaim(liabilityReason, affectedParty);
  return withSource(backButtonLink);
};

export const useUploadDocumentPetLiabilityNextButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_REVIEW);
};
