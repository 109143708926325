import React from 'react';

import {
  StyledReferraRewardBox,
  StyledReferraRewardText,
  StyledReferralList,
  StyledReferralListContainer,
  StyledReferralListItem,
  StyledReferralListItemTitle,
} from './ReferralPage.styled';
import { FormattedMessage } from 'react-intl';
import { Tag } from '../../../components/common';

const STATUS_COMPLETED = 'sent';

const getReferralCodeTitle = (rewardStoreName) => {
  return <FormattedMessage id="referral.reward.title" values={{ rewardbrand: rewardStoreName }} />;
};

export default function ReferralList(props) {
  const { referrals } = props;

  if (!referrals || !Array.isArray(referrals)) return null;

  return (
    <StyledReferralList>
      <StyledReferralListItem>
        <StyledReferralListItemTitle>
          <FormattedMessage id="referral.page.list.title.email" />
        </StyledReferralListItemTitle>

        <StyledReferralListItemTitle>
          <FormattedMessage id="referral.page.list.title.status" />
        </StyledReferralListItemTitle>
      </StyledReferralListItem>

      {referrals.map((referral) => {
        const { referee, rewardCouponCode, rewardCouponStoreName, status } = referral;

        const showVoucherCode =
          status === STATUS_COMPLETED && rewardCouponCode && rewardCouponStoreName;

        return (
          <StyledReferralListContainer key={referee?.email}>
            <StyledReferralListItem>
              <div>{referee?.email}</div>

              <Tag variant={status}>
                <FormattedMessage id={`referral.status.label.${status}`} />
              </Tag>
            </StyledReferralListItem>

            {showVoucherCode && (
              <StyledReferraRewardBox>
                <StyledReferraRewardText>
                  {getReferralCodeTitle(rewardCouponStoreName)}
                </StyledReferraRewardText>

                <StyledReferraRewardText>{rewardCouponCode}</StyledReferraRewardText>
              </StyledReferraRewardBox>
            )}
          </StyledReferralListContainer>
        );
      })}
    </StyledReferralList>
  );
}
