import React from 'react';
import PropTypes from 'prop-types';

import { useInsuranceType } from '../../hooks/useInsuranceType';
import {
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
} from '../../shared';

import { Button as StyledButton, Link, Href } from './';
import { Icon } from '../common';
import {
  InfoWindowButtonGroup,
  StyledInfoWindow,
  StyledInfoWindowParagraph,
  StyledInfoWindowTitle,
} from './styled/InfoWindow.styled';

const icons = {
  [INSURANCE_CATEGORY_DENTAL]: {
    'success': 'info.success',
    'failure': 'info.warning',
    'claim-success': 'info.success',
    'claim-warning': 'info.warning',
  },
  [INSURANCE_CATEGORY_PET_HEALTH]: {
    'success': 'info.success',
    'failure': 'info.warning',
    'claim-success': 'info.success',
    'claim-warning': 'info.warning',
  },
  [INSURANCE_CATEGORY_PET_LIABILITY]: {
    'success': 'info.success',
    'failure': 'info.warning',
    'claim-success': 'info.success',
    'claim-warning': 'info.warning',
  },
};

const Button = ({ text, func, href, to, target, style }) => {
  if (href)
    return (
      <Href variant={style || 'outline'} href={href} target={target}>
        {text}
      </Href>
    );
  if (to)
    return (
      <Link variant={style || 'outline'} to={to}>
        {text}
      </Link>
    );
  return (
    <StyledButton variant={style || 'outline'} onClick={func}>
      {text}
    </StyledButton>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  func: PropTypes.func,
  href: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  style: PropTypes.string,
};

const getInsuranceCategory = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return INSURANCE_CATEGORY_DENTAL;
    case INSURANCE_TYPE_PETOLO:
      return INSURANCE_CATEGORY_PET_HEALTH;
    default:
      return INSURANCE_CATEGORY_DENTAL;
  }
};

const InfoWindow = ({ mode, titleHtml, textHtml, button, buttons }) => {
  const insuranceType = useInsuranceType();
  const iconToUse = icons[getInsuranceCategory(insuranceType)][mode];

  return (
    <StyledInfoWindow>
      {mode && <Icon icon={iconToUse} alt="" />}

      {titleHtml && (
        <StyledInfoWindowTitle>
          <strong dangerouslySetInnerHTML={{ __html: titleHtml }} />
        </StyledInfoWindowTitle>
      )}

      {textHtml && (
        <StyledInfoWindowParagraph textAlign="center">{textHtml}</StyledInfoWindowParagraph>
      )}

      {button && (
        <InfoWindowButtonGroup>
          <Button
            text={button.text}
            func={button.func}
            style={button.style}
            href={button.href}
            to={button.to}
            target={button.target}
          />
        </InfoWindowButtonGroup>
      )}

      {buttons && (
        <InfoWindowButtonGroup>
          {buttons.map((button) => (
            <Button
              key={button.text}
              text={button.text}
              func={button.func}
              style={button.style}
              href={button.href}
              to={button.to}
              target={button.target}
            />
          ))}
        </InfoWindowButtonGroup>
      )}
    </StyledInfoWindow>
  );
};

InfoWindow.propTypes = {
  mode: PropTypes.string,
  titleHtml: PropTypes.string,
  textHtml: PropTypes.string,
  button: PropTypes.object,
  buttons: PropTypes.arrayOf(PropTypes.object),
};

export default InfoWindow;
