import { css } from 'styled-components';

export default css`
  // ===  Transitions (fade)  ===
  .fade {
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
      transition: all 0.5s ease-in;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: all 0.5s ease-in;
    }
  }

  // ===  Transition (slow-fade)  ===
  .slow-fade {
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
      transition: all 0.45s ease-in 0.15s;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: all 0.45s ease-in 0.15s;
    }
  }

  // ===  Transitions (quick-fade)  ===
  .quick-fade {
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
      transition: all 0.2s ease-in;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: all 0.2s ease-in;
    }
  }

  // ===  Transitions (fade-in)  ===
  .fade-in {
    &-enter {
      opacity: 0;
      transform: translate3d(0, -10%, 0);
    }

    &-enter-active {
      opacity: 1;
      transition: all 0.4s ease-in-out;
      transform: translate3d(0, 0%, 0);
    }

    &-exit {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }

    &-exit-active {
      opacity: 0;
      transition: all 0.4s ease-in-out;
      transform: translate3d(0, -10%, 0);
    }
  }

  // Transitions (nav-menu-desktop)
  .nav-menu-desktop {
    &-enter {
      opacity: 0;
      transform: translate3d(0, -50px, 0);
    }

    &-enter-active {
      opacity: 1;
      transition: all 0.4s ease-in-out;
      transform: translate3d(0, 0px, 0);
    }

    &-exit {
      opacity: 1;
      transform: translate3d(0, 0px, 0);
    }

    &-exit-active {
      opacity: 0;
      transition: all 0.4s ease-in-out;
      transform: translate3d(0, -50px, 0);
    }
  }

  // ===  Animation (slide-in-left)  ===
  @keyframes slide-in-left {
    from {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }

    50% {
      opacity: 0.7;
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  // ===  Animation (slide-out-left)  ===
  @keyframes slide-out-left {
    from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    10% {
      opacity: 0.3;
    }

    to {
      visibility: hidden;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  // ===  Animation (slide-in-right)  ===
  @keyframes slide-in-right {
    from {
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }

    50% {
      opacity: 0.7;
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }

  // ===  Animation (slide-out-right)  ===
  @keyframes slide-out-right {
    from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    10% {
      opacity: 0.3;
    }

    to {
      visibility: hidden;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }

  // ===  Transition (slide-in-list)  ===
  .slide-in-list {
    &.slide-in-enter {
      animation: slide-in-left 0.4s forwards;
      position: absolute;
    }

    &.slide-in-exit {
      animation: slide-out-left 0.2s forwards;
    }
  }

  .slide-in-details {
    &.slide-in-enter {
      animation: slide-in-right 0.4s forwards;
      position: absolute;
    }

    &.slide-in-exit {
      animation: slide-out-right 0.2s forwards;
    }
  }
`;
