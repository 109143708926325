import { useEffect, useState } from 'react';

import { getNewClaimFormDataValue, setNewClaimFormDataValue } from './newClaimFormData';

//# PET LIABILITY
export const NEW_PET_LIABILITY_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY = 'new-pet-liability-claim-data';

export const PET_LIABILITY_REASON = 'liability_reason';
export const PET_LIABILITY_REASON_ITEM = 'object_damaged';
export const PET_LIABILITY_REASON_ANOTHER_ANIMAL = 'another_animal_injured';
export const PET_LIABILITY_REASON_VEHICLE = 'transportation_damaged';
export const PET_LIABILITY_REASON_PROPERTY = 'property_damaged';
export const PET_LIABILITY_REASON_PERSON = 'person_injured';
export const PET_LIABILITY_REASON_OTHER = 'other';

export const PET_LIABILITY_DAMAGE_CAUSED_BY = 'damage_caused_by';
export const PET_LIABILITY_DAMAGE_CAUSED_BY_INSURED_PET = 'insured_pet';
export const PET_LIABILITY_DAMAGE_CAUSED_BY_OTHER = 'other';

export const PET_LIABILITY_INCIDENT_DATE = 'incident_date';
export const PET_LIABILITY_INCIDENT_TIME = 'incident_time';
export const PET_LIABILITY_INCIDENT_TIME_NOT_AVAILABLE = 'time_not_available';

export const PET_LIABILITY_INCIDENT_LOCATION = 'incident_location';

export const PET_LIABILITY_ACCOMPANIED_BY = 'accompanied_by';
export const PET_LIABILITY_ACCOMPANIED_BY_MYSELF = 'customer';
export const PET_LIABILITY_ACCOMPANIED_BY_PARTNER = 'partner';
export const PET_LIABILITY_ACCOMPANIED_BY_FAMILY = 'family';
export const PET_LIABILITY_ACCOMPANIED_BY_ROOMMATE = 'roommate';
export const PET_LIABILITY_ACCOMPANIED_BY_WORK_COLLEAGUE = 'work_colleague';
export const PET_LIABILITY_ACCOMPANIED_BY_NO_RELATION = 'not_related';

export const PET_LIABILITY_INCIDENT_NOTE = 'incident_note';

export const PET_LIABILITY_DAMAGED_ITEM = 'damaged_item';

export const PET_LIABILITY_DAMAGE_ESTIMATE = 'damage_estimate';
export const PET_LIABILITY_DAMAGE_ESTIMATE_UPTO_500 = 'between0and500';
export const PET_LIABILITY_DAMAGE_ESTIMATE_501_1000 = 'between501and1000';
export const PET_LIABILITY_DAMAGE_ESTIMATE_1001_5000 = 'between1001and5000';
export const PET_LIABILITY_DAMAGE_ESTIMATE_5001_10000 = 'between5001and10000';
export const PET_LIABILITY_DAMAGE_ESTIMATE_UNKNOWN = 'unknown';

export const PET_LIABILITY_AFFECTED_PARTY = 'affected_party';
export const PET_LIABILITY_AFFECTED_PARTY_OPTION_MYSELF = 'myself';
export const PET_LIABILITY_AFFECTED_PARTY_OPTION_OTHER = 'other';

export const PET_LIABILITY_AFFECTED_PARTY_DETAILS = 'affected_party_details';

export const PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY = 'relation_with_affected_party';
export const PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_CUSTOMER = 'customer';
export const PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_PARTNER = 'partner';
export const PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_FAMILY = 'family_member';
export const PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_ROOMMATE = 'housemate';
export const PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_WORK_COLLEAGUE = 'work_colleague';
export const PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_NO_RELATION = 'no_relationship';

export const PET_LIABILITY_MEDICAL_EXAM = 'medical_exam';
export const PET_LIABILITY_MEDICAL_EXAM_YES = 'yes';
export const PET_LIABILITY_MEDICAL_EXAM_NO = 'no';

export const getNewPetLiabilityClaimFormDataValue = (key) => {
  return getNewClaimFormDataValue(NEW_PET_LIABILITY_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY, key);
};

export const setNewPetLiabilityClaimFormDataValue = (key, value) => {
  setNewClaimFormDataValue(NEW_PET_LIABILITY_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY, key, value);
};

export const clearNewPetLiabilityClaimFormData = () => {
  if (!!sessionStorage) {
    sessionStorage.removeItem(NEW_PET_LIABILITY_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY);
  }
};

export const useNewPetLiabilityFormDataValue = (key) => {
  const [value, setValue] = useState(getNewPetLiabilityClaimFormDataValue(key));
  useEffect(() => {
    setNewPetLiabilityClaimFormDataValue(key, value);
  }, [key, value]);

  return [value, setValue];
};
