import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { max_width_md, min_width_md } from '../../../theme/breakpoints';
import { color } from '../../../theme/functions';

export const navMenuUnderlineBaseStyle = css`
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    bottom: 0.6rem;
    left: 0;
    right: 0;
    width: 100%;
    background-color: ${color('professional_light_blue')};
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.2s ease-out;
  }
`;

export const navMenuUnderlineActiveBaseStyle = css`
  &:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const navMenuBaseStyle = css`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 700;
  color: ${color('main_blue')};
  text-decoration: none;
  box-shadow: none;
  transition: color 0.25s ease-out;

  ${navMenuUnderlineBaseStyle};

  &.active,
  &:hover,
  &:focus,
  &:active {
    color: ${color('professional_light_blue')};
  }

  @media (max-width: ${max_width_md}) {
    font-size: 1rem;
  }
`;

// ===  BASE styles above this line  ===

export const StyledNavMenuDesktop = styled.section`
  position: relative;
  height: 48px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 50;
  background-color: ${color('white')};
  color: ${color('main_blue')};
  display: none;

  @media (min-width: ${min_width_md}) {
    display: block;
  }
`;

export const StyledNavMenuDesktopContainer = styled.nav`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
  height: 100%;
`;

export const StyledNavLink = styled(NavLink)`
  ${navMenuBaseStyle}

  position: relative;
  height: 100%;

  &.active,
  &:focus,
  &:active {
    &:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;
