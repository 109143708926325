import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PETOLO_STEP_UPLOAD_DOCUMENTS,
  PET_HEALTH_STEP_TREATMENT_CATEGORIES,
} from '../../new/newClaimsFormSteps';

export const useSelectDocumentTypePetoloBackButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();
  return withSource(PET_HEALTH_STEP_TREATMENT_CATEGORIES);
};

export const useSelectDocumentTypePetoloNextButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();
  return withSource(PETOLO_STEP_UPLOAD_DOCUMENTS);
};
