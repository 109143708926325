import React from 'react';
import { useParams } from 'react-router-dom';
import { Onboard } from '../../ui';
import UserClaimsOnboardCard1 from './UserClaimsOnboardCard1';
import UserClaimsOnboardCard2 from './UserClaimsOnboardCard2';
import UserClaimsOnboardCard3 from './UserClaimsOnboardCard3';
import UserClaimsOnboardCard4 from './UserClaimsOnboardCard4';
import { USER_CLAIMS_ONBOARD_KEY } from '../../../shared';
import { Container } from '../../common';

const userClaimsOnboard = [
  {
    id: 'UserClaimsOnboardCard1',
    name: UserClaimsOnboardCard1,
    titleHtml: 'Ablauf der<br />Kostenerstattung',
    bodyText:
      'Mit nur wenigen Schritten können Sie eine Erstattung starten und Ihre Auszahlung einfach, papierlos und schnell erhalten.',
    btnText: 'Überspringen',
  },
  {
    id: 'UserClaimsOnboardCard2',
    name: UserClaimsOnboardCard2,
    titleHtml: 'Hochladen von<br />Dokumenten',
    bodyText:
      'Nachfolgend können Sie Ihre Behandlungspläne oder Rechnungen via Upload zur Verfügung stellen. Laden Sie dazu einfach Fotos oder gescannte Dateien Ihrer Dokumente bei uns hoch.',
    btnText: 'Überspringen',
  },
  {
    id: 'UserClaimsOnboardCard3',
    name: UserClaimsOnboardCard3,
    titleHtml: 'Zusage<br />und Statusverlauf',
    bodyText:
      'Nach einer kurzen Prüfung sagen wir Ihnen, ob eine Kostenübernahme erfolgt. Den Status der Erstattung sehen sie dann jederzeit unter “Meine Kostenerstattungen”.',
    btnText: 'Überspringen',
  },
  {
    id: 'UserClaimsOnboardCard4',
    name: UserClaimsOnboardCard4,
    titleHtml: 'Auszahlung',
    bodyText:
      'Nach Einreichen der Rechnungen werden wir die Erstattungssumme innerhalb weniger Tage auf Ihr Konto überweisen.',
    btnText: 'Zu meinen Erstattungen ',
    btnType: 'primary',
  },
];

const UserClaimsOnboardDentolo = () => {
  let { id } = useParams();

  return (
    <Container>
      <Onboard
        initialIndex={id}
        name={USER_CLAIMS_ONBOARD_KEY}
        onboardingUrl="/user-claims/onboarding-dentolo"
        mainPageUrl="/user-claims-info"
        schema={userClaimsOnboard}
      />
    </Container>
  );
};

export default UserClaimsOnboardDentolo;
