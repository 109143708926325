import styled from 'styled-components';
import { min_width_md } from '../../../theme/breakpoints';

export const StyledSplashImageBottomLeftCorner = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;

  @media (min-width: ${min_width_md}) {
    display: block;
  }
`;

export const StyledSplashImageTopCenter = styled.svg`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;

  @media (min-width: ${min_width_md}) {
    display: block;
  }
`;

export const StyledSplashImageTopRightCorner = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  display: none;

  @media (min-width: ${min_width_md}) {
    display: block;
  }
`;

export const StyledSplashImageBottomRight = styled.svg`
  position: absolute;
  bottom: 0;
  right: 5rem;
  display: none;

  @media (min-width: ${min_width_md}) {
    display: block;
  }
`;

export const StyledSplashImageTopRightCornerMobile = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  display: block;

  @media (min-width: ${min_width_md}) {
    display: none;
  }
`;
