import gql from 'graphql-tag';

export const TOGGLE_NAV_MENU_MUTATION = gql`
  mutation {
    toggleNavMenu @client
  }
`;

export const TOGGLE_SHOW_ONBOARD = gql`
  mutation ($name: String!, $show: Boolean) {
    toggleShowOnboard(name: $name, show: $show) @client
  }
`;

export const SET_CONFIRM_EMAIL_MUTATION = gql`
  mutation ($email: String!) {
    setConfirmEmail(input: { email: $email }) @client
  }
`;

export const CONFIRM_EMAIL = gql`
  mutation ($email: String!, $gduid: String, $rememberMe: Boolean, $insuranceCategory: String) {
    confirmEmail(
      input: {
        email: $email
        gduid: $gduid
        rememberMe: $rememberMe
        insuranceCategory: $insuranceCategory
      }
    ) {
      email
      errors
    }
  }
`;

export const SIGN_IN = gql`
  mutation ($jwt: String!, $rememberMe: Boolean, $uuid: String!) {
    signIn(input: { jwt: $jwt, rememberMe: $rememberMe, uuid: $uuid }) {
      uuid
      firstSignIn
      errors
    }
  }
`;

export const SIGN_OUT = gql`
  mutation {
    signOut(input: { clientMutationId: "1" }) {
      errors
    }
  }
`;

export const UPLOAD_CUSTOMER_CLAIM_ATTACHMENTS = gql`
  mutation ($files: [Upload!]!) {
    claimFilesUpload(input: { files: $files }) {
      status
      errors
    }
  }
`;

export const CONTACT_US = gql`
  mutation ($subject: String!, $body: String!) {
    contactUs(input: { subject: $subject, body: $body }) {
      errors
    }
  }
`;

export const SWITCH_CUSTOMER = gql`
  mutation ($uuid: String!) {
    switchCustomer(input: { uuid: $uuid }) {
      errors
    }
  }
`;

export const SIGN_CONTRACT = gql`
  mutation {
    signContract(input: { clientMutationId: "1" }) {
      errors
    }
  }
`;

export const VERIFY_BIRTHDATE = gql`
  mutation ($jwt: String!, $birthdate: ISO8601DateTime!) {
    verifyBirthdate(input: { jwt: $jwt, birthdate: $birthdate }) {
      errors
    }
  }
`;

export const REMOVE_FAVORITE_DENTIST = gql`
  mutation ($dentistKey: String!) {
    removeFavoriteDentist(input: { dentistKey: $dentistKey }) {
      errors
    }
  }
`;

export const ADD_FAVORITE_DENTIST = gql`
  mutation ($dentistKey: String!) {
    addFavoriteDentist(input: { dentistKey: $dentistKey }) {
      errors
    }
  }
`;

export const CLEAR_ALL_CLAIM_NOTIFICATIONS = gql`
  mutation {
    resetClaimsStatusUpdated(input: {}) {
      errors
    }
  }
`;

export const CLEAR_CLAIM_NOTIFICATION = gql`
  mutation ($claimId: Int!) {
    resetClaimStatusUpdated(input: { claimId: $claimId }) {
      errors
    }
  }
`;

export const TRIGGER_WEBHOOK = gql`
  mutation ($uuid: String!) {
    triggerWebhook(input: { uuid: $uuid }) {
      errors
    }
  }
`;

export const CLEAR_CERTIF_DOC_NOTIFICATION = gql`
  mutation {
    notificationSeenByCustomer(input: {}) {
      errors
    }
  }
`;

export const UPDATE_CUSTOMER_DETAILS = gql`
  mutation (
    $gender: String!
    $email: String!
    $phoneNumber: String!
    $streetName: String!
    $houseNumber: String!
    $postcode: String!
    $city: String!
    $addressDetails: String
  ) {
    customer(
      input: {
        gender: $gender
        email: $email
        phoneNumber: $phoneNumber
        streetName: $streetName
        houseNumber: $houseNumber
        postcode: $postcode
        city: $city
        addressDetails: $addressDetails
      }
    ) {
      status
      errors
    }
  }
`;

export const UPDATE_INSURED_PERSON_DETAILS = gql`
  mutation (
    $gender: String!
    $streetName: String!
    $houseNumber: String!
    $postcode: String!
    $city: String!
  ) {
    insuredPerson(
      input: {
        gender: $gender
        streetName: $streetName
        houseNumber: $houseNumber
        postcode: $postcode
        city: $city
      }
    ) {
      status
      errors
    }
  }
`;

export const UPDATE_PET_DETAILS = gql`
  mutation ($transponderCode: String!) {
    updatePetDetails(input: { transponderCode: $transponderCode }) {
      status
      errors
    }
  }
`;

export const UPDATE_BANK_DETAILS = gql`
  mutation ($firstName: String!, $lastName: String!, $iban: String, $billingDay: Int) {
    updateBankDetails(
      input: {
        clientMutationId: "1"
        firstName: $firstName
        lastName: $lastName
        iban: $iban
        billingDay: $billingDay
      }
    ) {
      status
      errors
    }
  }
`;

export const UPLOAD_DENTOLO_CUSTOMER_CLAIM_DOCUMENTS = gql`
  mutation (
    $files: [Upload!]!
    $dentistKey: String
    $dentistName: String
    $amountCents: Int
    $documentType: String
    $treatmentCategories: [String!]
    $lossDate: ISO8601DateTime
    $treatmentStartDate: ISO8601DateTime
    $fillingsCount: String
    $claimId: String
  ) {
    claimFilesUpload(
      input: {
        files: $files
        dentistKey: $dentistKey
        dentistName: $dentistName
        amountCents: $amountCents
        documentType: $documentType
        treatmentCategories: $treatmentCategories
        lossDate: $lossDate
        treatmentStartDate: $treatmentStartDate
        fillingsCount: $fillingsCount
        claimId: $claimId
      }
    ) {
      status
      errors
    }
  }
`;

export const UPLOAD_PETOLO_CUSTOMER_CLAIM_DOCUMENTS = gql`
  mutation (
    $files: [Upload!]!
    $dentistKey: String
    $dentistName: String
    $amountCents: Int
    $documentType: String
    $treatmentCategories: [String!]
    $lossDate: ISO8601DateTime
    $treatmentStartDate: ISO8601DateTime
    $fillingsCount: String
    $videoConsultationId: String
    $treatmentInfo: String
    $claimId: String
  ) {
    claimFilesUpload(
      input: {
        files: $files
        dentistKey: $dentistKey
        dentistName: $dentistName
        amountCents: $amountCents
        documentType: $documentType
        treatmentCategories: $treatmentCategories
        lossDate: $lossDate
        treatmentStartDate: $treatmentStartDate
        fillingsCount: $fillingsCount
        videoConsultationId: $videoConsultationId
        treatmentInfo: $treatmentInfo
        claimId: $claimId
      }
    ) {
      status
      errors
    }
  }
`;

export const UPLOAD_PET_LIABILITY_CUSTOMER_CLAIM_DOCUMENTS = gql`
  mutation (
    $affectedPersonName: String!
    $attachments: [Upload!]!
    $damageCategory: String!
    $causedBy: String!
    $damageOccuredDate: ISO8601DateTime!
    $damageLocationStreetName: String!
    $damageLocationHouseNumber: String!
    $damageLocationPostcode: String!
    $damageLocationCity: String!
    $petSupervisor: String!
    $affectedPersonRelationship: String!
    $damageReport: String!
    $damagedObjectTitle: String
    $damagedObjectAge: String
    $damagedObjectPurchasePriceCents: Int
    $estimatedDamageValue: String
    $affectedPersonStreetName: String
    $affectedPersonHouseNumber: String
    $affectedPersonPostcode: String
    $affectedPersonCity: String
    $affectedPersonEmail: String
    $affectedPersonPhoneNumber: String
    $medicalExamination: Boolean
  ) {
    createPetLiabilityReport(
      input: {
        affectedPersonName: $affectedPersonName
        attachments: $attachments
        damageCategory: $damageCategory
        causedBy: $causedBy
        damageOccuredDate: $damageOccuredDate
        damageLocationStreetName: $damageLocationStreetName
        damageLocationHouseNumber: $damageLocationHouseNumber
        damageLocationPostcode: $damageLocationPostcode
        damageLocationCity: $damageLocationCity
        petSupervisor: $petSupervisor
        affectedPersonRelationship: $affectedPersonRelationship
        damageReport: $damageReport
        damagedObjectTitle: $damagedObjectTitle
        damagedObjectAge: $damagedObjectAge
        damagedObjectPurchasePriceCents: $damagedObjectPurchasePriceCents
        estimatedDamageValue: $estimatedDamageValue
        affectedPersonStreetName: $affectedPersonStreetName
        affectedPersonHouseNumber: $affectedPersonHouseNumber
        affectedPersonPostcode: $affectedPersonPostcode
        affectedPersonCity: $affectedPersonCity
        affectedPersonEmail: $affectedPersonEmail
        affectedPersonPhoneNumber: $affectedPersonPhoneNumber
        medicalExamination: $medicalExamination
      }
    ) {
      status
      errors
    }
  }
`;

export const CANCEL_CONTRACT = gql`
  mutation {
    cancelContract(input: {}) {
      errors
      status
    }
  }
`;

export const CHANGE_CANCELATION_REASON = gql`
  mutation ($cancelationReason: String!) {
    changeCancelationReason(input: { cancelationReason: $cancelationReason }) {
      errors
      status
    }
  }
`;

export const JOIN_REFERRAL_PROGRAM = gql`
  mutation {
    joinReferralProgram(input: {}) {
      referralCode
      referralJoined
      referralJoinedAt
      errors
    }
  }
`;

export const CREATE_LEAD_WITH_CUSTOMER_DATA = gql`
  mutation {
    createLeadWithCustomerData(input: {}) {
      uuid
      errors
    }
  }
`;

export const SET_BILLING_DAY = gql`
  mutation ($billingDay: Int!) {
    changeContractBillingDay(input: { billingDay: $billingDay }) {
      status
      errors
    }
  }
`;

export const CREATE_PAYMENT_LINK = gql`
  mutation ($successUrl: String!, $failureUrl: String!) {
    createDigitalPaymentLink(input: { successUrl: $successUrl, failureUrl: $failureUrl }) {
      status
      errors
      iframeUrl
    }
  }
`;
