import React, { useState, useEffect, useRef } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import utc from 'dayjs/plugin/utc';
import { VERIFY_BIRTHDATE, TRIGGER_WEBHOOK, useReady } from '../../shared';

import { InputField, Paragraph } from '../ui';
import { Paper } from '../common';
import { AuthContainer, PageTitle, DateContainer, Button } from './Login.styled';

dayjs.extend(utc);

const SecondAuth = ({ history, jwt, setBirthDate, customers }) => {
  const { colors } = useTheme();

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const ready = useReady();
  const client = useApolloClient();
  const intl = useIntl();

  const dayInputRef = useRef(null);
  const monthInputRef = useRef(null);
  const yearInputRef = useRef(null);
  const submitBtnRef = useRef(null);

  const email = Array.isArray(customers) && customers.length > 0 ? customers[0]?.email : '';
  const uuid = Array.isArray(customers) && customers.length > 0 ? customers[0]?.uuid : '';

  const [triggerWebhook] = useMutation(TRIGGER_WEBHOOK, {
    variables: {
      uuid: uuid || '',
    },
  });

  useEffect(() => {
    if (!!email && !!uuid) {
      triggerWebhook();
    }
    // eslint-disable-next-line
  }, [email, uuid]);

  const verifyBirthdate = (jwt, birthdate) => {
    client
      .mutate({
        mutation: VERIFY_BIRTHDATE,
        variables: {
          birthdate,
          jwt,
        },
      })
      .then((res) => {
        if (res.data && res.data.verifyBirthdate.errors.length < 1) {
          setBirthDate(birthdate);
        } else {
          setDisableForm(false);
          setIsInvalid(true);
          flushDateInput();
        }
      })
      .catch((error) => {
        setDisableForm(false);
        setIsInvalid(true);
        flushDateInput();
        catchTokenError(error);
      });
  };

  const flushDateInput = (_) => {
    setDay('');
    setMonth('');
    setYear('');
    if (dayInputRef) {
      dayInputRef.current.focus();
    }
  };

  const catchTokenError = (error) => {
    if (error && error.message.includes('Token sign in attempts exhausted')) {
      history.push('/authorization/failure');
    } else if (error && error.message.includes('Token is invalid')) {
      history.push('/login?invalid=true');
    }
  };

  const onDayInputChange = (e) => {
    const val = e.target.value;
    const day = val?.toString()?.slice(0, 2);
    if (day?.length === 2 && monthInputRef) {
      monthInputRef.current.focus();
    }
    setDay(day);
    setIsInvalid(false);
  };

  const onMonthInputChange = (e) => {
    const val = e.target.value;
    const month = val?.toString()?.slice(0, 2);
    if (month?.length === 2 && yearInputRef) {
      yearInputRef.current.focus();
    }
    setMonth(month);
    setIsInvalid(false);
  };

  const onYearInputChange = (e) => {
    const val = e.target.value;
    const year = val?.toString()?.slice(0, 4);
    if (year?.length === 4 && submitBtnRef) {
      submitBtnRef.current.focus();
    }
    setYear(year);
    setIsInvalid(false);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    setDisableForm(true);

    const monthNum = month.length === 1 ? `0${month}` : month;
    const dayNum = day.length === 1 ? `0${day}` : day;
    const dateISOString = `${year}-${monthNum}-${dayNum}T00:00:00.000Z`;
    if (dayjs(dateISOString).isValid()) {
      verifyBirthdate(jwt, dateISOString);
    } else {
      setDisableForm(false);
      setIsInvalid(true);
      flushDateInput();
    }
  };

  return (
    <AuthContainer>
      <PageTitle>
        <FormattedMessage id="second.auth.title" />
      </PageTitle>

      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Paper style={{ padding: '2rem' }}>
          <form onSubmit={(e) => onFormSubmit(e)}>
            <fieldset disabled={disableForm} aria-busy={disableForm}>
              <DateContainer>
                <InputField
                  id="second-auth-day"
                  name="second-auth-day"
                  placeholder="22"
                  labelText={intl.formatMessage({
                    id: 'second.auth.input.label.day',
                  })}
                  ref={dayInputRef}
                  required={true}
                  labelStyle={{ fontWeight: 700 }}
                  type="number"
                  value={day}
                  onChange={onDayInputChange}
                />

                <InputField
                  id="second-auth-month"
                  name="second-auth-month"
                  placeholder="10"
                  labelText={intl.formatMessage({
                    id: 'second.auth.input.label.month',
                  })}
                  ref={monthInputRef}
                  required={true}
                  labelStyle={{ fontWeight: 700 }}
                  type="number"
                  value={month}
                  onChange={onMonthInputChange}
                />

                <InputField
                  id="second-auth-year"
                  name="second-auth-year"
                  placeholder="1975"
                  labelText={intl.formatMessage({
                    id: 'second.auth.input.label.year',
                  })}
                  ref={yearInputRef}
                  required={true}
                  labelStyle={{ fontWeight: 700 }}
                  type="number"
                  value={year}
                  onChange={onYearInputChange}
                />
              </DateContainer>

              <CSSTransition in={isInvalid} timeout={600} classNames="fade" unmountOnExit>
                <div style={{ marginTop: '0.5rem', color: colors?.red }}>
                  <FormattedMessage id="second.auth.wrong.details" />
                </div>
              </CSSTransition>

              <Paragraph style={{ margin: '1.5rem 0' }}>
                {intl.formatMessage({ id: 'second.auth.description' })}
              </Paragraph>

              <div>
                <Button ref={submitBtnRef} type="submit">
                  <FormattedMessage id="second.auth.login.btn" />
                </Button>

                <p style={{ marginTop: '1.5rem' }}>
                  <span style={{ fontWeight: 700, color: colors?.warm_blue }}>*</span>{' '}
                  <FormattedMessage id="second.auth.fields.required" />
                </p>
              </div>
            </fieldset>
          </form>
        </Paper>
      </CSSTransition>
    </AuthContainer>
  );
};

SecondAuth.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object,
  jwt: PropTypes.string,
  setBirthDate: PropTypes.func,
};

export default withRouter(SecondAuth);
