import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { min_width_md } from '../../../theme/breakpoints';
import { color } from '../../../theme/functions';

const baseButtonStyles = css`
  padding: 0;
  width: fit-content;
  font-size: 1rem;
  border: none;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      cursor: default;
    `}

  ${({ disabled, variant }) =>
    !disabled &&
    variant === 'primary' &&
    css`
      &:hover {
        opacity: 0.9;
      }
    `}
`;

const baseVariantStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem;
  height: 3rem;
  font-weight: 700;
  border-radius: 7px;
  transition: opacity 0.3s ease-in-out;

  @media (min-width: ${min_width_md}) {
    height: 3.5rem;
    font-size: 1.25rem;
  }
`;

export const variantStyles = (variant) =>
  ({
    primary: css`
      ${baseButtonStyles}
      ${baseVariantStyles}
      color: ${color('secondary')};
      background-color: ${color('primary')};
    `,
    outline: css`
      ${baseButtonStyles}
      ${baseVariantStyles}
      color: ${color('main_blue')};
      border: 2px solid ${color('main_blue')};
      background: transparent;
    `,
    nostyle: css`
      ${baseButtonStyles}
      position: relative;
      height: auto;
      font-weight: normal;
      color: ${color('main_blue')};
      background: transparent;
      appearance: none;
      text-decoration: none;

      &::after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: ${color('main_blue')};
        transform-origin: bottom left;
        transition: transform 0.2s ease-out;
      }

      &:hover {
        color: ${color('professional_light_blue')};

        &::after {
          transform: scaleX(0);
          transform-origin: bottom right;
        }
      }

      &:active,
      &:focus {
        color: ${color('professional_light_blue')};

        &::after {
          background-color: ${color('professional_light_blue')};
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    `,
  }[variant]);

export const StyledButton = styled.button`
  ${({ variant }) => variantStyles(variant)}
`;

export const StyledLink = styled(Link)`
  text-decoration: none;

  ${({ variant }) => variantStyles(variant)}
`;

export const StyledHref = styled.a`
  ${({ variant }) => variantStyles(variant)}
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;

  > button {
    & + button {
      margin-top: 1rem;
    }
  }

  @media (min-width: ${min_width_md}) {
    flex-direction: row;
    justify-content: flex-end;

    margin-top: 2rem;

    > button {
      & + button {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }
`;
