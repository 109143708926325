import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import { useInsuranceType } from '../hooks/useInsuranceType';
import {
  CONTACT_US,
  useReady,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  GET_CUSTOMER,
} from '../shared';

import { ContactForm, ErrorPlaceholder, Paragraph } from './ui';
import { Container, PageTitle } from './common';
import { StyledAccordion, StyledContent, StyledWorkingTimes } from './styled/Contact.styled';

const getContactPhoneNumber = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return '030 959 99 33 00';
    case INSURANCE_TYPE_PETOLO:
      return '030 814 58 62 90';
    default:
      return '030 959 99 33 00';
  }
};

const getFormSubjects = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return [
        'Fragen zum Vertrag',
        'Monatsbeitrag',
        'Fragen zur Erstattung oder Auszahlung',
        'dentolo Zahnärzte',
        'Abdeckung durch Tarife',
        'Änderung von Kontakt- oder Vertragsdaten',
        'Sonstiges',
      ];
    case INSURANCE_TYPE_PETOLO:
      return [
        'Fragen zum Vertrag',
        'Monatsbeitrag',
        'Fragen zur Erstattung oder Auszahlung',
        'Abdeckung durch Tarife',
        'Änderung von Kontakt- oder Vertragsdaten',
        'Sonstiges',
      ];
    default:
      return [
        'Fragen zum Vertrag',
        'Monatsbeitrag',
        'Fragen zur Erstattung oder Auszahlung',
        'dentolo Zahnärzte',
        'Abdeckung durch Tarife',
        'Änderung von Kontakt- oder Vertragsdaten',
        'Sonstiges',
      ];
  }
};

const getPhoneIcon = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.telephone.blue.filled';

    case INSURANCE_TYPE_PETOLO:
      return 'icon.telephone.orange.filled';

    default:
      return 'icon.telephone.blue.filled';
  }
};

const Contact = () => {
  let history = useHistory();
  const intl = useIntl();

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);

  const contractNotCancelled = !customerData?.customer?.contract?.cancelationDate;

  const [formLoading, setFormLoading] = useState(false);
  const ready = useReady();
  const [body, setBody] = useState('');

  const insuranceType = useInsuranceType();

  const contactPhoneNumber = getContactPhoneNumber(insuranceType);
  const formSubjects = getFormSubjects(insuranceType);

  const [subject, setSubject] = useState(formSubjects.length > 0 ? formSubjects[0] : '');

  const [contactUs] = useMutation(CONTACT_US, {
    variables: {
      subject,
      body,
    },
  });

  const submitForm = () => {
    setFormLoading(true);
    contactUs()
      .then((res) => {
        if (res.data.contactUs && Object.keys(res.data.contactUs).length) {
          const errors = res.data.contactUs.errors;

          let status = 'failure';
          if (!errors.length) {
            status = 'success';
          }
          setFormLoading(false);
          history.push(`/contact/${status}`);
          window.scrollTo(0, 0);
        }
      })
      .catch();
  };

  const phoneIcon = getPhoneIcon(insuranceType);

  if (!customerLoading && !!customerData && !!Object.keys(customerData).length) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Container>
          <PageTitle>
            <FormattedMessage id="contact.page.main.title" />
          </PageTitle>

          <Paragraph>
            <FormattedMessage id="contact.page.main.description" />
          </Paragraph>

          {contractNotCancelled && (
            <Paragraph>
              <FormattedMessage id="contact.page.main.cancellation.text1" />
              <>&nbsp;</>
              <Link to={'/contract-cancellation'}>
                <FormattedMessage id="contact.page.main.cancellation.here_link" />
              </Link>
              <>&nbsp;</>
              <FormattedMessage id="contact.page.main.cancellation.text2" />
              <>&nbsp;</>
            </Paragraph>
          )}

          <ContactForm
            maxLength="10000"
            firstName={customerData?.customer?.firstName}
            lastName={customerData?.customer?.lastName}
            email={customerData?.customer?.email}
            submitForm={submitForm}
            formLoading={formLoading}
            formSubjects={formSubjects}
            body={body}
            setBody={setBody}
            subject={subject}
            setSubject={setSubject}
          />

          <StyledAccordion
            title={intl.formatMessage({ id: 'contact.page.main.phone' })}
            titleIcon={phoneIcon}
            expandText="Expand"
            collapseText="Collapse"
          >
            <StyledContent>
              <StyledWorkingTimes>
                <span>
                  <FormattedMessage id="contact.page.main.working.days" />
                  {':'}&nbsp;
                </span>
                <span>
                  <FormattedMessage id="contact.page.main.working.hours" />
                </span>
              </StyledWorkingTimes>

              <span>{contactPhoneNumber}</span>
            </StyledContent>
          </StyledAccordion>
        </Container>
      </CSSTransition>
    );
  } else if (!customerLoading) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <ErrorPlaceholder />
      </CSSTransition>
    );
  } else {
    return <></>;
  }
};

export default Contact;
