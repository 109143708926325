import React, { useState, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import SecondAuth from './SecondAuth';
import { GET_CUSTOMER, GET_CUSTOMERS, signInUser } from '../../shared';
import ErrorPlaceholder from '../ui/ErrorPlaceholder';

const clearDigitalPaymentMissedIbanStorage = (customers = []) => {
  customers.forEach((customer) => {
    const { key } = customer;
    localStorage.removeItem(`customer-missing-iban-${key}`);
  });
};
const Authorisation = () => {
  const history = useHistory();
  const location = useLocation();
  const [uuid, setUuid] = useState(null);
  const [birthDate, setBirthDate] = useState('');

  const urlValues = QueryString.parse(location.search);
  const rememberMe = urlValues?.remember_me || false;
  const jwt = urlValues?.auth || '';

  const client = useApolloClient();

  const getAllCustomers = useQuery(GET_CUSTOMERS, {
    variables: { jwt },
  });

  const getCustomer = useQuery(GET_CUSTOMER);
  clearDigitalPaymentMissedIbanStorage(getAllCustomers?.data?.customers);
  useEffect(() => {
    if (!getAllCustomers?.loading && !getCustomer.loading) {
      const loggedInCustomer = getCustomer?.data?.customer;
      const allCustomers = getAllCustomers?.data?.customers;

      if (!!loggedInCustomer) {
        history.push('/dashboard');
      } else {
        if (Array.isArray(allCustomers) && allCustomers.length === 1) {
          setUuid(allCustomers[0]?.uuid);
        } else if (
          allCustomers === null ||
          getAllCustomers?.error?.message?.includes('Token is invalid')
        ) {
          history.push(`/login?invalid=true&auth=${jwt}&remember_me=${rememberMe}`);
        }
      }
    }
  }, [getAllCustomers, getCustomer, history, jwt, rememberMe]);

  useEffect(() => {
    const signInUserWithUuid = signInUser(client, history, rememberMe, jwt);

    if (birthDate && !!uuid) {
      signInUserWithUuid(uuid);
    } else if (birthDate && !uuid) {
      history.push(`/select-customer${location.search}&birthdate=${birthDate}`);
    }
  }, [uuid, birthDate, history, location, jwt, rememberMe, client]);

  if (!getAllCustomers?.loading && getAllCustomers?.data?.customers) {
    return (
      <SecondAuth
        jwt={jwt}
        setBirthDate={setBirthDate}
        customers={getAllCustomers?.data?.customers}
      />
    );
  } else if (getAllCustomers?.error) {
    return <ErrorPlaceholder />;
  } else {
    return <></>;
  }
};

export default Authorisation;
