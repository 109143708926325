import React from 'react';
import PropTypes from 'prop-types';
import OnboardCard from './OnboardCard';
import { imgLinks } from '../../../shared';
import {
  StyledOnboardCardParent,
  StyledOnboardCardWrapper,
  StyledLaptopImage,
  StyledTabletImage,
  StyledPhoneImage,
} from './HomeOnboardPetolo.styled';
const HomeOnboardCard1 = ({ titleHtml, bodyText }) => {
  return (
    <OnboardCard titleHtml={titleHtml} bodyText={bodyText}>
      <StyledOnboardCardParent>
        <StyledOnboardCardWrapper>
          <StyledLaptopImage src={imgLinks['icon.petolo.desktop.logo']} alt="" />
          <StyledTabletImage src={imgLinks['icon.petolo.tablet.logo']} alt="" />
          <StyledPhoneImage src={imgLinks['icon.petolo.mobile.logo']} alt="" />
        </StyledOnboardCardWrapper>
      </StyledOnboardCardParent>
    </OnboardCard>
  );
};

HomeOnboardCard1.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
};

export default HomeOnboardCard1;
