// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

const GATracker = props => {
  useEffect(() => {
    const prod = process.env.NODE_ENV === 'production'
    if (
      prod &&
      typeof window !== 'undefined' &&
      typeof window.ga === 'function'
    ) {
      window.ga('set', 'page', props.location.pathname)
      window.ga('send', 'pageview')
    }
  }, [props.location.pathname])

  return props.children
}

export default withRouter(GATracker)
