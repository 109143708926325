import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { imgLinks } from '../../shared';
import { useMenuIcon } from '../../hooks/useIcons';

import {
  StyledCompanyLogoWrapper,
  StyledDaDirektLogo,
  StyledHeader,
  StyledHeaderContainer,
  StyledHeaderLogoLinks,
  StyledHeaderSticky,
} from './Header.styled';

function HeaderWithoutActions({ location }) {
  const menuIcon = useMenuIcon();

  const params = queryString.parse(location?.search);

  const customerSourceZurich = params.customer_source === 'zurich';

  return (
    <StyledHeader>
      <StyledHeaderSticky data-header-menu>
        <StyledHeaderContainer>
          <StyledHeaderLogoLinks>
            <StyledCompanyLogoWrapper to="/">
              <img src={imgLinks[menuIcon]} alt={menuIcon} />
            </StyledCompanyLogoWrapper>

            {customerSourceZurich && (
              <StyledDaDirektLogo src={imgLinks['icon.menu.dadirekt.dark']} alt="DaDirekt" />
            )}
          </StyledHeaderLogoLinks>
        </StyledHeaderContainer>
      </StyledHeaderSticky>
    </StyledHeader>
  );
}

HeaderWithoutActions.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(HeaderWithoutActions);
