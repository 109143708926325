import { useEffect, useLayoutEffect, useState } from 'react'
import queryString from 'query-string'
import * as claimStatuses from './claimStatus'
import { fetchData } from './utils'

export function useClaimStatus(status) {
  const [claimLabel, setClaimLabel] = useState('progress')

  useEffect(() => {
    if (!status) return

    switch (status) {
      case claimStatuses.NEW:
        setClaimLabel('progress')
        break

      case claimStatuses.WAITING_FOR_INFORMATION:
        setClaimLabel('info-wait')
        break
      case claimStatuses.BLOCKED:
        setClaimLabel('info-blocked')
        break

      case claimStatuses.REIMBURSEMENT_ACKNOWLEDGED:
      case claimStatuses.WAITING_FOR_PAYOUT:
      case claimStatuses.PARTIALLY_CLOSED:
        setClaimLabel('warning')
        break

      case claimStatuses.CLOSED:
      case claimStatuses.CANCELLED:
        setClaimLabel('closed')
        break

      case claimStatuses.DECLINED:
        setClaimLabel('declined')
        break

      default:
        setClaimLabel('progress')
        break
    }
  }, [status])

  return [claimLabel]
}

export function useReady() {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (!isReady) {
      setIsReady(true)
    }
  }, [isReady])

  return isReady
}

export function useSelectCustomer(pathname) {
  const [isSelectCustomer, setIsSelectCustomer] = useState(false)

  useEffect(() => {
    if (pathname === '/select-customer') {
      setIsSelectCustomer(true)
    } else {
      setIsSelectCustomer(false)
    }
  }, [pathname])

  return isSelectCustomer
}

export function useJwt(location = { search: '' }) {
  const urlValues = queryString.parse(location.search)
  const jwt = urlValues?.auth
  return jwt
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}

export const useFetch = (url = '', options = {}) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const request = fetchData(url, options)
    const abort = request(
      json => setResponse(json),
      error => setError(error)
    )

    return () => abort()
  }, [url, options])

  return { response, error }
}

export const useTrackZurichCustomer = _ => {
  const url = process.env.REACT_APP_ZURICH_TRACK_URL || ''
  const options = {
    method: 'POST',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
    }
  }
  const request = fetchData(url, options)
  request()
}

export const usePreloadTimeout = (timeout = 1000) => {
  const [preload, setPreload] = useState(false)

  useEffect(() => {
    let preloadTimer = null
    if (!preload) {
      preloadTimer = setTimeout(() => {
        setPreload(true)
      }, timeout)
    }

    return () => {
      if (preload) {
        setPreload(false)
        clearTimeout(preloadTimer)
      }
    }
  }, [timeout, preload])

  return [preload]
}
