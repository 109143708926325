import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import ClaimStatusCard from "./ClaimStatusCard";
import {
  StyledClaimStatusHistoryCard,
  StyledClaimStatusHistoryTitle,
  StyledClaimStatusHistoryItems,
} from "./styled/ClaimDetailsStatusHistory.styled";

const ClaimDetailsStatusHistory = ({ claim, skipHistoryItem }) => {
  return (
    <StyledClaimStatusHistoryCard>
      <StyledClaimStatusHistoryTitle>
        <FormattedMessage id={"common.title.history"} tagName="h5" />
      </StyledClaimStatusHistoryTitle>

      <StyledClaimStatusHistoryItems>
        {claim?.statusHistory
          .filter((historyItem) => !skipHistoryItem.includes(historyItem?.status))
          .map((historyItem, index, items) => (
            <ClaimStatusCard
              key={index}
              date={historyItem?.date}
              status={historyItem?.status}
              first={index === 0}
              last={items.length - 1 === index}
            />
          ))}
      </StyledClaimStatusHistoryItems>
    </StyledClaimStatusHistoryCard>
  );
};

export default injectIntl(ClaimDetailsStatusHistory);
