import React from "react"
import { useParams } from "react-router-dom"
import {
  PETOLO_DOCUMENT_DATE,
  PETOLO_TREATMENT_DATE,
  useNewPetoloFormDataValue
} from '../../claimFormData/newClaimFormData'

import SelectTreatmentDate from "./SelectTreatmentDate"
import {
  useSelectTreatmentDatePetoloBackButtonLink,
  useSelectTreatmentDatePetoloNextButtonLink
} from "./selectTreatmentDatePetoloHooks"

const SelectTreatmentDatePetolo = () => {
  const { claimId } = useParams();

  const backButtonLink = useSelectTreatmentDatePetoloBackButtonLink(claimId);
  const nextButtonLink = useSelectTreatmentDatePetoloNextButtonLink(claimId);

  const [documentCreationDate] = useNewPetoloFormDataValue(PETOLO_DOCUMENT_DATE);
  const [treatmentDate, setTreatmentDate] = useNewPetoloFormDataValue(PETOLO_TREATMENT_DATE);

  return (
    <SelectTreatmentDate
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      documentCreationDate={documentCreationDate}
      treatmentDate={treatmentDate}
      setTreatmentDate={setTreatmentDate}
      pageTitleId={'petolo.new_claim.select_treatment_creation_date.page_title'}
      formDescription={'petolo.new_claim.select_treatment_creation_date.form_description'}
      checkLabelText={'petolo.new_claim.select_treatment_creation_date.check_label_text'}
    />
  );
}

export default SelectTreatmentDatePetolo;