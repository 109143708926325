import styled from 'styled-components';

import { color } from '../../../theme/functions';

export const StyledDownloadLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  & + & {
    border-top: 1px solid ${color('light_gray')};
  }

  > a {
    position: relative;
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    color: ${color('main_blue')};
    margin-top: -2px;

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      bottom: -1px;
      left: 0;
      right: 0;
      width: 100%;
      background-color: ${color('main_blue')};
      transform-origin: bottom left;
      transition: transform 0.2s ease-out, color 0.2s ease-out;
    }

    &:hover {
      color: ${color('professional_light_blue')};

      &:after {
        transform: scaleX(0);
        transform-origin: bottom right;
      }
    }

    &:focus,
    &:active {
      color: ${color('professional_light_blue')};

      &::after {
        background-color: ${color('professional_light_blue')};
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
`;
