import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import { PETOLO_STEP_DOCUMENT_TYPE } from '../../new/newClaimsFormSteps';

export const useSelectTreatmentCategoriesPetHealthBackButtonLink = () => {
  return '/user-claims-info';
};

export const useSelectTreatmentCategoriesPetHealthNextButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();
  return withSource(PETOLO_STEP_DOCUMENT_TYPE);
};
