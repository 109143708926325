import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { color } from '../../theme/functions';

const variantStyles = (variant) =>
  ({
    info: css`
      background-color: ${color('gray_blue')};
    `,
    success: css`
      background-color: ${color('green')};
    `,
    error: css`
      color: white;
      background-color: ${color('faded_red')};
    `,
    transparent: css`
      border: 1px solid ${color('main_blue')};
      background-color: transparent;
    `,
    pending: css`
      background-color: ${color('clear_light_blue')};
    `,
    cancelled: css`
      background-color: ${color('lovely_rose')};
    `,
    sent: css`
      background-color: ${color('vitalized_green')};
    `,
  }[variant]);

const StyledTag = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 1.375rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: ${color('main_blue')};
  border-radius: 5px;

  ${({ variant }) => variantStyles(variant)};
`;

export { StyledTag as Tag };

StyledTag.defaultProps = {
  variant: 'info',
};

StyledTag.propTypes = {
  variant: PropTypes.string,
};
