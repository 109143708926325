import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../components/ui';
import { BackButton, ButtonContainer, PageTitle } from '../../../../components/common';

import {
  PET_LIABILITY_INCIDENT_LOCATION,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useIncidentLocationPetLiabilityBackButtonLink,
  useIncidentLocationPetLiabilityNextButtonLink,
} from './useInsidentLocationPetLiability';

import AddressForm, { validatePostCode } from '../common/PersonalDetails/AddressForm';
import { INVALID_FIELD, VALID_FIELD } from '../../../../shared';
import { PersonalDetailsFormContainer } from '../common/PersonalDetails/PersonalDetails.styled';

function IncidentLocationPetLiability() {
  const history = useHistory();

  const [liveValidation, setLiveValidation] = useState(false);
  const [postCodeStatus, setPostCodeStatus] = useState(VALID_FIELD);

  useEffect(() => {
    return () => {
      setLiveValidation(false);
    };
  }, []);

  const [incidentLocation, setIncidentLocation] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_INCIDENT_LOCATION
  );
  const { address, houseNumber, postcode, city } = incidentLocation || {};

  const backButtonLink = useIncidentLocationPetLiabilityBackButtonLink();
  const nextButtonLink = useIncidentLocationPetLiabilityNextButtonLink();

  const handleAddressChange = (ev) =>
    setIncidentLocation({ ...incidentLocation, address: ev?.target?.value });
  const handleHouseNumberChange = (ev) =>
    setIncidentLocation({ ...incidentLocation, houseNumber: ev?.target?.value });
  const handlePostcodeChange = (ev) =>
    setIncidentLocation({ ...incidentLocation, postcode: ev?.target?.value });
  const handleCityChange = (ev) =>
    setIncidentLocation({ ...incidentLocation, city: ev?.target?.value });

  const validateAddress = useCallback(() => {
    let isValid = true;
    if (!address || !houseNumber || !city) {
      isValid = false;
    }

    if (!validatePostCode(postcode)) {
      setPostCodeStatus(INVALID_FIELD);
      isValid = false;
    } else {
      setPostCodeStatus(VALID_FIELD);
    }

    return isValid;
  }, [address, houseNumber, postcode, city]);

  const handleButtonClick = () => {
    setLiveValidation(true);

    if (!validateAddress()) return;

    history.push(nextButtonLink);
  };

  useEffect(() => {
    if (!liveValidation) return;

    validateAddress();
  }, [liveValidation, validateAddress]);

  const validForm = !!address && !!houseNumber && !!postcode && !!city;

  return (
    <>
      <BackButton to={backButtonLink}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      <PageTitle>
        <FormattedMessage id="pet_liability.new_claim.incident_location.page_title" />
      </PageTitle>

      <PersonalDetailsFormContainer>
        <AddressForm
          address={address || ''}
          houseNumber={houseNumber || ''}
          postcode={postcode || ''}
          city={city || ''}
          onAddressChange={handleAddressChange}
          onHouseNumberChange={handleHouseNumberChange}
          onPostcodeChange={handlePostcodeChange}
          onCityChange={handleCityChange}
          postCodeStatus={postCodeStatus}
        />
      </PersonalDetailsFormContainer>

      <ButtonContainer>
        <Button onClick={handleButtonClick} disabled={!validForm} data-testid="button-next">
          <FormattedMessage id="common.button.next" />
        </Button>
      </ButtonContainer>
    </>
  );
}

export default IncidentLocationPetLiability;
