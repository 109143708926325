import React from 'react';
import { useParams } from 'react-router-dom';
import { PETOLO_DASHBOARD_ONBOARD_KEY } from '../../../shared';
import { Onboard } from '../../ui';
import HomeOnboardCard1 from './HomeOnboardCard1';
import HomeOnboardCard2 from './HomeOnboardCard2';
import HomeOnboardCard3 from './HomeOnboardCard3';
import { Container } from '../../common';

const HomeOnboard = [
  {
    id: 'HomeOnboardCard1',
    name: HomeOnboardCard1,
    titleHtml: 'Willkommen im petolo<br />Kundenkonto!',
    bodyText:
      'Von hier aus haben Sie Zugang zu allen wichtigen Informationen und Funktionen Ihres petolo Versicherungsschutzes.',
    btnText: 'Überspringen',
  },
  {
    id: 'HomeOnboardCard2',
    name: HomeOnboardCard2,
    titleHtml: 'Übersicht Ihrer petolo<br />Leistungen',
    bodyText:
      'In Ihrem Kundenkonto finden Sie alle Informationen zu Ihren Tarif-Leistungen, können Rechnungen in wenigen Schritten hochladen und z.B. den Status Ihrer Kostenerstattung einsehen.',
    btnText: 'Überspringen',
  },
  {
    id: 'HomeOnboardCard3',
    name: HomeOnboardCard3,
    titleHtml: 'Neue Funktionen &<br />exklusive Vorteile',
    bodyText:
      'Ihre petolo Services & Vorteile werden stetig erweitert und verbessert, damit Sie einfach, schnell und unkompliziert von unseren Leistungen profitieren können. Über Änderungen in Ihrem Kundenkonto informieren wir Sie rechtzeitig.',
    btnText: 'Zum Kundenkonto',
    btnType: 'primary',
  },
];

const HomeOnboardPetolo = () => {
  let { id } = useParams();

  return (
    <Container>
      <Onboard
        initialIndex={id}
        name={PETOLO_DASHBOARD_ONBOARD_KEY}
        onboardingUrl="/intro-petolo"
        mainPageUrl="/dashboard"
        schema={HomeOnboard}
      />
    </Container>
  );
};

export default HomeOnboardPetolo;
