import React from 'react';

import { imgLinks, INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO } from '../../shared';
import { useInsuranceType } from '../../hooks/useInsuranceType';
import { useMenuIcon } from '../../hooks/useIcons';

import {
  StyledCompanyLogoWrapper,
  StyledDaDirektLogo,
  StyledHeaderLogoLinks,
} from './Header.styled';

const getClickEventName = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'click dentolo logo';
    case INSURANCE_TYPE_PETOLO:
      return 'click petolo logo';
    default:
      return 'click dentolo logo';
  }
};

// const getPaddingValue = (insuranceType) => {
//   switch (insuranceType) {
//     case INSURANCE_TYPE_DENTOLO:
//       return '0 0 0.2em 0';
//     case INSURANCE_TYPE_PETOLO:
//       return '0.2em 0 0 0';
//     default:
//       return '0 0 0.2em 0';
//   }
// };

function HeaderLogoLinks({ customer, trackDtEvent, showDaDirektImg }) {
  const insuranceType = useInsuranceType();
  const menuIcon = useMenuIcon();

  const handleCompanyLogoClick = () => {
    trackDtEvent(getClickEventName(insuranceType), customer)();
  };

  return (
    <StyledHeaderLogoLinks>
      <StyledCompanyLogoWrapper to="/" onClick={handleCompanyLogoClick}>
        <img
          src={imgLinks[menuIcon]}
          alt={menuIcon}
          // style={{ padding: getPaddingValue(insuranceType) }}
        />
      </StyledCompanyLogoWrapper>

      {showDaDirektImg && (
        <StyledDaDirektLogo src={imgLinks['icon.menu.dadirekt.dark']} alt="DaDirekt" />
      )}
    </StyledHeaderLogoLinks>
  );
}

export default HeaderLogoLinks;
