import styled from 'styled-components';

import { THEME_PETOLO } from '../../../shared';
import { min_width_md } from '../../../theme/breakpoints';
import { color } from '../../../theme/functions';

import { ButtonContainer } from '../../../components/common';
import { Button } from '../../../components/ui';

export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  margin-top: 16px;
`;

export const StyledDesktopImage = styled.img`
  display: none;

  @media (min-width: ${min_width_md}) {
    display: block;
  }
`;

export const StyledMobileImage = styled.img`
  display: block;

  @media (min-width: ${min_width_md}) {
    display: none;
  }
`;

export const StyledButtonContainer = styled(ButtonContainer)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-flow: column-reverse;

  @media (min-width: ${min_width_md}) {
    flex-direction: row;
    flex-flow: row;
  }
`;

export const StyledButton = styled(Button)`
  margin: 0.5em auto;
  width: fit-content;

  @media (min-width: ${min_width_md}) {
    margin: 0.5em;
    width: auto;
  }
`;

export const StyledLandingPageNormalText = styled.div`
  font-weight: 500;
  text-align: center;
  margin-bottom: 1em;
  padding: 0 1em;
  color: ${color('main_blue')};

  p {
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: ${min_width_md}) {
    p {
      font-size: 20px;
      line-height: 26px;
    }
  }
`;

export const StyledLandingPageBoldText = styled(StyledLandingPageNormalText)`
  p {
    font-weight: 700;
  }
`;

export const StyledCancellationDate = styled(StyledLandingPageBoldText)`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 0 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  background: ${({ theme: { name } }) => color(name === THEME_PETOLO ? 'primary' : 'secondary')};
  color: ${color('main_blue')};
  border-radius: 3px;

  @media (min-width: ${min_width_md}) {
    font-size: 20px;
    line-height: 26px;
  }
`;
