import React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { useInsuranceType } from '../../hooks/useInsuranceType';
import { DA_DIREKT_POLICY_CATEGORIES, GET_CUSTOMER } from '../../shared';

const PageRenderForInsuranceType = ({
  insuranceType: givenInsuranceType,
  excludeDaDirekt,
  children,
}) => {
  const insuranceType = useInsuranceType();

  const { data: customerData } = useQuery(GET_CUSTOMER);

  const policyCategory = customerData?.customer?.contract?.policyCategory;
  const isDaDirektCustomer =
    Number.isInteger(policyCategory) && DA_DIREKT_POLICY_CATEGORIES.includes(policyCategory);

  if (isDaDirektCustomer && excludeDaDirekt) {
    return <Redirect to="/404" />;
  } else {
    if (insuranceType === givenInsuranceType || !givenInsuranceType) {
      return <>{children}</>;
    } else {
      return <></>;
    }
  }
};

export default PageRenderForInsuranceType;
