import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { imgLinks } from '../../shared';
import { color } from '../../theme/functions';

export const StyledCheckbox = styled.input`
  position: relative;
  margin: 0;
  padding: 0;
  min-width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${color('white')};
  border: 0.05rem solid ${color('professional_light_blue')};
  border-radius: 2px;
  appearance: none;
  cursor: pointer;
  transition: border 0.3s ease-in-out;

  &:hover {
    border-color: ${color('main_blue')};
  }

  &:focus {
    box-shadow: none;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background-image: url(${imgLinks['icon.menu.blue.mark']});
    background-position: center;
    background-repeat: no-repeat;
    transform: scale(0);
    transition: transform 0.2s ease-in-out;
  }

  &:checked:after {
    transform: scale(1);
  }
`;

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  color: ${color('main_blue')};
  font-weight: 450;
  cursor: pointer;

  > span[data-label] {
    position: relative;
    margin-left: 0.5rem;

    ${({ required }) =>
      required &&
      css`
        padding-left: 0.75rem;

        &::before {
          content: '*';
          position: absolute;
          top: 0;
          left: 0;
          font-size: 1.1rem;
          color: ${color('faded_red')};
        }
      `}
  }
`;

function Checkbox(props) {
  const { children, label, checked, onChange, ...rest } = props;

  return (
    <StyledLabel {...rest}>
      <StyledCheckbox
        type="checkbox"
        checked={checked}
        onChange={onChange}
        data-testid="previous_date_checkbox"
      />

      <span data-label>{label || children}</span>
    </StyledLabel>
  );
}

export default Checkbox;

Checkbox.defaultProps = {
  alignItems: 'center',
};

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
