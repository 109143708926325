import styled from 'styled-components';

export const StyledPetoloReferralStatusEmptyContainer = styled.div`
  text-align: center;
`;

export const StyledPetoloReferralStatusEmptyTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const StyledPetoloReferralStatusEmptyDescription = styled.div`
  font-size: 1rem;
  font-weight: 450;
`;

export const StyledPetoloReferralStatusEmptyImageContainer = styled.div`
  margin: 1.75rem auto 2rem;
  width: 9.25rem;
  transform: rotate(-10.5deg);
`;

export const StyledPetoloReferralStatusEmptyImg = styled.img`
  max-width: 100%;
  border-radius: 6px;
  box-shadow: -8.126px 8.126px 9.752px rgba(27, 53, 98, 0.25);
`;
