import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Button } from './';
import { Icon, Text } from '../common';
import { StyledDentistDetailsAvatarContainer } from '../styled/DentistsDetails.styled';
import {
  StyledDentistFloatCard,
  StyledDentistFloatCardAvatar,
  StyledDentistFloatCardItem,
  StyledDentistFloatCardButtonContainer,
  StyledDentistFloatCardWrapper,
} from './styled/DentiststFloatCard.styled';

const DentistsFloatCard = ({ dentist, setDentist, setShowDetails }) => {
  const intl = useIntl();

  const avatarSrc = dentist?.avatar;
  const address = `${dentist?.streetName} ${dentist?.houseNumber}, ${dentist?.postcode} ${dentist?.city}`;

  const avatarContainerAlignItems = dentist?.dentoloPartner ? 'flex-start' : 'center';

  if (!dentist) return <></>;
  return (
    <StyledDentistFloatCard>
      <Text fontSize="1.15rem">{dentist?.practiceName}</Text>

      <StyledDentistFloatCardWrapper>
        <StyledDentistDetailsAvatarContainer alignItems={avatarContainerAlignItems}>
          {avatarSrc && <StyledDentistFloatCardAvatar src={avatarSrc} alt="avatar" />}

          <div>
            <Text fontSize="1.25rem" fontWeight="700">
              {dentist?.title} {dentist?.name}
            </Text>

            <Text fontSize="1rem" lineHeight="1rem">
              {intl.formatMessage({ id: 'dentist.details.dentolo.dentist' })}
            </Text>
          </div>
        </StyledDentistDetailsAvatarContainer>

        <StyledDentistFloatCardItem>
          <Icon icon="icon.pin" alt="address" />
          <label>{address}</label>
        </StyledDentistFloatCardItem>

        <StyledDentistFloatCardButtonContainer>
          <Button variant="outline" onClick={() => setDentist(null)}>
            <FormattedMessage id="common.button.close" />
          </Button>

          <Button onClick={() => setShowDetails(true)}>Details</Button>
        </StyledDentistFloatCardButtonContainer>
      </StyledDentistFloatCardWrapper>
    </StyledDentistFloatCard>
  );
};

export default DentistsFloatCard;

DentistsFloatCard.propTypes = {
  /** This contains all the information needed to render the component */
  dentist: PropTypes.object,
  /** Set dentist to null to render <></> (and hide this float window) */
  setDentist: PropTypes.func,
  /** Sets the ShowDetails to true/false, in order to show the DentistDetailsModal */
  setShowDetails: PropTypes.func,
};
