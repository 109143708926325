import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { imgLinks } from '../../shared';
import { min_width_md } from '../../theme/breakpoints';

const StyledIcon = styled.img`
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;

  @media (min-width: ${min_width_md}) {
    width: 3.5rem;
    height: 3.5rem;
  }
`;

const StyledIconContainer = styled.div`
  margin-right: 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
  align-self: center;
`;

export default function Icon({ icon, alt, ...rest }) {
  return <StyledIcon src={imgLinks[icon]} alt={alt || ''} {...rest} />;
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export function WrongListItemIcon() {
  return (
    <StyledIconContainer>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_758_14976)">
          <path
            d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
            fill="#003264"
          />
          <rect
            x="4"
            y="5.04346"
            width="1.50943"
            height="8.75472"
            rx="0.754717"
            transform="rotate(-43.7308 4 5.04346)"
            fill="white"
          />
          <rect
            width="1.51519"
            height="8.75472"
            rx="0.757594"
            transform="matrix(-0.722596 -0.691271 -0.691271 0.722596 11.3701 5.27417)"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_758_14976">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </StyledIconContainer>
  );
}

export function RightListItemIconPetolo() {
  return (
    <StyledIconContainer>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.83639 10.3598L2.91909 6.95314C2.57986 6.65938 2.13028 6.50422 1.66924 6.52179C1.2082 6.53935 0.773482 6.72822 0.460707 7.04682C0.147932 7.36543 -0.0172742 7.78769 0.00143178 8.2207C0.0201378 8.65371 0.221224 9.06201 0.560453 9.35577L5.90039 13.9926C6.07973 14.1485 6.29209 14.2671 6.5236 14.3406C6.7551 14.4141 7.00052 14.4409 7.2438 14.4192C7.48708 14.3975 7.72274 14.3278 7.93537 14.2146C8.14799 14.1015 8.33278 13.9475 8.47766 13.7626L15.6412 4.62433C15.7818 4.4522 15.8844 4.25546 15.9431 4.04564C16.0017 3.83581 16.0152 3.6171 15.9828 3.40232C15.9504 3.18753 15.8726 2.98097 15.7542 2.79474C15.6357 2.6085 15.4789 2.44633 15.2929 2.3177C15.1069 2.18907 14.8955 2.09658 14.671 2.04563C14.4464 1.99468 14.2134 1.9863 13.9853 2.02098C13.7573 2.05566 13.539 2.1327 13.3431 2.24759C13.1471 2.36249 12.9776 2.51293 12.8443 2.69011L6.83639 10.3598Z"
          fill="#FFA445"
        />
      </svg>
    </StyledIconContainer>
  );
}

export function RightListItemIconDentolo() {
  return (
    <StyledIconContainer>
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.7139 11.8022C16.7139 15.9444 13.356 19.3022 9.21387 19.3022C5.07173 19.3022 1.71387 15.9444 1.71387 11.8022C1.71387 7.66011 5.07173 4.30225 9.21387 4.30225C13.356 4.30225 16.7139 7.66011 16.7139 11.8022Z"
          fill="#32FF96"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1991 2.13022C21.7288 2.67435 21.7171 3.54487 21.173 4.07459L9.35324 15.5813L4.68344 10.2956C4.18064 9.72655 4.2344 8.8576 4.80349 8.35481C5.37259 7.85202 6.24153 7.90577 6.74433 8.47487L9.5031 11.5974L19.2547 2.10412C19.7989 1.57441 20.6694 1.58609 21.1991 2.13022Z"
          fill="#003264"
        />
      </svg>
    </StyledIconContainer>
  );
}
