import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { useQuery } from '@apollo/client';
import { InfoCard } from '../ui';
import { LOCAL_CONFIRM_EMAIL, GET_CUSTOMER, useReady } from '../../shared';

import { AuthContainer, PageTitle } from './Login.styled';

const LoginStatus = ({ intl }) => {
  let { status } = useParams();
  let history = useHistory();

  const confirmedEmail = useQuery(LOCAL_CONFIRM_EMAIL);
  const customer = useQuery(GET_CUSTOMER);
  const ready = useReady();

  const failedRequest = intl.formatMessage({ id: 'login.status.failed' });
  const buttonBack = intl.formatMessage({ id: 'common.button.back' });
  const notActivated = intl.formatMessage({ id: 'login.status.not.activated' });
  const clickRegister = intl.formatMessage({ id: 'login.status.click.register' });
  const tryAgain = intl.formatMessage({ id: 'login.status.try.again' });

  const emailSentSuccess = (confirmEmail) =>
    intl.formatMessage({ id: 'login.status.email.sent' }, { confirmEmail });
  const emailSentFailed = (confirmEmail) =>
    intl.formatMessage({ id: 'login.status.email.failed' }, { confirmEmail });

  let infoSettings = {
    mode: 'failure',
    titleHtml: failedRequest,
  };

  let backBtnText = buttonBack;

  if (confirmedEmail?.data?.confirmEmail) {
    if (status === 'failure') {
      infoSettings = {
        mode: 'failure',
        titleHtml: emailSentFailed(confirmedEmail?.data?.confirmEmail),
        text: notActivated,
        button: { text: tryAgain, to: '/login' },
      };

      backBtnText = tryAgain;
    } else if (status === 'success') {
      infoSettings = {
        mode: 'success',
        titleHtml: emailSentSuccess(confirmedEmail?.data?.confirmEmail),
        text: clickRegister,
        button: { text: backBtnText, to: '/login' },
      };
    }
  }

  if (!!customer?.data?.customer?.key) {
    history.push('/');
  }

  return (
    <AuthContainer>
      <PageTitle>
        <FormattedMessage id="login.common.title" />
      </PageTitle>

      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <>
          <InfoCard
            mode={infoSettings?.mode}
            titleHtml={infoSettings?.titleHtml}
            textHtml={infoSettings.text}
            button={infoSettings?.button}
          />
        </>
      </CSSTransition>
    </AuthContainer>
  );
};

export default injectIntl(LoginStatus);
