import styled from 'styled-components';

import { min_width_md } from '../../../../theme/breakpoints';

import { StyledReferralCoverImageContainer } from '../ReferralPage.styled';

export const StyledDentoloReferralStatusEmptyContainer = styled.div`
  text-align: center;
`;

export const StyledDentoloReferralStatusEmptyTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 700;

  @media (min-width: ${min_width_md}) {
    font-size: 1.5rem;
  }
`;

export const StyledDentoloReferralStatusEmptyDescription = styled.div`
  font-size: 1rem;
  font-weight: 450;
`;

export const StyledDentoloReferralStatusEmptyImageContainer = styled(
  StyledReferralCoverImageContainer
)`
  margin: 1rem auto 2.25rem;
  padding: 0;
  width: 260px;
  height: 100px;
  overflow: hidden;
`;

styled(StyledReferralCoverImageContainer)``;

export const StyledDentoloReferralStatusEmptyImg = styled.img`
  max-width: 100%;
  width: 100%;
  height: auto;
`;
