import styled from 'styled-components';

import { boxShadow, color } from '../../../theme/functions';
import { min_width_lg, min_width_md, min_width_sm } from '../../../theme/breakpoints';

import { IconButton, Text } from '../../common';

export const StyledInsureAnotherPetBannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  background-color: ${color('white')};
  z-index: 1;
  box-shadow: ${boxShadow('box_shadow_1')};

  @media (min-width: ${min_width_md}) {
    height: 230px;
    box-shadow: none;
  }
`;

export const StyledInsureAnotherPetBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 5rem;
  padding: 1rem;

  @media (min-width: ${min_width_md}) {
    flex-direction: row;
    padding: 2rem;
  }
`;

export const StyledInsureAnotherPetBannerPetoloLogo = styled.img`
  position: relative;
  width: 100px;
  height: auto;
  margin-bottom: 1rem;
`;

export const StyledInsureAnotherPetBannerCloseButton = styled(IconButton)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 24px;
  height: 24px;
  z-index: 2;

  @media (min-width: ${min_width_md}) {
    right: 1.5rem;
    top: 1.5rem;
  }
`;

export const StyledInsureAnotherPetBannerPetImage = styled.img`
  position: absolute;
  right: -3rem;
  bottom: 0.5rem;
  width: 180px;
  height: auto;

  @media (min-width: ${min_width_sm}) {
  }

  @media (min-width: ${min_width_md}) {
    bottom: -4.5rem;
    left: 48rem;
    width: 400px;
  }

  @media (min-width: ${min_width_lg}) {
    left: 56rem;
  }
`;

export const StyledInsureAnotherPetBannerContent = styled.div`
  max-width: 35rem;
  z-index: 1;
`;
export const StyledInsureAnotherPetBannerContentTitle = styled(Text)`
  margin-bottom: 1rem;
  width: 70%;

  @media (min-width: ${min_width_md}) {
    margin-bottom: 0.5rem;
  }
`;
export const StyledInsureAnotherPetBannerContentDescription = styled(Text)`
  margin-bottom: 1.5rem;
  padding-right: 3rem;
  display: none;

  @media (min-width: ${min_width_md}) {
    display: block;
  }
`;
