import styled from 'styled-components';

import { color as colorFn } from '../../theme/functions';

const Separator = styled.hr`
  border: none;
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: ${({ color }) => color || colorFn('gray_blue')};
`;

export default Separator;
