import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { useInsuranceType } from '../../hooks/useInsuranceType';
import { imgLinks, formatDate, INSURANCE_TYPE_PETOLO } from '../../shared';

import {
  StyledClaimStatusCard,
  StyledClaimStatusCardButton,
  StyledArrowIcon,
  StyledTreatmentDetailsContainer,
  StyledTreatmentDetails,
  StyledTreatmentOtherInfo,
  StyledClaimStatus,
} from './styled/ClaimStatus.styled';

const ClaimStatus = ({ claim, setSelectedClaim }) => {
  const {
    id,
    key,
    status,
    treatmentCategory,
    statusDetails,
    notificationDate,
    lossDate,
    statusUpdated,
  } = claim;

  const history = useHistory();

  const insuranceType = useInsuranceType();
  const isPetolo = insuranceType === INSURANCE_TYPE_PETOLO;

  const handleClaimStatusClick = () => {
    setSelectedClaim({
      statusUpdated,
      notificationDate,
      treatmentCategory,
      statusDetails,
      lossDate,
      status,
      id,
      key,
    });

    history.push(`/user-claims-info/${id}`);
  };

  return (
    <StyledClaimStatusCard>
      <StyledClaimStatusCardButton
        statusUpdated={statusUpdated}
        onClick={handleClaimStatusClick}
        variant="nostyle"
        ariaLabel="Toggle Claim"
      >
        <>
          <StyledArrowIcon src={imgLinks['icon.accordion.arrow.down']} alt=">" />

          <StyledTreatmentDetailsContainer>
            <StyledTreatmentDetails>
              {!!treatmentCategory && (
                <FormattedMessage id={`treatment.category.${treatmentCategory}`} />
              )}
              {isPetolo && !treatmentCategory && (
                <FormattedMessage id={`treatment.category.pet_null`} />
              )}
            </StyledTreatmentDetails>
            <StyledTreatmentOtherInfo data-hj-suppress="true">
              {formatDate(lossDate || notificationDate)} ({key})
            </StyledTreatmentOtherInfo>
          </StyledTreatmentDetailsContainer>

          <StyledClaimStatus claimStatus={status}>
            <FormattedMessage id={`claim.status.label.${status}`} tagName="span" />
          </StyledClaimStatus>
        </>
      </StyledClaimStatusCardButton>
    </StyledClaimStatusCard>
  );
};

ClaimStatus.propTypes = {
  claim: PropTypes.object.isRequired,
  setSelectedClaim: PropTypes.func,
};

export default ClaimStatus;
