import { useClaimUploadFlowSource } from "../../claimUploadFlowSource";
import {
  DENTOLO_DOCUMENT_TYPE_INVOICE,
  DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
} from "../../claimFormData/newClaimFormData";
import {
  DENTOLO_STEP_TREATMENT_DATE,
  DENTOLO_STEP_TREATMENT_PRICE,
  DENTOLO_STEP_UPLOAD_DOCUMENTS
} from "../../new/newClaimsFormSteps";
import {
  DENTOLO_EDIT_STEP_TREATMENT_DATE,
  DENTOLO_EDIT_STEP_TREATMENT_PRICE,
  DENTOLO_EDIT_STEP_UPLOAD_DOCUMENTS,
  PARAM_CLAIM_ID
} from "../../edit/editClaimFormSteps";

export const useSelectDocumentCreationDateDentoloBackButtonLink = (claimId) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const backButtonLink = DENTOLO_EDIT_STEP_UPLOAD_DOCUMENTS.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLink);
  } else {
    return withSource(DENTOLO_STEP_UPLOAD_DOCUMENTS);
  }
}

const getNextButtonLinkForNewClaim = (documentType) => {
  switch (documentType) {
    case DENTOLO_DOCUMENT_TYPE_INVOICE:
      return DENTOLO_STEP_TREATMENT_DATE;
    case DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN:
      return DENTOLO_STEP_TREATMENT_PRICE;
    default:
      return DENTOLO_STEP_TREATMENT_PRICE;
  }
}

const getNextButtonLinkForExistingClaim = (documentType) => {
  switch (documentType) {
    case DENTOLO_DOCUMENT_TYPE_INVOICE:
      return DENTOLO_EDIT_STEP_TREATMENT_DATE;
    case DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN:
      return DENTOLO_EDIT_STEP_TREATMENT_PRICE;
    default:
      return DENTOLO_EDIT_STEP_TREATMENT_PRICE;
  }
}

export const useSelectDocumentCreationDateDentoloNextButtonLink = (claimId, documentType) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const nextButtonLink = getNextButtonLinkForExistingClaim(documentType);
    const nextButtonLinkWithClaimId = nextButtonLink.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLinkWithClaimId);
  } else {
    const nextButtonLink = getNextButtonLinkForNewClaim(documentType);
    return withSource(nextButtonLink);
  }
}