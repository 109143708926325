import styled from 'styled-components';
import { min_width_md } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

export const StyledNotificationTitle = styled.p`
  font-size: 1rem;
  font-weight: 700;
  color: ${color('main_blue')};
  line-height: 1.75rem;
  text-align: center;
  margin-bottom: 1rem;

  @media (min-width: ${min_width_md}) {
    font-size: 1rem;
    line-height: 24px;
  }
`;

export const StyledNotificationDescription = styled(StyledNotificationTitle)`
  font-weight: 450;
`;
export const StyledNotificationButton = styled.p`
  font-size: 1rem;
`;
export const StyledPaymentStatusContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
`;
export const StyledPStatusIconContainer = styled.div`
  margin-bottom: 1rem;
`;
