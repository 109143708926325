import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { useQuery } from '@apollo/client';

import { useInsuranceType } from '../../hooks/useInsuranceType';
import {
  useSelectCustomer,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  GET_CUSTOMER,
  INSURANCE_CATEGORY_PET_HEALTH,
  DA_DIREKT_POLICY_CATEGORIES,
} from '../../shared';
import {
  myProfileLinkItems,
  miscellaneousLinkItems,
  petHelpLinkItems,
  useClaimsInfoLink,
  claimsReportLink,
  dentistsMapLink,
  onlineVetsLink,
  referralLink,
} from './navMenuConfig';

import NavLinkDropdown from './NavLinkDropdown';
import {
  StyledNavLink,
  StyledNavMenuDesktop,
  StyledNavMenuDesktopContainer,
} from './styled/NavMenuDesktop.styled';

const getMenuLinksConfig = (insuranceType, customerInsuranceCategory, isDaDirektCustomer) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return isDaDirektCustomer
        ? [useClaimsInfoLink, dentistsMapLink]
        : [useClaimsInfoLink, dentistsMapLink, referralLink];

    case INSURANCE_TYPE_PETOLO:
      if (customerInsuranceCategory === INSURANCE_CATEGORY_PET_HEALTH) {
        return isDaDirektCustomer
          ? [useClaimsInfoLink, onlineVetsLink]
          : [useClaimsInfoLink, onlineVetsLink, referralLink];
      }

      return [claimsReportLink, referralLink];

    default:
      return [useClaimsInfoLink, dentistsMapLink, referralLink];
  }
};

function NavMenuDesktop() {
  const { data: customerData } = useQuery(GET_CUSTOMER);
  const customerInsuranceCategory = customerData?.customer?.insuranceCategory;
  const customerPolicyCategory = customerData?.customer?.contract?.policyCategory;
  const [ready, setReady] = useState(false);
  const location = useLocation();

  const insuranceType = useInsuranceType();
  const isDentolo = insuranceType === INSURANCE_TYPE_DENTOLO;
  const isDaDirektCustomer =
    Number.isInteger(customerPolicyCategory) &&
    DA_DIREKT_POLICY_CATEGORIES.includes(customerPolicyCategory);

  const menuLinksConfig = getMenuLinksConfig(
    insuranceType,
    customerInsuranceCategory,
    isDaDirektCustomer
  );

  useEffect(() => {
    if (!ready) setReady(true);
  }, [ready]);

  const handleNavLinkClick = (ev, linkItem) => {
    if (location?.pathname === linkItem.path) {
      ev.preventDefault();
    }
  };

  if (useSelectCustomer(location?.pathname)) {
    return <></>;
  }

  return (
    <CSSTransition in={ready} timeout={400} classNames="nav-menu-desktop" unmountOnExit>
      <StyledNavMenuDesktop data-nav-menu-desktop>
        <StyledNavMenuDesktopContainer>
          <NavLinkDropdown
            caption={<FormattedMessage id={`menu.header.item.my_profile`} />}
            linkItems={myProfileLinkItems}
          />

          {menuLinksConfig.map((linkItem) => (
            <StyledNavLink
              key={linkItem.path}
              to={linkItem.path}
              onClick={(ev) => handleNavLinkClick(ev, linkItem)}
            >
              {linkItem.title}
            </StyledNavLink>
          ))}

          {isDentolo ? (
            <NavLinkDropdown
              caption={<FormattedMessage id={`menu.header.item.miscellaneous`} />}
              linkItems={miscellaneousLinkItems}
            />
          ) : (
            <NavLinkDropdown
              caption={<FormattedMessage id={`menu.header.item.help`} />}
              linkItems={petHelpLinkItems}
            />
          )}
        </StyledNavMenuDesktopContainer>
      </StyledNavMenuDesktop>
    </CSSTransition>
  );
}

export default NavMenuDesktop;
