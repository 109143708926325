import React from 'react';
import { FormattedMessage } from 'react-intl';

import Budget from './Budget';
import { CheckIcon } from './';
import {
  StyledPolicyDetailsSectionTitle,
  StyledPolicyDetailsList,
  StyledPolicyDetailsTextLg,
  StyledPolicyDetailsText,
} from '../styled/PolicyDetails.styled';

const Conditions = ({ conditions, budgetInfo, budgetAmountInfo, hasAddon }) => {
  if (!Array.isArray(conditions)) return null;

  return (
    <div style={{ margin: '1.5rem 0' }}>
      <StyledPolicyDetailsSectionTitle>
        <FormattedMessage id="policy.all_details.conditions.title" />:
      </StyledPolicyDetailsSectionTitle>

      {conditions.map((item, index) => (
        <StyledPolicyDetailsList alignItems="flex-start" key={index}>
          <CheckIcon />

          <div>
            <StyledPolicyDetailsTextLg>{item?.title || ''}</StyledPolicyDetailsTextLg>
            <StyledPolicyDetailsText>{item?.text || ''}</StyledPolicyDetailsText>
          </div>
        </StyledPolicyDetailsList>
      ))}

      <Budget budgetInfo={budgetInfo} budgetAmountInfo={budgetAmountInfo} hasAddon={hasAddon} />
    </div>
  );
};

export default Conditions;
