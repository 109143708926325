import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PET_LIABILITY_STEP_ACCOMPANIED_BY,
  PET_LIABILITY_STEP_INCIDENT_DATE_TIME,
} from '../../new/newClaimsFormSteps';

export const useIncidentLocationPetLiabilityBackButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_INCIDENT_DATE_TIME);
};

export const useIncidentLocationPetLiabilityNextButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  const nextButtonLink = PET_LIABILITY_STEP_ACCOMPANIED_BY;
  return withSource(nextButtonLink);
};
