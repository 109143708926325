import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';

import useForm from '../../shared/useForm';
import { GET_CUSTOMER, UPDATE_PET_DETAILS } from '../../shared';
import { onlyDigitRegex, whitespaceRegex } from '../../shared/regex';

import { Input } from '../common';
import { ErrorMessage } from '../common/FormElements';
import { Button } from '../ui';
import {
  StyledForm,
  StyledFormLabel,
  StyledFormGroup,
  StyledAdditionalDetailsText,
  StyledButtonContainer,
} from './CustomerDetailsEditor.styled';

const isNumber = (value) => onlyDigitRegex.test(value);

function validateForm(values) {
  const { transponderCode } = values || {};
  const codeWithoutWhitespaces = !!transponderCode
    ? transponderCode.replace(whitespaceRegex, '')
    : '';

  let errors = {};

  if (
    !codeWithoutWhitespaces ||
    codeWithoutWhitespaces?.length !== 15 ||
    !isNumber(codeWithoutWhitespaces) ||
    !Number.isInteger(Number.parseInt(codeWithoutWhitespaces))
  ) {
    errors.transponderCode = 'pet.details.form.transponder_code.validation.message';
  }

  return errors;
}

const customValidateForm = (values) => {
  const { transponderCode } = values;
  const codeWithoutWhitespaces = !!transponderCode
    ? transponderCode.replace(whitespaceRegex, '')
    : '';

  let errors = {};

  if (
    !!codeWithoutWhitespaces &&
    (!isNumber(codeWithoutWhitespaces) ||
      !Number.isInteger(Number.parseInt(codeWithoutWhitespaces)) ||
      codeWithoutWhitespaces?.length > 15)
  ) {
    errors.transponderCode = 'pet.details.form.transponder_code.validation.message';
  }

  return errors;
};

function PetDetailsEditor({ data, onSuccess, onError }) {
  const intl = useIntl();

  const [isProcessing, setIsProcessing] = useState(false);
  const [customErrors, setCustomErrors] = useState({});

  const {
    values,
    errors: formErrors,
    isDirty,
    handleChange,
    handleSubmit,
  } = useForm(data, handleFormSubmit, validateForm);

  const { name, transponderCode, ...otherValues } = values;

  const [updatePetDetails] = useMutation(UPDATE_PET_DETAILS, {
    variables: {
      ...otherValues,
      transponderCode: !!transponderCode ? transponderCode.replace(whitespaceRegex, '') : null,
    },
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  React.useEffect(() => {
    const errs = customValidateForm(values);
    setCustomErrors(errs);
  }, [values]);

  async function handleFormSubmit(ev) {
    ev && ev.preventDefault();

    try {
      setIsProcessing(true);

      const res = await updatePetDetails();
      const { data } = res;

      if (data?.updatePetDetails?.status !== 'success')
        throw new Error(data?.updatePetDetails?.errors);

      onSuccess();
    } catch (err) {
      onError();
    } finally {
      setIsProcessing(false);
    }
  }

  const isBtnDisabled =
    !isDirty ||
    isProcessing ||
    Object.keys(formErrors).length > 0 ||
    Object.keys(customErrors)?.length > 0;

  return (
    <StyledForm onSubmit={handleSubmit} novalidate>
      <StyledFormGroup>
        <StyledFormLabel disabled={true} htmlFor="petName">
          <FormattedMessage id="pet.details.form.name.label" />
        </StyledFormLabel>

        <Input
          id="petName"
          name="name"
          placeholder="Luna"
          value={values?.name || ''}
          disabled={true}
        />
      </StyledFormGroup>

      <StyledFormGroup>
        <StyledFormLabel htmlFor="transponderCode">
          <FormattedMessage id="pet.details.form.transponder_code.label" />
        </StyledFormLabel>

        <Input
          type="text"
          id="transponderCode"
          name="transponderCode"
          inputMode="numeric"
          placeholder={intl.formatMessage({ id: 'pet.details.form.transponder_code.placeholder' })}
          value={values?.transponderCode || ''}
          onChange={handleChange}
        />
        {(customErrors?.transponderCode || formErrors?.transponderCode) && (
          <ErrorMessage>
            <FormattedMessage id={customErrors?.transponderCode || formErrors?.transponderCode} />
          </ErrorMessage>
        )}
      </StyledFormGroup>

      <StyledAdditionalDetailsText>
        <FormattedMessage id="pet.details.form.additional.details.line.1" />
      </StyledAdditionalDetailsText>

      <StyledAdditionalDetailsText>
        <FormattedMessage id="pet.details.form.additional.details.line.2" />
      </StyledAdditionalDetailsText>

      <StyledButtonContainer>
        <Button type="submit" variant="primary" disabled={isBtnDisabled}>
          <FormattedMessage id="pet.details.form.submit.button" />
        </Button>
      </StyledButtonContainer>
    </StyledForm>
  );
}

export default PetDetailsEditor;
