import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Container } from '../../../components/common';
import UploadTreatmentDocumentDentolo from '../components/UploadDocument/UploadTreatmentDocumentDentolo';
import SelectDocumentCreationDateDentolo from '../components/SelectDocumentCreationDate/SelectDocumentCreationDateDentolo';
import SelectTreatmentDateDentolo from '../components/SelectTreatmentDate/SelectTreatmentDateDentolo';
import TreatmentAmountDentolo from '../components/TreatmentAmount/TreatmentAmountDentolo';
import DentistDetails from '../components/DentistDetails';
import FillingCount from '../components/FillingCount';
import SubmitClaimFormDentolo from '../components/SubmitClaimForm/SubmitClaimFormDentolo';
import { useReady } from '../../../shared';
import {
  PARAM_CLAIM_ID,
  DENTOLO_EDIT_STEP_UPLOAD_DOCUMENTS,
  DENTOLO_EDIT_STEP_DOCUMENT_DATE,
  DENTOLO_EDIT_STEP_TREATMENT_DATE,
  DENTOLO_EDIT_STEP_TREATMENT_PRICE,
  DENTOLO_EDIT_STEP_DENTIST_DETAILS,
  DENTOLO_EDIT_STEP_FILLING_COUNT,
  DENTOLO_EDIT_STEP_REVIEW,
} from './editClaimFormSteps';

const EditDentoloClaim = ({
  selectedDocuments,
  setSelectedDocuments,
  setClaimSubmitted,
  relevantClaims,
}) => {
  const ready = useReady();
  const { path } = useRouteMatch();

  const relevantClaimsCount = relevantClaims?.length || 0;

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <Route
          render={({ location }) => (
            <Switch location={location}>
              <Route
                exact
                path={path}
                render={({ match }) => {
                  const claimId = match?.params?.claimId;

                  return !isNaN(claimId) ? (
                    <Redirect
                      to={{
                        pathname: DENTOLO_EDIT_STEP_UPLOAD_DOCUMENTS.replace(
                          PARAM_CLAIM_ID,
                          claimId
                        ),
                        search: location.search,
                      }}
                    />
                  ) : (
                    <Redirect to={`/user-claims-info`} />
                  );
                }}
              />

              <Route path={DENTOLO_EDIT_STEP_UPLOAD_DOCUMENTS} exact>
                <UploadTreatmentDocumentDentolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  setClaimSubmitted={setClaimSubmitted}
                  claimsCount={relevantClaimsCount}
                />
              </Route>

              <Route exact path={DENTOLO_EDIT_STEP_DOCUMENT_DATE}>
                <SelectDocumentCreationDateDentolo />
              </Route>

              <Route exact path={DENTOLO_EDIT_STEP_TREATMENT_DATE}>
                <SelectTreatmentDateDentolo />
              </Route>

              <Route exact path={DENTOLO_EDIT_STEP_TREATMENT_PRICE}>
                <TreatmentAmountDentolo />
              </Route>

              <Route exact path={DENTOLO_EDIT_STEP_DENTIST_DETAILS}>
                <DentistDetails />
              </Route>

              <Route exact path={DENTOLO_EDIT_STEP_FILLING_COUNT}>
                <FillingCount />
              </Route>

              <Route exact path={DENTOLO_EDIT_STEP_REVIEW}>
                <SubmitClaimFormDentolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  setClaimSubmitted={setClaimSubmitted}
                />
              </Route>
            </Switch>
          )}
        />
      </Container>
    </CSSTransition>
  );
};

export default EditDentoloClaim;
