import React from 'react';
import useFrontendBranchName from '../../hooks/useFrontendBranchName';
import useBackendBranchName from '../../hooks/useBackendBranchName';
import { StyledBranchName } from './Footer.styled';

export default function BetaBranchName() {
  const frontendBranchName = useFrontendBranchName();
  const backendBranchName = useBackendBranchName();

  if (frontendBranchName || backendBranchName) {
    return (
      <StyledBranchName>
        {!!frontendBranchName ? <>Frontend Branch: {frontendBranchName}</> : null}
        <br />
        {!!backendBranchName ? <>Backend Branch: {backendBranchName}</> : null}
      </StyledBranchName>
    );
  } else {
    return <></>;
  }
}
