import {
  imgLinks,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
} from '../../shared';

export const getInsCategoryImg = (insCategory) => {
  let img = 'icon.menu.green.tooth';
  if (
    insCategory === INSURANCE_CATEGORY_PET_HEALTH ||
    insCategory === INSURANCE_CATEGORY_PET_LIABILITY
  ) {
    img = 'icon.menu.orange.paw';
  }
  if (insCategory === INSURANCE_CATEGORY_DENTAL) {
    img = 'icon.menu.green.tooth';
  }
  return imgLinks[img];
};
