import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useInsuranceType } from '../../hooks/useInsuranceType';
import BetaBranchName from './BetaBranchName';
import {
  getDateProtectionLink,
  getFooterCopyright,
  getImprintLink,
  getSupportLink,
} from './footerUtils';
import {
  StyledFooterContainer,
  StyledFooterCopyright,
  StyledFooterLinkSeparator,
  StyledFooterLinksWrapper,
  StyledFooterWrapper,
} from './Footer.styled';

const Footer = () => {
  const insuranceType = useInsuranceType();
  const footerCopyrights = getFooterCopyright(insuranceType);
  const imprintLink = getImprintLink(insuranceType);
  const dateProtectionLink = getDateProtectionLink(insuranceType);
  const supportLink = getSupportLink(insuranceType);

  return (
    <StyledFooterContainer>
      <StyledFooterWrapper>
        <StyledFooterLinksWrapper>
          <a href={imprintLink} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="common.title.imprint" />
          </a>

          <StyledFooterLinkSeparator>|</StyledFooterLinkSeparator>

          <a href={dateProtectionLink} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="common.title.data.protection" />
          </a>

          <StyledFooterLinkSeparator>|</StyledFooterLinkSeparator>

          {/* eslint-disable-next-line */}
          <a
            href="#"
            onClick={() => {
              if (!!window.UC_UI) {
                window.UC_UI.showSecondLayer();
              }
            }}
          >
            <FormattedMessage id="common.title.cookie.settings" />
          </a>

          <StyledFooterLinkSeparator>|</StyledFooterLinkSeparator>

          <a href={supportLink} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="common.title.help" />
          </a>
        </StyledFooterLinksWrapper>

        <StyledFooterCopyright>{footerCopyrights}</StyledFooterCopyright>
      </StyledFooterWrapper>

      <BetaBranchName />
    </StyledFooterContainer>
  );
};

export default Footer;
