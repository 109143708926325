import React from 'react';
import PropTypes from 'prop-types';
import OnboardCard from './OnboardCard';
import { imgLinks } from '../../../shared';
import {
  StyledOnboardCardParent,
  StyledOnboardCard2Wrapper,
  StyledShieldImage,
  StyledHandImage,
} from './HomeOnboardPetolo.styled';
const HomeOnboardCard2 = ({ titleHtml, bodyText }) => {
  return (
    <OnboardCard titleHtml={titleHtml} bodyText={bodyText}>
      <StyledOnboardCardParent>
        <StyledOnboardCard2Wrapper>
          <StyledShieldImage src={imgLinks['icon.petolo.shield.orange']} alt="" />
          <StyledHandImage src={imgLinks['icon.petolo.hand.money']} alt="" />
        </StyledOnboardCard2Wrapper>
      </StyledOnboardCardParent>
    </OnboardCard>
  );
};

HomeOnboardCard2.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
};

export default HomeOnboardCard2;
