import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import Honeybadger from "@honeybadger-io/js";
import {
  LOCAL_NAV_MENU,
  LOCAL_SHOW_ONBOARDS,
  LOCAL_CONFIRM_EMAIL,
  DENTOLO_DOMAIN,
  graphQLEndpointDentolo,
  graphQLEndpointPetolo,
  APP_ENV,
  NODE_ENV,
} from "./shared";

const honeyBadgerConfig = {
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: APP_ENV || NODE_ENV,
  reportData: false,
  disabled: window.location.hostname === "localhost",
};
export const honeybadger = Honeybadger.configure(honeyBadgerConfig);

export const INVALID_TOKEN = "invalidToken";
export const LOGGED_OUT = "loggedOut";
export const errorsMap = new Map([
  [INVALID_TOKEN, "Token is invalid"],
  [LOGGED_OUT, "You must be logged in"],
]);

const cache = new InMemoryCache();

cache.writeQuery({
  query: LOCAL_NAV_MENU,
  data: {
    showNavMenu: false,
  },
});

cache.writeQuery({
  query: LOCAL_CONFIRM_EMAIL,
  data: {
    confirmEmail: "",
  },
});

cache.writeQuery({
  query: LOCAL_SHOW_ONBOARDS,
  data: {
    showOnboards: [
      {
        name: "user-claims-onboard",
        show: false,
        __typename: "user-claims-onboard",
      },
      {
        name: "home-onboard",
        show: false,
        __typename: "home-onboard",
      },
      {
        name: "petolo-home-onboard",
        show: false,
        __typename: "petolo-home-onboard",
      },
    ],
  },
});

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, path }) => {
        // Ignore the errors for queries used for login, log errors for others
        if (![...errorsMap.values()].includes(message)) {
          const errorLog = `[GraphQL error]: Message: ${message}, Path: ${path}`;
          honeybadger.notify(errorLog);
        }
      });
    }
    if (networkError) {
      honeybadger.notify(networkError);
    }
  }),
  createUploadLink({
    uri: window.location.host.includes(DENTOLO_DOMAIN)
      ? graphQLEndpointDentolo
      : graphQLEndpointPetolo,
    credentials: "include",
  }),
]);

const resolvers = {
  Mutation: {
    toggleNavMenu(_, _variables, { cache }) {
      const { showNavMenu } = cache.readQuery({ query: LOCAL_NAV_MENU });

      cache.writeQuery({
        query: LOCAL_NAV_MENU,
        data: { showNavMenu: !showNavMenu },
      });
    },
    setConfirmEmail(_, variables, { cache }) {
      cache.writeQuery({
        query: LOCAL_CONFIRM_EMAIL,
        data: { confirmEmail: variables.input.email },
      });
    },
    toggleShowOnboard(_, variables, { cache }) {
      const { showOnboards } = cache.readQuery({ query: LOCAL_SHOW_ONBOARDS });

      const filtered = showOnboards.filter((item) => item.name !== variables.name);

      cache.writeQuery({
        query: LOCAL_CONFIRM_EMAIL,
        data: {
          showOnboards: [
            ...filtered,
            {
              name: variables.name,
              show: variables.show,
              __typename: variables.name,
            },
          ],
        },
      });
    },
  },
};

export const apolloClient = new ApolloClient({
  link,
  cache,
  resolvers,
});
