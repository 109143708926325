export const baseUrl = process.env.REACT_APP_BASE_URL;
export const graphQLEndpointDentolo = process.env.REACT_APP_GRAPHQL_ENDPOINT_DENTOLO;
export const graphQLEndpointPetolo = process.env.REACT_APP_GRAPHQL_ENDPOINT_PETOLO;
export const helpCenterLink = `//support.dentolo.de/hc/de`;
export const petHelpCenterLink = `//support.petolo.de/hc/de`;

export const helpCenterLinkCancellation =
  '//support.dentolo.de/hc/de/articles/360020463380-Wie-kann-ich-meinen-Zahnschutz-widerrufen-k%C3%BCndigen-?utm_source=account_area&utm_medium=contact&utm_campaign=cancellation_withdrawal';
export const googleMapsKey = `AIzaSyCX-qPHe8YYr7O0tNfrqNTIawhQ_2eW6TA`;

export const PETOLO_IMPRINT_LINK = 'https://www.petolo.de/impressum';
export const PETOLO_DATE_PROTECT_LINK = 'https://www.petolo.de/datenschutz';
export const PETOLO_HELP_LINK = 'https://petolo.zendesk.com/hc/de';

export const DENTOLO_IMPRINT_LINK = 'https://dentolo.de/impressum';
export const DENTOLO_DATE_PROTECT_LINK = 'https://dentolo.de/datenschutz';

export const PETOLO_REFERRAL_URL = process.env.REACT_APP_PETOLO_REFERRAL_URL;
export const PETOLO_INSURE_ANOTHER_PET_URL = process.env.REACT_APP_PETOLO_INSURE_ANOTHER_PET_URL;
export const DENTOLO_REFERRAL_URL = process.env.REACT_APP_DENTOLO_REFERRAL_URL;
export const PETOLO_REFERRAL_TERMS_LINK =
  'https://www.petolo.de/freunde-werben-teilnahmebedingungen';
export const DENTOLO_REFERRAL_TERMS_LINK =
  'https://versicherung.dentolo.de/freunde-werben-teilnahmebedingungen/';
