import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import {
  GET_CUSTOMER,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  SIGN_OUT,
  useReady,
} from '../../shared';
import { DECLINED, PENDING } from '../../shared/contractStatus';
import IllustrationDesktopDentolo from './IllustrationDesktopDentolo';
import IllustrationDesktopPetolo from './IllustrationDesktopPetolo';
import IllustrationMobileDentolo from './IllustrationMobileDentolo';
import IllustrationMobilePetolo from './IllustrationMobilePetolo';
import { Container, PageTitle, Paper } from '../../components/common';
import { FormattedMessage } from 'react-intl';
import {
  StyledIllustrationContainerMobile,
  StyledIllustrationContainerDesktop,
  StyledContractPendingApprovalContent,
  StyledContractPendingApprovalButton,
} from './ContractPendingApproval.styled';
import { useInsuranceType } from '../../hooks/useInsuranceType';

const ContractNotApprovedPageTitle = ({ insuranceType }) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return <FormattedMessage id="contract_not_approved.title.dentolo" />;
    case INSURANCE_TYPE_PETOLO:
      return <FormattedMessage id="contract_not_approved.title.petolo" />;
    default:
      return <FormattedMessage id="contract_not_approved.title.dentolo" />;
  }
};

const DesktopIllustration = ({ insuranceType }) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return <IllustrationDesktopDentolo />;
    case INSURANCE_TYPE_PETOLO:
      return <IllustrationDesktopPetolo />;
    default:
      return <IllustrationDesktopDentolo />;
  }
};

const MobileIllustration = ({ insuranceType }) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return <IllustrationMobileDentolo />;
    case INSURANCE_TYPE_PETOLO:
      return <IllustrationMobilePetolo />;
    default:
      return <IllustrationMobileDentolo />;
  }
};

export default function ContractPendingApproval() {
  let history = useHistory();
  const ready = useReady();

  const insuranceType = useInsuranceType();

  const { loading: customerLoading, data: customerData } = useQuery(GET_CUSTOMER);
  const contractStatus = customerData?.customer?.contract?.status;

  const [signOut] = useMutation(SIGN_OUT, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  useEffect(() => {
    if (!customerLoading) {
      if (contractStatus === DECLINED) {
        signOut().then(() => history.push('/login?invalid=true'));
      } else if (contractStatus !== PENDING) {
        history.push('/');
      }
    }
  }, [history, signOut, contractStatus, customerLoading]);

  return (
    <CSSTransition
      in={ready && !customerLoading}
      timeout={600}
      classNames="slow-fade"
      unmountOnExit
    >
      <Container>
        <PageTitle>
          <ContractNotApprovedPageTitle insuranceType={insuranceType} />
        </PageTitle>

        <StyledIllustrationContainerDesktop>
          <DesktopIllustration insuranceType={insuranceType} />
        </StyledIllustrationContainerDesktop>

        <StyledIllustrationContainerMobile>
          <MobileIllustration insuranceType={insuranceType} />
        </StyledIllustrationContainerMobile>

        <Paper>
          <StyledContractPendingApprovalContent>
            <FormattedMessage id="contract_not_approved.text_1" />
          </StyledContractPendingApprovalContent>

          <StyledContractPendingApprovalContent style={{ fontWeight: '700' }}>
            <FormattedMessage id="contract_not_approved.text_2" />
          </StyledContractPendingApprovalContent>

          <StyledContractPendingApprovalButton
            type="submit"
            variant="primary"
            onClick={() => history.push('/')}
          >
            <FormattedMessage id="contract_not_approved.button" />
          </StyledContractPendingApprovalButton>
        </Paper>
      </Container>
    </CSSTransition>
  );
}
