import styled, { css } from 'styled-components';

import { color } from '../../../theme/functions';

import { Paper } from '../../../components/common';

export const StyledPromotionCarousel = styled(Paper)`
  margin-bottom: 1.5rem;
`;

export const StyledPromotionCarouselControls = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledPromotionCarouselActionDotsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
`;

export const StyledPromotionCarouselActionDots = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: transparent;
  border: 1px solid ${color('main_blue')};
  border-radius: 50%;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background-color: ${color('main_blue')};
      pointer-events: none;
      cursor: default;
    `}
`;
