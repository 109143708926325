import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PET_LIABILITY_STEP_DAMAGE_CAUSED_BY,
  PET_LIABILITY_STEP_INCIDENT_LOCATION,
} from '../../new/newClaimsFormSteps';

export const useIncidentDateTimePetLiabilityBackButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_DAMAGE_CAUSED_BY);
};

export const useIncidentDateTimePetLiabilityNextButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  const nextButtonLink = PET_LIABILITY_STEP_INCIDENT_LOCATION;
  return withSource(nextButtonLink);
};
