import React from 'react';
import { useInsuranceType } from '../../hooks/useInsuranceType';
import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO } from '../../shared';
import Dentolo404Page from './Dentolo404Page/Dentolo404Page';
import Petolo404Page from './Petolo404Page/Petolo404Page';

export default function PageNotFound() {
  const insuranceType = useInsuranceType();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return <Dentolo404Page />;
    case INSURANCE_TYPE_PETOLO:
      return <Petolo404Page />;
    default:
      return <></>;
  }
}
