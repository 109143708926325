import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  DENTOLO_STEP_TREATMENT_CATEGORY,
  DENTOLO_STEP_UPLOAD_DOCUMENTS,
} from '../../new/newClaimsFormSteps';

export const useSelectDocumentTypeDentoloBackButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();
  return withSource(DENTOLO_STEP_TREATMENT_CATEGORY);
};

export const useSelectDocumentTypeDentoloNextButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();
  return withSource(DENTOLO_STEP_UPLOAD_DOCUMENTS);
};
