import React from 'react';

import {
  PET_LIABILITY_AFFECTED_PARTY,
  PET_LIABILITY_MEDICAL_EXAM,
  PET_LIABILITY_MEDICAL_EXAM_NO,
  PET_LIABILITY_MEDICAL_EXAM_YES,
  PET_LIABILITY_REASON,
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_PERSON,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useMedicalExamPetLiabilityBackButtonLink,
  useMedicalExamPetLiabilityNextButtonLink,
} from './useMedicalExamPetLiability';

import ListWithRadioButton from '../common/ListWithRadioButton';

const medicalExamConfig = [
  {
    label: 'pet_liability.new_claim.medical_exam.yes',
    value: PET_LIABILITY_MEDICAL_EXAM_YES,
  },
  {
    label: 'pet_liability.new_claim.medical_exam.no',
    value: PET_LIABILITY_MEDICAL_EXAM_NO,
  },
];

export default function MedicalExamPetLiability() {
  const [liabilityReason] = useNewPetLiabilityFormDataValue(PET_LIABILITY_REASON);

  const [affectedParty] = useNewPetLiabilityFormDataValue(PET_LIABILITY_AFFECTED_PARTY);

  const [medicalExam, setMedicalExam] = useNewPetLiabilityFormDataValue(PET_LIABILITY_MEDICAL_EXAM);

  const backButtonLink = useMedicalExamPetLiabilityBackButtonLink(affectedParty);
  const nextButtonLink = useMedicalExamPetLiabilityNextButtonLink();

  const pageTitleId =
    liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL
      ? 'pet_liability.new_claim.medical_exam.page_title_animal'
      : liabilityReason === PET_LIABILITY_REASON_PERSON
      ? 'pet_liability.new_claim.medical_exam.page_title_person'
      : '';

  return (
    <ListWithRadioButton
      name="reason_pet_liability"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId={pageTitleId}
      listConfig={medicalExamConfig}
      selected={medicalExam}
      setSelected={setMedicalExam}
    />
  );
}
