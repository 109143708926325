import styled from 'styled-components';

import { min_width_md } from '../../../theme/breakpoints';

import { ButtonContainer, Separator } from '../../../components/common';
import { Button } from '../../../components/ui';

export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 1.5em;
`;

export const StyledCancellationReasonsTitle = styled.h1`
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;

  @media (min-width: ${min_width_md}) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const StyledCancellationReasonsDescription = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 1em;

  @media (min-width: ${min_width_md}) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const StyledSeparator = styled(Separator)`
  margin: 1.5em 0;
`;

export const StyledButtonContainer = styled(ButtonContainer)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-flow: column-reverse;

  @media (min-width: ${min_width_md}) {
    flex-direction: row;
    flex-flow: row;
  }
`;

export const StyledButton = styled(Button)`
  margin: 0.5em auto;
  width: fit-content;

  @media (min-width: ${min_width_md}) {
    margin: 0.5em;
    width: auto;
  }
`;

export const StyledRadioContainer = styled.div`
  margin-top: 1.5rem;
`;

export const StyledReason = styled.div`
  display: flex;
  word-break: break-word;
  margin-bottom: 2em;
`;
