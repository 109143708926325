import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PET_LIABILITY_STEP_LIABILITY_REASON,
  PET_LIABILITY_STEP_INCIDENT_DATE_TIME,
} from '../../new/newClaimsFormSteps';

export const useDamageCausedByPetLiabilityBackButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_LIABILITY_REASON);
};

export const useDamageCausedByPetLiabilityNextButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  const nextButtonLink = PET_LIABILITY_STEP_INCIDENT_DATE_TIME;
  return withSource(nextButtonLink);
};
