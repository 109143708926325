const baseUrl = process.env.REACT_APP_POLICY_DETAILS_ENDPOINT

export const fetchPolicyCoverageInfo = (poicyCategoryId, imCoverage, locale) => {
  const imCovergaeSuffix = imCoverage ? '_im_coverage' : '';
  const fileName = `policy_category_${poicyCategoryId}${imCovergaeSuffix}.json`

  return fetch(`${baseUrl}/${locale}/${fileName}`)
    .then(response => response.json())
    .catch(error => {
      window.onerror(error)
      return {}
    })
}