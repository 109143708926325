import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { InfoWindow } from './ui';
import { BackButton, Container, PageTitle } from './common';

const ContactStatus = () => {
  const { status } = useParams();
  const intl = useIntl();

  const messageSent = intl.formatMessage({ id: 'contact.page.main.sent' });
  const registerViaEmail = intl.formatMessage({ id: 'contact.page.main.register.email' });
  const backToStartPage = intl.formatMessage({ id: 'contact.page.main.back' });
  const failedToSend = intl.formatMessage({ id: 'contact.page.main.fail' });
  const cancelSending = intl.formatMessage({ id: 'contact.page.main.cancel' });
  const tryAgain = intl.formatMessage({ id: 'contact.page.main.try.again' });

  const successButtonsConfig = {
    text: backToStartPage,
    to: '/',
  };
  const failureButtonsConfig = [
    {
      text: cancelSending,
      to: '/',
      style: 'outline',
    },
    {
      text: tryAgain,
      to: '/contact',
      style: 'primary',
    },
  ];

  return (
    <Container>
      <BackButton to="/contact" />

      <PageTitle>
        <FormattedMessage id="contact.page.main.form.message" />
      </PageTitle>

      {status === 'success' ? (
        <InfoWindow
          mode="success"
          titleHtml={messageSent}
          textHtml={registerViaEmail}
          button={successButtonsConfig}
        />
      ) : (
        <InfoWindow mode="failure" titleHtml={failedToSend} buttons={failureButtonsConfig} />
      )}
    </Container>
  );
};

export default ContactStatus;
