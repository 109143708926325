import {
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_PERSON,
} from '../../claimFormData/newClaimFormDataPetLiability';
import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PET_LIABILITY_STEP_ACCOMPANIED_BY,
  PET_LIABILITY_STEP_AFFECTED_PARTY,
  PET_LIABILITY_STEP_DAMAGED_ITEM,
} from '../../new/newClaimsFormSteps';

export const useIncidentNotePetLiabilityBackButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_ACCOMPANIED_BY);
};

export const useIncidentNotePetLiabilityNextButtonLink = (liabilityReason) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  const nextButtonLink =
    liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL ||
    liabilityReason === PET_LIABILITY_REASON_PERSON
      ? PET_LIABILITY_STEP_AFFECTED_PARTY
      : PET_LIABILITY_STEP_DAMAGED_ITEM;
  return withSource(nextButtonLink);
};
