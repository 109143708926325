import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { max_width_sm, min_width_lg } from '../../theme/breakpoints';
import { color, height } from '../../theme/functions';

export const StyledHeader = styled.header`
  position: relative;
  width: 100%;
  height: ${height('headerDesktop')};
  z-index: 1000;
`;

export const StyledHeaderSticky = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${height('headerDesktop')};
  background-color: ${color('white')};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 99;
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  margin: 0 auto;

  @media (min-width: ${max_width_sm}) {
    max-width: 720px;
  }
  @media (min-width: ${min_width_lg}) {
    max-width: 960px;
  }
`;
/* @media (min-width: ${min_width_sm}) {
    max-width: 440px;
  } */
/* @media (min-width: 576px) {
    max-width: 540px;
  } */

export const StyledHeaderLogoLinks = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.5rem;

  img {
    transition: all 0.3s;
  }
`;

export const StyledCompanyLogoWrapper = styled(Link)`
  opacity: 1;
  transition: 0.6s opacity 0.2s ease-out;
`;

export const StyledDaDirektLogo = styled.img`
  width: 5rem;
  height: auto;
`;

export const StyledHeaderBurgerMenu = styled.div`
  position: relative;
  z-index: 99;
  cursor: pointer;

  @media (min-width: ${max_width_sm}) {
    display: none;
  }
`;

export const StyledHeaderBurgerMenuButton = styled.div`
  padding: 0.25rem 0.2rem;
  width: 2rem;
  height: 2rem;
  background-color: ${color('white')};
  border: 2px solid ${color('light_gray')};
  border-radius: 0.125rem;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

export const StyledHeaderBurgerMenuLine = styled.div`
  width: 100%;
  height: 0.25rem;
  background-color: ${color('main_blue')};
  transition: opacity 0.1s linear, transform 0.3s ease-in-out;

  & + & {
    margin-top: 0.25rem;
  }

  ${({ open }) =>
    open &&
    css`
      &:first-child {
        transform: rotate(45deg) translate3d(5px, 5px, 0);
      }

      &:nth-child(2) {
        opacity: 0;
        transition-delay: 0.1s;
      }

      &:last-child {
        transform: rotate(-45deg) translate3d(6px, -6px, 0);
      }
    `}
`;
