import styled, { css } from 'styled-components';

import { min_width_md } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

import { Paper } from '../common';
import { navMenuUnderlineBaseStyle } from '../NavMenu/styled/NavMenuDesktop.styled';

export const StyledDownloadContributionConfirmationWrapper = styled(Paper)`
  margin-top: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;
  color: ${color('main_blue')};

  ${({ hasContribution }) =>
    !hasContribution &&
    css`
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      text-align: center;
    `};
`;

export const PaymentOverviewHelpContent = styled(Paper)`
  margin-top: 1.25rem;
  text-align: right;

  > h4 {
    margin-bottom: 1.5rem;
    color: ${color('white')};
    text-align: left;
  }
`;

export const PaymentOverviewHelpCenterLink = styled.a`
  position: relative;
  font-size: 1rem;
  color: ${color('white')};
  text-align: right;
  text-decoration: none;
  cursor: pointer;

  h4 {
    color: ${color('white')};
  }

  ${navMenuUnderlineBaseStyle};
  &::after {
    background-color: ${color('white')};
    bottom: 0;
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &:hover,
  &:focus {
    &::after {
      transform: scaleX(0);
      transform-origin: bottom right;
    }
  }

  @media (min-width: ${min_width_md}) {
    font-size: 1.25rem;
  }
`;
