import React from 'react';
import styled from 'styled-components';

import { color } from '../../../../theme/functions';

import { Input as InputField } from '../../../../components/common';

const StyledInputContainer = styled.div`
  position: relative;
`;

const StyledInput = styled(InputField)`
  padding-right: 2.5rem;
`;

const StyledSuffix = styled.span`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  font-size: 1rem;
  font-weight: 450;
  color: ${color('main_blue')};
`;

export const Input = (props) => {
  const { suffix, ...rest } = props;

  return (
    <StyledInputContainer>
      <StyledSuffix>{suffix}</StyledSuffix>
      <StyledInput {...rest} />
    </StyledInputContainer>
  );
};
