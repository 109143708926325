import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import { formatDate, GET_VIDEO_CONSULTATIONS } from "../../../../shared";
import { PETOLO_NO_SUITABLE_VIDEO_CONSULTATION } from "../../claimFormData/newClaimFormData";

export function useSelectableVideoConsultationsList() {
  const intl = useIntl();

  const defaultConsultationOption = {
    id: PETOLO_NO_SUITABLE_VIDEO_CONSULTATION,
    label: intl.formatMessage({ id: 'petolo.new_claim.select_video_consultation.option.default.label' }),
  };

  const { data: videoConsultationsData } = useQuery(GET_VIDEO_CONSULTATIONS);
  const videoConsultations = videoConsultationsData?.videoConsultations;

  if (Array.isArray(videoConsultations)) {
    const selectableVideoConsultations = videoConsultations.reduce((agg, next) => {
      const videoConsulationOption = {
        id: `${next?.id}`,
        label: next?.dateTime ? `${formatDate(next.dateTime, 'DD.MM.YYYY - HH:mm')} Uhr` : '',
      };
      return [videoConsulationOption, ...agg];
    }, [defaultConsultationOption]);

    return selectableVideoConsultations;
  } else {
    return [];
  }
}