import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import AsyncCreatable from 'react-select/async-creatable';
import PropTypes from 'prop-types';

import { useFuse } from '../../hooks/useFuse';
import { customSelectStyles } from './Select';

const FuzzySelect = (props) => {
  const { options, fuzzyOptions, searchTextCleaner, ...rest } = props;

  const fuse = useFuse(options, fuzzyOptions);

  //# search with promise
  const searchOptions = (inputValue) =>
    new Promise((resolve) => {
      if (!inputValue) return;

      resolve(fuse.search(inputValue).map((c) => ({ ...c.item })));
    });

  //# call promise searchOptions
  const loadOptions = (searchTerm) => {
    const cleanedSearchTerm =
      typeof searchTextCleaner === 'function' ? searchTextCleaner(searchTerm) : searchTerm;

    return searchOptions(cleanedSearchTerm);
  };

  const themeContext = useContext(ThemeContext);
  const { theme } = themeContext || {};

  const customStylesForSelect = customSelectStyles(theme);

  return (
    <AsyncCreatable
      styles={customStylesForSelect}
      defaultOptions={options}
      {...rest}
      loadOptions={loadOptions}
    />
  );
};

export { FuzzySelect };

FuzzySelect.propTypes = {
  options: PropTypes.array,
  fuzzyOptions: PropTypes.object.isRequired,
  searchTextCleaner: PropTypes.func,
};
