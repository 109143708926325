import styled from 'styled-components';

import { THEME_PETOLO } from '../../shared';
import { color } from '../../theme/functions';

export const StyledFooterContainer = styled.footer`
  flex-shrink: 0;
  padding: 1.5rem 1rem;
  background-color: ${({ theme: { name } }) =>
    color(name === THEME_PETOLO ? 'ultra_light_orange' : 'main_blue')};
`;

export const StyledFooterWrapper = styled.div`
  max-width: 960px;
  margin: auto;
`;

export const StyledFooterLinksWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  font-size: 1rem;
  font-weight: 450;

  > a {
    position: relative;
    color: ${color('secondary')};
    text-decoration: none;
    transition: color 0.2s ease-out;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: ${({ theme: { name } }) =>
        color(name === THEME_PETOLO ? 'main_orange' : 'clear_light_blue')};
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.2s ease-out;
    }

    &:hover,
    &:active,
    &:focus {
      color: ${({ theme: { name } }) =>
        color(name === THEME_PETOLO ? 'main_orange' : 'clear_light_blue')};
    }

    &:active,
    &:focus {
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
`;

export const StyledFooterLinkSeparator = styled.span`
  margin: 0 1rem;
  font-size: 1.25rem;
  color: ${color('secondary')};
`;

export const StyledFooterCopyright = styled.div`
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 450;
  color: ${({ theme: { name } }) => color(name === THEME_PETOLO ? 'main_orange' : 'white')};
`;

export const StyledBranchName = styled.div`
  margin-top: 1em;
  color: #999;
  text-align: right;
`;
