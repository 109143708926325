import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_CUSTOMER, INSURANCE_CATEGORY_PET_HEALTH } from '../../shared';
import Fressnapf from '../Fressnapf';
import Petolo404Page from '../../features/404/Petolo404Page/Petolo404Page';

const OnlineVets = () => {
  const { data: customerData } = useQuery(GET_CUSTOMER);
  const customerInsuranceCategory = customerData.customer?.insuranceCategory;

  if (customerInsuranceCategory === INSURANCE_CATEGORY_PET_HEALTH) {
    return <Fressnapf />;
  } else {
    return <Petolo404Page />;
  }
};

export default OnlineVets;
