import styled from 'styled-components';

import { min_width_md } from '../../../theme/breakpoints';

import { BackButton } from '../../../components/common';
import { color } from '../../../theme/functions';

export const StyledButtonContainer = styled.div`
  margin-top: 2em;
  text-align: center;
  display: none;

  > button {
    margin: auto;
  }

  @media only screen and (min-width: ${min_width_md}) {
    display: block;
  }
`;

export const StyledImg = styled.img`
  width: 100%;
  margin-left: 0;
  margin-top: 5em;
  margin-bottom: 1em;

  @media only screen and (min-width: ${min_width_md}) {
    margin-left: -1em;
    margin-top: 0;
  }
`;
export const StyledBackButton = styled(BackButton)`
  display: flex;
  @media only screen and (min-width: ${min_width_md}) {
    display: none;
  }
`;
export const StyledText = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: ${color('main_blue')} !important;
  @media only screen and (min-width: ${min_width_md}) {
    font-size: 30px;
    font-weight: 700;
  }
`;
