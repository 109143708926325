import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import {
  StyledNavMenuAccordion,
  StyledNavMenuAccordionContent,
  StyledNavMenuAccordionToggle,
  StyledNavMenuAccordionToggleArrowIcon,
  StyledNavMenuAccordionToggleLabel,
  StyledUserContainer,
} from './styled/NavMenuAccordion.styled';

function NavMenuAccordionItem(props) {
  const {
    children,
    title,
    collapsible,
    active,
    customLogo,
    type,
    onExpand,
    onCollapse,
    onTitleClick,
  } = props;

  const [open, setOpen] = useState(false);

  const onContentToggle = () => {
    if (collapsible) {
      setOpen(!open);
    }

    onTitleClick();
  };

  return (
    <StyledNavMenuAccordion type={type} expanded={open}>
      <StyledNavMenuAccordionToggle onClick={onContentToggle} expanded={open} active={active}>
        <StyledUserContainer>
          <>{customLogo}</>
          <StyledNavMenuAccordionToggleLabel>{title}</StyledNavMenuAccordionToggleLabel>
        </StyledUserContainer>

        {collapsible && (
          <StyledNavMenuAccordionToggleArrowIcon
            icon="icon.menu.accordion.arrow.down"
            $rotate={open}
          />
        )}
      </StyledNavMenuAccordionToggle>

      <CSSTransition
        in={open}
        timeout={350}
        onEnter={(ev) => onExpand(ev)}
        onExit={(ev) => onCollapse(ev)}
        classNames="fade-in"
        unmountOnExit
      >
        <StyledNavMenuAccordionContent>{children}</StyledNavMenuAccordionContent>
      </CSSTransition>
    </StyledNavMenuAccordion>
  );
}

export default NavMenuAccordionItem;

NavMenuAccordionItem.defaultProps = {
  title: null,
  collapsible: true,
  onExpand: () => {},
  onCollapse: () => {},
  onTitleClick: () => {},
};
