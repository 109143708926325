import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PETOLO_STEP_CUSTOMER_NOTE,
  PETOLO_STEP_REVIEW,
  PETOLO_STEP_TREATMENT_PRICE,
  PETOLO_STEP_VIDEO_CONSULTATION,
} from '../../new/newClaimsFormSteps';
import {
  PETOLO_LINK_VIDEO_CONSULTATION_YES,
  PETOLO_TREATMENT_CATEGORY_OTHERS,
} from '../../claimFormData/newClaimFormData';

export const useLinkVideoConsultationQuestionBackButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();
  return withSource(PETOLO_STEP_TREATMENT_PRICE);
};

export const useLinkVideoConsultationQuestionNextButtonLink = (
  linkVideoConsultationAnswer,
  treatmentCategories
) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  if (linkVideoConsultationAnswer === PETOLO_LINK_VIDEO_CONSULTATION_YES) {
    return withSource(PETOLO_STEP_VIDEO_CONSULTATION);
  } else {
    if (
      Array.isArray(treatmentCategories) &&
      treatmentCategories.length === 1 &&
      treatmentCategories[0] !== PETOLO_TREATMENT_CATEGORY_OTHERS
    ) {
      return withSource(PETOLO_STEP_REVIEW);
    } else {
      return withSource(PETOLO_STEP_CUSTOMER_NOTE);
    }
  }
};
