import styled from 'styled-components';

import { Button } from '../Button';

import { color } from '../../../theme/functions';
import { min_width_lg, min_width_md } from '../../../theme/breakpoints';

export const StyledAccordionToggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAccordionTitle = styled.h3`
  font-size: 0.875rem;
  color: ${color('main_blue')};

  @media (min-width: ${min_width_md}) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const StyledAccordionButton = styled(Button)`
  font-size: 0.8125rem;
  font-weight: 300;
  line-height: 1.25rem;
  text-decoration: underline;

  @media (min-width: ${min_width_lg}) {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const StyledAccordionContent = styled.div`
  margin-top: 2rem;
`;
