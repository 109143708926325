//# Add slides/cards for the `PromotionCarousel` in this file

import {
  DA_DIREKT_POLICY_CATEGORIES,
  DA_DIREKT_PET_POLICY_CATEGORIES,
  DENTOLO_PET_LIABILITY_POLICY_CATEGORIES,
} from '../../../shared';

import { ReferralLinkCardDentolo } from '../ReferralLinkCard/ReferralLinkCardDentolo';
import { ReferralLinkCardPetolo } from '../ReferralLinkCard/ReferralLinkCardPetolo';
// import { CarInsuranceCard } from '../CarInsuranceCard/CarInsuranceCard';  use this for car insurance referall

//# `hidden` property is used to hide the card from policy categories in its value

//# DENTOLO
export const promotionSlidesDentolo = [
  {
    id: 'link-referral-dentolo',
    card: <ReferralLinkCardDentolo />,
    hidden: [...DA_DIREKT_POLICY_CATEGORIES],
  },
  // {
  //   // to be removed after 30th of November 2022
  //   id: 'link-referral-dentolo-car-insurance',
  //   card: <CarInsuranceCard />,
  //   hidden: [...DA_DIREKT_PET_POLICY_CATEGORIES, ...DENTOLO_PET_HEALTH_POLICY_CATEGORIES],
  // },
];

//# PETOLO
//# TODO: add content after promotions for `PETOLO` is ready
// empty array will not render the carousel
export const promotionSlidesPetolo = [
  {
    id: 'link-referral-petolo',
    card: <ReferralLinkCardPetolo />,
    hidden: [...DA_DIREKT_PET_POLICY_CATEGORIES, ...DENTOLO_PET_LIABILITY_POLICY_CATEGORIES],
  },
];
