import styled from 'styled-components';
import { max_width_xs, min_width_lg, min_width_md } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

import { Button } from '../ui';
import { StyledOnlineVetSlider } from '../OnlineVets/OnlineVets.styled';

export const StyledFressnapfSlideContainer = styled.div`
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto;

  @media (min-width: ${min_width_lg}) {
    width: 60rem;
  }
`;

export const StyledDesktopSlidesContainer = styled.div`
  display: none;
  margin-top: 5em;

  @media only screen and (min-width: ${min_width_md}) {
    display: flex;
    justify-content: center;
    align-items: stretch;
    column-gap: 2rem;
  }
`;

export const StyledDesktopSlideContainer = styled.div`
  position: relative;
  text-align: center;
  background-color: ${color('white')};
  border-radius: 2em;

  padding: 2.5rem 1rem 1.5rem;
`;

export const StyledFressnapfMobileButton = styled(Button)`
  display: block;
  margin-top: 1.5em;
  margin-bottom: 1.5em;

  @media only screen and (min-width: ${min_width_md}) {
    display: none;
  }
`;

export const StyledFressnapfDesktopButton = styled(Button)`
  display: none;
  margin: 3em auto;

  @media only screen and (min-width: ${min_width_md}) {
    display: block;
  }
`;

export const StyledMobileSlidesContainer = styled(StyledOnlineVetSlider)`
  @media only screen and (max-width: ${max_width_xs}) {
    margin-bottom: 4.5em;
  }

  @media only screen and (min-width: ${min_width_md}) {
    display: none;
  }
`;
