import { useEffect } from "react"
import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO, THEME_DENTOLO, THEME_PETOLO } from "../shared"
import { useInsuranceType } from "./useInsuranceType"

const getFavIcon = (theme) => {
  switch (theme) {
    case THEME_DENTOLO:
      return '/favicon/favicon.ico'
    case THEME_PETOLO:
      return '/favicon/favicon-petolo.ico'
    default:
      return ''
  }
}

const updateFavicon = theme => {
  let link = document.querySelector("link[rel~='icon']")
  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.getElementsByTagName('head')[0].appendChild(link)
  }
  if (theme) {
    link.href = getFavIcon(theme)
  }
}

const getTheme = insuranceType => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return THEME_DENTOLO
    case INSURANCE_TYPE_PETOLO:
      return THEME_PETOLO
    default:
      return THEME_DENTOLO
  }
}

export const useApplicationTheme = () => {
  const insuranceType = useInsuranceType()
  const theme = getTheme(insuranceType)

  useEffect(() => {
    updateFavicon(theme)
  }, [theme])

  return theme
}