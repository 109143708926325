import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { imgLinks } from '../../shared';
import { color } from '../../theme/functions';

const baseStyle = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0 0 1.5rem;
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 700;
  color: ${color('main_blue')};
  text-decoration: none;
  cursor: pointer;

  &:focus {
    box-shadow: none;
  }

  > img {
    margin-right: 0.5rem;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const StyledButton = styled.button`
  ${baseStyle}
`;
const StyledLink = styled(Link)`
  ${baseStyle}
`;
const StyledHref = styled.a`
  ${baseStyle}
`;

function BackButton(props) {
  const { label, to, href, target, onClick, ...rest } = props;

  const intl = useIntl();

  const displayText =
    label ||
    intl.formatMessage({
      id: 'common.button.back',
    });

  if (onClick) {
    return (
      <StyledButton onClick={onClick} {...rest}>
        <img src={imgLinks['icon.back.arrow']} alt="back" />

        {displayText}
      </StyledButton>
    );
  } else if (to) {
    return (
      <StyledLink to={to} {...rest}>
        <img src={imgLinks['icon.back.arrow']} alt="back" />

        {displayText}
      </StyledLink>
    );
  } else if (href) {
    return (
      <StyledHref href={href} target={target} {...rest}>
        <img src={imgLinks['icon.back.arrow']} alt="back" />

        {displayText}
      </StyledHref>
    );
  } else {
    return null;
  }
}

export { BackButton };

BackButton.defaultProps = {
  'data-testid': 'back-button',
};

const requiredPropsCheck = (expectedType) => (props, propName, componentName) => {
  const suppliedProp = props?.[propName];

  if (!props.onClick && !props.href && !props.to) {
    return new Error(
      `One of 'onClick' or 'href' or 'to' is required by '${componentName}' component.`
    );
  } else if (suppliedProp && typeof suppliedProp !== expectedType) {
    return new Error(
      `Invalid prop \`${propName}\` of type \`${typeof suppliedProp}\` supplied to \`${componentName}\`, expected \`${expectedType}\`.`
    );
  }
};

BackButton.propTypes = {
  to: requiredPropsCheck('string'),
  href: requiredPropsCheck('string'),
  target: PropTypes.string,
  onClick: requiredPropsCheck('function'),
};
