import React from 'react';
import PropTypes from 'prop-types';

import { Separator, IconButton } from '../../../components/common';
import {
  StyledPromotionCarouselControls,
  StyledPromotionCarouselActionDots,
  StyledPromotionCarouselActionDotsContainer,
} from './PromotionCarousel.styled';

const PromotionCarouselControls = (props) => {
  const { slides, current, onPrevious, onNext, onGoto } = props;

  return (
    <>
      <Separator />

      <StyledPromotionCarouselControls>
        <IconButton icon="icon.back.arrow" size="sm" onClick={onPrevious} />

        <StyledPromotionCarouselActionDotsContainer>
          {slides.map((slide, idx) => (
            <StyledPromotionCarouselActionDots
              key={slide?.id}
              active={current === idx}
              onClick={() => onGoto(idx)}
            />
          ))}
        </StyledPromotionCarouselActionDotsContainer>

        <IconButton icon="icon.caret.right" size="sm" onClick={onNext} />
      </StyledPromotionCarouselControls>
    </>
  );
};

export { PromotionCarouselControls };

PromotionCarouselControls.propTypes = {
  slides: PropTypes.array,
  current: PropTypes.number,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onGoto: PropTypes.func,
};
