import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledActiveUserItem,
  StyledUserItemAvatar,
  StyledUserItemName,
  StyledUserItemPolicy,
} from './styled/SelectUser.styled';

const ActiveUserItem = ({ name, policyName, to, disabled }) => {
  return (
    <StyledActiveUserItem to={to} active={!disabled}>
      <>
        <StyledUserItemAvatar>
          {name ? name.substring(0, 1).toUpperCase() : ''}
        </StyledUserItemAvatar>

        <div>
          <StyledUserItemName>{name}</StyledUserItemName>

          <StyledUserItemPolicy>{policyName}</StyledUserItemPolicy>
        </div>
      </>
    </StyledActiveUserItem>
  );
};

ActiveUserItem.propTypes = {
  name: PropTypes.string.isRequired,
  policyName: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default ActiveUserItem;
