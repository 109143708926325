import React from 'react'
import { useQuery } from '@apollo/client'
import Dashboard from './Dashboard'
import ErrorPlaceholder from '../components/ui/ErrorPlaceholder'
import { GET_CUSTOMER } from '../shared'

const Homepage = () => {
  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER)

  if (!customerLoading && !!customerData) {
    return (<Dashboard customer={customerData?.customer} />)
  } else if (!customerLoading) {
    return (<ErrorPlaceholder />)
  } else {
    return <></>
  }
}

export default Homepage
