import styled from 'styled-components';
import { Tab, TabList, TabPanel } from 'react-tabs';

import { THEME_DENTOLO, THEME_PETOLO } from '../../../shared';
import { max_width_sm, min_width_md, min_width_sm } from '../../../theme/breakpoints';
import { color } from '../../../theme/functions';

import { Paper, Text } from '../../../components/common';
import { Button, Paragraph } from '../../../components/ui';

const roundBulletColor = ({ theme }) => {
  const { name, colors } = theme || {};

  switch (name) {
    case THEME_DENTOLO:
      return colors?.main_green;
    case THEME_PETOLO:
      return colors?.main_orange;
    default:
      return colors?.main_green;
  }
};

export const StyledReferralTabList = styled(TabList)`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 1rem;
  font-size: 1rem;
  font-weight: 700;
`;

export const StyledReferralTab = styled(Tab)`
  flex-basis: 50%;
  padding: 0.5rem 0;
  font-size: 1rem;
  font-weight: 700;
  color: ${color('professional_light_blue')};
  text-align: center;
  line-height: 22px;
  border-bottom: 1px solid ${color('professional_light_blue')};
  cursor: pointer;
  transition: color 0.3s ease-in-out, border-bottom-color 0.3s ease-in-out;

  &.selected-referral-tab,
  &:not(.selected-referral-tab):hover {
    color: ${color('main_blue')};
    border-bottom-color: ${color('main_blue')};
  }
`;

export const StyledReferralTabPanel = styled(TabPanel)`
  margin-top: 1.5rem;

  @media (max-width: ${max_width_sm}) {
    margin-top: 2rem;
  }
`;

export const StyledReferralCoverImageContainer = styled(Paper)`
  margin-bottom: 1rem;
  padding: 0;
  overflow: hidden;

  @media (min-width: ${min_width_md}) {
    margin-bottom: 1.5rem;
  }
`;

export const StyledReferralCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledReferralCard = styled(Paper)`
  & + & {
    margin-top: 1rem;
  }
`;

export const StyledSocialLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: center;
`;

export const StyledSocialGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (min-width: ${min_width_sm}) {
    width: 60%;
  }
`;

export const StyledSocialImg = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
`;

export const StyledReferralStepsContainer = styled.div`
  counter-reset: list-number;
`;

export const StyledReferralStepItem = styled.div`
  font-size: 1rem;
  font-weight: 450;
  line-height: 22px;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

export const StyledReferralStepItemContent = styled.div`
  display: inline-block;
  font-size: 1rem;
  font-weight: 450;
`;

export const StyledReferralStepTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 0.5rem;
  font-size: 1rem;
  font-weight: 700;

  &::before {
    content: counter(list-number) '.';
    counter-increment: list-number;
    font-size: inherit;
    font-weight: 700;
  }

  @media (min-width: ${min_width_md}) {
    font-size: 1.25rem;
  }
`;

export const StyledReferralAdditionalInformationContainer = styled.div`
  margin-top: 0.5rem;
`;

export const StyledReferralAdditionalInformation = styled.div`
  font-size: 1rem;
  font-weight: 450;
  line-height: 22px;

  &::before {
    content: '';
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${roundBulletColor};
    border-radius: 50%;
    margin-right: 0.625rem;
  }
`;

export const StyledReferralCopyLinkButton = styled(Button)`
  margin-top: 1rem;
  width: 100%;

  @media (min-width: ${min_width_md}) {
    width: auto;
  }
`;

export const StyledReferralText = styled(Text)`
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 450;
  line-height: 22px;
  text-align: ${({ textAlign }) => textAlign || 'center'};
`;

export const StyledReferralCopyLinkOrSocialsText = styled(StyledReferralText)`
  font-weight: 700;
  text-align: left;

  @media (min-width: ${min_width_md}) {
    text-align: center;
  }
`;

export const StyledReferralIdContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 3px;
  background-color: ${color('ultra_light_gray')};

  > p {
    line-height: 22px;
  }
`;

export const StyledReferralIdBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem auto 0;
  padding: 0.5rem;
  width: 100%;
  max-width: 300px;
  border: 1px solid ${color('professional_light_blue')};
  border-radius: 3px;
`;

export const StyledReferralFooterText = styled(Paragraph)`
  font-size: 0.75rem;
  text-align: left;
  line-height: 16px;

  & + & {
    margin-top: 1rem;
  }

  a::after {
    bottom: 0;
  }
`;

export const StyledReferralTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
`;

export const StyledReferralList = styled.div``;

export const StyledReferralListContainer = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid ${color('light_gray')};
`;

export const StyledReferralListItem = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
`;

export const StyledReferralListItemTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const StyledReferraRewardBox = styled.div`
  margin: 1rem auto 0;
  padding: 0.3rem 0;
  width: 360px;
  border-radius: 5px;
  border: 1px dashed ${color('professional_light_blue')};
  text-align: center;
`;

export const StyledReferraRewardText = styled.div`
  font-size: 0.75rem;
  font-weight: 450;

  & + & {
    margin-top: 0.5rem;
  }
`;

export const StyledReferralStatusDescriptionContainer = styled.div`
  margin-top: 2rem;
`;

export const StyledReferralStatusDescription = styled(Paragraph)`
  margin-top: 0;
  font-size: 1rem;
`;
