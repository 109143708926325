import React from 'react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

import { GET_CUSTOMER } from '../../../../shared';
import { usePetLiabilityClaims } from '../../usePetLiabilityClaims';
import { useEditIcon } from '../../../../hooks/useIcons';
import {
  PET_LIABILITY_ACCOMPANIED_BY,
  PET_LIABILITY_AFFECTED_PARTY,
  PET_LIABILITY_AFFECTED_PARTY_DETAILS,
  PET_LIABILITY_AFFECTED_PARTY_OPTION_MYSELF,
  PET_LIABILITY_DAMAGED_ITEM,
  PET_LIABILITY_DAMAGE_CAUSED_BY,
  PET_LIABILITY_DAMAGE_ESTIMATE,
  PET_LIABILITY_INCIDENT_DATE,
  PET_LIABILITY_INCIDENT_LOCATION,
  PET_LIABILITY_INCIDENT_NOTE,
  PET_LIABILITY_INCIDENT_TIME,
  PET_LIABILITY_MEDICAL_EXAM,
  PET_LIABILITY_MEDICAL_EXAM_YES,
  PET_LIABILITY_REASON,
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_PERSON,
  PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY,
  PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_CUSTOMER,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';

import SubmitClaimForm from './SubmitClaimForm';
import {
  useEditDocumentsLinkPetLiability,
  useSubmitClaimFormPetLiabilityBackButtonLink,
} from './submitClaimFormPetLiabilityHooks';

function SubmitClaimFormPetLiability(props) {
  const { tarif, selectedDocuments, setSelectedDocuments, setClaimSubmitted } = props;

  const intl = useIntl();
  const editIcon = useEditIcon();

  const { data: customerData } = useQuery(GET_CUSTOMER);
  const { customer } = customerData || {};
  const customerName = `${customer?.firstName} ${customer?.lastName}`;

  const [submitPetLiabilityClaim] = usePetLiabilityClaims();

  const [liabilityReason] = useNewPetLiabilityFormDataValue(PET_LIABILITY_REASON);
  const [damagedCausedBy] = useNewPetLiabilityFormDataValue(PET_LIABILITY_DAMAGE_CAUSED_BY);
  const [incidentDate] = useNewPetLiabilityFormDataValue(PET_LIABILITY_INCIDENT_DATE);
  const [incidentTime] = useNewPetLiabilityFormDataValue(PET_LIABILITY_INCIDENT_TIME);
  const [incidentLocation] = useNewPetLiabilityFormDataValue(PET_LIABILITY_INCIDENT_LOCATION);
  const [accompaniedBy] = useNewPetLiabilityFormDataValue(PET_LIABILITY_ACCOMPANIED_BY);
  const [incidentNote] = useNewPetLiabilityFormDataValue(PET_LIABILITY_INCIDENT_NOTE);

  const [damagedItem] = useNewPetLiabilityFormDataValue(PET_LIABILITY_DAMAGED_ITEM);
  const [damageEstimate] = useNewPetLiabilityFormDataValue(PET_LIABILITY_DAMAGE_ESTIMATE);

  const [affectedParty] = useNewPetLiabilityFormDataValue(PET_LIABILITY_AFFECTED_PARTY);
  const [detailsOfAffectedParty] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_AFFECTED_PARTY_DETAILS
  );
  const [relationWithAffectedParty] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY
  );
  const [medicalExam] = useNewPetLiabilityFormDataValue(PET_LIABILITY_MEDICAL_EXAM);

  let damagedDateTime;
  if (incidentDate && incidentTime) {
    damagedDateTime = dayjs(`${incidentDate}T${incidentTime}`).toISOString();
  } else if (incidentDate) {
    damagedDateTime = dayjs(incidentDate || '').toISOString();
  }

  const damagedObjectPrice = !isNaN(damagedItem?.price) ? Number(damagedItem?.price) : 0;

  const {
    address: incidentAddress,
    houseNumber: incidentHouseNumber,
    postcode: incidentPostcode,
    city: incidentCity,
  } = incidentLocation || {};

  const incidentDateForDisplay = !!incidentDate
    ? intl.formatDate(incidentDate, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : '';

  const generateIncidentLocationToDisplay = () => {
    let locationToDisplay = '';

    if (incidentAddress) locationToDisplay = incidentAddress;

    if (locationToDisplay && incidentHouseNumber)
      locationToDisplay = `${locationToDisplay} ${incidentHouseNumber}`;

    if (incidentPostcode)
      locationToDisplay = `${locationToDisplay}${
        locationToDisplay ? ', ' : ' '
      }${incidentPostcode}`;

    if (incidentCity) {
      locationToDisplay = locationToDisplay ? `${locationToDisplay} ${incidentCity}` : incidentCity;
    }

    return locationToDisplay;
  };

  const cardContent = [
    { label: 'pet_liability.new_claim.submit_claim_form.card_label.tariff', value: tarif },
    {
      label: 'pet_liability.new_claim.submit_claim_form.card_label.incident_date',
      value: incidentDateForDisplay,
    },
    {
      label: 'pet_liability.new_claim.submit_claim_form.card_label.city',
      value: generateIncidentLocationToDisplay(),
    },
  ];

  const generatePayload = () => {
    const validDocuments = Array.isArray(selectedDocuments)
      ? selectedDocuments.filter((d) => d?.valid)
      : [];

    let payload = {
      damageCategory: liabilityReason,
      causedBy: damagedCausedBy,
      damageOccuredDate: damagedDateTime,
      damageLocationStreetName: incidentAddress,
      damageLocationHouseNumber: incidentHouseNumber,
      damageLocationPostcode: incidentPostcode,
      damageLocationCity: incidentCity,
      petSupervisor: accompaniedBy,
      damageReport: incidentNote,

      attachments: validDocuments.map((item) => item.file),
    };

    // add either `medical_examination` or `damaged_object` details
    if (
      liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL ||
      liabilityReason === PET_LIABILITY_REASON_PERSON
    ) {
      payload = {
        ...payload,
        medicalExamination: medicalExam === PET_LIABILITY_MEDICAL_EXAM_YES,
      };
    } else {
      payload = {
        ...payload,
        damagedObjectTitle: damagedItem?.item,
        damagedObjectAge: damagedItem?.age,
        damagedObjectPurchasePriceCents: damagedObjectPrice * 100,
        estimatedDamageValue: damageEstimate,
      };
    }

    //# `affected_person` details
    if (affectedParty === PET_LIABILITY_AFFECTED_PARTY_OPTION_MYSELF) {
      payload = {
        ...payload,
        affectedPersonName: customerName,
        affectedPersonRelationship: PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_CUSTOMER,
      };
    } else {
      payload = {
        ...payload,
        affectedPersonName: detailsOfAffectedParty?.name,
        affectedPersonStreetName: detailsOfAffectedParty?.address,
        affectedPersonHouseNumber: detailsOfAffectedParty?.houseNumber,
        affectedPersonPostcode: detailsOfAffectedParty?.postcode,
        affectedPersonCity: detailsOfAffectedParty?.city,
        affectedPersonEmail: detailsOfAffectedParty?.email,
        affectedPersonPhoneNumber: detailsOfAffectedParty?.phone,
        affectedPersonRelationship: relationWithAffectedParty,
      };
    }

    return payload;
  };

  const uploadClaimForm = () => {
    const payload = generatePayload();

    submitPetLiabilityClaim({
      payload,
      setSelectedDocuments,
      setClaimSubmitted,
    });
  };

  const backButtonLink = useSubmitClaimFormPetLiabilityBackButtonLink();
  const editUploadedFilesLink = useEditDocumentsLinkPetLiability();

  return (
    <SubmitClaimForm
      selectedDocuments={selectedDocuments}
      backButtonLink={backButtonLink}
      editUploadedFilesLink={editUploadedFilesLink}
      pageTitleId="pet_liability.new_claim.submit_claim_form.page_title"
      editIcon={editIcon}
      formDataCardHeaderId="pet_liability.new_claim.submit_claim_form.data_card.title"
      cardContent={cardContent}
      uploadedFilesCardHeaderId="pet_liability.new_claim.submit_claim_form.uploaded_files_card.title"
      uploadClaimForm={uploadClaimForm}
      submitButtonId="pet_liability.new_claim.submit_claim_form.button.send"
    />
  );
}

export default SubmitClaimFormPetLiability;
