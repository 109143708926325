import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { CREDIT_CARD, PAYPAL, GOOGLEPAY, APPLEPAY, MASTERCARD, VISA } from '../../shared';

import { StyledPaymentDetailsItem } from '../styled/CustomerDetails.styled';
import {
  MasterCardIcon,
  VisaIcon,
  PaypalIcon,
  GooglePayIcon,
  ApplePayIcon,
} from '../CustomerDetails/DigitalPaymentsIcon';

const DigitalPaymentInfo = (props) => {
  const { financialInstrument } = props;
  const { kind, cardDetails } = financialInstrument || {};

  switch (kind) {
    case CREDIT_CARD:
      const expiryMonth = cardDetails?.expiryMonth;
      const expiryYear = cardDetails?.expiryYear;
      const accountNumber = cardDetails?.accountNumber;
      const cardType = cardDetails?.cardBrand;
      const convertedExpiryMonth = Number.isInteger(expiryMonth)
        ? expiryMonth?.toString().padStart(2, '0')
        : '-';
      const convertedExpiryYear = Number.isInteger(expiryYear)
        ? expiryYear?.toString().padStart(4, '20')
        : '-';
      return (
        <StyledPaymentDetailsItem>
          <div data-label>
            <CreditCardIcon cardType={cardType} />
          </div>
          <div data-value>
            <p>{accountNumber}</p>
            <p data-label>
              <FormattedMessage id={'policy.details.account.digital.payment.expire'} />
              {convertedExpiryMonth}/{convertedExpiryYear}
            </p>
          </div>
        </StyledPaymentDetailsItem>
      );
    case GOOGLEPAY:
      return (
        <StyledPaymentDetailsItem>
          <div data-label>
            <GooglePayIcon />
          </div>
          <div data-value>
            <FormattedMessage id={`policy.details.account.digital.payment.googlePay`} />
          </div>
        </StyledPaymentDetailsItem>
      );
    case APPLEPAY:
      return (
        <StyledPaymentDetailsItem>
          <div data-label>
            <ApplePayIcon />
          </div>
          <div data-value>
            <FormattedMessage id={`policy.details.account.digital.payment.applePay`} />
          </div>
        </StyledPaymentDetailsItem>
      );
    case PAYPAL:
      return (
        <StyledPaymentDetailsItem>
          <div data-label>
            <PaypalIcon />
          </div>
          <div data-value>
            <FormattedMessage id={`policy.details.account.digital.payment.paypal`} />
          </div>{' '}
        </StyledPaymentDetailsItem>
      );

    default:
      return <></>;
  }
};

export default DigitalPaymentInfo;

function CreditCardIcon({ cardType }) {
  if (cardType === VISA) {
    return <VisaIcon />;
  } else if (cardType === MASTERCARD) {
    return <MasterCardIcon />;
  }

  return <></>;
}

DigitalPaymentInfo.propTypes = {
  financialInstrument: PropTypes.object.isRequired,
};
