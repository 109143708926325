import React from 'react';
import PropTypes from 'prop-types';

import {
  imgLinks,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
} from '../../shared';

import {
  StyledSelectUserItemButton,
  StyledUserItemLogo,
  StyledUserItemName,
  StyledUserItemPolicy,
} from './styled/SelectUser.styled';

const getInsuranceCategoryIcon = (insuranceCategory) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
      return imgLinks['icon.menu.green.tooth.lg'];
    case INSURANCE_CATEGORY_PET_HEALTH:
      return imgLinks['icon.menu.orange.paw.lg'];
    case INSURANCE_CATEGORY_PET_LIABILITY:
      return imgLinks['icon.menu.orange.paw.lg'];
    default:
      return imgLinks['icon.menu.green.tooth.lg'];
  }
};

const SelectUserItem = (props) => {
  const { name, policyName, uuid, disabled, setChildUuid, isContractEnded, insuranceCategory } =
    props;

  return (
    <StyledSelectUserItemButton
      variant="nostyle"
      onClick={() => setChildUuid(uuid)}
      active={!isContractEnded}
      disabled={disabled}
    >
      <>
        <StyledUserItemLogo>
          <img src={getInsuranceCategoryIcon(insuranceCategory)} alt={insuranceCategory || ''} />
        </StyledUserItemLogo>

        <div style={{ textAlign: 'left' }}>
          <StyledUserItemName>{name}</StyledUserItemName>

          <StyledUserItemPolicy>{policyName}</StyledUserItemPolicy>
        </div>
      </>
    </StyledSelectUserItemButton>
  );
};

SelectUserItem.propTypes = {
  name: PropTypes.string.isRequired,
  policyName: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  uuid: PropTypes.string.isRequired,
  /** Sets the uuid of the customer */
  setChildUuid: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectUserItem;
