import { gql } from '@apollo/client';

export const LOCAL_SHOW_ONBOARDS = gql`
  query {
    showOnboards @client {
      name
      show
    }
  }
`;

export const LOCAL_NAV_MENU = gql`
  query {
    showNavMenu @client
  }
`;

export const LOCAL_CONFIRM_EMAIL = gql`
  query {
    confirmEmail @client
  }
`;

export const GET_CUSTOMER = gql`
  query {
    digitalPaymentMethod {
      active
      financialInstrument {
        kind
        cardDetails
      }
    }
    customer {
      key
      uuid
      firstName
      lastName
      gender
      email
      bankAccountFirstName
      bankAccountLastName
      iban
      postcode
      houseNumber
      streetName
      city
      addressDetails
      phoneNumber
      policyName
      endedContract
      insuredPersonIsCustomer
      referralJoined
      referralCode
      insuredPerson {
        gender
        firstName
        lastName
        streetName
        houseNumber
        postcode
        city
      }
      insuranceCategory
      insuredPet {
        name
        breed
        dateOfBirth
        gender
        transponderCode
      }
      referralData {
        referralId
      }
      contract {
        key
        status
        billingDay
        policyCategory
        policyName
        monthlyPremium
        acceptedAt
        startingAt
        cancelationDate
        cancelationType
        signed
        riskCarrier
        imCoverage
        addOnTrialMonth {
          status
          startingAt
        }
        addonBudgedSchedule {
          addonKey
          limits {
            year
            amountCents
          }
        }
        contractBudgetSchedule {
          year
          amountCents
        }
        contractPremium {
          age
          price
        }
      }
      communicationList {
        id
        channels
      }
    }
  }
`;

export const GET_CLAIMS = gql`
  query {
    claims {
      id
      key
      status
      treatmentCategory
      treatmentPlan
      createdAt
      statusDetails
      notificationDate
      lossDate
      statusUpdated
    }
  }
`;

export const GET_CLAIM = gql`
  query ($id: Int!) {
    claim(id: $id) {
      key
      status
      treatmentCategory
      treatmentPlan
      createdAt
      updatedAt
      notificationDate
      declineReason
      note
      statusUpdated
      lockedAmountCents
      lockedReimbursableAmount
      statusDetails
      lossDate
      statusHistory {
        date
        status
      }
      payouts {
        key
        amountCents
        recipientName
        recipientIban
        confirmedAt
        createdAt
        lossDate
      }
      videoConsultation {
        id
      }
      externalContact {
        name
        email
        phoneNumber
        externalClaimId
      }
    }
  }
`;

export const GET_DENTISTS = gql`
  query {
    dentists {
      latitude
      longitude
      name
      avatar
      city
      houseNumber
      openingHours
      phoneNumber
      postcode
      practiceName
      streetName
      title
      websiteUrl
      referenceKey
      dentoloPartner
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query ($jwt: String) {
    customers(jwt: $jwt) {
      firstName
      lastName
      key
      uuid
      policyName
      email
      endedContract
      insuredPersonIsCustomer
      insuredPerson {
        gender
        firstName
        lastName
        streetName
        houseNumber
        postcode
        city
      }
      insuranceCategory
      insuredPet {
        name
      }
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query {
    documents {
      name
      url
    }
  }
`;

export const GET_CCDOCUMENTS = gql`
  query {
    contributionConfirmationsDocuments {
      name
      url
      year
      seenByCustomer
    }
  }
`;

export const GET_PAYMENT = gql`
  query {
    payments {
      status
      billingMonth
      amountCents
      policyAmountCents
      addonAmountCents
      collectAiClaimLink
    }
  }
`;

export const GET_FAVORITE_DENTISTS = gql`
  query {
    customer {
      favoriteDentists {
        dentistKey
      }
    }
  }
`;

export const GET_CUSTMER_ASSIGNMENT_COUNT = gql`
  query {
    customer {
      key
      attachmentsPendingCount
    }
  }
`;

export const GET_VIDEO_CONSULTATIONS = gql`
  query {
    videoConsultations {
      id
      dateTime
      anamnese
    }
  }
`;

export const GET_CANCELLATION_PAGE_DATA = gql`
  query {
    cancellationPageData {
      key
    }
  }
`;

export const GET_CANCELLATION_AVAILABLE_DATES = gql`
  query ($cancellationType: String!) {
    cancellationAvailableDates(cancellationType: $cancellationType) {
      earliestAvailableDate
    }
  }
`;

export const GET_CONTRACT_CANCELLATION_REASONS = gql`
  query {
    cancellationAvailableReasons {
      reasons
    }
  }
`;

export const GET_REFERRAL_LIST = gql`
  query {
    referrals {
      referee {
        email
      }
      rewardCouponCode
      rewardCouponStoreName
      status
    }
  }
`;
