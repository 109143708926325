import React from 'react';
import styled from 'styled-components';

import { useCheckIcon } from '../../hooks/useIcons';

import { Icon } from '../common';

const StyledIcon = styled(Icon)`
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
`;

const CheckIcon = () => {
  const checkIcon = useCheckIcon();

  return <StyledIcon icon={checkIcon} alt="check" />;
};

export default CheckIcon;
