import React from 'react';

import { Separator } from '../common';
import { StyledFlexContainer } from '../styled/PolicyInfo.styled';
import {
  StyledPolicyDetailsSectionTitle,
  StyledPolicyDetailsTextLg,
  StyledPolicyDetailsText,
} from '../styled/PolicyDetails.styled';

const MainDetails = ({ details }) => {
  if (Array.isArray(details)) {
    return details.map((item, index) => (
      <div key={index}>
        <StyledPolicyDetailsSectionTitle>{item?.title}</StyledPolicyDetailsSectionTitle>

        {Array.isArray(item?.rates) &&
          item.rates.map((rate, index) => (
            <StyledFlexContainer marginBottom="1rem" key={index}>
              <StyledPolicyDetailsText>{rate?.name}</StyledPolicyDetailsText>

              <StyledPolicyDetailsTextLg>{rate?.value}</StyledPolicyDetailsTextLg>
            </StyledFlexContainer>
          ))}

        {!!item?.subText && <StyledPolicyDetailsText>{item.subText}</StyledPolicyDetailsText>}

        <Separator />
      </div>
    ));
  } else {
    return <></>;
  }
};

export default MainDetails;
