import styled, { css } from 'styled-components';

import { IconButton } from '../../common';

export const StyledFavoriteDentist = styled.div`
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'auto')};
  overflow-y: auto;
`;

export const StyledFavoriteDentistMessage = styled.div`
  text-align: center;
  padding: 1.5rem 1rem;

  ${({ hasShadow }) =>
    hasShadow &&
    css`
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    `}
`;

export const StyledFavoriteDentistBox = styled.div`
  padding: 1.25rem;
`;

export const StyledFavoriteDentistCard = styled.div`
  padding: 0.5rem 0 0;
`;

export const StyledFavoriteDentistCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
`;

export const StyledFavoriteDentistFavButton = styled(IconButton)`
  width: 1.375rem;
  height: 1.375rem;

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledFavoriteDentistDetailsButtonWrapper = styled.div`
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-end;

  > button {
    box-shadow: none;
  }
`;
