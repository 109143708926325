import React from 'react';
import {
  PET_LIABILITY_AFFECTED_PARTY_DETAILS,
  PET_LIABILITY_REASON,
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_PERSON,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useDetailsOfAffectedPartyPetLiabilityBackButtonLink,
  useDetailsOfAffectedPartyPetLiabilityNextButtonLink,
} from './useDetailsOfAffectedPartyPetLiability';

import PersonalDetailsForm from '../common/PersonalDetails/PersonalDetailsForm';

function DetailsOfAffectedPartyPetLiability() {
  const [liabilityReason] = useNewPetLiabilityFormDataValue(PET_LIABILITY_REASON);
  const [details, setDetails] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_AFFECTED_PARTY_DETAILS
  );

  const { name, address, houseNumber, postcode, city, email, phone } = details || {};

  const backButtonLink = useDetailsOfAffectedPartyPetLiabilityBackButtonLink();
  const nextButtonLink = useDetailsOfAffectedPartyPetLiabilityNextButtonLink();

  const handleNameChange = (ev) => setDetails({ ...details, name: ev?.target?.value });
  const handleAddressChange = (ev) => setDetails({ ...details, address: ev?.target?.value });
  const handleHouseNumberChange = (ev) =>
    setDetails({ ...details, houseNumber: ev?.target?.value });
  const handlePostcodeChange = (ev) => setDetails({ ...details, postcode: ev?.target?.value });
  const handleCityChange = (ev) => setDetails({ ...details, city: ev?.target?.value });
  const handleEmailChange = (ev) => setDetails({ ...details, email: ev?.target?.value });
  const handlePhoneChange = (ev) => setDetails({ ...details, phone: ev?.target?.value });

  const getPageTitleId = () => {
    if (liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL) {
      return 'pet_liability.new_claim.details_of_affected_party.page_title.injured_animal';
    } else if (liabilityReason === PET_LIABILITY_REASON_PERSON) {
      return 'pet_liability.new_claim.details_of_affected_party.page_title.injured_person';
    } else {
      return 'pet_liability.new_claim.details_of_affected_party.page_title.damage';
    }
  };

  return (
    <PersonalDetailsForm
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId={getPageTitleId()}
      fullname={name}
      address={address}
      houseNumber={houseNumber}
      postcode={postcode}
      city={city}
      email={email}
      telephone={phone}
      onNameChange={handleNameChange}
      onAddressChange={handleAddressChange}
      onHouseNumberChange={handleHouseNumberChange}
      onPostcodeChange={handlePostcodeChange}
      onCityChange={handleCityChange}
      onEmailChange={handleEmailChange}
      onTelephoneChange={handlePhoneChange}
    />
  );
}

export default DetailsOfAffectedPartyPetLiability;
