import React, { useState, useEffect } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import { withRouter } from 'react-router-dom'
import { GET_CUSTOMER } from '../../shared'
import { Loading } from '../ui'

// TODO: refactor auth -> check on every page open
const PleaseSignIn = ({ history, children }) => {
  const [showLoadingScreen, setShowLoadingScreen] = useState(true)
  const client = useApolloClient()
  const { data: customerData, loading, error } = useQuery(GET_CUSTOMER, { fetchPolicy: 'network-only' })

  useEffect(() => {
    const validCustomer = customerData && Object.keys(customerData).length
    const contractSigned = customerData?.customer?.contract?.signed

    if (!loading && (!customerData || error)) {
      client.cache.reset()
      history.push('/login')
    } else if (validCustomer && !contractSigned) {
      history.push('/confirm-documents')
    } else if (validCustomer) {
      setShowLoadingScreen(false)
    }
  }, [history, client, loading, customerData, error])

  if (showLoadingScreen) {
    return <Loading showLogo />
  } else {
    return children
  }
}

export default withRouter(PleaseSignIn)
