import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledOnBoardCard,
  StyledOnBoardCardTitle,
  StyledOnBoardCardText,
} from './HomeOnboardDentolo.styled';
const OnboardCard = ({ titleHtml, bodyText, children }) => {
  return (
    <StyledOnBoardCard>
      <StyledOnBoardCardTitle dangerouslySetInnerHTML={{ __html: titleHtml || '' }} />
      <>{children}</>
      <StyledOnBoardCardText>{bodyText || ``}</StyledOnBoardCardText>
    </StyledOnBoardCard>
  );
};

OnboardCard.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
  children: PropTypes.object,
};

export default OnboardCard;
