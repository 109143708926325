import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useInsuranceType } from '../../hooks/useInsuranceType';
import { useFileUploadIcon } from '../../hooks/useIcons';
import { CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT } from '../../features/claims/claimUploadFlowSource';
import {
  DENTOLO_EDIT_STEP_MAIN,
  PETOLO_EDIT_STEP_MAIN,
} from '../../features/claims/edit/editClaimFormSteps';
import {
  showMoney,
  formatDate,
  claimStatus,
  claimStatusExplanation,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
} from '../../shared';

import { Icon, Separator } from '../common';
import { StyledClaimStatus } from './styled/ClaimStatus.styled';
import {
  StyledClaimStatusInfoCard,
  StyledClaimStatusUpdatedTime,
  StyledClaimStatusDetails,
  StyledClaimStatusTitle,
  StyledUserClaimComments,
} from './styled/ClaimDetailsInfo.styled';
import {
  StyledUploadButtonContainer,
  StyledUploadButtonLabel,
} from '../../features/claims/components/UploadDocument/FileUploader.styled';

const getUploadDocumentLink = (insuranceType, claimId) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return `${DENTOLO_EDIT_STEP_MAIN}/${claimId}?source=${CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT}`;
    case INSURANCE_TYPE_PETOLO:
      return `${PETOLO_EDIT_STEP_MAIN}/${claimId}?source=${CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT}`;
    default:
      return `${DENTOLO_EDIT_STEP_MAIN}/${claimId}?source=${CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT}`;
  }
};

const ClaimDetailsInfo = ({
  selectedClaim,
  claim,
  latestPayout,
  customer,
  claimLabel,
  isSameIban,
  maskIban,
}) => {
  const history = useHistory();
  const { id: claimId } = useParams();
  const isDocTreatmentPlan = claim?.treatmentPlan;
  const isReimbAcknowStatus = claim?.status === claimStatus.REIMBURSEMENT_ACKNOWLEDGED;
  const isReimbursment = isDocTreatmentPlan && isReimbAcknowStatus && claim?.lockedAmountCents > 0;

  const insuranceType = useInsuranceType();
  const uploadDocumentLink = getUploadDocumentLink(insuranceType, claimId);
  const uploadDocumentCtaIcon = useFileUploadIcon();

  const goToUploadStep = () => {
    history.push(uploadDocumentLink);
  };

  return (
    <StyledClaimStatusInfoCard>
      <StyledClaimStatusUpdatedTime>
        {formatDate(claim?.updatedAt, 'DD.MM.YY HH:mm')}{' '}
        <FormattedMessage id={'claim.details.general.hour'} />
      </StyledClaimStatusUpdatedTime>

      {claim?.statusDetails && (
        <StyledClaimStatusTitle>
          <FormattedMessage id={`claim.status.details.${claim?.statusDetails}`} tagName="h5" />

          {isReimbursment && (
            <StyledClaimStatus claimStatus={claim?.status}>
              <FormattedMessage id={`claim.details.general.amount`} tagName="span" />
              <span>: {showMoney(claim?.lockedAmountCents / 100)}</span>
            </StyledClaimStatus>
          )}
        </StyledClaimStatusTitle>
      )}

      {/* Important: there can be no payouts for a claim with status [WAITING_FOR_PAYOUT, CLOSED, NEW] */}
      {claimStatusExplanation[claim?.statusDetails] && (
        <StyledClaimStatusDetails>
          {claimStatusExplanation[claim?.statusDetails].statusExplanation({
            isSameIban: isSameIban(customer?.iban, latestPayout?.recipientIban),
            payoutAmount: showMoney(latestPayout?.amountCents / 100),
            recepientIban: maskIban(latestPayout?.recipientIban),
            recepientName: latestPayout?.recipientName,
            reimbursableAmount: showMoney(claim?.lockedAmountCents / 100),
            isReimbursment,
            text: '',
            insuranceType,
          })}
        </StyledClaimStatusDetails>
      )}

      {isReimbursment && (
        <StyledClaimStatusDetails>
          <FormattedMessage id={`claim.details.general.load.invoice`} />
        </StyledClaimStatusDetails>
      )}

      {claim?.note &&
        (claimStatus.WAITING_FOR_INFORMATION === claim?.status ||
          claimStatus.DECLINED === claim?.status) && (
          <StyledUserClaimComments>{claim?.note}</StyledUserClaimComments>
        )}

      {(isReimbAcknowStatus || claimStatus.WAITING_FOR_INFORMATION === claim?.status) && (
        <>
          <Separator />

          <StyledUploadButtonContainer onClick={goToUploadStep}>
            <Icon
              icon={uploadDocumentCtaIcon}
              alt="claim upload step button"
              data-testid="btn-goto-upload-step"
              style={{ width: '3.5rem', height: '3.5rem' }}
            />

            <StyledUploadButtonLabel>
              <FormattedMessage
                id={`common.button.upload.${isReimbursment ? 'invoice' : 'document'}`}
              />
            </StyledUploadButtonLabel>
          </StyledUploadButtonContainer>
        </>
      )}
    </StyledClaimStatusInfoCard>
  );
};

export default ClaimDetailsInfo;
