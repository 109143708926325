import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import {
  StyledModalCloseButton,
  StyledModalContainer,
  StyledModalOverlay,
  StyledModalTitle,
} from './styled/Modal.styled';

function AnimatedModal(props) {
  const { isOpen, timeout, ...rest } = props;

  useEffect(() => {
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <CSSTransition in={isOpen} timeout={timeout} classNames="modal-animation" unmountOnExit>
      <Modal isOpen={isOpen} {...rest} />
    </CSSTransition>
  );
}

export default AnimatedModal;

AnimatedModal.defaultProps = {
  timeout: 300,
};

AnimatedModal.propTypes = {
  isOpen: PropTypes.bool,
  timeout: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

function Modal(props) {
  const { children, isOpen, size, title, showClose, onClose, enableCloseOnOverlayClick } = props;

  const portalRoot = document.getElementById('portal');

  // useEffect(() => {
  //   return () => {
  //     document.body.classList.remove('no-scroll');
  //   };
  // }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isOpen]);

  const handleOverlayClick = (ev) => {
    ev.stopPropagation();

    if (enableCloseOnOverlayClick) {
      onClose();
    }

    return;
  };

  return ReactDOM.createPortal(
    <StyledModalOverlay onClick={handleOverlayClick} role="dialog">
      <StyledModalContainer size={size} onClick={(ev) => ev.stopPropagation()}>
        {showClose && (
          <StyledModalCloseButton
            icon="icon.failed"
            size="sm"
            onClick={onClose}
            aria-label="Close modal"
          />
        )}

        {title && <StyledModalTitle>{title}</StyledModalTitle>}

        <div>{children}</div>
      </StyledModalContainer>
    </StyledModalOverlay>,
    portalRoot
  );
}

Modal.defaultProps = {
  showClose: true,
  size: 'md',
  enableCloseOnOverlayClick: false,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  size: PropTypes.string,
  title: PropTypes.string,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  enableCloseOnOverlayClick: PropTypes.bool,
};
