export const NODE_ENV = process.env.NODE_ENV;
export const APP_ENV = process.env.REACT_APP_ENV;

export const INSURANCE_CATEGORY_PET_HEALTH = 'pet_health';
export const INSURANCE_CATEGORY_PET_LIABILITY = 'pet_liability';

export const INSURANCE_CATEGORY_DENTAL = 'dental';
export const INSURANCE_CATEGORIES = [INSURANCE_CATEGORY_PET_HEALTH, INSURANCE_CATEGORY_DENTAL];

export const DASHBOARD_ONBOARD_KEY = 'home-onboard';
export const USER_CLAIMS_ONBOARD_KEY = 'user-claims-onboard';
export const PETOLO_DASHBOARD_ONBOARD_KEY = 'petolo-home-onboard';
export const CCDOCS_LS_SHOW_KEY = 'ccdocs-ls-show';
export const PETOLO_CROSS_SELLING_BANNER_KEY = 'hide-petolo-cross-selling-banner';
export const BANNER_INSURE_ANOTHER_PET_KEY = 'banner-insure-another-pet';

export const DENTAL_GREEN_COLOR = 'green';
export const PET_ORANGE_COLOR = 'orange';

export const DENTOLO_DOMAIN = 'dentolo.de';
export const PETOLO_DOMAIN = 'petolo.de';

export const INSURANCE_TYPE_DENTOLO = 'dentolo';
export const INSURANCE_TYPE_PETOLO = 'petolo';

export const INSURANCE_TYPES = [INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO];
export const DEFAULT_INSURANCE_TYPE = INSURANCE_TYPE_DENTOLO;

export const THEME_DENTOLO = 'dentolo';
export const THEME_PETOLO = 'petolo';
export const AVAILABLE_THEMES = [THEME_DENTOLO, THEME_PETOLO];

export const EDITOR_MODAL_MODE_FORM = 'form';
export const EDITOR_MODAL_MODE_SUCCESS = 'success';
export const EDITOR_MODAL_MODE_FAILURE = 'failure';

export const DENTOLO_DENTAL_POLICY_CATEGORIES = [6, 7, 8, 13];
export const DENTOLO_PET_HEALTH_POLICY_CATEGORIES = [14, 15, 16, 23, 24, 25];
export const DENTOLO_PET_LIABILITY_POLICY_CATEGORIES = [26, 27];

export const DA_DIREKT_DENTAL_POLICY_CATEGORIES = [9, 10, 11, 12];
export const DA_DIREKT_PET_POLICY_CATEGORIES = [17, 18, 19, 20, 21, 22];
export const DA_DIREKT_POLICY_CATEGORIES = [
  ...DA_DIREKT_DENTAL_POLICY_CATEGORIES,
  ...DA_DIREKT_PET_POLICY_CATEGORIES,
];

export const CAT_POLICY_CATEGORIES = [20, 21, 22, 23, 24, 25];

export const VALID_DATE = 'valid_date';
export const NOT_VALID_DATE = 'not_valid_date';
export const IN_PAST_DATE = 'in_past_date';
export const VALID_TIME = 'valid_time';
export const INVALID_TIME = 'invalid_time';

export const VALID_FIELD = 'valid_field';
export const INVALID_FIELD = 'invalid_field';

export const SERVICE_EMAIL_DENTOLO = 'service@dentolo.de';
export const SERVICE_EMAIL_PETOLO = 'service@petolo.de';

export const CREDIT_CARD = 'CREDIT_CARD';
export const PAYPAL = 'PAYPAL';
export const GOOGLEPAY = 'GOOGLEPAY';
export const APPLEPAY = 'APPLEPAY';
export const MASTERCARD = 'MASTERCARD';
export const VISA = 'VISA';
export const PAYMENT_SUCCESS_STATUS = 'success';
export const PAYMENT_FAILURE_STATUS = 'fail';
