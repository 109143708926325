import { useEffect, useState } from 'react';

import { dateDiff } from '../../../shared/utils';
import { TREATMENT_CATEGORY_REQUEST_FOR_INFO, TREATMENT_CATEGORY_PZR } from '../../../shared';

export const NEW_DENTOLO_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY = 'newDentoloClaimFormData';
export const NEW_PETOLO_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY = 'newPetoloClaimFormData';

const getNewClaimFormData = (sessionStorageKey) => {
  if (!!sessionStorage) {
    const formDataString = !!sessionStorage ? sessionStorage.getItem(sessionStorageKey) : null;
    try {
      const formData = JSON.parse(formDataString);
      return formData;
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

export const getNewClaimFormDataValue = (sessionStorageKey, key) => {
  if (!!sessionStorage) {
    const formData = getNewClaimFormData(sessionStorageKey);
    const value = formData?.[key] || '';
    return value;
  } else {
    return '';
  }
};

export const setNewClaimFormDataValue = (sessionStorageKey, key, value) => {
  if (!!sessionStorage) {
    const formData = getNewClaimFormData(sessionStorageKey);
    const newFormData = Object.assign({}, formData, { [key]: value });
    const newFormDataString = JSON.stringify(newFormData);
    sessionStorage.setItem(sessionStorageKey, newFormDataString);
  }
};

//# DENTOLO
export const DENTOLO_TREATMENT_CATEGORY = 'treatment_category';
export const DENTOLO_TREATMENT_CATEGORY_TEETH_CLEANING = 'pzr';
export const DENTOLO_TREATMENT_CATEGORY_FILLING = 'filling';
export const DENTOLO_TREATMENT_CATEGORY_OTHERS = 'other';

export const DENTOLO_DOCUMENT_TYPE = 'document_type';
export const DENTOLO_DOCUMENT_TYPE_INVOICE = 'invoice';
export const DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN = 'treatment_plan';

export const DENTOLO_DOCUMENT_DATE = 'document_date';
export const DENTOLO_TREATMENT_DATE = 'treatment_date';

export const DENTOLO_DENTIST_ID = 'dentist_id';
export const DENTOLO_DENTIST_NAME = 'dentist_name';
export const DENTOLO_PARTNER_DENTIST = 'dentolo_dentist';

export const DENTOLO_TREATMENT_AMOUNT = 'treatment_amount';

export const DENTOLO_FILLING_COUNT = 'number_of_fillings';

export const DENTOLO_IS_EXISTING_CLAIM_ANSWER = 'is_existing_claim_answer';
export const DENTOLO_IS_EXISTING_CLAIM_ANSWER_YES = 'yes';
export const DENTOLO_IS_EXISTING_CLAIM_ANSWER_NO = 'no';
export const DENTOLO_SELECTED_EXISTING_CLAIM = 'selected_claim';
export const DENTOLO_EXISTING_CLAIM_OPTION_NOT_SELECTED = 'not_selected';

export const getNewDentoloClaimFormDataValue = (key) => {
  return getNewClaimFormDataValue(NEW_DENTOLO_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY, key);
};

export const setNewDentoloClaimFormDataValue = (key, value) => {
  setNewClaimFormDataValue(NEW_DENTOLO_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY, key, value);
};

export const clearNewDentoloClaimFormData = () => {
  if (!!sessionStorage) {
    sessionStorage.removeItem(NEW_DENTOLO_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY);
  }
};

export const useNewDentoloFormDataValue = (key) => {
  const [value, setValue] = useState(getNewDentoloClaimFormDataValue(key));
  useEffect(() => {
    setNewDentoloClaimFormDataValue(key, value);
  }, [key, value]);

  return [value, setValue];
};

export const relevantDentoloClaimsForList = (claims) => {
  if (!Array.isArray(claims)) return [];

  const filteredClaims = claims.filter((claim) => {
    if (!claim?.lossDate) return false;

    const isOlderThanTwoYears = dateDiff('year', claim?.lossDate) > 2;

    return (
      !isOlderThanTwoYears &&
      claim?.treatmentCategory !== TREATMENT_CATEGORY_REQUEST_FOR_INFO &&
      claim?.treatmentCategory !== TREATMENT_CATEGORY_PZR
    );
  });

  return filteredClaims;
};

//# PETOLO
export const PET_HEALTH_TREATMENT_CATEGORIES = 'treatment_categories';
export const PET_HEALTH_TREATMENT_CATEGORY_VACCINATION = 'pet_vaccination';
export const PET_HEALTH_TREATMENT_CATEGORY_CASTRATION_OR_STERILIZATION =
  'pet_castration_or_sterilization';
export const PET_HEALTH_TREATMENT_CATEGORY_WORMING = 'pet_worming';
export const PET_HEALTH_TREATMENT_CATEGORY_FLEAS_AND_TICKS = 'pet_fleas_and_ticks';
export const PET_HEALTH_TREATMENT_CATEGORY_GASTROINTESTINAL_DISEASE = 'pet_digestive_system';

export const PETOLO_TREATMENT_CATEGORY_OTHERS = 'other';

export const PETOLO_DOCUMENT_TYPE = 'document_type';
export const PETOLO_DOCUMENT_TYPE_INVOICE = 'invoice';
export const PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN = 'treatment_plan';

export const PETOLO_DOCUMENT_DATE = 'document_date';
export const PETOLO_TREATMENT_DATE = 'treatment_date';
export const PETOLO_TREATMENT_AMOUNT = 'treatment_amount';

export const PETOLO_LINK_VIDEO_CONSULTATION = 'link_video_consultation';
export const PETOLO_LINK_VIDEO_CONSULTATION_YES = 'yes';
export const PETOLO_LINK_VIDEO_CONSULTATION_NO = 'no';

export const PETOLO_VIDEO_CONSULTATION_ID = 'video_consultation_id';
export const PETOLO_NO_SUITABLE_VIDEO_CONSULTATION = 'no_suitable_option';

export const PETOLO_CUSTOMER_NOTE = 'customer_note';

export const PETOLO_IS_EXISTING_CLAIM_ANSWER = 'is_existing_claim_answer';
export const PETOLO_IS_EXISTING_CLAIM_ANSWER_YES = 'yes';
export const PETOLO_IS_EXISTING_CLAIM_ANSWER_NO = 'no';
export const PETOLO_SELECTED_EXISTING_CLAIM = 'selected_claim';
export const PETOLO_EXISTING_CLAIM_OPTION_NOT_SELECTED = 'not_selected';

export const getNewPetoloClaimFormDataValue = (key) => {
  return getNewClaimFormDataValue(NEW_PETOLO_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY, key);
};

export const setNewPetoloClaimFormDataValue = (key, value) => {
  setNewClaimFormDataValue(NEW_PETOLO_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY, key, value);
};

export const clearNewPetoloClaimFormData = () => {
  if (!!sessionStorage) {
    sessionStorage.removeItem(NEW_PETOLO_CLAIM_FORM_DATA_SESSIONSTORAGE_KEY);
  }
};

export const useNewPetoloFormDataValue = (key) => {
  const [value, setValue] = useState(getNewPetoloClaimFormDataValue(key));
  useEffect(() => {
    setNewPetoloClaimFormDataValue(key, value);
  }, [key, value]);

  return [value, setValue];
};

export const relevantPetHealthClaimsForList = (claims) => {
  if (!Array.isArray(claims)) return [];

  const filteredClaims = claims.filter((claim) => {
    if (!claim?.lossDate) return false;

    const isOlderThanTwoYears = dateDiff('year', claim?.lossDate) > 2;

    return !isOlderThanTwoYears;
  });

  return filteredClaims;
};
