import styled from 'styled-components';

export const StyledCheckboxContainer = styled.div`
  display: flex;
  margin-top: 1em;

  span[data-label] {
    font-size: 0.875rem;
  }
`;
