import styled, { css } from 'styled-components';

import { color, width, height } from '../../../theme/functions';

import { Container } from '../../common';

export const StyledPaymentNotificationContainer = styled(Container)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const StyledFixedContainer = styled.div`
  position: relative;

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      top: ${height('headerMobile')};
      margin-top: 1px;
      left: 50%;
      width: ${width('container')};
      transform: translateX(-50%);
      z-index: 2;
    `}
`;

export const StyledPayNowLinkContainer = styled.div`
  margin-top: 0.25rem;
  text-align: ${({ textAlign }) => textAlign || 'right'};

  > a {
    font-size: 1.185rem;
    font-weight: 700;
    border-bottom: 2px solid ${color('main_blue')};
    transition: border 0.2s ease;

    &:hover {
      border-bottom-color: transparent;
    }
  }
`;
