import styled from 'styled-components';

import { min_width_md } from '../../../theme/breakpoints';
import { color, height } from '../../../theme/functions';

export const StyledDentistsMap = styled.div`
  position: relative;
  width: 100vw;
  height: 100%;
`;

export const StyledDentistsMapOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${color('ultra_clear_light_blue')};
  overflow: hidden;
  z-index: 30;
`;

export const StyledDentistsMapWrapper = styled.div`
  position: relative;
  background: transparent;
  overflow: hidden;
  appearance: none;
  height: calc(100vh - ${height('headerMobile')});

  @media (min-width: ${min_width_md}) {
    height: calc(100vh - ${height('headerDesktop')} - ${height('navMenuDesktop')});
  }
`;

export const StyledDentistsMapUI = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledDentistsMapTitleContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 50%;
  padding: 0 1rem;
  width: 100%;
  max-width: 480px;
  transform: translateX(-50%);
  z-index: 10;

  @media (min-width: ${min_width_md}) {
    left: 0;
    transform: translateX(0);
  }
`;
