import styled from 'styled-components';

import { color } from '../../theme/functions';

export const StyledOnlineVetFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    margin-bottom: 1.5rem;
  }
`;

export const StyledOnlineVetCoverImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
`;

export const StyledOnlineVetSliderTitle = styled.h5`
  font-size: 1.25rem;
  font-weight: 700;
  padding-bottom: 1rem;
`;

export const StyledOnlineVetSliderDesc = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const StyledOnlineVetSliderCounter = styled.img`
  position: absolute;
  top: -3rem;
  left: 50%;
  transform: translateX(-50%);
  width: 4rem;
`;

export const StyledOnlineVetSlider = styled.div`
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  text-align: center;
  line-height: 1.5rem;
  background-color: ${color('white')};
  border-radius: 2rem;

  .slick-list {
    .slick-slide {
      padding: 2.5rem 1.5rem 2rem;
    }
  }

  .slick-arrow {
    position: absolute;
    bottom: -3.5rem;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.5rem;
    font-size: 0;
    border: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    z-index: 2;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      display: inline-block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
    }
  }
  .slick-prev {
    left: 0;

    &::before {
      background-image: url('//s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/user-area-icons/icon.caret.left.svg');
    }
  }

  .slick-next {
    right: 0;

    &::before {
      background-image: url('//s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/user-area-icons/icon.caret.right.svg');
    }
  }

  .slick-disabled {
    opacity: 0;
  }
`;

export const StyledOnlineVetSliderCustomDots = styled.ul`
  list-style: none;
  position: absolute;
  bottom: -3rem;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0;
  margin: 0;

  li {
    button {
      margin: 0;
      padding: 0;
      font-size: 0;
      width: 0.5rem;
      height: 0.5rem;
      border: 1px solid ${color('main_blue')};
      border-radius: 50%;
      background-color: transparent;
      cursor: pointer;
      transition: background-color 0.23s ease-in-out;
    }

    &.slick-active {
      button {
        background-color: ${color('main_blue')};
      }
    }
  }
`;
