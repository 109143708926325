import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { min_width_md } from '../theme/breakpoints';
import { useInsuranceInfo } from '../hooks/useInsuranceInfo';
import {
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
} from '../shared';
import {
  DENTOLO_STEP_MAIN,
  PETOLO_STEP_MAIN,
  PET_LIABILITY_STEP_MAIN,
} from '../features/claims/new/newClaimsFormSteps';

import { InfoWindow } from './ui';
import { Container, PageTitle, Paper } from './common';

const StyledPaper = styled(Paper)`
  padding: 2rem 1rem;

  @media (min-width: ${min_width_md}) {
    padding: 2rem 3.125rem;
  }
`;

const getTryAgainUrl = (insuranceCategory) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
      return DENTOLO_STEP_MAIN;

    case INSURANCE_CATEGORY_PET_HEALTH:
      return PETOLO_STEP_MAIN;

    case INSURANCE_CATEGORY_PET_LIABILITY:
      return PET_LIABILITY_STEP_MAIN;

    default:
      return DENTOLO_STEP_MAIN;
  }
};

const getInfoWindowSuccessTitleId = (insuranceCategory) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
    case INSURANCE_CATEGORY_PET_HEALTH:
      return 'file.upload.success.title.html';

    case INSURANCE_CATEGORY_PET_LIABILITY:
      return 'file.upload.pet_liability.success.title.html';

    default:
      return 'file.upload.success.title.html';
  }
};

const getInfoWindowSuccessText = (insuranceCategory, filesNum) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
    case INSURANCE_CATEGORY_PET_HEALTH:
      return filesNum === '1'
        ? 'file.upload.success.text.html.singular.delay'
        : 'file.upload.success.text.html.plural.delay';

    case INSURANCE_CATEGORY_PET_LIABILITY:
      return 'file.upload.pet_liability.success.text.html';

    default:
      return filesNum === '1'
        ? 'file.upload.success.text.html.singular.delay'
        : 'file.upload.success.text.html.plural.delay';
  }
};

function FileUploadStatus() {
  const intl = useIntl();

  const { status } = useParams();
  const { category: insuranceCategory } = useInsuranceInfo();

  const { search } = useLocation();
  const params = queryString.parse(search);

  const filesNum = Number.isInteger(parseInt(params?.count)) ? params.count : '0';

  const isSuccess = status === 'success';
  const infoWindowMode = isSuccess ? 'claim-success' : 'failure';
  const infoWindowTitle = intl.formatMessage({
    id: isSuccess
      ? getInfoWindowSuccessTitleId(insuranceCategory)
      : 'file.upload.failure.title.html',
  });

  const infoWindowSuccessText = intl.formatMessage(
    {
      id: getInfoWindowSuccessText(insuranceCategory, filesNum),
    },
    { filesNum }
  );

  const infoWindowFailureText = intl.formatMessage({ id: 'file.upload.failure.text.html' });

  const infoWindowSuccessButton = {
    text: intl.formatMessage({ id: 'file.upload.success.back.home.btn' }),
    to: '/',
  };
  const infoWindowFailureButtons = [
    {
      
      text: intl.formatMessage({ id: 'file.upload.failure.cancel.btn' }),
      to: '/',
      style: 'outline',
    },
    {
      text: intl.formatMessage({ id: 'file.upload.failure.try.again.btn' }),
      to: getTryAgainUrl(insuranceCategory),
      style: 'primary',
    },
  ];

  return (
    <>
      <Container>
        <PageTitle>
          <FormattedMessage id="file.upload.title" />
        </PageTitle>

        <StyledPaper>
          <InfoWindow
            mode={infoWindowMode}
            titleHtml={infoWindowTitle}
            textHtml={isSuccess ? infoWindowSuccessText : infoWindowFailureText}
            button={isSuccess ? infoWindowSuccessButton : null}
            buttons={!isSuccess ? infoWindowFailureButtons : null}
          />
        </StyledPaper>
      </Container>
    </>
  );
}

export default FileUploadStatus;
