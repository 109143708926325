import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { Button } from '../Button';
import { color, boxShadow } from '../../../theme/functions';
import { min_width_md } from '../../../theme/breakpoints';

export const StyledSelectUserItemButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  background-color: ${color('white')};
  box-shadow: ${boxShadow('box_shadow_1')};
  border-radius: 5px;
  transition: opacity 0.3s ease-in-out;

  & + & {
    margin-top: 1rem;
  }

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.5;
    `}

  &::after {
    content: none;
  }
`;

//# implementing this way coz was receiving following warning:
//# - Received `true` for a non-boolean attribute `active`
export const StyledActiveUserItem = styled(({ active, ...rest }) => <Link {...rest} />)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${color('main_blue')};

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `};
`;

export const StyledUserItemLogo = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 1rem;

  @media (min-width: ${min_width_md}) {
    width: 4.5rem;
    height: 4.5rem;
    margin-right: 1.5rem;
  }
`;

export const StyledUserItemAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 1rem;
  font-size: 2.5rem;
  font-weight: 400;
  background-color: ${color('main_green')};
  border-radius: 50%;

  @media (min-width: ${min_width_md}) {
    width: 5.5rem;
    height: 5.5rem;
    margin-right: 1.5rem;
    font-size: 3rem;
  }
`;

export const StyledUserItemName = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  text-decoration: underline;
`;

export const StyledUserItemPolicy = styled.div`
  margin-top: 0.5rem;
  font-size: 1rem;
`;

export const CurrentCustomerBox = styled.div`
  margin-bottom: 2rem;

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.5;
    `}
`;
