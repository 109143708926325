import React, { useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';

import { useTrackDtEvent } from '../../DtlTracker';
import { GET_DENTISTS, GET_CUSTOMER, googleMapsKey } from '../../shared';

import { StyledDentistsMapUI } from './styled/DentistsMap.styled';

const setDentistsMarkers = (map, dentists, setDentist, onMarkerClick) => {
  const markers = [];
  if (dentists?.length) {
    for (const dentist of dentists) {
      if (dentist?.latitude && dentist?.longitude) {
        const { latitude: lat, longitude: long } = dentist;
        const latLng = new window.google.maps.LatLng(lat, long);
        const marker = new window.google.maps.Marker({
          map,
          icon: 'https://cdn.faircare.de/maps/markers/icon.geomarker-64x64@1x.png',
          position: latLng,
        });

        window.google.maps.event.addListener(marker, 'click', function () {
          onMarkerClick && onMarkerClick(dentist);
          map.panTo(this.getPosition());
          setDentist(dentist);
        });
        markers.push(marker);
      }
    }
  }
  return markers;
};

const setDentistsClusters = (map, markers) => {
  new window.MarkerClusterer(map, markers, {
    maxZoom: 20,
    imagePath: 'https://cdn.faircare.de/maps/markers/m',
  });
};

const countVisibleMarkers = (map, markers) => {
  const bounds = map.getBounds();
  let count = 0;
  for (let i = 0; i < markers.length; i++) {
    if (bounds.contains(markers[i].getPosition())) {
      count++;
    }
  }
  return count;
};

const zoomToDentists = (map, markers, currentZoom, onEnd) => {
  map.setZoom(currentZoom);
  setTimeout(() => {
    const visibleDentistsCount = countVisibleMarkers(map, markers);
    if (currentZoom > 5 && visibleDentistsCount <= 0) {
      zoomToDentists(map, markers, currentZoom - 1, onEnd);
    } else if (visibleDentistsCount > 0) {
      setTimeout(() => onEnd(), 800);
    }
  });
};

const centerMapByPostcode = (map, markers, postcode, setReady) => {
  const geocoder = new window.google.maps.Geocoder();
  if (geocoder) {
    geocoder.geocode(
      {
        address: `${postcode}`,
        componentRestrictions: {
          country: 'DE',
        },
      },
      function (results, status) {
        if (status === window?.google?.maps?.GeocoderStatus?.OK && results?.length) {
          const geometry = results[0]?.geometry;
          if (geometry) {
            map.panTo(geometry?.location);
            zoomToDentists(map, markers, 14, (_) => setReady(true));
          }
        } else {
          setReady(true);
        }
      }
    );
  }
};

const onMapLoad = (client, map, setDentist, setReady, onMarkerClick) => {
  const getCustomer = client.query({ query: GET_CUSTOMER });
  const getDentists = client.query({ query: GET_DENTISTS });

  Promise.all([getDentists, getCustomer])
    .then(([dentistsData, customerData]) => {
      const dentists = dentistsData?.data?.dentists || [];
      const { postcode } = customerData?.data?.customer || {};
      const markers = [...setDentistsMarkers(map, dentists, setDentist, onMarkerClick)];

      setDentistsClusters(map, markers);
      centerMapByPostcode(map, markers, postcode, setReady);
    })
    .catch();
};

const onScriptLoad = (id, options, client, setDentist, setReady, onMarkerClick) => {
  if (!!window?.google?.maps) {
    const map = new window.google.maps.Map(document.getElementById(id), options);
    onMapLoad(client, map, setDentist, setReady, onMarkerClick);
  }
};

const loadMapClusters = (id, options, client, setDentist, setReady, onMarkerClick) => {
  if (typeof window.MarkerClusterer === 'function') {
    onScriptLoad(id, options, client, setDentist, setReady, onMarkerClick);
  } else {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = `https://cdn.faircare.de/maps/markerclustererplus.js`;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
    s.addEventListener('load', (e) => {
      onScriptLoad(id, options, client, setDentist, setReady, onMarkerClick);
    });
  }
};

const loadGoogleMaps = (id, options, client, setDentist, setReady, onMarkerClick) => {
  if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
    loadMapClusters(id, options, client, setDentist, setReady, onMarkerClick);
  } else {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = `https://maps.google.com/maps/api/js?key=${googleMapsKey}`;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
    s.addEventListener('load', (e) => {
      loadMapClusters(id, options, client, setDentist, setReady, onMarkerClick);
    });
  }
};

const DentistsMapUI = ({ id, options, setDentist, setReady, customer, ...rest }) => {
  const client = useApolloClient();
  const [sendUserData] = useTrackDtEvent();
  const trackDtEvent = useCallback(
    (dentist = '') => {
      const trackData = {
        userData: {
          eventType: 'click dentist marker',
          time: new Date().toISOString(),
          url: window?.location?.href,
          uuid: customer?.uuid,
          dentist: dentist?.referenceKey,
        },
      };
      sendUserData(trackData);
    },
    [customer, sendUserData]
  );

  useEffect(() => {
    if (!window.google) {
      loadGoogleMaps(id, options, client, setDentist, setReady, trackDtEvent);
    } else {
      loadMapClusters(id, options, client, setDentist, setReady, trackDtEvent);
    }
  }, [id, options, client, setDentist, setReady, trackDtEvent]);

  return <StyledDentistsMapUI id={id} {...rest} />;
};

DentistsMapUI.propTypes = {
  /** The id of the element that the map will init */
  id: PropTypes.string.isRequired,
  /** You can see an example of the options below */
  options: PropTypes.object,
  /** We are using setDentist here to set a dentist on each marker's click event */
  setDentist: PropTypes.func,
  /** Use setReady to hide the Loading component and show the map */
  setReady: PropTypes.func,
  customer: PropTypes.object,
};

export default DentistsMapUI;
