import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { StyledDropdownSearch } from './styled/DropdownSearch.styled';

function DropdownSearch(props) {
  const { defaultValue, placeholder, autoFocus, options, isDisabled, isSearchable, onSelect } =
    props;

  const [selectedOption, setSelectedOption] = useState(defaultValue || null);
  const onOptionSelect = (option) => {
    onSelect(option);
    setSelectedOption(option);
  };

  return (
    <StyledDropdownSearch>
      <Select
        isDisabled={isDisabled}
        placeholder={placeholder}
        classNamePrefix="dropdown-search"
        defaultValue={selectedOption}
        autoFocus={autoFocus}
        isLoading={!options.length}
        isSearchable={isSearchable}
        onChange={onOptionSelect}
        options={options}
      />
    </StyledDropdownSearch>
  );
}

export default DropdownSearch;

DropdownSearch.defaultProps = {
  defaultValue: null,
  placeholder: '',
  autoFocus: false,
  options: [],
  isDisabled: false,
  isSearchable: false,
  onSelect: () => {},
};

DropdownSearch.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onSelect: PropTypes.func,
};
