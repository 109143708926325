const sizes = {
  height: {
    headerDesktop: '4rem',
    headerMobile: '4rem',
    navMenuDesktop: '3.1rem',
  },
  width: {
    container: '40rem',
  },
};

export default sizes;
