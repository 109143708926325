import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  ButtonContainer,
  ErrorMessage,
  PageTitle,
  Text,
  TextArea,
} from '../../../../components/common';
import { Button } from '../../../../components/ui';
import useDebounce from '../../../../hooks/useDebounce';

const CustomerNote = ({
  backButtonLink,
  nextButtonLink,
  pageTitleId,
  maxWords,
  pageDescriptionId,
  customerNote,
  setCustomerNote,
  placeholder,
  errorMessageId,
}) => {
  let history = useHistory();
  const intl = useIntl();
  const [isValid, setIsValid] = useState(true);
  const [text, setText] = useState(customerNote);
  const debouncedInfoText = useDebounce(text, 500);

  const maxLetterExceeded = useCallback(
    (text) => {
      return text.trim().split(/\s+/).length > maxWords;
    },
    [maxWords]
  );
  useEffect(() => {
    if (debouncedInfoText) {
      if (maxLetterExceeded(debouncedInfoText)) {
        setCustomerNote('');
        setIsValid(false);
      } else {
        setCustomerNote(debouncedInfoText);
        setIsValid(true);
      }
    } else {
      setCustomerNote('');
    }
  }, [debouncedInfoText, setCustomerNote, maxLetterExceeded]);

  const handleCustomerNoteChange = (ev) => {
    if (!ev) return;
    const value = ev?.target?.value;
    setText(value);
  };

  const defaultErrorMessageId = 'new_claim.date.info_text_max_error_message';

  return (
    <>
      <BackButton to={backButtonLink}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      <PageTitle>
        <FormattedMessage id={pageTitleId} />
      </PageTitle>

      <Text style={{ marginBottom: '1.5rem' }}>
        <FormattedMessage id={pageDescriptionId} />
      </Text>

      <TextArea
        name="treatment_Info_box"
        placeholder={intl.formatMessage({
          id: placeholder,
        })}
        value={text}
        onChange={handleCustomerNoteChange}
        data-testid="treatment-info-text"
      />
      {!isValid && (
        <ErrorMessage>
          <FormattedMessage id={errorMessageId || defaultErrorMessageId} />
        </ErrorMessage>
      )}
      <ButtonContainer>
        <Button
          variant="primary"
          disabled={!debouncedInfoText || maxLetterExceeded(debouncedInfoText)}
          onClick={() => history.push(nextButtonLink)}
        >
          <FormattedMessage id="common.button.next" />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default CustomerNote;
