import styled from 'styled-components';

import { min_width_md } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

export const StyledPromotionCarouselCard = styled.div`
  width: 100%;
`;

export const StyledPromotionCarouselCardContent = styled.div`
  display: flex;
  column-gap: 1rem;
  /* min-height: 4em; */

  @media (min-width: ${min_width_md}) {
    flex-direction: column;
    justify-content: flex-start;
    /* min-height: 9em; */
  }
`;

export const StyledPromotionCarouselCardContentImageWrapper = styled.div`
  width: 2.7rem;
  height: 2.7rem;

  > img {
    height: 100%;
  }

  @media (min-width: ${min_width_md}) {
    width: 2.875rem;
    height: 2.875rem;
    margin: 0 auto;
  }
`;

export const StyledPromotionCarouselCardContentWrapper = styled.h4`
  text-align: left;
  font-size: 18px;
  color: ${color('main_blue')};

  @media (min-width: ${min_width_md}) {
    text-align: center;
    margin-top: 2rem;
    margin-left: 0;
    font-size: 20px;
  }
`;

export const StyledPromotionCarouselCardMegaContent = styled.span`
  font-weight: 700;
`;
export const StyledPromotionCarouselCardNormalContent = styled.span`
  font-weight: 300;
`;

export const StyledPromotionCarouselCardContentTitle = styled.h4`
  font-size: 1rem;
  font-weight: 700;
  color: ${color('main_blue')};
  text-align: left;

  @media (min-width: ${min_width_md}) {
    margin-left: 0;
    margin-top: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const StyledPromotionCarouselButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
