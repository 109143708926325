import { useClaimUploadFlowSource } from "../../claimUploadFlowSource";
import {
  PARAM_CLAIM_ID,
  PETOLO_EDIT_STEP_TREATMENT_DATE,
  PETOLO_EDIT_STEP_TREATMENT_PRICE,
  PETOLO_EDIT_STEP_UPLOAD_DOCUMENTS
} from "../../edit/editClaimFormSteps";
import {
  PETOLO_DOCUMENT_TYPE_INVOICE,
  PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
} from "../../claimFormData/newClaimFormData";
import {
  PETOLO_STEP_TREATMENT_DATE,
  PETOLO_STEP_TREATMENT_PRICE,
  PETOLO_STEP_UPLOAD_DOCUMENTS
} from "../../new/newClaimsFormSteps";

export const useSelectDocumentCreationDatePetoloBackButtonLink = (claimId) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const backButtonLink = PETOLO_EDIT_STEP_UPLOAD_DOCUMENTS.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLink);
  } else {
    return withSource(PETOLO_STEP_UPLOAD_DOCUMENTS);
  }
}

const getNextButtonLinkForNewClaim = (documentType) => {
  switch (documentType) {
    case PETOLO_DOCUMENT_TYPE_INVOICE:
      return PETOLO_STEP_TREATMENT_DATE;
    case PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN:
      return PETOLO_STEP_TREATMENT_PRICE;
    default:
      return PETOLO_STEP_TREATMENT_PRICE;
  }
}

const getNextButtonLinkForExistingClaim = (documentType) => {
  switch (documentType) {
    case PETOLO_DOCUMENT_TYPE_INVOICE:
      return PETOLO_EDIT_STEP_TREATMENT_DATE;
    case PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN:
      return PETOLO_EDIT_STEP_TREATMENT_PRICE;
    default:
      return PETOLO_EDIT_STEP_TREATMENT_PRICE;
  }
}

export const useSelectDocumentCreationDatePetoloNextButtonLink = (claimId, documentType) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const nextButtonLink = getNextButtonLinkForExistingClaim(documentType);
    const nextButtonLinkWithClaimId = nextButtonLink.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLinkWithClaimId);
  } else {
    const nextButtonLink = getNextButtonLinkForNewClaim(documentType);
    return withSource(nextButtonLink);
  }
}