import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { isValidEmail } from '../../../../../utils/emailValidationUtils';

import { Button } from '../../../../../components/ui';
import {
  BackButton,
  ButtonContainer,
  ErrorMessage,
  FormLabel,
  Input,
  PageTitle,
} from '../../../../../components/common';
import { PersonalDetailsFormContainer } from './PersonalDetails.styled';
import { INVALID_FIELD, VALID_FIELD } from '../../../../../shared';
import AddressForm, { validatePostCode } from './AddressForm';

const validatePhoneNumber = (phone) => (!!phone ? phone.length >= 6 && phone.length <= 12 : true);

function PersonalDetailsForm(props) {
  const {
    backButtonLink,
    nextButtonLink,
    pageTitleId,
    fullname,
    address,
    houseNumber,
    postcode,
    city,
    email,
    telephone,
    onNameChange,
    onAddressChange,
    onHouseNumberChange,
    onPostcodeChange,
    onCityChange,
    onEmailChange,
    onTelephoneChange,
  } = props;

  const history = useHistory();

  const [startLiveValidation, setStartLiveValidation] = useState(false);
  const [postCodeStatus, setPostCodeStatus] = useState(VALID_FIELD);
  const [emailStatus, setEmailStatus] = useState(VALID_FIELD);
  const [phoneStatus, setPhoneStatus] = useState(VALID_FIELD);

  const validateForm = useCallback(() => {
    let isValid = true;

    if (!validatePostCode(postcode)) {
      setPostCodeStatus(INVALID_FIELD);
      isValid = false;
    } else {
      setPostCodeStatus(VALID_FIELD);
    }

    if (email && !isValidEmail(email)) {
      setEmailStatus(INVALID_FIELD);
      isValid = false;
    } else {
      setEmailStatus(VALID_FIELD);
    }

    if (!validatePhoneNumber(telephone)) {
      setPhoneStatus(INVALID_FIELD);
      isValid = false;
    } else {
      setPhoneStatus(VALID_FIELD);
    }

    return isValid;
  }, [email, telephone, postcode]);

  useEffect(() => {
    if (!startLiveValidation) return;

    validateForm();
  }, [startLiveValidation, validateForm]);

  const handleButtonClick = (ev) => {
    ev && ev.preventDefault();

    if (!startLiveValidation) {
      setStartLiveValidation(true);
    }

    const isValid = validateForm();
    if (!isValid || !validForm) return;

    history.push(nextButtonLink);
  };

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    if (/^([0-9]\d*)$/.test(value) || value === '') {
      onTelephoneChange(event);
    }
  };

  const validForm = !!fullname && !!address && !!houseNumber && !!postcode && !!city;

  return (
    <>
      <BackButton to={backButtonLink}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      <PageTitle>
        <FormattedMessage id={pageTitleId} />
      </PageTitle>

      <PersonalDetailsFormContainer>
        <FormLabel>
          <FormattedMessage id="new_claim.form.label.name" />
        </FormLabel>
        <Input
          type="text"
          name="fullname"
          placeholder="Max Mustermann"
          value={fullname || ''}
          onChange={onNameChange}
        />

        <AddressForm
          address={address || ''}
          houseNumber={houseNumber || ''}
          postcode={postcode || ''}
          city={city || ''}
          onAddressChange={onAddressChange}
          onHouseNumberChange={onHouseNumberChange}
          onPostcodeChange={onPostcodeChange}
          onCityChange={onCityChange}
          postCodeStatus={postCodeStatus}
        />

        <FormLabel>
          <FormattedMessage id="new_claim.form.label.email.optional" />
        </FormLabel>
        <Input
          type="email"
          name="email"
          placeholder="max@mustermann.com"
          value={email || ''}
          onChange={onEmailChange}
        />
        {emailStatus === INVALID_FIELD && (
          <ErrorMessage data-testid="error-email">
            <FormattedMessage id="personal.details.form.email.error.message" />
          </ErrorMessage>
        )}

        <FormLabel>
          <FormattedMessage id="new_claim.form.label.phone.optional" />
        </FormLabel>
        <Input
          type="tel"
          minLength="6"
          maxLength="12"
          name="telephone"
          placeholder="0176 12345678"
          value={telephone || ''}
          onChange={handlePhoneChange}
        />
        {phoneStatus === INVALID_FIELD && (
          <ErrorMessage data-testid="error-email">
            <FormattedMessage id="personal.details.form.phone.error.message" />
          </ErrorMessage>
        )}
      </PersonalDetailsFormContainer>

      <ButtonContainer>
        <Button onClick={handleButtonClick} disabled={!validForm} data-testid="button-next">
          <FormattedMessage id="common.button.next" />
        </Button>
      </ButtonContainer>
    </>
  );
}

export default PersonalDetailsForm;
