import React from 'react';
import { FormattedMessage } from 'react-intl';

import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO } from '../../shared';
import { useInsuranceType } from '../../hooks/useInsuranceType';
import {
  Premium,
  Conditions,
  AdditionalAdvantagesPetolo,
  AdditionalAdvantages,
  MainDetails,
  AddOns,
} from '../PolicyDetailsItems';

import { Paper } from '../common';
import { StyledPolicyDetailsLink } from '../styled/PolicyDetails.styled';

const PolicyDetailsCard = ({ policyCoverageInfo, customer }) => {
  const insuranceType = useInsuranceType();
  const isDentolo = insuranceType === INSURANCE_TYPE_DENTOLO;
  const isPetolo = insuranceType === INSURANCE_TYPE_PETOLO;

  const contractPremium = customer?.contract?.contractPremium;
  const contractBudgetSchedule = customer?.contract?.contractBudgetSchedule;
  const budgetScheduleAddon = customer?.contract?.addonBudgedSchedule;
  const budgetAmountInfo = !!budgetScheduleAddon
    ? budgetScheduleAddon?.limits
    : contractBudgetSchedule;
  const showInSinglePage = !!policyCoverageInfo?.single_page;

  return (
    <Paper>
      <AddOns details={policyCoverageInfo?.addons} />
      <MainDetails details={policyCoverageInfo?.main} />

      {isDentolo && <AdditionalAdvantages details={policyCoverageInfo?.additional_advantages} />}

      {isPetolo && (
        <AdditionalAdvantagesPetolo details={policyCoverageInfo?.additional_advantages_petolo} />
      )}

      {showInSinglePage && (
        <>
          <Conditions
            conditions={policyCoverageInfo?.conditions}
            budgetInfo={policyCoverageInfo?.budget}
            budgetAmountInfo={budgetAmountInfo}
            hasAddon={!!budgetScheduleAddon}
          />

          <Premium premiumInfo={policyCoverageInfo?.premium} contractPremium={contractPremium} />
        </>
      )}

      {!showInSinglePage && isDentolo && (
        <StyledPolicyDetailsLink to="/policy-details" variant="outline">
          <FormattedMessage id="policy.covergae_info.all_details.button" />
        </StyledPolicyDetailsLink>
      )}
    </Paper>
  );
};

export default PolicyDetailsCard;
