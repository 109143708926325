import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import { GET_REFERRAL_LIST, imgLinks, useReady } from '../../../../shared';

import ReferralList from '../ReferralList';
import ReferralStatusDescription from '../ReferralStatusDescription';
import { Loading } from '../../../../components/ui';
import { PageTitle } from '../../../../components/common';
import { StyledReferralCard } from '../ReferralPage.styled';
import {
  StyledPetoloReferralStatusEmptyContainer,
  StyledPetoloReferralStatusEmptyDescription,
  StyledPetoloReferralStatusEmptyImageContainer,
  StyledPetoloReferralStatusEmptyImg,
  StyledPetoloReferralStatusEmptyTitle,
} from './PetoloReferral.styled';

const referralStatusDescription = [
  { id: 1, descriptionId: 'referral.status.description.pending' },
  { id: 2, descriptionId: 'referral.status.description.cancelled' },
  { id: 3, descriptionId: 'referral.status.description.completed' },
];

function PetoloReferralStatus() {
  const ready = useReady();

  const transitionTimeout = 500;

  const { data, loading, refetch } = useQuery(GET_REFERRAL_LIST);
  const { referrals } = data || {};

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) return <Loading showLogo />;

  if (!loading && (referrals?.length <= 0 || !referrals)) {
    return <EmptyPetoloReferralStatus />;
  }

  return (
    <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
      <StyledReferralCard>
        <PageTitle style={{ fontSize: '1.5rem' }}>
          <FormattedMessage id="referral.page.status.title" />
        </PageTitle>

        <ReferralList referrals={referrals} />

        <ReferralStatusDescription
          titleId="referral.status.description.title"
          statusDescription={referralStatusDescription}
        />
      </StyledReferralCard>
    </CSSTransition>
  );
}

export default PetoloReferralStatus;

const EmptyPetoloReferralStatus = () => {
  return (
    <StyledPetoloReferralStatusEmptyContainer>
      <StyledPetoloReferralStatusEmptyTitle>
        <FormattedMessage id="referral.page.status.empty.title" />
      </StyledPetoloReferralStatusEmptyTitle>

      <StyledPetoloReferralStatusEmptyImageContainer>
        <StyledPetoloReferralStatusEmptyImg
          src={imgLinks['image.petolo.referral.fressnapf.voucher']}
          alt="petolo referral"
        />
      </StyledPetoloReferralStatusEmptyImageContainer>

      <StyledPetoloReferralStatusEmptyDescription>
        <FormattedMessage
          id="referral.page.status.empty.description"
          values={{ product: 'petolo' }}
        />
      </StyledPetoloReferralStatusEmptyDescription>
    </StyledPetoloReferralStatusEmptyContainer>
  );
};
