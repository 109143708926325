const breakpoints = {
  max_width_xs: '480px',
  min_width_sm: '480px',
  max_width_sm: '768px',
  min_width_md: '768px',
  max_width_md: '992px',
  min_width_lg: '992px',
};

export default breakpoints;

export const max_width_xs = '480px';
export const min_width_sm = '480px';
export const max_width_sm = '768px';
export const min_width_md = '768px';
export const max_width_md = '992px';
export const min_width_lg = '992px';
