import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import { PET_LIABILITY_STEP_DAMAGE_CAUSED_BY } from '../../new/newClaimsFormSteps';

export const useLiabilityReasonPetLiabilityBackButtonLink = () => {
  return '/user-claims-info';
};

export const useLiabilityReasonPetLiabilityNextButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  const nextButtonLink = PET_LIABILITY_STEP_DAMAGE_CAUSED_BY;
  return withSource(nextButtonLink);
};
