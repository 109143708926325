import React from 'react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { showMoney, germanMonths } from '../../shared';

import { Paragraph } from '../ui';
import {
  StyledPaymentStatusBlock,
  StyledPaymentStatusBlockIcon,
  StyledPaymentStatusBlockRepayLink,
  StyledPaymentStatusBlockTag,
} from './PaymentDetails.styled';

function PaymentStatusBlock(props) {
  const {
    status,
    billingMonth,
    amountCents,
    policyAmountCents,
    addonAmountCents,
    collectAiClaimLink,
    executeAt,
  } = props?.data;

  const inValidStatus = status === 'failed' || status === 'blocked' ? true : false;
  const imageStatus = () => {
    switch (status) {
      case 'failed':
      case 'blocked':
      case 'cancelled':
        return 'icon.failed';
      case 'paid':
        return 'icon.success';
      case 'exportable':
      case 'upcoming':
      case 'exported':
        return 'confirm.time';
      case 'refunded':
      case 'refundable':
        return 'flying.money';
      default:
        return 'icon.failed';
    }
  };

  const exportableStatus = () => {
    if (status === 'exported' && executeAt) {
      return (
        <StyledPaymentStatusBlockTag variant="info">
          <FormattedMessage id="payment.exported.message.one" />
          {dayjs(executeAt).date()}
          {'.'}
          {dayjs(executeAt).month() + 1}
          <FormattedMessage id="payment.exported.message.two" />
        </StyledPaymentStatusBlockTag>
      );
    } else if (status === 'exportable' || status === 'upcoming') {
      return (
        <StyledPaymentStatusBlockTag variant="info">
          <FormattedMessage id="payment.exportable.message" />
        </StyledPaymentStatusBlockTag>
      );
    } else if (status === 'cancelled') {
      return (
        <StyledPaymentStatusBlockTag variant="transparent">
          <FormattedMessage id="payment.cancelled.message" />
        </StyledPaymentStatusBlockTag>
      );
    } else if (status === 'refunded' || status === 'refundable') {
      return (
        <StyledPaymentStatusBlockTag variant="info">
          {status === 'refunded' ? (
            <FormattedMessage id="payment.exportable.refunded" />
          ) : (
            <FormattedMessage id="payment.exportable.refundable" />
          )}
        </StyledPaymentStatusBlockTag>
      );
    }
  };

  return (
    <StyledPaymentStatusBlock>
      <h4>
        {germanMonths[dayjs(billingMonth).month()]}
        &nbsp;
        {dayjs(billingMonth).year()}
      </h4>

      <Paragraph style={{ marginTop: '0.25rem' }}>
        <FormattedMessage id="payment.status.total_premium" />
        :&nbsp;
        {showMoney(amountCents / 100)}
      </Paragraph>

      {Number.isInteger(addonAmountCents) && addonAmountCents > 0 && (
        <Paragraph style={{ marginTop: '0.25rem', fontSize: '0.875rem' }}>
          {Number.isInteger(policyAmountCents) && (
            <>
              {`${showMoney(policyAmountCents / 100)} `}
              <FormattedMessage id="payment.status.policy_premium" />
            </>
          )}
          &nbsp;
          {`+ ${showMoney(addonAmountCents / 100)} `}
          <FormattedMessage id="policies.addons.im_coverage" />
        </Paragraph>
      )}

      {exportableStatus()}

      {inValidStatus && (
        <>
          <StyledPaymentStatusBlockTag variant="error">
            <FormattedMessage id="payment.blocked.message" />
          </StyledPaymentStatusBlockTag>

          {status === 'failed' && (
            <StyledPaymentStatusBlockRepayLink href={collectAiClaimLink}>
              <FormattedMessage id="payment.failed.re.payment.link" />
            </StyledPaymentStatusBlockRepayLink>
          )}
        </>
      )}

      {status === 'paid' && (
        <StyledPaymentStatusBlockTag variant="success">
          <FormattedMessage id="payment.paid.message" />
        </StyledPaymentStatusBlockTag>
      )}

      <StyledPaymentStatusBlockIcon icon={imageStatus()} alt="payment status" />
    </StyledPaymentStatusBlock>
  );
}

PaymentStatusBlock.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PaymentStatusBlock;
