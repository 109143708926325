import { SIGN_IN, SWITCH_CUSTOMER, VERIFY_BIRTHDATE } from './mutations'
import {
  GET_CLAIMS, GET_CUSTOMER, GET_CUSTOMERS, GET_DOCUMENTS,
  GET_PAYMENT, GET_CUSTMER_ASSIGNMENT_COUNT,GET_CCDOCUMENTS,GET_VIDEO_CONSULTATIONS
} from './queries'
import { INVALID_TOKEN } from '../ApolloConfig'
import { useTrackZurichCustomer } from './commonHooks'

const identifyCustomer = (client, history, firstSignIn) => {
  return client
    .query({ query: GET_CUSTOMER, fetchPolicy: 'no-cache' })
    .then(res => {
      if (res.data && Object.keys(res.data.customer).length) {
        const contract = res?.data?.customer?.contract
        if (contract?.riskCarrier === 'zurich') {
          useTrackZurichCustomer()
        }

        if (contract?.signed) {
          history.push('/dashboard')
        } else {
          history.push('/confirm-documents')
        }
      }
    })
    .catch(_ => {
      history.push('/dashboard')
    })
}

const catchInvalidError = (history, error) => {
  const { message } = error || {}
  if (message?.includes(INVALID_TOKEN)) {
    history.push('/login?invalid=true')
    return
  }
  history.push('/login')
}

export const signInUser = (client, history, rememberMe, jwt) => uuid => {
  return client
    .mutate({
      mutation: SIGN_IN,
      variables: {
        uuid,
        rememberMe: rememberMe === 'true',
        jwt,
      },
      refetchQueries: [
        { query: GET_CUSTOMERS, variables: { jwt } },
        { query: GET_CUSTOMER },
        { query: GET_PAYMENT },
      ],
    })
    .then(res => {
      if (res.data && res.data.signIn.uuid) {
        const firstSignIn = res.data.signIn.firstSignIn
        identifyCustomer(client, history, firstSignIn)
      }
    })
    .catch(error => {
      catchInvalidError(history, error)
    })
}

export const switchCustomer = (client, history) => uuid => {
  return client
    .mutate({
      mutation: SWITCH_CUSTOMER,
      variables: {
        uuid,
      },
      refetchQueries: [
        { query: GET_CUSTOMERS },
        { query: GET_CUSTOMER },
        { query: GET_PAYMENT },
        { query: GET_CLAIMS },
        { query: GET_DOCUMENTS },
        { query: GET_CUSTMER_ASSIGNMENT_COUNT },
        { query: GET_CCDOCUMENTS },
        { query: GET_VIDEO_CONSULTATIONS },
      ],
    })
    .then(res => {
      if (res?.data && !res?.data?.switchCustomer?.errors?.length) {
        return identifyCustomer(client, history, null)
      }
    })
    .catch(error => {
      catchInvalidError(history, error)
    })
}

export const verifyBirthdate = (client, history, rememberMe, jwt, birthdate, uuid) => {
  return client
    .mutate({
      mutation: VERIFY_BIRTHDATE,
      variables: {
        birthdate,
        jwt,
      },
    })
    .then(res => {
      if (res.data && res.data.verifyBirthdate.errors.length < 1) {
        const signInUserWithUuid = signInUser(client, history, rememberMe, jwt)
        signInUserWithUuid(uuid)
      } else {
        history.push('/login?invalid=true')
      }
    })
    .catch(_ => {
      history.push('/login?invalid=true')
    })
}
