import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { imgLinks } from '../../../shared';

import { Container } from '../../../components/common';
import { Button } from '../../../components/ui';
import {
  StyledButtonContainer,
  StyledImg,
  StyledBackButton,
  StyledText,
} from './Petolo404Page.styled';

const Petolo404Page = () => {
  let history = useHistory();
  const intl = useIntl();

  return (
    <Container>
      <StyledBackButton
        to={'/dashboard'}
        label={<FormattedMessage id="common.button.back.home" />}
      ></StyledBackButton>

      <StyledImg src={imgLinks['petolo.404.page']} alt="404"></StyledImg>
      <StyledText>
        {intl.formatMessage({
          id: 'petolo.404.page.message',
        })}
      </StyledText>
      <StyledButtonContainer>
        <Button variant="primary" onClick={() => history.push('/dashboard')}>
          <FormattedMessage id="common.button.back.home" />
        </Button>
      </StyledButtonContainer>
    </Container>
  );
};

export default Petolo404Page;
