import styled from 'styled-components';
import { min_width_md } from '../../theme/breakpoints';

export const StyledPageContent = styled.div`
  flex: 1 0 auto;
  margin-bottom: 2em;

  @media (min-width: ${min_width_md}) {
    margin-bottom: 4em;
  }
`;
