//# theme helper functions

const path = (keys, props) => {
  if (!Array.isArray(keys) || keys?.length < 1 || !props) return null;

  const value = keys.reduce((total, value) => (total = total?.[value]), props);

  return value;
};

export const theme =
  (keys = []) =>
  (props) =>
    path(['theme', ...keys], props);

export const boxShadow = (key) => theme(['boxShadow', key]);
export const color = (key) => theme(['colors', key]);
export const colorWithOpacity = (key, alpha = 0.5) => addAlphaToHex(color(key), alpha);
export const size = (keys = []) => theme(['sizes', ...keys]);
export const height = (key) => size(['height', key]);
export const width = (key) => size(['width', key]);

//# Misc functions
export const addAlphaToHex = (value, opacity) => (props) => {
  const color = value(props);

  // coerce values so it is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};
