import { useClaimUploadFlowSource } from "../../claimUploadFlowSource";
import { DENTOLO_IS_EXISTING_CLAIM_ANSWER_YES } from "../../claimFormData/newClaimFormData";
import {
  DENTOLO_STEP_DOCUMENT_TYPE,
  DENTOLO_STEP_EXISTING_CLAIMS,
  DENTOLO_STEP_UPLOAD_DOCUMENTS
} from "../../new/newClaimsFormSteps";

export const useExistingClaimQuestionDentoloBackButtonLink = () => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();
  return withSource(DENTOLO_STEP_DOCUMENT_TYPE);
}

const getNextButtonLink = (isExistingClaim) => {
  if (isExistingClaim === DENTOLO_IS_EXISTING_CLAIM_ANSWER_YES) {
    return DENTOLO_STEP_EXISTING_CLAIMS;
  } else {
    return DENTOLO_STEP_UPLOAD_DOCUMENTS;
  }
}

export const useExistingClaimQuestionDentoloNextButtonLink = (isExistingClaim) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();
  const nextButtonLink = getNextButtonLink(isExistingClaim);
  return withSource(nextButtonLink);
}