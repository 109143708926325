import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { TOGGLE_SHOW_ONBOARD, LOCAL_SHOW_ONBOARDS } from '../../shared';
import {
  StyledOnboardWrapper,
  StyledSlidesWrapper,
  StyledOnboardRow,
  StyledOnboardContainer,
  StyledOnBoardingButton,
  StyledBoardingSlider,
} from './styled/Onboard.styled';

const Onboard = ({ initialIndex, schema, name, onboardingUrl, mainPageUrl }) => {
  let history = useHistory();

  const [onboardPosition, setOnboardPosition] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const initialIndexValue = parseInt(initialIndex, 10);
  const integerInitialIndex = isNaN(initialIndexValue) ? 1 : initialIndexValue;
  const positiveIntitalIndex = integerInitialIndex <= 0 ? 1 : integerInitialIndex;
  const validInitialIndex = positiveIntitalIndex > schema.length ? 1 : positiveIntitalIndex;

  const sliderSettings = {
    dots: true,
    infinite: false,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: validInitialIndex - 1,
    beforeChange: (_, next) => {
      history.push(`${onboardingUrl}/${next + 1}`);
    },
    afterChange: (i) => {
      setSlideIndex(i);
    },
    onLazyLoad: (i) => {
      setSlideIndex(i[0]);
    },
    onInit: () => {
      history.push(`${onboardingUrl}/${validInitialIndex}`);
      setSlideIndex(0);
    },
  };

  const [skipOnboard] = useMutation(TOGGLE_SHOW_ONBOARD, {
    variables: {
      name,
      show: false,
    },
    refetchQueries: [{ query: LOCAL_SHOW_ONBOARDS }],
  });

  useEffect(() => {
    document.querySelector('html').classList.add('no-scroll');

    return () => {
      document.querySelector('html').classList.remove('no-scroll');
    };
  }, []);

  useEffect(() => {
    let timer = null;
    const handleResize = () => {
      timer = setTimeout(() => {
        const headerMenu = document.querySelector('[data-header-menu]');
        if (headerMenu) {
          setOnboardPosition({
            height: window.innerHeight - headerMenu.offsetHeight,
            top: headerMenu.offsetHeight,
          });
        }
      }, 0);
    };
    if (onboardPosition === null) handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
    };
  }, [onboardPosition]);

  useEffect(() => {
    const focusTimer = setTimeout(() => {
      if (slideIndex === 0 && document.querySelector('.slick-arrow.slick-next')) {
        document.querySelector('.slick-arrow.slick-next').focus();
      }
      if (document.querySelector('.u3-onboard-btn-last')) {
        document.querySelector('.u3-onboard-btn-last').focus();
      }
    }, 0);
    return () => {
      clearTimeout(focusTimer);
    };
  }, [slideIndex]);

  const skipAction = (name) => {
    window.localStorage.setItem(name, 'skip');
    skipOnboard();
    history.push(mainPageUrl);
  };

  const onSlideBtnCLick = () => {
    skipAction(name);
  };

  const last = schema.length === slideIndex + 1 ? true : false;
  return (
    <>
      {onboardPosition && (
        <StyledOnboardWrapper
          style={{
            height: onboardPosition.height,
            top: onboardPosition.top,
          }}
        >
          <StyledOnboardContainer>
            <StyledOnboardRow>
              <StyledSlidesWrapper>
                <StyledBoardingSlider {...sliderSettings}>
                  {schema.map((card) => (
                    <card.name {...card} onboardName={name} key={card.id} />
                  ))}
                </StyledBoardingSlider>
              </StyledSlidesWrapper>

              {schema && (
                <StyledOnBoardingButton
                  last={last}
                  onClick={onSlideBtnCLick}
                  variant={schema[slideIndex]?.btnType || 'outline'}
                >
                  {schema[slideIndex].btnText || ``}
                </StyledOnBoardingButton>
              )}
            </StyledOnboardRow>
          </StyledOnboardContainer>
        </StyledOnboardWrapper>
      )}
    </>
  );
};

Onboard.propTypes = {
  /** The name of your onboard */
  name: PropTypes.string.isRequired,
  /** It's an array with all the onboard pages */
  schema: PropTypes.array.isRequired,
};

export default Onboard;
