import React from 'react';
import { FormattedMessage } from 'react-intl';

import { imgLinks, helpCenterLink, petHelpCenterLink } from '../../shared';
import { INSURANCE_TYPE_DENTOLO } from '../../shared';

export const miscellaneousLinkItems = [
  {
    text: <FormattedMessage id={`menu.header.item.help_center`} />,
    link: helpCenterLink,
    externalUrl: true,
    icon: imgLinks[`icon.menu.headphone`],
  },
  {
    text: <FormattedMessage id={`menu.header.item.contacts`} />,
    link: '/contact',
    externalUrl: false,
    icon: imgLinks[`icon.menu.phone`],
  },
];

export const petHelpLinkItems = [
  {
    text: <FormattedMessage id={`menu.header.item.help_center`} />,
    link: petHelpCenterLink,
    externalUrl: true,
    icon: imgLinks[`icon.menu.headphone`],
  },
  {
    text: <FormattedMessage id={`menu.header.item.contacts`} />,
    link: '/contact',
    externalUrl: false,
    icon: imgLinks[`icon.menu.phone`],
  },
];

export const myProfileLinkItems = [
  {
    text: <FormattedMessage id={`menu.header.item.tarif`} />,
    link: '/policy-info',
    externalUrl: false,
    icon: imgLinks[`icon.menu.info`],
  },
  {
    text: <FormattedMessage id={`menu.header.item.contact_details`} />,
    link: '/user-info',
    externalUrl: false,
    icon: imgLinks[`icon.menu.person`],
  },
  {
    text: <FormattedMessage id={`menu.header.item.docs`} />,
    link: '/contract-docs',
    externalUrl: false,
    icon: imgLinks[`icon.menu.doc`],
  },
  {
    text: <FormattedMessage id={`menu.header.item.premiums`} />,
    link: '/payment-details',
    externalUrl: false,
    icon: imgLinks[`icon.menu.money`],
  },
  {
    text: <FormattedMessage id={`menu.header.item.contribution_statement`} />,
    link: '/payment-overview',
    externalUrl: false,
    icon: imgLinks[`icon.contribution.blue.filled`],
    type: INSURANCE_TYPE_DENTOLO,
    iconSpacing: true,
  },
];

export const useClaimsInfoLink = {
  path: '/user-claims-info',
  title: <FormattedMessage id={`menu.header.item.reimbursement`} />,
};
export const claimsReportLink = {
  path: '/user-claims-info',
  title: <FormattedMessage id="menu.header.item.claim_report" />,
};

export const dentistsMapLink = {
  path: '/dentists-map',
  title: <FormattedMessage id={`menu.header.item.find_dentist`} />,
};

export const onlineVetsLink = {
  path: '/online-vets',
  title: <FormattedMessage id={`menu.header.item.find_vet`} />,
};

export const referralLink = {
  path: '/referral',
  title: <FormattedMessage id={`menu.header.item.referrals`} />,
};

export const contactLink = {
  path: '/contact',
  title: <FormattedMessage id={`menu.header.item.contacts`} />,
};
