import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { getFailedPayments, GET_PAYMENT } from '../../shared';

import { Notification } from '../common';
import { Href, Link } from '../ui';
import { StyledNotificationText } from '../styled/Notification.styled';
import {
  StyledPaymentNotificationContainer,
  StyledFixedContainer,
  StyledPayNowLinkContainer,
} from './styled/Payment.styled';

const NotificationContentsContainer = ({ failedPayments, children }) => {
  return failedPayments?.length === 1 ? (
    <>{children}</>
  ) : (
    <Link variant="nostyle" to="/payment-details">
      {children}
    </Link>
  );
};

const PayNowLink = ({ failedPayment }) => {
  const paymentLink = failedPayment?.collectAiClaimLink;

  return !!paymentLink ? (
    <Href variant="nostyle" href={paymentLink}>
      Jetzt bezahlen
    </Href>
  ) : (
    <Link variant="nostyle" to="/payment-details">
      Jetzt bezahlen
    </Link>
  );
};

const PaymentNotification = () => {
  let { pathname: urlPath } = useLocation();

  const [scrollY, setScrollY] = useState(0);
  const [height, setHeight] = useState('0px');

  const scroll = () => {
    setScrollY(window.pageYOffset);
  };
  const resize = () => {
    setHeight(`${ref?.current?.clientHeight || 0}px`);
  };

  useEffect(() => {
    window.addEventListener('scroll', scroll);
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('scroll', scroll);
      window.removeEventListener('resize', resize);
    };
  }, [urlPath]);

  const ref = useRef(null);
  const notificationDivRef = useCallback((node) => {
    if (node) {
      setHeight(`${node?.clientHeight}px`);
    }
    ref.current = node;
  }, []);

  const getPayments = useQuery(GET_PAYMENT);
  const payments = getPayments?.data?.payments;
  const failedPayments = getFailedPayments(payments);

  const scrollThreshold = window?.visualViewport?.width > 768 ? 68 : 16;

  if (!failedPayments || failedPayments?.length < 1) return null;

  if (failedPayments.length > 0) {
    return (
      <StyledPaymentNotificationContainer style={{ height }}>
        <StyledFixedContainer fixed={scrollY > scrollThreshold} ref={notificationDivRef}>
          <Notification type="danger">
            <NotificationContentsContainer failedPayments={failedPayments}>
              <StyledNotificationText $title noSpacing>
                Ihr Schutz ist nicht aktiv
              </StyledNotificationText>

              {failedPayments.length === 1 && (
                <>
                  <StyledNotificationText>
                    Klicken Sie hier, um Ihren offenen Beitrag zu bezahlen
                  </StyledNotificationText>

                  <StyledPayNowLinkContainer>
                    <PayNowLink failedPayment={failedPayments[0]} />
                  </StyledPayNowLinkContainer>
                </>
              )}

              {failedPayments.length > 1 && (
                <StyledNotificationText>
                  Klicken Sie hier, um Ihre offenen Beiträge zu bezahlen
                </StyledNotificationText>
              )}
            </NotificationContentsContainer>
          </Notification>
        </StyledFixedContainer>
      </StyledPaymentNotificationContainer>
    );
  }
};

export default PaymentNotification;
