import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ErrorMessage, FormLabel, Input } from '../../../../../components/common';
import { StyledAddressContainer, StyledPostcodeContainer } from './PersonalDetails.styled';
import { INVALID_FIELD } from '../../../../../shared';

export const validatePostCode = (postCode) =>
  !!postCode && postCode.length === 5 && /^([0-9]\d*)$/.test(postCode);

export default function AddressForm(props) {
  const {
    address,
    houseNumber,
    postcode,
    city,
    onAddressChange,
    onHouseNumberChange,
    onPostcodeChange,
    onCityChange,
    postCodeStatus,
  } = props;

  const handleStreetChange = (event) => {
    const streetValue = event.target.value;
    const sanitizedStreetValue = streetValue ? streetValue.replace(/  +/g, ' ') : '';
    const newEvent = { target: { value: sanitizedStreetValue } };
    onAddressChange(newEvent);
  };

  const handleHouseNumberChange = (event) => {
    const houseNumberValue = event.target.value;
    const sanitizedHouseNumberValue = houseNumberValue ? houseNumberValue.replace(/  +/g, ' ') : '';
    const newEvent = { target: { value: sanitizedHouseNumberValue } };
    onHouseNumberChange(newEvent);
  };

  const handlePostCodeChange = (event) => {
    const { value } = event.target;
    if (/^([0-9]\d*)$/.test(value) || value === '') {
      onPostcodeChange(event);
    }
  };

  const handleCityChange = (event) => {
    const cityValue = event.target.value;
    const sanitizedCityValue = cityValue
      ? cityValue.replace(/[0-9]/g, '').replace(/  +/g, ' ')
      : '';
    const newEvent = { target: { value: sanitizedCityValue } };
    onCityChange(newEvent);
  };

  return (
    <>
      <FormLabel>
        <FormattedMessage id="new_claim.form.label.street_number" />
      </FormLabel>
      <StyledAddressContainer>
        <Input
          type="text"
          name="address"
          placeholder="Sonnenstraße"
          value={address}
          onChange={handleStreetChange}
        />

        <Input
          type="text"
          name="houseNumber"
          placeholder="5"
          value={houseNumber}
          onChange={handleHouseNumberChange}
        />
      </StyledAddressContainer>

      <FormLabel>
        <FormattedMessage id="new_claim.form.label.postcode_city" />
      </FormLabel>
      <StyledPostcodeContainer>
        <Input
          type="text"
          name="postcode"
          placeholder="12345"
          value={postcode}
          onChange={handlePostCodeChange}
          minLength="5"
          maxLength="5"
          autocomplete="postal-code"
          inputMode="numeric"
        />
        <Input
          type="text"
          name="city"
          placeholder="Berlin"
          value={city}
          onChange={handleCityChange}
          autocomplete="locality"
          minLength="2"
        />
      </StyledPostcodeContainer>

      {postCodeStatus === INVALID_FIELD && (
        <ErrorMessage data-testid="error-postcode">
          <FormattedMessage id="new_claim.invalid.postcode" />
        </ErrorMessage>
      )}
    </>
  );
}
