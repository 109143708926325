const colors = {
  main_blue: '#003264',
  main_orange: '#ffa445',
  main_green: '#32ff96',
  clear_light_blue: '#dff1fd',
  faded_red: '#e46666',
  gray: '#666',
  gray_blue: '#d0d9e3',
  dark_gray_blue: '#56788c',
  green: '#b8ef9d',
  light_gray: '#e7e7e8',
  lovely_rose: '#fac8d2',
  professional_light_blue: '#7c95ae',
  red: '#ff0000',
  sky_blue: '#68ddff',
  warm_blue: '#a5add8',
  white: '#fff',
  ultra_clear_light_blue: '#f3f9fe',
  ultra_light_gray: '#f7f7f8',
  ultra_light_orange: '#fff4d9',
  vitalized_green: '#32FF96',
  vitalized_dark_green: '#2ad97f',
};

export default colors;
