import React from 'react';
import {
  PET_LIABILITY_REASON,
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_PERSON,
  PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY,
  PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_FAMILY,
  PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_NO_RELATION,
  PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_PARTNER,
  PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_ROOMMATE,
  PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_WORK_COLLEAGUE,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';

import {
  useRelationWithAffectedPartyPetLiabilityBackButtonLink,
  useRelationWithAffectedPartyPetLiabilityNextButtonLink,
} from './useRelationWithAffectedPartyPetLiability';

import ListWithRadioButton from '../common/ListWithRadioButton';

const relationConfig = [
  {
    label: 'pet_liability.new_claim.relation_with_affected_party.partner',
    value: PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_PARTNER,
  },
  {
    label: 'pet_liability.new_claim.relation_with_affected_party.family',
    value: PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_FAMILY,
  },
  {
    label: 'pet_liability.new_claim.relation_with_affected_party.flatmate',
    value: PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_ROOMMATE,
  },
  {
    label: 'pet_liability.new_claim.relation_with_affected_party.work_colleague',
    value: PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_WORK_COLLEAGUE,
  },
  {
    label: 'pet_liability.new_claim.relation_with_affected_party.no_relation',
    value: PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY_NO_RELATION,
  },
];

function RelationWithAffectedPartyPetLiability() {
  const [liabilityReason] = useNewPetLiabilityFormDataValue(PET_LIABILITY_REASON);
  const [relation, setRelation] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_RELATION_WITH_AFFECTED_PARTY
  );

  const backButtonLink = useRelationWithAffectedPartyPetLiabilityBackButtonLink();
  const nextButtonLink = useRelationWithAffectedPartyPetLiabilityNextButtonLink(liabilityReason);

  const getPageTitleId = () => {
    if (liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL) {
      return 'pet_liability.new_claim.relation_with_affected_party.page_title.injured_animal';
    } else if (liabilityReason === PET_LIABILITY_REASON_PERSON) {
      return 'pet_liability.new_claim.relation_with_affected_party.page_title.injured_person';
    } else {
      return 'pet_liability.new_claim.relation_with_affected_party.page_title.damage';
    }
  };

  return (
    <ListWithRadioButton
      name="affected_party_pet_liability"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId={getPageTitleId()}
      listConfig={relationConfig}
      selected={relation}
      setSelected={setRelation}
    />
  );
}

export default RelationWithAffectedPartyPetLiability;
