import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { min_width_md } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

import { Icon, Paper, Tag } from '../common';
import { navMenuUnderlineBaseStyle } from '../NavMenu/styled/NavMenuDesktop.styled';

const paymentDetailsLinkBaseStyle = css`
  position: relative;
  width: fit-content;
  font-size: 1rem;
  text-decoration: none;

  ${navMenuUnderlineBaseStyle}
  &::after {
    transform: scaleX(1);
    bottom: 0;
    transform-origin: bottom left;
  }

  &:hover,
  &:focus {
    &::after {
      transform: scaleX(0);
      transform-origin: bottom right;
    }
  }
`;

export const StyledPaymentStatusDocument = styled(Paper)`
  margin-bottom: 1.5rem;

  > h4 {
    margin-bottom: 1.5rem;
  }
`;

export const StyledPaymentStatusDocumentLink = styled(Link)`
  ${paymentDetailsLinkBaseStyle}
  color: ${color('white')};

  &::after {
    background-color: ${color('white')};
  }

  @media (min-width: ${min_width_md}) {
    font-size: 1.125rem;
  }
`;

export const StyledPaymentStatusBlock = styled(Paper)`
  position: relative;

  & + & {
    margin-top: 1rem;
  }
`;

export const StyledPaymentStatusBlockRepayLink = styled.a`
  ${paymentDetailsLinkBaseStyle}
  display: block;
  margin-top: 1rem;
  font-weight: 700;
  color: ${color('main_blue')};
  cursor: pointer;

  &::after {
    background-color: ${color('main_blue')};
  }

  @media (min-width: ${min_width_md}) {
    font-size: 1.25rem;
  }
`;

export const StyledPaymentStatusBlockIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  width: 1.5rem;

  @media (min-width: ${min_width_md}) {
    width: 2rem;
    height: 2rem;
  }
`;

export const StyledPaymentStatusBlockTag = styled(Tag)`
  display: flex;
  margin-top: 1rem;
  width: fit-content;
`;
