import styled from "styled-components"

export const StyledRadioContainer = styled.div`
  margin-top: 1rem;
`;

export const RadioOptionContainer = styled.div`
  display: flex;
  cursor:pointer;
  word-break: break-word;
`

