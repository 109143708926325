import styled, { css } from 'styled-components';

import { max_width_sm, min_width_md } from '../../theme/breakpoints';

import { IconButton, Paper } from '../common';

export const StyledCustomerDetailsCard = styled(Paper)`
  & + & {
    margin-top: 1rem;
  }
`;

export const StyledCustomerDetailsCardTitle = styled.h5`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;

  ${({ noSpacing }) =>
    noSpacing &&
    css`
      margin-bottom: 0;
    `}
`;

export const StyledCustomerDetailsCardTitleButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const StyledCustomerDetailsModalButton = styled(IconButton)`
  @media (max-width: ${max_width_sm}) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const StyledCustomerDetailsButtonContainer = styled.div`
  margin-top: 1.25rem;

  > a {
    margin: 0 auto;
  }

  @media (min-width: ${min_width_md}) {
    > a {
      margin-left: 0;
    }
  }
`;

export const StyledCustomerDetailsItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 2rem;
  font-size: 1rem;

  & + & {
    margin-top: 0.5rem;
  }

  > div[data-value] {
    font-weight: 700;
  }

  @media (min-width: ${min_width_md}) {
    flex-direction: row;

    > div[data-label] {
      flex: 0 1 130px;
    }

    & + & {
      margin-top: 1rem;
    }
  }
`;

export const StyledPaymentDetailsItem = styled(StyledCustomerDetailsItem)`
  flex-direction: row;
  margin-bottom: 1rem;
  column-gap: 0.5rem;
  align-items: center;

  > div[data-label] {
    flex: none;
  }
  > div[data-value] {
    > p[data-label] {
      font-weight: 100;
    }
  }
`;
