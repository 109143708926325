import { useClaimUploadFlowSource } from "../../claimUploadFlowSource";
import {
  DENTOLO_EDIT_STEP_DOCUMENT_DATE,
  DENTOLO_EDIT_STEP_TREATMENT_PRICE,
  PARAM_CLAIM_ID
} from "../../edit/editClaimFormSteps";
import {
  DENTOLO_STEP_DOCUMENT_DATE,
  DENTOLO_STEP_TREATMENT_PRICE,
} from "../../new/newClaimsFormSteps";

export const useSelectTreatmentDateDentoloBackButtonLink = (claimId) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const backButtonLink = DENTOLO_EDIT_STEP_DOCUMENT_DATE.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLink);
  } else {
    return withSource(DENTOLO_STEP_DOCUMENT_DATE);
  }
}

export const useSelectTreatmentDateDentoloNextButtonLink = (claimId) => {
  const [/*source*/, withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const nextButtonLink = DENTOLO_EDIT_STEP_TREATMENT_PRICE.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLink);
  } else {
    return withSource(DENTOLO_STEP_TREATMENT_PRICE);
  }
}