import React from 'react';

import { CheckIcon } from './';
import { Separator } from '../common';
import {
  StyledPolicyDetailsSectionTitle,
  StyledPolicyDetailsList,
  StyledPolicyDetailsTextLg,
  StyledPolicyDetailsText,
} from '../styled/PolicyDetails.styled';

const AdditionalAdvantagesPetolo = ({ details }) => {
  if (!Array.isArray(details)) return null;

  return (
    <>
      {details.map((item, index) => {
        const { title, subtitle, advantages } = item || {};
        return (
          <div key={index}>
            <StyledPolicyDetailsSectionTitle>{title}</StyledPolicyDetailsSectionTitle>

            {advantages?.map((advantage, index) => (
              <StyledPolicyDetailsList key={index}>
                <CheckIcon />

                <StyledPolicyDetailsText>{advantage?.text}</StyledPolicyDetailsText>
              </StyledPolicyDetailsList>
            ))}

            <StyledPolicyDetailsTextLg style={{ textAlign: 'right' }}>
              {subtitle}
            </StyledPolicyDetailsTextLg>

            {index !== details.length - 1 && <Separator />}
          </div>
        );
      })}
    </>
  );
};

export default AdditionalAdvantagesPetolo;
