import React, { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { CSSTransition } from 'react-transition-group';

import { getReferralShareToSocialList } from '../referralPageHelper';
import {
  useReady,
  GET_CUSTOMER,
  PETOLO_REFERRAL_URL,
  JOIN_REFERRAL_PROGRAM,
  PETOLO_REFERRAL_TERMS_LINK,
} from '../../../../shared';

import { Separator } from '../../../../components/common';
import { ErrorPlaceholder, Loading } from '../../../../components/ui';
import {
  StyledReferralAdditionalInformation,
  StyledReferralAdditionalInformationContainer,
  StyledReferralCard,
  StyledReferralCardContent,
  StyledReferralCopyLinkButton,
  StyledReferralCopyLinkOrSocialsText,
  StyledReferralFooterText,
  StyledReferralIdBox,
  StyledReferralIdContainer,
  StyledReferralStepItem,
  StyledReferralStepTitle,
  StyledReferralStepsContainer,
  StyledReferralText,
  StyledReferralTitle,
  StyledSocialGroup,
  StyledSocialImg,
  StyledSocialLinksContainer,
} from '../ReferralPage.styled';

const referralSteps = [
  {
    id: 1,
    title: <FormattedMessage id="referral.page.step.one.title" />,
    description: <FormattedMessage id="referral.page.step.one.description" />,
  },
  {
    id: 2,
    title: <FormattedMessage id="referral.page.step.two.title" />,
    description: (
      <FormattedMessage
        id="referral.page.step.two.description"
        values={{
          product: 'petolo',
          insurance: 'Tierversicherung',
          giftbrand: 'Fressnapf',
          amount: 50,
        }}
      />
    ),
  },
];

const additionalInfo = [
  {
    id: 1,
    element: (
      <FormattedMessage id="referral.page.additional_info.one" values={{ brand: 'Fressnapf' }} />
    ),
  },
  {
    id: 2,
    element: <FormattedMessage id="referral.page.additional_info.two" values={{ days: 30 }} />,
  },
];

function PetoloReferralInformation() {
  const intl = useIntl();
  const ready = useReady();

  const transitionTimeout = 500;

  const [refUrl, setRefUrl] = useState(PETOLO_REFERRAL_URL);

  const tooltipNode = useRef(null);

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const customer = customerData?.customer;
  const refId = customer?.referralCode;

  const [
    generatePetHealthReferralCode,
    { loading: referralCodeLoading, error: referralCodeGenerationError },
  ] = useMutation(JOIN_REFERRAL_PROGRAM, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  const waMessage = intl.formatMessage(
    { id: 'referral.page.petolo.whatsapp.message' },
    { referralUrl: encodeURIComponent(refUrl) }
  );

  const emailContent = {
    to: '',
    subject: intl.formatMessage({ id: 'referral.page.petolo.email.subject' }),
    body: intl.formatMessage(
      { id: 'referral.page.petolo.email.message' },
      { referralUrl: encodeURIComponent(refUrl) }
    ),
  };

  const socialParams = {
    refUrl,
    fbIcon: 'icon.user.referral.pet.fb',
    waIcon: 'icon.user.referral.pet.whatsapp',
    waMessage,
    emailIcon: 'icon.user.referral.pet.email',
    emailContent,
  };

  // Get social items to share the referral link
  const shareSocialItems = getReferralShareToSocialList(socialParams);

  // Generate referral code if customer does not have one
  useEffect(() => {
    const hasReferralCode = !!customer?.referralCode;

    if (!hasReferralCode) {
      generatePetHealthReferralCode();
    }
  }, [customer, generatePetHealthReferralCode]);

  // Add referral `id` to the referral url
  useEffect(() => {
    if (refId && refUrl.indexOf('&refid=') < 0) {
      setRefUrl(`${refUrl}&refid=${refId}`);
    }
  }, [refId, refUrl]);

  // Copy to clipboard
  const onLinkCopy = async () => {
    if (!window?.navigator?.clipboard) return;

    try {
      await window.navigator.clipboard.writeText(refUrl);

      if (!tooltipNode?.current) return;

      ReactTooltip.show(tooltipNode.current);

      setTimeout(() => {
        ReactTooltip.hide(tooltipNode.current);
      }, 1200);
    } catch (err) {
      console.error('Could not copy text: ', err);
    }
  };

  if (referralCodeLoading || customerLoading) return <Loading showLogo />;

  if ((!customerLoading && !customer) || referralCodeGenerationError) return <ErrorPlaceholder />;

  if (!customerLoading && !!customer) {
    return (
      <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
        <StyledReferralCard>
          <StyledReferralTitle>
            <FormattedMessage id="referral.page.information.header" />
          </StyledReferralTitle>

          <StyledReferralStepsContainer>
            {referralSteps?.map((item) => {
              const { id, title, description } = item || {};

              return (
                <StyledReferralStepItem key={id}>
                  <StyledReferralStepTitle>{title}</StyledReferralStepTitle>

                  <p>{description}</p>
                </StyledReferralStepItem>
              );
            })}
          </StyledReferralStepsContainer>

          <StyledReferralAdditionalInformationContainer>
            {additionalInfo?.map((item) => {
              const { id, element } = item || {};

              return (
                <StyledReferralAdditionalInformation key={id}>
                  {element}
                </StyledReferralAdditionalInformation>
              );
            })}
          </StyledReferralAdditionalInformationContainer>

          <StyledReferralCardContent>
            <StyledReferralCopyLinkButton
              variant="primary"
              data-for="link-copied"
              data-tip
              data-event="none"
              ref={tooltipNode}
              onClick={onLinkCopy}
            >
              <FormattedMessage id="referral.page.copy.button" />
            </StyledReferralCopyLinkButton>

            <ReactTooltip id="link-copied" className="u3-referral-tooltip" effect="solid">
              <FormattedMessage id="referral.page.link.copied" />
            </ReactTooltip>
          </StyledReferralCardContent>

          <StyledReferralCopyLinkOrSocialsText>
            <FormattedMessage id="referral.page.share_in_socials" />
          </StyledReferralCopyLinkOrSocialsText>

          <StyledSocialLinksContainer>
            <StyledSocialGroup>
              {shareSocialItems?.map((socialItem) => (
                <StyledSocialImg
                  key={socialItem?.id}
                  src={socialItem?.image}
                  alt={socialItem?.id}
                  onClick={() => socialItem?.click(refUrl, intl)}
                />
              ))}
            </StyledSocialGroup>
          </StyledSocialLinksContainer>

          <StyledReferralIdContainer>
            <p>
              <FormattedMessage
                id="referral.page.referral_id.description"
                values={{
                  product: 'petolo',
                  insurance: 'Tierversicherung',
                  b: (chunk) => <strong>{chunk}</strong>,
                }}
              />
            </p>

            <StyledReferralText>
              <FormattedMessage id="referral.page.referral_id.title" />
            </StyledReferralText>

            <StyledReferralIdBox>{refId}</StyledReferralIdBox>
          </StyledReferralIdContainer>

          <Separator />

          <StyledReferralFooterText>
            <FormattedMessage id="referral.page.privacy.text" />
          </StyledReferralFooterText>

          <StyledReferralFooterText>
            <FormattedMessage
              id="referral.page.terms_and_conditions.text"
              values={{
                a: (chunk) => (
                  <a target="_blank" rel="noopener noreferrer" href={PETOLO_REFERRAL_TERMS_LINK}>
                    {chunk}
                  </a>
                ),
              }}
            />
          </StyledReferralFooterText>
        </StyledReferralCard>
      </CSSTransition>
    );
  } else {
    return <></>;
  }
}

export default PetoloReferralInformation;
