import React, { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import QueryString from 'query-string';

import { GET_CUSTOMER, GET_CUSTOMERS, TOGGLE_SHOW_ONBOARD, LOCAL_SHOW_ONBOARDS } from '../shared';
import { getNameAccordingToInsuranceCategory } from '../utils/customerUtils';
import { switchCustomer, verifyBirthdate, useReady } from '../shared';

import { CurrentCustomerBox } from './ui/styled/SelectUser.styled';
import { BackButton, Container, PageTitle } from './common';
import { ActiveUserItem, ErrorPlaceholder, Paragraph, SelectUserItem } from './ui';

const SelectUser = () => {
  let history = useHistory();
  const urlValues = QueryString.parse(history?.location?.search);
  const rememberMe = urlValues?.remember_me;
  const jwt = urlValues?.auth;
  const birthdate = urlValues?.birthdate;

  const [uuid, setUuid] = useState(null);
  const [sentUuid, setSentUuid] = useState(false);

  const [promises, setPromises] = useState([]);
  const [didMutate, setDidMutate] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);

  const { data: customerData, loading: customerDataLoading } = useQuery(GET_CUSTOMER);
  const {
    data: allCustomersData,
    loading: allCustomersLoading,
    error,
  } = useQuery(GET_CUSTOMERS, { variables: { jwt } });

  const isCustomersDataLoading = customerDataLoading || allCustomersLoading;
  const currentCustomer = customerData?.customer;
  const allCustomers = allCustomersData?.customers;
  const customers = allCustomers?.filter((c) => c?.key !== currentCustomer?.key);

  const showOnboardsQuery = useQuery(LOCAL_SHOW_ONBOARDS);

  const client = useApolloClient();

  const ready = useReady();

  const setChildUuid = (uuid) => {
    setUuid(uuid);
    setDisableButtons(true);
  };

  const inactiveContractPlaceholder = <FormattedMessage id="contract.placeholder.inactive" />;
  const isCustomerContractEnded = currentCustomer?.contract?.status === 'ended';

  const sortCustomers = (customerA, customerB) => {
    // show 'active' contracts first
    return customerA?.endedContract?.localeCompare(customerB?.endedContract);
  };

  useEffect(() => {
    const switchCustomerToUuid = switchCustomer(client, history);

    if (!customerDataLoading && !!uuid && !sentUuid) {
      setSentUuid(true);
      if (!!currentCustomer) {
        switchCustomerToUuid(uuid);
      } else {
        verifyBirthdate(client, history, rememberMe, jwt, birthdate, uuid);
      }
    }
  }, [
    uuid,
    sentUuid,
    customerDataLoading,
    currentCustomer,
    client,
    history,
    rememberMe,
    jwt,
    birthdate,
  ]);

  useEffect(() => {
    if (showOnboardsQuery?.loading) return;
    if (!promises.length && showOnboardsQuery?.data?.showOnboards) {
      const showOnboards = showOnboardsQuery?.data?.showOnboards;
      const promisesArr = showOnboards.map((onboard) =>
        client.mutate({
          mutation: TOGGLE_SHOW_ONBOARD,
          variables: {
            name: onboard?.name,
            show: false,
          },
        })
      );
      setPromises(promisesArr);
    }
    if (promises.length && !didMutate) {
      Promise.all(promises)
        .then((res) => {
          setDidMutate(true);
        })
        .catch((err) => {});
    }
  }, [showOnboardsQuery, client, promises, didMutate]);

  if (!isCustomersDataLoading && !!customers) {
    return (
      <>
        <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
          <Container>
            {currentCustomer && (
              <>
                <BackButton to="/" disabled={disableButtons} />

                <CurrentCustomerBox active={!isCustomerContractEnded}>
                  <ActiveUserItem
                    name={getNameAccordingToInsuranceCategory(currentCustomer)}
                    policyName={
                      isCustomerContractEnded
                        ? inactiveContractPlaceholder
                        : currentCustomer?.policyName
                    }
                    to="/"
                    disabled={disableButtons}
                  />
                </CurrentCustomerBox>
              </>
            )}

            <PageTitle>
              <FormattedMessage id="select.customer.insured.title" />
            </PageTitle>

            <Paragraph>
              <FormattedMessage id="select.customer.insured.note" />
            </Paragraph>

            <div style={{ marginTop: '1.5rem' }}>
              {customers &&
                customers.sort(sortCustomers).map((customer) => {
                  const { uuid, endedContract, insuranceCategory, policyName } = customer || {};

                  const isContractEnded = endedContract === 'true';

                  return (
                    <SelectUserItem
                      key={uuid}
                      uuid={uuid}
                      name={getNameAccordingToInsuranceCategory(customer)}
                      policyName={isContractEnded ? inactiveContractPlaceholder : policyName}
                      insuranceCategory={insuranceCategory}
                      isContractEnded={isContractEnded}
                      setChildUuid={setChildUuid}
                      disabled={disableButtons}
                    />
                  );
                })}
            </div>
          </Container>
        </CSSTransition>
      </>
    );
  } else if (!!error) {
    return <ErrorPlaceholder />;
  } else {
    return <></>;
  }
};

export default SelectUser;
