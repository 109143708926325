import React from 'react'
import { PropTypes } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { GET_CUSTOMERS, useSelectCustomer, useJwt } from '../../shared'

const NavMenuChangeCustomerLink = props => {
  const urlValues = queryString.parse(props.location.search)
  const jwt = useJwt(props.location)

  const getCustomers = useQuery(GET_CUSTOMERS, { variables: { jwt } })
  const numOfCustomers =
    getCustomers.data.customers && getCustomers.data.customers.length
      ? getCustomers.data.customers.length
      : 0

  if (
    useSelectCustomer(props.location.pathname) ||
    urlValues.auth ||
    numOfCustomers <= 1
  )
    return <></>
  return (
    <Link {...props} variant="nostyle" to="/select-customer">
      <div className="small">
        <FormattedMessage id="sign.out.change.user" />
      </div>
    </Link>
  )
}

NavMenuChangeCustomerLink.propTypes = {
  location: PropTypes.object,
}

export default NavMenuChangeCustomerLink
