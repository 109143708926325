import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/ui';
import {
  BackButton,
  DatePicker,
  FormSectionContainer,
  ButtonContainer,
  ErrorMessage,
  PageTitle,
  Text,
} from '../../../../components/common';
import {
  combineFullDateFromInputs,
  getInputFieldValuesFromDate,
  validateMonthRange,
} from './dateUtils';
import { dateDiff } from '../../../../shared/utils';
import { VALID_DATE, NOT_VALID_DATE, IN_PAST_DATE } from '../../../../shared/constants';

const SelectDocumentCreationDate = ({
  backButtonLink,
  nextButtonLink,
  pageTitleId,
  formDescription,
  setDocumentCreationDate,
  documentCreationDate,
}) => {
  const history = useHistory();

  const documentCreationDateInputFieldValues = getInputFieldValuesFromDate(documentCreationDate);
  const [date, setDate] = useState(documentCreationDateInputFieldValues);

  const { day, month, year } = date;
  const setDay = (newDay) => setDate({ day: newDay, month, year });
  const setMonth = (newMonth) => setDate({ day, month: newMonth, year });
  const setYear = (newYear) => setDate({ day, month, year: newYear });

  const [dateStatus, setDateStatus] = useState(VALID_DATE);

  useEffect(() => {
    const newDocumentCreationDate = combineFullDateFromInputs(day, month, year);
    const newDocumentCreationDateObject = new Date(newDocumentCreationDate);
    const currentDate = new Date();
    const isValidDate = !isNaN(newDocumentCreationDateObject);
    if (isValidDate) {
      setDateStatus(VALID_DATE);
    }

    const notInTheFuture = isValidDate ? newDocumentCreationDateObject < currentDate : false;
    if (notInTheFuture && validateMonthRange(newDocumentCreationDate)) {
      setDocumentCreationDate(newDocumentCreationDate);
    } else {
      setDocumentCreationDate('');
    }
  }, [day, month, year, setDocumentCreationDate]);

  const handleButtonClick = () => {
    if (!documentCreationDate || !validateMonthRange(documentCreationDate)) {
      setDateStatus(NOT_VALID_DATE);
    } else if (
      documentCreationDate &&
      validateMonthRange(documentCreationDate) &&
      dateDiff('year', new Date(documentCreationDate)) >= 5
    ) {
      setDateStatus(IN_PAST_DATE);
    } else {
      history.push(nextButtonLink);
    }
  };

  const disabledFormChecker = () => {
    return dateStatus !== VALID_DATE || !day || !month || !year || year.length !== 4;
  };

  return (
    <>
      <BackButton to={backButtonLink}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      <PageTitle>
        <FormattedMessage id={pageTitleId} />
      </PageTitle>

      <FormSectionContainer>
        <Text style={{ fontSize: '1em', marginBottom: '.5em' }}>
          <FormattedMessage id={formDescription} />
        </Text>

        <DatePicker
          day={day}
          month={month}
          year={year}
          setDay={setDay}
          setMonth={setMonth}
          setYear={setYear}
        />

        {dateStatus === NOT_VALID_DATE && (
          <ErrorMessage data-testid="error-date">
            <FormattedMessage id="new_claim.invalid.date" />
          </ErrorMessage>
        )}
        {dateStatus === IN_PAST_DATE && (
          <ErrorMessage data-testid="error-date">
            <FormattedMessage id="new_claim.invalid.date_past" />
          </ErrorMessage>
        )}
      </FormSectionContainer>
      <ButtonContainer>
        <Button
          data-testid="next_button"
          variant="primary"
          onClick={handleButtonClick}
          disabled={disabledFormChecker()}
        >
          <FormattedMessage id="common.button.next" />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default SelectDocumentCreationDate;
