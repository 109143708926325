import React, { useState } from 'react';
import { TabPanel, Tabs } from 'react-tabs';
import { FormattedMessage } from 'react-intl';

import PetoloReferralInformation from './PetoloReferralInformation';
import PetoloReferralStatus from './PetoloReferralStatus';
import { Container, PageTitle } from '../../../../components/common';
import {
  StyledReferralTab,
  StyledReferralTabList,
  StyledReferralTabPanel,
} from '../ReferralPage.styled';

function PetoloReferral() {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  const handleSelectTab = (index) => {
    setSelectedTabIdx(index);
  };

  return (
    <Container>
      <PageTitle style={{ fontSize: '1.875rem' }}>
        <FormattedMessage
          id="referral.page.petolo.title"
          values={{ amount: 50, couponbrand: 'Fressnapf-Gutschein' }}
        />
      </PageTitle>

      <Tabs
        selectedIndex={selectedTabIdx}
        selectedTabClassName="selected-referral-tab"
        onSelect={handleSelectTab}
      >
        <StyledReferralTabList>
          <StyledReferralTab>
            <FormattedMessage id="referral.page.tab.label.one" />
          </StyledReferralTab>
          <StyledReferralTab>
            <FormattedMessage id="referral.page.tab.label.two" />
          </StyledReferralTab>
        </StyledReferralTabList>

        <StyledReferralTabPanel>
          <PetoloReferralInformation />
        </StyledReferralTabPanel>

        <TabPanel>
          <PetoloReferralStatus />
        </TabPanel>
      </Tabs>
    </Container>
  );
}

export default PetoloReferral;
