import React from 'react';
import NavUser from './NavUser';

function NavMenuSelectUserPopup(props) {
  const { trackDtEvent, customerSelect, isCustomerLoading, customer, customers } = props;

  if (customer) {
    return (
      <NavUser
        customers={customers}
        customer={customer}
        isCustomerLoading={isCustomerLoading}
        clickUserIcon={trackDtEvent(`click person logo`, customer)}
        customerSelect={customerSelect}
      />
    );
  }
  return <></>;
}

export default NavMenuSelectUserPopup;
