import { THEME_DENTOLO, THEME_PETOLO } from '../shared';
import { boxShadows, colors, sizes } from './';

const fonts = {
  primary: 'Circular TT, Arial',
};

const basePalette = {
  fonts,
  sizes,
};

export const palette = {
  [THEME_DENTOLO]: {
    ...basePalette,
    colors: {
      ...colors,
      primary: colors?.main_blue,
      secondary: colors?.main_green,
      viewport_background: colors?.ultra_clear_light_blue,
    },
    boxShadow: {
      ...boxShadows,
      primary: `0 0 0.25rem 0.0625rem ${colors.main_green}`,
    },
  },
  [THEME_PETOLO]: {
    ...basePalette,
    colors: {
      ...colors,
      primary: colors?.main_orange,
      secondary: colors?.main_blue,
      viewport_background: colors?.ultra_light_gray,
    },
    boxShadow: {
      ...boxShadows,
      primary: `0 0 0.25rem 0.0625rem ${colors.main_orange}`,
    },
  },
};
