import styled from 'styled-components';

import { min_width_md } from '../../theme/breakpoints';

import { Paper } from '../common';
import { Separator } from '../common';

export const StyledSignDocsSeparator = styled(Separator)`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

export const StyledSignDocsPaper = styled(Paper)`
  margin-top: 1.5rem;

  @media (min-width: ${min_width_md}) {
    margin-top: 2rem;
  }
`;

export const StyledButtonContainer = styled.div`
  margin-top: 1.5rem;

  > button {
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${min_width_md}) {
    margin-top: 2rem;

    > button {
      margin-right: 0;
    }
  }
`;
