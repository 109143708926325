import React from 'react';

import {
  PET_LIABILITY_AFFECTED_PARTY,
  PET_LIABILITY_AFFECTED_PARTY_OPTION_MYSELF,
  PET_LIABILITY_AFFECTED_PARTY_OPTION_OTHER,
  PET_LIABILITY_REASON,
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_PERSON,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useAffectedPartyPetLiabilityBackButtonLink,
  useAffectedPartyPetLiabilityNextButtonLink,
} from './useAffectedPartyPetLiability';

import ListWithRadioButton from '../common/ListWithRadioButton';

const affectedPartyConfig = [
  {
    label: 'pet_liability.new_claim.affected_party.option.myself',
    value: PET_LIABILITY_AFFECTED_PARTY_OPTION_MYSELF,
  },
  {
    label: 'pet_liability.new_claim.affected_party.option.other',
    value: PET_LIABILITY_AFFECTED_PARTY_OPTION_OTHER,
  },
];

function AffectedPartyPetLiability() {
  const [liabilityReason] = useNewPetLiabilityFormDataValue(PET_LIABILITY_REASON);

  const [affectedParty, setAffectedParty] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_AFFECTED_PARTY
  );

  const backButtonLink = useAffectedPartyPetLiabilityBackButtonLink(liabilityReason);
  const nextButtonLink = useAffectedPartyPetLiabilityNextButtonLink(liabilityReason, affectedParty);

  const getPageTitleId = () => {
    if (liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL) {
      return 'pet_liability.new_claim.affected_party.page_title.injured_animal';
    } else if (liabilityReason === PET_LIABILITY_REASON_PERSON) {
      return 'pet_liability.new_claim.affected_party.page_title.injured_person';
    } else {
      return 'pet_liability.new_claim.affected_party.page_title.who_is_affected';
    }
  };

  return (
    <ListWithRadioButton
      name="affected_party_pet_liability"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId={getPageTitleId()}
      listConfig={affectedPartyConfig}
      selected={affectedParty}
      setSelected={setAffectedParty}
    />
  );
}

export default AffectedPartyPetLiability;
