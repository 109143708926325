import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { parseFloatToCents } from '../../../../shared';
import { useEditIcon } from '../../../../hooks/useIcons';
import {
  DENTOLO_DENTIST_ID,
  DENTOLO_DENTIST_NAME,
  DENTOLO_DOCUMENT_DATE,
  DENTOLO_DOCUMENT_TYPE,
  DENTOLO_FILLING_COUNT,
  DENTOLO_TREATMENT_AMOUNT,
  DENTOLO_TREATMENT_CATEGORY,
  DENTOLO_TREATMENT_DATE,
  useNewDentoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import SubmitClaimForm from './SubmitClaimForm';
import { useDentoloClaims } from '../../useDentoloClaims';
import {
  useEditDetailsLinkDentolo,
  useEditDocumentsLinkDentolo,
  useSubmitClaimFormDentoloBackButtonLink,
} from './submitClaimFormDentoloHooks';

const SubmitClaimFormDentolo = (props) => {
  const { selectedDocuments, setSelectedDocuments, setClaimSubmitted } = props;

  const { claimId } = useParams();

  const intl = useIntl();

  const [submitDentoloClaim] = useDentoloClaims(claimId);

  const editIcon = useEditIcon();

  const [treatmentCategory] = useNewDentoloFormDataValue(DENTOLO_TREATMENT_CATEGORY);
  const treatmentCategoryTextMappingId = !!treatmentCategory
    ? `dentolo.new_claim.submit_claim_form.treatment_category.${treatmentCategory}`
    : 'dentolo.new_claim.submit_claim_form.treatment_category.other';

  const [documentType] = useNewDentoloFormDataValue(DENTOLO_DOCUMENT_TYPE);
  const documentTypeMappingId = !!documentType
    ? `dentolo.new_claim.select_document_type.${documentType}`
    : '';

  const [documentDate] = useNewDentoloFormDataValue(DENTOLO_DOCUMENT_DATE);
  const formattedDocumentDate = !!documentDate
    ? intl.formatDate(documentDate, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : '';

  const [treatmentDate] = useNewDentoloFormDataValue(DENTOLO_TREATMENT_DATE);
  const formattedTreatmentDate = !!treatmentDate
    ? intl.formatDate(treatmentDate, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : '';

  const [treatmentAmount] = useNewDentoloFormDataValue(DENTOLO_TREATMENT_AMOUNT);
  const formattedTreatmentAmount = !!treatmentAmount
    ? intl.formatNumber(treatmentAmount, { style: 'currency', currency: 'EUR' })
    : '';

  const [fillingsCount] = useNewDentoloFormDataValue(DENTOLO_FILLING_COUNT);

  const [dentistId] = useNewDentoloFormDataValue(DENTOLO_DENTIST_ID);
  const [dentistName] = useNewDentoloFormDataValue(DENTOLO_DENTIST_NAME);

  let cardContent = [
    {
      label: 'dentolo.new_claim.submit_claim_form.card_label.document_type',
      value: !!documentTypeMappingId ? intl.formatMessage({ id: documentTypeMappingId }) : '',
    },
    {
      label: 'dentolo.new_claim.submit_claim_form.card_label.document_date',
      value: formattedDocumentDate,
    },
    {
      label: 'dentolo.new_claim.submit_claim_form.card_label.treatment_date',
      value: formattedTreatmentDate,
    },
    {
      label: 'dentolo.new_claim.submit_claim_form.card_label.amount',
      value: formattedTreatmentAmount,
    },
    {
      label: 'dentolo.new_claim.submit_claim_form.card_label.fillings',
      value: fillingsCount,
    },
    {
      label: 'dentolo.new_claim.submit_claim_form.card_label.dentist',
      value: dentistName,
    },
  ];

  const uploadClaimForm = () => {
    const validDocuments = Array.isArray(selectedDocuments)
      ? selectedDocuments.filter((d) => d?.valid)
      : [];

    const payload = {
      files: validDocuments.map((item) => item.file),
      dentistKey: !!dentistId ? dentistId : null,
      dentistName: dentistName,
      amountCents: parseFloatToCents(treatmentAmount),
      documentType: documentType,
      treatmentCategories: !!claimId ? [] : [treatmentCategory],
      lossDate: documentDate,
      treatmentStartDate: !!treatmentDate ? treatmentDate : null,
      fillingsCount: !!fillingsCount ? fillingsCount : null,
      claimId: claimId || null,
    };

    submitDentoloClaim({
      payload,
      setSelectedDocuments,
      setClaimSubmitted,
    });
  };

  const backButtonLink = useSubmitClaimFormDentoloBackButtonLink(claimId, treatmentCategory);
  const editDetailsButtonLink = useEditDetailsLinkDentolo(claimId);
  const editUploadedFilesLink = useEditDocumentsLinkDentolo(claimId);

  return (
    <SubmitClaimForm
      selectedDocuments={selectedDocuments}
      backButtonLink={backButtonLink}
      editDetailsButtonLink={editDetailsButtonLink}
      editUploadedFilesLink={editUploadedFilesLink}
      pageTitleId={'dentolo.new_claim.submit_claim_form.page_title'}
      editIcon={editIcon}
      formDataCardHeaderId={treatmentCategoryTextMappingId}
      cardContent={cardContent}
      uploadedFilesCardHeaderId={'dentolo.new_claim.submit_claim_form.uploaded_files.title'}
      uploadClaimForm={uploadClaimForm}
      submitButtonId={'dentolo.new_claim.submit_claim_form.send_button'}
    />
  );
};

export default SubmitClaimFormDentolo;
