import styled from 'styled-components';

import { min_width_md } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

import { Accordion, PageTitle } from '../common';

export const StyledContactFormTitle = styled(PageTitle)`
  margin-top: 2rem;
`;

export const StyledContactFormField = styled.div`
  color: ${color('main_blue')};
  & + & {
    margin-top: 1rem;
  }
  > label {
    display: ${({ sameLine }) => (sameLine ? 'inline-block' : 'block')};
    margin-bottom: ${({ sameLine }) => (sameLine ? 0 : '0.5rem')};
    font-weight: 700;
  }
  > select,
  > textarea {
    width: 100%;
    padding: 0.5rem;
    border: 0.1rem solid ${color('warm_blue')};
    border-radius: 4px;
    font-size: 1rem;
    color: ${color('main_blue')};
  }
  > select {
    appearance: none;
    padding-right: 2rem;
    background: url(https://s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/icons/icon.caret-down-24x24.svg)
      no-repeat #fff;
    background-position: center right 0.375rem;
  }
`;

export const StyledContactFormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const StyledAccordion = styled(Accordion)`
  margin-top: 1.75rem;
  margin-bottom: 3rem;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 3rem;
  row-gap: 0.5rem;

  @media (min-width: ${min_width_md}) {
    flex-direction: row;
    padding-left: 3.5rem;
    column-gap: 1.25rem;
  }
`;

export const StyledWorkingTimes = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${min_width_md}) {
    flex-direction: row;
  }
`;
