import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import DentistDetailsUI from './DentistDetailsUI';
import { BackButton, Container, PageTitle } from '../common';
import { StyledDentistDetailsModal } from '../styled/DentistsDetails.styled';

const DentistDetailsModal = ({ dentist, showDetails, setShowDetails }) => {
  const intl = useIntl();

  if (!showDetails) return <></>;

  return (
    <StyledDentistDetailsModal>
      <Container>
        <BackButton
          label={intl.formatMessage({ id: 'dentist.details.back.to.map' })}
          onClick={() => setShowDetails(false)}
        />

        {dentist.practiceName && <PageTitle>{dentist.practiceName}</PageTitle>}

        <DentistDetailsUI dentist={dentist} detailsPage={true} />
      </Container>
    </StyledDentistDetailsModal>
  );
};

DentistDetailsModal.propTypes = {
  dentist: PropTypes.object,
  setShowDetails: PropTypes.func,
  showDetails: PropTypes.bool,
};

export default DentistDetailsModal;
