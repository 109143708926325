import React from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { formatDate } from "../../shared";
import {
  StyledClaimDetailsStatusHistoryItem,
  StyledProgressTimeline,
  StyledProgressTimelineVertex,
  StyledProgressTimelineEdge,
  StyledClaimDetailsStatusHistoryItemDetails,
  StyledStatusDate,
} from "./styled/ClaimStatusCard.styled";

const ClaimStatusCard = ({ date, status, first, last }) => {
  return (
    <StyledClaimDetailsStatusHistoryItem>
      <StyledProgressTimeline>
        <StyledProgressTimelineVertex />
        <StyledProgressTimelineEdge
          first={first && !last}
          last={last && !first}
          one={last && first}
        />
      </StyledProgressTimeline>

      <StyledClaimDetailsStatusHistoryItemDetails>
        {date && <StyledStatusDate>{formatDate(date)}</StyledStatusDate>}
        <FormattedMessage id={`claim.history.status.${status}`} tagName="h6" />
      </StyledClaimDetailsStatusHistoryItemDetails>
    </StyledClaimDetailsStatusHistoryItem>
  );
};

ClaimStatusCard.propTypes = {
  date: PropTypes.string,
  status: PropTypes.string,
  last: PropTypes.bool,
};

export default injectIntl(ClaimStatusCard);
