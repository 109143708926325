import styled, { css } from 'styled-components';

import { min_width_md } from '../../../theme/breakpoints';
import { color } from '../../../theme/functions';

import Icon from '../../common/Icon';

export const StyledAccordion = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: ${color('white')};
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  & + & {
    margin-top: 0.5rem;
  }
`;

export const StyledAccordionContainer = styled.div``;

export const StyledAccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem;
  color: ${color('main_blue')};
  cursor: pointer;

  > div {
    flex: 1;
    margin-right: 1.5rem;
  }
`;

export const StyledAccordionTitleIcon = styled(Icon)`
  width: 2rem;
  height: auto;

  @media (min-width: ${min_width_md}) {
    width: 2.5rem;
  }
`;

export const StyledAccordionChevron = styled(Icon)`
  transition: transform 0.3s ease-in-out;

  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(-180deg);
      transform-origin: center;
    `}
`;

export const StyledAccordionSummary = styled.div`
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.4s ease-in-out;

  &[aria-expanded='true'] {
    max-height: ${({ contentEl }) =>
      contentEl?.scrollHeight ? `${contentEl?.scrollHeight}px` : '1000px'};
  }
`;

export const StyledAccordionContent = styled.div`
  padding: 0.5rem 1rem 1.25rem;
  color: ${color('main_blue')};
  opacity: 0;
  transition-delay: 0.2s;
  transition: opacity 0.2s ease-in-out;

  &[aria-expanded='true'] {
    opacity: 1;
  }
`;
