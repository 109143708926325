import React from 'react';
import ListWithRadioButton from '../common/ListWithRadioButton';
import {
  DENTOLO_DOCUMENT_TYPE,
  DENTOLO_TREATMENT_CATEGORY,
  DENTOLO_TREATMENT_CATEGORY_TEETH_CLEANING,
  DENTOLO_TREATMENT_DATE,
  DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
  DENTOLO_DOCUMENT_TYPE_INVOICE,
  useNewDentoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useSelectDocumentTypeDentoloBackButtonLink,
  useSelectDocumentTypeDentoloNextButtonLink,
} from './selectDocumentTypeDentoloHooks';

const documentTypeConfig = [
  {
    label: 'dentolo.new_claim.select_document_type.invoice',
    value: DENTOLO_DOCUMENT_TYPE_INVOICE,
  },
  {
    label: 'dentolo.new_claim.select_document_type.treatment_plan',
    value: DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
  },
];

const SelectDocumentTypeDentolo = () => {
  const [documentType, setDocumentType] = useNewDentoloFormDataValue(DENTOLO_DOCUMENT_TYPE);
  const [treatmentCategory] = useNewDentoloFormDataValue(DENTOLO_TREATMENT_CATEGORY);
  const [, /*treatmentDate*/ setTreatmentDate] = useNewDentoloFormDataValue(DENTOLO_TREATMENT_DATE);

  const backButtonLink = useSelectDocumentTypeDentoloBackButtonLink();
  const nextButtonLink = useSelectDocumentTypeDentoloNextButtonLink();

  const setDocumentTypeWithExtraSteps = (newDocumentType) => {
    setDocumentType(newDocumentType);

    if (newDocumentType === DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN) {
      setTreatmentDate('');
    }
  };

  let excludedDocumentTypes = [];
  if (treatmentCategory === DENTOLO_TREATMENT_CATEGORY_TEETH_CLEANING) {
    excludedDocumentTypes = [...excludedDocumentTypes, DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN];
  }

  const filteredDocumentTypeConfig = documentTypeConfig.filter(
    (config) => !excludedDocumentTypes.includes(config.value)
  );

  return (
    <ListWithRadioButton
      name="document_type_dentolo"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId={'dentolo.new_claim.select_document_type.page_title'}
      listConfig={filteredDocumentTypeConfig}
      selected={documentType}
      setSelected={setDocumentTypeWithExtraSteps}
    />
  );
};

export default SelectDocumentTypeDentolo;
