import styled, { css } from 'styled-components';
import PropTypes, { oneOfType } from 'prop-types';

import { color } from '../../theme/functions';
import { min_width_md } from '../../theme/breakpoints';

const StyledParagraph = styled.p`
  ${({ fontWeight, textAlign }) => css`
    margin-bottom: 1.5rem;
    font-size: 1rem;
    font-weight: ${fontWeight};
    color: ${color('main_blue')}
    text-align: ${textAlign};
    line-height: 24px;

    @media (min-width: ${min_width_md}) {
      font-size: 1.25rem;
    }
  `}
`;

StyledParagraph.defaultProps = {
  fontWeight: 450,
  textAlign: 'left',
};

StyledParagraph.propTypes = {
  fontWeight: oneOfType([PropTypes.number, PropTypes.string]),
  textAlign: PropTypes.string,
};

export { StyledParagraph as Paragraph };
