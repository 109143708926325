import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CheckIcon } from './';
import {
  StyledPolicyDetailsSectionTitle,
  StyledPolicyDetailsList,
  StyledPolicyDetailsText,
} from '../styled/PolicyDetails.styled';

const AdditionalAdvantages = ({ details }) => {
  if (!Array.isArray(details)) return null;

  return (
    <>
      <StyledPolicyDetailsSectionTitle>
        <FormattedMessage id="policy.covergae_info.additional_advantages.title" />
      </StyledPolicyDetailsSectionTitle>

      {details.map((item, index) => (
        <StyledPolicyDetailsList key={index}>
          <CheckIcon />

          <StyledPolicyDetailsText>{item}</StyledPolicyDetailsText>
        </StyledPolicyDetailsList>
      ))}
    </>
  );
};

export default AdditionalAdvantages;
