import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';

import { PAYMENT_FAILURE_STATUS, useReady } from '../shared';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { Container, Paper, Icon } from './common';
import { Button } from './ui';
import {
  StyledNotificationTitle,
  StyledPaymentStatusContainer,
  StyledPStatusIconContainer,
  StyledNotificationButton,
  StyledNotificationDescription,
} from './styled/PaymentStatus.styled';

import { PAYMENT_SUCCESS_STATUS, SET_BILLING_DAY, GET_CUSTOMER } from '../shared';
import { FormattedMessage } from 'react-intl';

const StatusBody = ({ status }) => {
  if (status === PAYMENT_SUCCESS_STATUS) {
    return (
      <>
        <StyledPStatusIconContainer>
          <Icon icon="notification.success" alt="success" />
        </StyledPStatusIconContainer>

        <StyledNotificationTitle>
          <FormattedMessage id="customer.details.digital.payment.success.title" />
        </StyledNotificationTitle>
      </>
    );
  }
  if (status === PAYMENT_FAILURE_STATUS) {
    return (
      <>
        <StyledPStatusIconContainer>
          <Icon icon="notification.warning" alt="success" />
        </StyledPStatusIconContainer>

        <StyledNotificationTitle>
          <FormattedMessage id="customer.details.digital.payment.failure.title" />
        </StyledNotificationTitle>
        <StyledNotificationDescription>
          <FormattedMessage id="customer.details.digital.payment.failure.desc" />
        </StyledNotificationDescription>
      </>
    );
  }
  return <></>;
};

const PaymentStatus = () => {
  const history = useHistory();
  const ready = useReady();
  const { status } = useParams();
  const { data: customerData } = useQuery(GET_CUSTOMER);
  const { customer } = customerData || {};
  const { key } = customer || {};
  const billingDayFromStorage = localStorage.getItem(`edit-payment-billing-day-${key}`);
  const [changeContractBillingDay] = useMutation(SET_BILLING_DAY, {
    variables: {
      billingDay: Number(billingDayFromStorage),
    },
  });
  const updateBillingDay = () => {
    changeContractBillingDay().then(() => {
      localStorage.removeItem(`edit-payment-billing-day-${key}`);
    });
  };

  if (status === PAYMENT_SUCCESS_STATUS && Number(billingDayFromStorage)) {
    // send the billing day from novalnet form page to the backend to set after the webhook received if the payment succeded
    updateBillingDay();
  }

  const handleStatusButton = () => {
    history.push(`/user-info`);
  };
  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <Paper>
          <StyledPaymentStatusContainer>
            <StatusBody status={status} />
            <Button variant="outline" onClick={handleStatusButton}>
              <StyledNotificationButton>
                <FormattedMessage id="customer.details.digital.payment.success.btn" />
              </StyledNotificationButton>
            </Button>
          </StyledPaymentStatusContainer>
        </Paper>
      </Container>
    </CSSTransition>
  );
};

export default PaymentStatus;
