import { useEffect, useState } from "react";
import { baseUrl, APP_ENV } from "../shared";

const useBackendBranchName = () => {
  const [backendBranchName, setBackendBranchName] = useState("");

  useEffect(() => {
    if (APP_ENV === "beta") {
      fetch(`${baseUrl}/current_branch`)
        .then((response) => response.json())
        .then((json) => {
          const deployedBranchName = json?.name;
          if (!!deployedBranchName) {
            setBackendBranchName(deployedBranchName);
          }
        })
        .catch(() => null);
    }
  }, []);

  return backendBranchName;
};

export default useBackendBranchName;
